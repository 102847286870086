import { get, post, put } from './httpClient.service';

const DEFAULT_ENTRY_POINT = 'payments-request';

export async function getPaymentsRequest(
  _start: number,
  _end: number,
  _sort: string,
  user: string,
  status: string,
  filters: {
    fromDate: string;
    toDate: string;
  }
): Promise<any> {
  return get(`${DEFAULT_ENTRY_POINT}`, {
    _start,
    _end,
    _sort,
    user,
    status,
    ...filters
  });
}
export async function getuserPaymentsRequest(
  _start: number,
  _end: number,
  _sort: string,
  user: string,
  status: string,
  filters: {
    fromDate: string;
    toDate: string;
  }
): Promise<any> {
  return get(`${DEFAULT_ENTRY_POINT}`, {
    _start,
    _end,
    _sort,
    user,
    status,
    ...filters
  });
}

export async function updatePaymentRequestStatus(
  hash,
  statusCode
): Promise<any> {
  return put(`${DEFAULT_ENTRY_POINT}/status/${hash}`, { statusCode });
}

export async function getXMLs(hashes): Promise<any> {
  return post(`${DEFAULT_ENTRY_POINT}/xml/unified`, { hashes }, 'blob');
}

export async function getZip(hashes): Promise<any> {
  return post(`${DEFAULT_ENTRY_POINT}/zip`, { hashes }, 'blob');
}
