import React from 'react';
import {
  Create,
  SimpleForm,
  useCreate,
  useNotify,
  useRecordContext
} from 'react-admin';
import { SportsForm } from '../sportsForm/SportsForm';
import { FormToolbar } from '../../../../components/shared/form/formToolbar/FormToolbar';
import { convertFileToBase64 } from '../../../../core/utils/file-utils';
import { useNavigate } from 'react-router-dom';

export const SportsCreate = () => {
  const [create, { isLoading, isError }] = useCreate();
  const notify = useNotify();
  const record = useRecordContext();
  const navigate = useNavigate();
  const getImage = async (data) => {
    if (data.icon?.rawFile instanceof File) {
      data.icon = await convertFileToBase64(data.icon);
    } else {
      data.icon =
        data.icon == null ? null : record.icon ? record.icon.title : null;
    }

    return data.icon;
  };

  const sportsSave = async (data) => {
    data.icon = await getImage(data);
    create(
      'sports',
      { data },
      {
        onSuccess: () => {
          notify(
            'resources.configuration.sports.notifications.create.success',
            {
              type: 'success'
            }
          );
          navigate(-1);
        },
        onError(error: any) {
          notify(error.message, { type: 'warning' });
        }
      }
    );
  };

  return (
    <>
      <Create resource="sports">
        <SimpleForm
          onSubmit={sportsSave}
          toolbar={<FormToolbar isLoading={isLoading} isError={isError} />}
          className={'form'}
        >
          <SportsForm />
        </SimpleForm>
      </Create>
    </>
  );
};
