import React from 'react';
import { TopToolbar } from 'react-admin';
import { Button } from '../../../../../../components/shared/buttons/Button';

export const LinkedTeacherListActions = ({ setSection, sectionCreate }) => {
  const goToCreate = () => {
    setSection(sectionCreate);
  };

  return (
    <TopToolbar>
      <Button
        onClick={goToCreate}
        label="resources.users.linkedTeachers.actions.create"
      ></Button>
    </TopToolbar>
  );
};
