import { get } from './httpClient.service';

const DEFAULT_ENTRY_POINT = 'lessons';

export async function getLessonsFilteredInEvents(lessonHash): Promise<any> {
  return get(`${DEFAULT_ENTRY_POINT}/${lessonHash}/events`, {});
}

export async function getLesson(lessonHash, deleted = false): Promise<any> {
  return get(`${DEFAULT_ENTRY_POINT}/${lessonHash}`, { deleted });
}
