import axiosInstance from './base/axios.client';
const DEFAULT_ENTRY_POINT = 'public';

export async function getCountries(): Promise<any> {
  const response = await axiosInstance.get(`${DEFAULT_ENTRY_POINT}/countries`);

  return response;
}

export async function getRegions(
  country: string,
  _start: number,
  _end: number,
  _sort: string
): Promise<any> {
  const response = await axiosInstance.get(`${DEFAULT_ENTRY_POINT}/regions`, {
    params: { country, _start, _end, _sort }
  });

  return response;
}

export async function getLatesTermAndCondition(
  privilege: string
): Promise<any> {
  const response = await axiosInstance.get(
    `${DEFAULT_ENTRY_POINT}/terms-and-conditions/${privilege}/latest`
  );

  return response;
}

export async function getLatesPrivacyPolicy(privilege: string): Promise<any> {
  const response = await axiosInstance.get(
    `${DEFAULT_ENTRY_POINT}/privacy-policies/${privilege}/latest`
  );

  return response;
}

export async function getCountry(countryHash: string): Promise<any> {
  const response = await axiosInstance.get(
    `${DEFAULT_ENTRY_POINT}/countries/${countryHash}`
  );

  return response;
}

export async function getRegion(regionHash: string): Promise<any> {
  const response = await axiosInstance.get(
    `${DEFAULT_ENTRY_POINT}/regions/${regionHash}`
  );

  return response;
}
