import React, { useState } from 'react';
import { Confirm, useDelete, useNotify } from 'react-admin';

export const DeleteCalendarEquipmentRentDialog = ({
  showDeleteDialog,
  setShowDeleteDialog,
  event,
  setOpenCourseEventDialog,
  deleteSeries,
  setRefresh
}) => {
  const [erase] = useDelete();
  const notify = useNotify();
  const [loading, setLoading] = useState(false);

  const onCloseDeleteDialog = () => {
    setShowDeleteDialog(false);
    setOpenCourseEventDialog(false);
  };

  const onDeleteCourseEvent = () => {
    if (deleteSeries) {
      onDeleteSeries();
    } else {
      onDeleteEvent();
    }
  };

  const onDeleteSeries = () => {
    setLoading(true);
    erase(
      'equipment-rent-events',
      {
        id: event.equipmentRentEventHash
      },
      {
        onSuccess: () => {
          notify(
            'resources.equipmentRentsCalendar.dialog.delete.actions.success',
            {
              type: 'success'
            }
          );
          setRefresh(true);
          onCloseDeleteDialog();
          setLoading(false);
        },
        onError(error: any) {
          notify(error.message, { type: 'warning' });
          setLoading(false);
        }
      }
    );
  };

  const onDeleteEvent = () => {
    setLoading(true);
    erase(
      'events',
      {
        id: event.id
      },
      {
        onSuccess: () => {
          notify(
            'resources.equipmentRentsCalendar.dialog.delete.actions.success',
            {
              type: 'success'
            }
          );
          setRefresh(true);
          onCloseDeleteDialog();
          setLoading(false);
        },
        onError(error: any) {
          notify(error.message, { type: 'warning' });
          setLoading(false);
        }
      }
    );
  };

  return (
    <Confirm
      loading={loading}
      title="resources.equipmentRentsCalendar.dialog.delete.actions.title"
      content="resources.equipmentRentsCalendar.dialog.delete.actions.message"
      onConfirm={onDeleteCourseEvent}
      isOpen={showDeleteDialog}
      onClose={onCloseDeleteDialog}
    />
  );
};
