import React from 'react';
import {
  required,
  SelectInput,
  SimpleForm,
  useNotify,
  useRecordContext,
  useRefresh
} from 'react-admin';
import { Invoice } from '../../../../core/models/invoice/invoice';
import {
  INVOICE_STATUS_EMITTED,
  INVOICE_STATUS_INVOICED,
  INVOICE_STATUS_PENDING
} from '../../../../core/constants/invoice.constants';
import { updateInvoiceStatus } from '../../../../core/services/invoice-api.service';

export const StatusField = (props: any) => {
  const record: Invoice = useRecordContext(props);
  const notify = useNotify();
  const refresh = useRefresh();

  const statusSave = (e) => {
    updateInvoiceStatus(record.id, e.target.value)
      .then(() => {
        notify('resources.invoicing.invoices.notifications.status.success', {
          type: 'success'
        });
        refresh();
      })
      .catch((error) => {
        notify(error.message, { type: 'warning' });
      });
  };

  const statusChoices = () => {
    return [
      {
        id: INVOICE_STATUS_PENDING,
        name: 'resources.invoicing.invoices.list.pending'
      },
      {
        id: INVOICE_STATUS_EMITTED,
        name: 'resources.invoicing.invoices.list.emitted'
      },
      {
        id: INVOICE_STATUS_INVOICED,
        name: 'resources.invoicing.invoices.list.invoiced'
      }
    ];
  };

  return (
    <SimpleForm toolbar={false} className="status-form">
      <SelectInput
        source="status"
        optionText="name"
        choices={statusChoices()}
        label={false}
        value={record.invoiceStatus.id}
        defaultValue={record.invoiceStatus.id}
        onChange={statusSave}
        helperText={false}
        validate={[required()]}
      ></SelectInput>
    </SimpleForm>
  );
};
