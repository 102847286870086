import axiosInstance, {
  getDefaultAnonymousRequestConfig,
  getDefaultAnonymousRequestConfigWithErrorHandling
} from './base/axios.client';
import { getSession, setSession } from './base/storage-service';

const DEFAULT_ENTRY_POINT = 'auth';

export async function recoverPasword(email: string): Promise<any> {
  const postBody = { email };
  const response = await axiosInstance.post(
    `${DEFAULT_ENTRY_POINT}/recover-password`,
    postBody,
    getDefaultAnonymousRequestConfigWithErrorHandling()
  );

  return response;
}

export async function resetPassword(
  hash,
  password,
  passwordRepeat
): Promise<any> {
  const postBody = { hash, password, passwordRepeat };
  const response = await axiosInstance.post(
    `${DEFAULT_ENTRY_POINT}/reset-password`,
    postBody,
    getDefaultAnonymousRequestConfig()
  );

  return response;
}

export async function generatePassword(
  hash,
  password,
  passwordRepeat
): Promise<any> {
  const postBody = { hash, password, passwordRepeat };
  const response = await axiosInstance.post(
    `${DEFAULT_ENTRY_POINT}/generate-password`,
    postBody,
    getDefaultAnonymousRequestConfig()
  );

  return response;
}

export async function refreshToken() {
  const refreshToken = getSession()?.refreshToken;
  const response = await axiosInstance.post(
    `${DEFAULT_ENTRY_POINT}/token?refresh_token=${refreshToken}`,
    null,
    getDefaultAnonymousRequestConfig()
  );
  if (response.status !== 200) {
    throw Error(response.statusText);
  }

  setSession(response.data);
}
