import { useEffect, useState } from 'react';
import { PRIVILEGE_ADMIN } from '../constants/privilege.constants';
import { useDataProvider } from 'react-admin';
import decodeJwt from 'jwt-decode';

export const UseTermsAndConditionsAndPrivacyPolicy = (permission: string) => {
  const dataProvider = useDataProvider();
  const [termAndCondition, setTermAndCondition] = useState(null);
  const [loadingTermAndCondition, setLoadingTermAndCondition] = useState(true);
  const [privacyPolicy, setPrivacyPolicy] = useState(null);
  const [loadingPrivacyPolicy, setLoadingPrivacyPolicy] = useState(true);

  const user: any = localStorage.getItem('token')
    ? decodeJwt(localStorage.getItem('token'))
    : null;

  useEffect(() => {
    if (!permission || permission === PRIVILEGE_ADMIN) {
      return;
    }

    dataProvider
      .getOne(`public/terms-and-conditions/${permission}`, { id: 'latest' })
      .then(({ data }) => {
        setTermAndCondition(data);
        setLoadingTermAndCondition(false);
      })
      .catch((error) => {
        setTermAndCondition(null);
        setLoadingTermAndCondition(false);
      });
    dataProvider
      .getOne(`public/privacy-policies/${permission}`, {
        id: 'latest'
      })
      .then(({ data }) => {
        setPrivacyPolicy(data);
      })
      .catch((error) => {
        setLoadingPrivacyPolicy(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permission]);

  const checkTermsAndConditionsAndPrivacyPolicy =
    user &&
    ((!user.isLastTermAndConditionAccepted && termAndCondition) ||
      (!user.isLastPrivacyPolicyAccepted && privacyPolicy)) &&
    permission !== PRIVILEGE_ADMIN;

  return [
    checkTermsAndConditionsAndPrivacyPolicy,
    loadingTermAndCondition,
    loadingPrivacyPolicy,
    termAndCondition,
    privacyPolicy
  ];
};
