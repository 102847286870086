import {
  AutocompleteArrayInput,
  BooleanInput,
  NumberInput,
  RadioButtonGroupInput,
  ReferenceArrayInput,
  ReferenceInput,
  required,
  SelectInput,
  TextInput,
  useRecordContext
} from 'react-admin';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Voucher } from '../../../../core/models/voucher/voucher';
import { InputRichTextField } from '../../form/InputRichTextField/InputRichTextField';

export const VoucherForm = (props) => {
  const record: Voucher = useRecordContext();
  const { setValue } = useFormContext();
  const lessonTypeNumber = 1;
  const lessonTypeTime = 2;
  const [category, setCategory] = useState<number>(lessonTypeNumber);

  useEffect(() => {
    if (record) {
      setCategory(
        record.lessonNumber !== null ? lessonTypeNumber : lessonTypeTime
      );
    }
  }, [record, setValue]);
  const handleCategoryChange = (e) => {
    const categoryNumber = Number(e.target.value);
    setCategory(categoryNumber);
    if (categoryNumber === lessonTypeNumber) {
      setValue('timeRangeType', null);
    }

    if (categoryNumber === lessonTypeTime) {
      setValue('lessonNumber', null);
    }
  };

  const categoryChoices = [
    {
      id: lessonTypeNumber,
      name: 'resources.users.vouchers.fields.lessonsNumber'
    },
    { id: lessonTypeTime, name: 'resources.users.vouchers.fields.time' }
  ];

  return (
    <div className="form-container">
      <TextInput
        className="full-line"
        source="title"
        validate={[required()]}
        label="resources.users.vouchers.fields.title"
      />
      <InputRichTextField
        size={['20']}
        validate={[required()]}
        source={'conditions'}
        label="resources.users.vouchers.fields.conditions"
      />
      <RadioButtonGroupInput
        className="full-line"
        source="category"
        validate={[required()]}
        choices={categoryChoices}
        onChange={(e) => handleCategoryChange(e)}
        label="resources.users.vouchers.fields.category"
        defaultValue={category}
      />
      <ReferenceArrayInput
        source="voucherLessonLevelPriceIds"
        reference={`users/${props.userHash}/lesson-level-prices`}
        isRequired={true}
      >
        <AutocompleteArrayInput
          label="resources.users.vouchers.fields.lessons"
          optionValue="id"
          isRequired={true}
          className={` ${category === lessonTypeTime ? 'hide' : ''}`}
          optionText={(lesson) =>
            `${lesson.lesson.title} - ${lesson.lessonLevelPriceResponse.time} - ${lesson.lessonLevelPriceResponse.price}€`
          }
        />
      </ReferenceArrayInput>
      <NumberInput
        source="lessonNumber"
        validate={[required()]}
        className={` ${category === lessonTypeTime ? 'hide' : ''}`}
        label="resources.users.vouchers.fields.lessonsNumber"
      />
      <ReferenceInput
        source="timeRangeType"
        reference="time-range-types"
        sort={{ field: 'name', order: 'ASC' }}
      >
        <SelectInput
          optionText="name"
          source="timeRangeType"
          defaultValue=""
          validate={[required()]}
          className={` ${category === lessonTypeNumber ? 'hide' : ''}`}
          label="resources.users.vouchers.fields.timeRange"
        />
      </ReferenceInput>
      <NumberInput
        source="price"
        validate={[required()]}
        label="resources.users.vouchers.fields.price"
      />
      <BooleanInput
        className="boolean-input"
        source="isEnabled"
        label="resources.users.vouchers.fields.disable"
      />
    </div>
  );
};
