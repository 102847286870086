import { DefaultEditorOptions, RichTextInput } from 'ra-input-rich-text';
import React from 'react';
import { InputRichTextFieldToolbar } from './InputRichTextFieldToolbar';

export const InputRichTextField = ({ size, validate, source, ...props }) => (
  <RichTextInput
    editorOptions={EditorOptions}
    toolbar={<InputRichTextFieldToolbar size={'1000'} />}
    label="resources.configuration.termsAndConditions.fields.text"
    source={source}
    validate={validate}
    {...props}
  />
);

export const EditorOptions = {
  ...DefaultEditorOptions,
  extensions: [...DefaultEditorOptions.extensions]
};
