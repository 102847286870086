import { patch } from './httpClient.service';

const DEFAULT_ENTRY_POINT = 'notifications';

export async function updateNotificationDeviceLang(lang, token): Promise<any> {
  return patch(
    `${DEFAULT_ENTRY_POINT}/device/language`,
    { token },
    { 'Accept-Language': lang }
  );
}
