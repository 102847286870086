import polyglotI18nProvider from 'ra-i18n-polyglot';
import { resolveBrowserLocale } from 'react-admin';
import en from './i18n/en';
import es from './i18n/es';

const translations = { en, es };

export const LANGS = [
  { code: 'es', label: 'Español' },
  { code: 'en', label: 'English' }
];

export const i18nProvider = polyglotI18nProvider(
  //@ts-ignore
  (locale) => {
    if (locale.startsWith('es')) {
      import('moment/locale/' + locale);
    } else {
      import('moment/locale/' + locale + '-gb');
    }

    return translations[locale];
  },
  resolveBrowserLocale(),
  { allowMissing: true, interpolation: { escape: false } }
);
