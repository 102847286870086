import React from 'react';
import { AutocompleteInput, Loading, required, useGetList } from 'react-admin';

export const FilterClub = (props) => {
  const { data: clubs, isLoading } = useGetList('users/clubs', {
    sort: { field: 'firstName', order: 'ASC' }
  });

  if (isLoading) {
    return <Loading />;
  }

  return (
    <AutocompleteInput
      source="filterClub"
      optionText="name"
      label="resources.users.fields.club"
      validate={[required()]}
      defaultValue=""
      choices={
        clubs.length > 0
          ? clubs.map((club) => ({
              id: club.id, // The value of the input
              name: club.firstName // The value of the input
            }))
          : []
      }
    />
  );
};
