import React from 'react';
import { CourseCreateShared } from '../../../../../../components/shared/courses/courseCreate/CourseCreateShared';

export const CourseCreate = ({
  setSection,
  sectionList,
  userHash,
  smallCourses,
  isClub
}) => {
  return (
    <CourseCreateShared
      setSection={setSection}
      sectionList={sectionList}
      userHash={userHash}
      smallCourses={smallCourses}
      isClub={isClub}
    />
  );
};
