import React, { useEffect } from 'react';
import {
  Loading,
  required,
  SelectInput,
  useGetList,
  useRecordContext,
  useTranslate
} from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { CitySelect } from './CitySelect';

export const RegionSelect = (props) => {
  const translate = useTranslate();
  const record = useRecordContext();
  const { setValue } = useFormContext();
  const [region, setRegion] = React.useState('');
  const [regions, setRegions] = React.useState([]);
  const { data: regionsData, isLoading: regionsIsLoading } = useGetList(
    'public/regions',
    {
      filter: {
        country: props.country
      },
      sort: { field: 'name', order: 'ASC' },
      pagination: { page: 1, perPage: 9999999 }
    }
  );

  useEffect(() => {
    setRegions(regionsData);

    if (record && record.region) {
      setRegion(record.region);
    }
  }, [props.country, regionsData]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleRegionChange = (value) => {
    setRegion(value);
    setValue('city', '');
  };

  const createEmptyChoice = () => {
    if (regions && regions.length > 0 && regions[0].id != null) {
      regions.unshift({
        id: null,
        name: ' '
      });
    }
  };

  if (regionsIsLoading) {
    return <Loading />;
  }

  if (!regionsIsLoading && regions && regions.length === 0) {
    return (
      <p className="error field--warning">
        {translate('resources.users.notifications.regionsNotFound')}
      </p>
    );
  }

  if (regions && regions.length > 0) {
    createEmptyChoice();

    return (
      <>
        <SelectInput
          optionText="name"
          source="region"
          label="resources.users.fields.region"
          validate={[required()]}
          choices={regions ?? []}
          defaultValue=""
          onChange={(event) => handleRegionChange(event.target.value)}
        />
        {region && <CitySelect region={region} />}
      </>
    );
  }
};
