import React from 'react';
import { useRecordContext } from 'react-admin';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton } from '@mui/material';
import { DialogDeleteLinkedClub } from './DialogDeleteLinkedClub';
import { UserParent } from '../../../../core/models/user/userParent';

export const LinkedClubDeleteButton = (props) => {
  const record: UserParent = useRecordContext(props);
  const [showDeleteDialog, setShowDeleteDialog] = React.useState(false);

  const handleDeleteLinkedClub = () => setShowDeleteDialog(true);

  return (
    <>
      <IconButton onClick={() => handleDeleteLinkedClub()}>
        <DeleteIcon />
      </IconButton>
      <DialogDeleteLinkedClub
        showDeleteDialog={showDeleteDialog}
        setShowDeleteDialog={setShowDeleteDialog}
        id={record.id}
      />
    </>
  );
};
