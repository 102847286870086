import { Grid, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import {
  ArrayInput,
  AutocompleteArrayInput,
  BooleanInput,
  FormDataConsumer,
  NumberInput,
  ReferenceArrayInput,
  ReferenceInput,
  SelectArrayInput,
  SelectInput,
  SimpleFormIterator,
  TextInput,
  minValue,
  required,
  useRecordContext,
  useTranslate
} from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { PAYMENT_TYPE_UNIQUE } from '../../../../core/constants/paymentType.constants';
import {
  USER_TYPE_ADULT,
  USER_TYPE_CHILD
} from '../../../../core/constants/userTypes.constants';
import { UserHashContext } from '../../../../core/context/userHashContext';
import { getLessonsFilteredInEvents } from '../../../../core/services/lesson-api.service';
import { getLinkedTeachers } from '../../../../core/services/userParent-api.service';
import { CheckPriceAdvice } from './CheckPriceAdvice';

export const LessonForm = (props) => {
  const adult = Number(USER_TYPE_ADULT);
  const child = Number(USER_TYPE_CHILD);
  const paymentUnique = PAYMENT_TYPE_UNIQUE;
  const translate = useTranslate();
  const [usertype, setUserType] = useState([child]);
  const record = useRecordContext();
  const { setValue, getValues } = useFormContext();
  const [showAdvice, setShowAdvice] = useState(false);
  const params = useParams();
  const userContext = useContext(UserHashContext);
  const [userHash, setUserHash] = useState(params.id || userContext.userHash);

  const [linkedTeachers, setLinkedTeachers] = useState<any>([]);

  useEffect(() => {
    if (props.isClub) {
      if (userHash) {
        getLinkedTeachers(0, 0, 'id', userHash).then((res) => {
          setLinkedTeachers(res.data);
        });
      } else if (params && params.id) {
        setUserHash(params.id);
        getLinkedTeachers(0, 0, 'id', params.id).then((res) => {
          setLinkedTeachers(res.data);
        });
      } else if (userContext) {
        setUserHash(userContext.userHash);
        getLinkedTeachers(0, 0, 'id', userContext.userHash).then((res) => {
          setLinkedTeachers(res.data);
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (record) {
      setUserType(record.userTypes);
    } else {
      setValue('userTypes', [child]);
      setValue('paymentType', paymentUnique);
    }
  }, [record, setValue, child, paymentUnique]);

  const getSource = (i: number) => {
    if (i === 0) return 'price';
  };

  const hideFromToYears = () => {
    if (
      (!usertype?.includes(child) && usertype?.includes(adult)) ||
      (usertype?.includes(adult) && usertype?.includes(child))
    ) {
      return 'hide';
    }

    return '';
  };

  const hideLevelType = () => {
    if (
      (usertype?.includes(child) && !usertype?.includes(adult)) ||
      (usertype?.includes(adult) && usertype?.includes(child))
    ) {
      return 'hide';
    }

    return '';
  };

  const getTabLabel = (levelArray, i) => {
    return `${levelArray[i].fromYears}- ${levelArray[i].toYears} ${translate(
      'resources.users.fields.lesson.years'
    )}`;
  };

  const handleUserTypeChange = (value) => {
    setUserType(value);
  };

  const getLessonInEvents = async () => {
    if (!record) {
      return;
    }

    const { data } = await getLessonsFilteredInEvents(record.id);
    data.forEach((lesson) => {
      setShowAdvice(false);
      lesson?.level?.[0]?.prices?.forEach((price, index) => {
        if (
          price.price !== null &&
          price.price !== getValues('price')[index].price
        ) {
          setShowAdvice(true);
          return;
        }
      });
    });
  };

  const getFullName = (firstName: string, lastName: string) => {
    return `${firstName ?? ''} ${lastName ?? ''}`;
  };

  return (
    <div className="lesson-form">
      {props.isClub && record && !!record?.userHash ? (
        <Typography component="h1" textAlign={'center'} margin={'1rem'}>
          {getFullName(record?.userFirstName, record?.userLastName)}
        </Typography>
      ) : null}

      {props.isClub && !record ? (
        <SelectInput
          optionText={(record) =>
            getFullName(record.userName, record.userLastFirstName)
          }
          source="userHash"
          choices={linkedTeachers}
          defaultValue=""
          optionValue="userHash"
          validate={[required()]}
          label="resources.users.fields.lesson.teacher"
        ></SelectInput>
      ) : null}

      <TextInput
        source="title"
        validate={[required()]}
        label="resources.users.fields.lesson.title"
      />
      <ReferenceInput
        source="userTypes"
        reference="user-types"
        sort={{ field: 'name', order: 'ASC' }}
      >
        <SelectArrayInput
          optionText="name"
          source="userTypes"
          defaultValue={usertype}
          validate={[required()]}
          onChange={(e) => handleUserTypeChange(e.target.value)}
          label="resources.users.fields.lesson.userType"
        />
      </ReferenceInput>
      <div className="level-wrap">
        <ArrayInput source="level" label="">
          <SimpleFormIterator
            disableAdd
            disableRemove
            disableReordering
            className="level"
          >
            <NumberInput
              source="fromYears"
              label="resources.users.fields.lesson.fromYears"
              className={hideFromToYears()}
            />
            <NumberInput
              source="toYears"
              label="resources.users.fields.lesson.toYears"
              className={hideFromToYears()}
            />
            <ReferenceInput
              source="levelType"
              reference="level-types"
              sort={{ field: 'name', order: 'ASC' }}
              className={'level-type'}
            >
              <SelectInput
                optionText="name"
                source="levelType"
                defaultValue=""
                validate={[required()]}
                className={hideLevelType()}
                label="resources.users.fields.lesson.level"
              />
            </ReferenceInput>
          </SimpleFormIterator>
        </ArrayInput>
      </div>

      <NumberInput
        source="minimumStudents"
        label="resources.users.fields.lesson.minimumStudents"
      />
      <NumberInput
        source="maximumStudents"
        label="resources.users.fields.lesson.maximumStudents"
      />

      <ReferenceArrayInput
        source="lessonEquipmentRentIds"
        reference={`users/${props.userHash}/equipment-rents`}
        isRequired={true}
      >
        <AutocompleteArrayInput
          className="autocomplete-array"
          label="resources.users.fields.lesson.material"
          optionValue="id"
          optionText="name"
        />
      </ReferenceArrayInput>

      <FormDataConsumer>
        {({ formData }) => {
          const levelArray = Array.isArray(formData.level)
            ? formData.level
            : [formData.level];

          return (
            <div className="tabs tabs--form">
              <div className="tabList">
                {levelArray?.map((el, i) => (
                  <div
                    key={i}
                    className="tabTitle"
                    hidden={
                      levelArray[i].fromYears == null ||
                      levelArray[i].toYears == null
                    }
                  >
                    {getTabLabel(levelArray, i)}
                  </div>
                ))}
              </div>
              {levelArray?.map((el, i) => (
                <div key={i} className="tabPanel">
                  <ArrayInput source={getSource(i)} label="">
                    <SimpleFormIterator
                      disableAdd
                      disableRemove
                      disableReordering
                    >
                      <TextInput source="time" label="" disabled />
                      <TextInput
                        source="time"
                        label={false}
                        className="hidden"
                      />
                      <TextInput
                        source="value"
                        label={false}
                        className="hidden"
                      />
                      <NumberInput
                        source="price"
                        label=""
                        className="lesson-input-price"
                        onChange={(e) => getLessonInEvents()}
                      />
                      <p>
                        {translate('resources.users.fields.lesson.lessonPrice')}
                      </p>
                      <BooleanInput source="enabled" label="" />
                    </SimpleFormIterator>
                  </ArrayInput>
                </div>
              ))}
            </div>
          );
        }}
      </FormDataConsumer>

      <ReferenceInput
        source="paymentType"
        reference="payments"
        sort={{ field: 'name', order: 'ASC' }}
      >
        <SelectInput
          optionText="name"
          source="paymentType"
          defaultValue={paymentUnique}
          validate={[required()]}
          disabled={true}
          label="resources.users.fields.lesson.paymentType"
        />
      </ReferenceInput>
      <ReferenceInput
        source="lessonType"
        reference="lessons/lesson-types"
        sort={{ field: 'name', order: 'ASC' }}
      >
        <SelectInput
          optionText="name"
          source="lessonType"
          defaultValue=""
          validate={[required()]}
          label="resources.users.fields.lesson.lessonType"
        />
      </ReferenceInput>
      <Grid container spacing={1} style={{ width: '100%' }}>
        <Grid item style={{ width: '100%' }}>
          <Typography variant="h6" align="center" style={{ width: '100%' }}>
            {translate('resources.users.fields.lesson.minimumNotice')}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <NumberInput
            source="minimumNoticeDays"
            validate={minValue(1)}
            label="resources.users.fields.lesson.minimumNoticeDays"
          />
        </Grid>
        <Grid item xs={6}>
          <NumberInput
            source="minimumNoticeHours"
            label="resources.users.fields.lesson.minimumNoticeHours"
          />
        </Grid>
      </Grid>

      <NumberInput
        source="freeCancellationPeriod"
        validate={[required()]}
        label="resources.users.fields.lesson.freeCancellationPeriod"
      />
      <BooleanInput
        source="isEnabled"
        className={'boolean-input'}
        label="resources.users.fields.lesson.disable"
      />
      {showAdvice && <CheckPriceAdvice />}
    </div>
  );
};
