import {
  VALIDATION_IS_GREATHER,
  VALIDATION_POSITIVE_NUMBER,
  VALIDATION_REQUIRED
} from '../../../../core/constants/validation.constants';
import { isObject } from '../../../../core/utils/object-utils';
import { VALIDATION_MORE_THAN_ZERO } from './../../../../core/constants/validation.constants';

export function lessonFormValidator(values: any) {
  const errors: any = {};
  if (Array.isArray(values.level)) {
    errors.level = values.level?.map((level) => {
      const levelErrors: any = {};
      if (
        (level != null && level.fromYears > level.toYears) ||
        level.fromYears < 0 ||
        level.toYears < 0
      ) {
        levelErrors.fromYears = VALIDATION_IS_GREATHER;
      }

      if (level.fromYears < 0) {
        levelErrors.fromYears = VALIDATION_MORE_THAN_ZERO;
      }

      if (level.toYears < 0) {
        levelErrors.toYears = VALIDATION_MORE_THAN_ZERO;
      }
      return levelErrors;
    });
  }

  if (Array.isArray(values.price)) {
    errors.price = values.price?.map((price) => {
      const pricesErrors: any = {};

      if (price.price < 0 && typeof price.price == 'number') {
        pricesErrors.price = VALIDATION_POSITIVE_NUMBER;
      }

      if (!price.price && isObject(price.price) && price.enabled) {
        pricesErrors.price = VALIDATION_REQUIRED;
      }

      return pricesErrors;
    });
  }

  if (
    (values.minimumNoticeHours <= 0 && values.minimumNoticeDays === null) ||
    (values.minimumNoticeDays <= 0 && values.minimumNoticeHours <= 0)
  ) {
    errors.minimumNoticeHours = VALIDATION_MORE_THAN_ZERO;
  }

  if (values.minimumNoticeHours < 0 && values.minimumNoticeDays !== null) {
    errors.minimumNoticeHours = VALIDATION_POSITIVE_NUMBER;
  }

  if (values.minimumNoticeDays < 0 && values.minimumNoticeHours !== null) {
    errors.minimumNoticeDays = VALIDATION_POSITIVE_NUMBER;
  }

  if (
    values.minimumNoticeDays <= 0 &&
    values.minimumNoticeDays !== null &&
    values.minimumNoticeHours === null
  ) {
    errors.minimumNoticeDays = VALIDATION_MORE_THAN_ZERO;
  }

  if (!values.title) {
    errors.title = VALIDATION_REQUIRED;
  }

  if (!values.userTypes) {
    errors.userTypes = VALIDATION_REQUIRED;
  }

  if (!values.paymentType) {
    errors.paymentType = VALIDATION_REQUIRED;
  }

  if (!values.lessonType) {
    errors.lessonType = VALIDATION_REQUIRED;
  }

  if (
    !values.freeCancellationPeriod &&
    isObject(values.freeCancellationPeriod)
  ) {
    errors.freeCancellationPeriod = VALIDATION_REQUIRED;
  }

  if (
    values.freeCancellationPeriod < 0 &&
    typeof values.freeCancellationPeriod == 'number'
  ) {
    errors.freeCancellationPeriod = VALIDATION_POSITIVE_NUMBER;
  }

  if (values.minimumStudents < 0 && typeof values.minimumStudents == 'number') {
    errors.minimumStudents = VALIDATION_POSITIVE_NUMBER;
  }

  if (values.maximumStudents < 0 && typeof values.maximumStudents == 'number') {
    errors.maximumStudents = VALIDATION_POSITIVE_NUMBER;
  }

  if (values.userTypes.length === 0) {
    errors.userTypes = VALIDATION_REQUIRED;
  }

  return errors;
}
