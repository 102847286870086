import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import React, { useState } from 'react';
import {
  RadioButtonGroupInput,
  SimpleForm,
  usePermissions,
  useRecordContext,
  useTranslate
} from 'react-admin';
import {
  PRIVILEGE_ADMIN,
  PRIVILEGE_TEACHER
} from '../../../../../../core/constants/privilege.constants';
import { DialogDeleteEquipmentRentEvent } from '../DialogDeleteEquipmentRentEvent';
import { CalendarEquipmentRentDialogGroupedEventToolbar } from './CalendarEquipmentRentDialogGroupedEventToolbar';

export const CalendarEquipmentRentEventDialogGroupedEvent = (props) => {
  const onCloseDialog = () => {
    props.setOpenDialog(false);
  };

  const translate = useTranslate();
  const record = useRecordContext();
  const permissions = usePermissions();

  const isTeacher = permissions.permissions === PRIVILEGE_TEACHER;
  const isAdmin = permissions.permissions === PRIVILEGE_ADMIN;

  const formattedEnd = props.event?.end?.toLocaleString();
  const formattedStart = props.event?.start?.toLocaleString();
  const teacher = `${props.event?.userName} ${props.event?.userLastName}`;
  const [group, setGroup] = useState(null);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const findEventFromChoice = (id) => {
    if (props.event.group.length === 0) return null;
    return props.event.group.find((event) => event.id === id);
  };

  const onDeleteEvent = () => {
    setShowDeleteDialog(true);
  };

  const createChoicesFromGroup = () => {
    const choices = [];
    if (props.event && Array.isArray(props.event.group)) {
      props.event.group.forEach((event) => {
        choices.push({
          id: event?.id,
          name: `${event?.eventTitle} - ${event?.userName} ${event?.userLastName}`
        });
      });
    }
    return choices;
  };

  return (
    <>
      <Dialog fullWidth open={props.openDialog} onClose={onCloseDialog}>
        <DialogTitle>{props.event?.modalTitle}</DialogTitle>
        <DialogContent className="event-dialog">
          {isAdmin && (
            <p hidden={!record.isClub}>
              {translate('resources.users.calendar.lessonEvent.club')}
              {record.firstName}
            </p>
          )}
          <p hidden={isTeacher}>
            {translate('resources.users.calendar.lessonEvent.teacher')}
            {teacher}
          </p>
          <p>
            {translate('resources.users.calendar.lessonEvent.start')}
            {formattedStart.slice(0, -3)}
          </p>
          <p>
            {translate('resources.users.calendar.lessonEvent.end')}
            {formattedEnd.slice(0, -3)}
          </p>
          <SimpleForm
            className={'form'}
            toolbar={
              <CalendarEquipmentRentDialogGroupedEventToolbar
                onDeleteEvent={onDeleteEvent}
                group={group}
              />
            }
          >
            <RadioButtonGroupInput
              label="resources.users.calendar.lessonEvent.group.title"
              source="group"
              choices={createChoicesFromGroup()}
              onChange={(e) => {
                setGroup(e?.target?.value);
              }}
              className="radiogroup radiogroup-modal"
            ></RadioButtonGroupInput>
          </SimpleForm>
        </DialogContent>
      </Dialog>
      <DialogDeleteEquipmentRentEvent
        event={findEventFromChoice(group)}
        showDeleteDialog={showDeleteDialog}
        setShowDeleteDialog={setShowDeleteDialog}
        setOpenEquipmentRentEventDialog={props.setOpenDialog}
        setRefresh={props.setRefresh}
      />
    </>
  );
};
