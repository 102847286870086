import React from 'react';
import { useRecordContext } from 'react-admin';
import { UserParent } from '../../../../core/models/user/userParent';
import { Button } from '../../buttons/Button';
import { DialogLinkClub } from './DialogLinkClub';

export const LinkedClubLinkedButton = (props) => {
  const record: UserParent = useRecordContext(props);
  const [showLinkDialog, setShowLinkDialog] = React.useState(false);

  const handleLinkLinkedClub = () => setShowLinkDialog(true);

  return (
    <>
      {record.status.isRequired && (
        <Button
          label="resources.users.linkedClubs.list.linkButton"
          onClick={() => handleLinkLinkedClub()}
        ></Button>
      )}
      <DialogLinkClub
        showLinkDialog={showLinkDialog}
        setShowLinkDialog={setShowLinkDialog}
        id={record.id}
      />
    </>
  );
};
