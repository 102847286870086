import { Tooltip, TooltipProps, styled, tooltipClasses } from '@mui/material';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import {
  CheckboxGroupInput,
  DateInput,
  NumberInput,
  RadioButtonGroupInput,
  ReferenceInput,
  SelectInput,
  TimeInput,
  maxValue,
  required,
  usePermissions,
  useTranslate
} from 'react-admin';
import { Views } from 'react-big-calendar/dist/react-big-calendar.esm';
import { useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import {
  PRIVILEGE_ADMIN,
  PRIVILEGE_CLUB
} from '../../../../../core/constants/privilege.constants';
import {
  TIME_TYPE_DAY,
  TIME_TYPE_MONTH,
  TIME_TYPE_WEEK
} from '../../../../../core/constants/timeType.constants';
import { UserHashContext } from '../../../../../core/context/userHashContext';
import {
  formatMillis,
  getCurrentDate,
  getMillis
} from '../../../../../core/utils/date.utils';
import { CourseForm } from '../../../courses/courseForm/CourseForm';
import { LessonForm } from '../../../lessons/lessonForm/LessonForm';

type CalendarAddEventDialogFormType = {
  slotInfo;
  isAvailability;
  isLesson;
  isFullLesson;
  isCourse;
  isSmallCourses;
  id;
  record;
  linkedTeachers;
  view;
  isClub;
  fromTabs;
  selectedUsers;
};

export const CalendarAddEventDialogForm = (
  props: CalendarAddEventDialogFormType
) => {
  let { userHash } = useContext(UserHashContext);
  const { setValue, getValues } = useFormContext();
  const translate = useTranslate();
  const permissions = usePermissions();
  const params = useParams();
  const isTeacher = params?.privilege === 'teachers';
  const isAdmin = permissions.permissions === PRIVILEGE_ADMIN;
  const isClub =
    permissions.permissions === PRIVILEGE_CLUB || props.record?.isClub;
  const [category, setCategory] = useState(
    isClub ? props.isLesson : props.isAvailability
  );
  const defaultTimeType = TIME_TYPE_DAY;
  const [selectedTimeType, setSelectedTimeType] =
    useState<number>(defaultTimeType);
  const currentYear = getCurrentDate();
  currentYear.setFullYear(currentYear.getFullYear() + 1);
  const date = formatMillis('yyyy-MM-DD', getMillis(currentYear));
  const [smallCourses, setSmallCourses] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const iterateEndDate = 1;
  const iterateEndIterations = 2;
  const choices = [
    {
      id: iterateEndDate,
      name: 'resources.users.calendar.dialog.fields.afterDate'
    },
    {
      id: iterateEndIterations,
      name: 'resources.users.calendar.dialog.fields.afterIterations'
    }
  ];
  const [iterateEndType, setIterateEndType] = useState<number>(iterateEndDate);

  userHash = isAdmin ? props.record?.id : userHash;

  const categoryChoices = [
    {
      id: props.isAvailability,
      name: 'resources.users.calendar.dialog.fields.availability',
      disabled: isClub
    },
    {
      id: props.isLesson,
      name: 'resources.users.calendar.dialog.fields.lesson'
    },
    {
      id: props.isFullLesson,
      name: 'resources.users.calendar.dialog.fields.fullLesson'
    },
    {
      id: props.isCourse,
      name: 'resources.users.calendar.dialog.fields.course'
    },
    {
      id: props.isSmallCourses,
      name: 'resources.users.calendar.dialog.fields.smallCourse'
    }
  ];

  const clubCategoryChoices = [
    {
      id: props.isLesson,
      name: 'resources.users.calendar.dialog.fields.lesson'
    },
    {
      id: props.isFullLesson,
      name: 'resources.users.calendar.dialog.fields.fullLesson'
    },
    {
      id: props.isCourse,
      name: 'resources.users.calendar.dialog.fields.course'
    },
    {
      id: props.isSmallCourses,
      name: 'resources.users.calendar.dialog.fields.smallCourse'
    }
  ];

  useEffect(() => {
    const date = new Date();
    const dateStart = props.slotInfo.start;
    const dateEnd = props.slotInfo.end;

    if (props.view === Views.MONTH) {
      if (props.slotInfo.start && props.slotInfo.start.getHours() === 0) {
        dateStart.setHours(date.getHours());
        dateStart.setMinutes(0);
      }

      if (props.slotInfo.end && props.slotInfo.end.getHours() === 0) {
        dateEnd.setHours(date.getHours());
        dateEnd.setMinutes(30);
      }
    }

    setStartDate(dateStart);
    setEndDate(dateEnd);

    setValue('startDate', dateStart);
    setValue('endDate', dateEnd);
  }, [props.slotInfo, props.slotInfo.start, props.slotInfo.end, props.view]); // eslint-disable-line react-hooks/exhaustive-deps

  const onChangeCategory = (value) => {
    setCategory(value);
    if (value === props.isCourse) {
      setSmallCourses(false);
    }

    if (value === props.isSmallCourses) {
      setSmallCourses(true);
    }
    resetValues();
  };

  const onChangeIterationDateEnd = (value) => {
    if (value) {
      setValue('iterationEnd', null);
    }
  };

  const onChangeIterationNumberEnd = (value) => {
    if (value) {
      setValue('iterationDateEnd', null);
    }
  };

  const handleIterationEndTypeChange = (e) => {
    const categoryNumber = Number(e.target.value);
    setIterateEndType(categoryNumber);
    if (categoryNumber === iterateEndDate) {
      setValue('iterationEnd', null);
    }

    if (categoryNumber === iterateEndIterations) {
      setValue('iterationEndDate', null);
    }
  };

  const onChangeSelectedTimeType = (value: number) => {
    setSelectedTimeType(value);
    if (value === TIME_TYPE_DAY) {
      setValue('dayTypes', null);
    }
  };

  const resetValues = () => {
    onChangeSelectedTimeType(defaultTimeType);
    setValue('timeType', defaultTimeType);
    setValue('itearationType', iterateEndDate);
    setIterateEndType(iterateEndDate);
    setValue('iterationEnd', null);
    setValue('iterationDateEnd', null);
  };

  const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: 'black',
      fontSize: 12,
      border: '1px solid #0F2C5E',
      width: '56%',
      borderRadius: '10px'
    }
  }));

  return (
    <>
      <RadioButtonGroupInput
        className={'category'}
        source="category"
        label="resources.users.calendar.dialog.fields.category"
        choices={isClub ? clubCategoryChoices : categoryChoices}
        defaultValue={isClub ? props.isLesson : props.isAvailability}
        onChange={(e) => onChangeCategory(e.target.value)}
      />

      {(category === props.isAvailability ||
        category === props.isLesson ||
        category === props.isFullLesson) && (
        <>
          <div className="time">
            <TimeInput
              source="startDate"
              label="resources.users.calendar.dialog.fields.start"
            />
            <TimeInput
              source="endDate"
              label="resources.users.calendar.dialog.fields.end"
            />
          </div>
          {category === props.isLesson && (
            <>
              <ReferenceInput
                source="lesson"
                reference={`users/${props.id}/lessons`}
                sort={{ field: 'title', order: 'ASC' }}
                filter={{
                  parentHash: isClub ? userHash : null,
                  parentInfo: isClub && isTeacher,
                  userHashes: props.selectedUsers
                }}
              >
                <SelectInput
                  optionText={(record: any) =>
                    record.title + ' - ' + record.userFirstName
                  }
                  source="lesson"
                  defaultValue=""
                  validate={[required()]}
                  label="resources.users.calendar.dialog.fields.lesson"
                />
              </ReferenceInput>
            </>
          )}
          <div
            style={
              selectedTimeType === TIME_TYPE_DAY ? { display: 'none' } : {}
            }
          >
            <ReferenceInput
              source="dayTypes"
              reference="day-types"
              sort={{ field: 'name', order: 'ASC' }}
              defaultValue={defaultTimeType}
            >
              <CheckboxGroupInput
                className={'dayTypes'}
                optionText="shortName"
                source="dayTypes"
                label="resources.users.calendar.dialog.fields.dayType"
              />
            </ReferenceInput>
          </div>
          <div className="repeat">
            <label>
              {translate('resources.users.calendar.dialog.fields.iteration')}
            </label>
            <div
              className="time"
              style={{ alignItems: 'center', width: '97%' }}
            >
              <SelectInput
                optionText="name"
                source="timeType"
                defaultValue={defaultTimeType}
                validate={[required()]}
                label=""
                onChange={(e) => {
                  onChangeSelectedTimeType(e.target.value);
                }}
                choices={[
                  {
                    id: TIME_TYPE_DAY,
                    name: 'resources.users.calendar.dialog.fields.day'
                  },
                  {
                    id: TIME_TYPE_WEEK,
                    name: 'resources.users.calendar.dialog.fields.week'
                  },
                  {
                    id: TIME_TYPE_MONTH,
                    name: 'resources.users.calendar.dialog.fields.month'
                  }
                ]}
                style={{ marginRight: 20 }}
              />
              <StyledTooltip
                title={
                  <Fragment>
                    <p>
                      {translate(
                        'resources.users.calendar.dialog.tooltip.repeatEachMonth'
                      )}
                      <b>
                        {translate(
                          'resources.users.calendar.dialog.tooltip.onlyForWeek'
                        )}
                      </b>
                      {translate(
                        'resources.users.calendar.dialog.tooltip.days'
                      )}
                    </p>
                    <p>
                      {translate(
                        'resources.users.calendar.dialog.tooltip.toRepeat'
                      )}
                      <b>
                        {translate(
                          'resources.users.calendar.dialog.tooltip.all'
                        )}
                      </b>
                      {translate(
                        'resources.users.calendar.dialog.tooltip.pleaseSelect'
                      )}
                      <b>
                        {translate(
                          'resources.users.calendar.dialog.tooltip.week'
                        )}
                      </b>
                      ".
                    </p>
                  </Fragment>
                }
                placement="right-end"
                hidden={selectedTimeType !== TIME_TYPE_MONTH}
              >
                <p style={{ paddingBottom: 20 }}>
                  {translate('resources.users.calendar.dialog.tooltip.title')}
                </p>
              </StyledTooltip>
            </div>
          </div>
          <RadioButtonGroupInput
            source="itearationType"
            value={iterateEndType}
            validate={[required()]}
            className="radiogroup"
            label="resources.users.calendar.dialog.fields.end"
            choices={choices}
            onChange={(e) => handleIterationEndTypeChange(e)}
            defaultValue={choices[0].id}
          />
          <DateInput
            source="iterationDateEnd"
            label=""
            validate={maxValue(date, 'validation.dateOneYearOrLess')}
            onChange={(e) => onChangeIterationDateEnd(e.target.value)}
            defaultValue={null}
            className={iterateEndType === iterateEndIterations && 'hide'}
          />
          <NumberInput
            source="iterationEnd"
            label="resources.users.calendar.dialog.fields.afterIterations"
            onChange={(e) => onChangeIterationNumberEnd(e.target.value)}
            className={iterateEndType === iterateEndDate && 'hide'}
          />
        </>
      )}

      {category !== props.isAvailability && (
        <ReferenceInput
          source="room"
          reference={`users/${userHash}/rooms`}
          sort={{ field: 'name', order: 'ASC' }}
          filter={{ lesson: getValues('lesson') }}
        >
          <SelectInput
            optionText="name"
            source="room"
            defaultValue=""
            validate={[]}
            label="resources.users.calendar.dialog.fields.room"
          />
        </ReferenceInput>
      )}

      {(category === props.isSmallCourses || category === props.isCourse) && (
        <CourseForm
          smallCourses={smallCourses}
          startDate={startDate}
          endDate={endDate}
          isClub={props.record?.isClub || (isClub && !props.fromTabs)}
        />
      )}
      {category === props.isFullLesson && (
        <LessonForm
          isClub={props.record?.isClub || (isClub && !props.fromTabs)}
          userHash={userHash}
        />
      )}
    </>
  );
};
