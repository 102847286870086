import React, { useContext, useEffect, useState } from 'react';
import { HeaderTitleContext } from '../../../../core/context/headerTitleContext';
import { Datagrid, FunctionField, List, TextField } from 'react-admin';
import { CustomPagination } from '../../../../components/general/Pagination';
import { UserSport } from '../../../../core/models/userSport/userSport';
import { UserLinkedSportsListActions } from './UserLinkedSportsListActions';
import { UserLinkedSportsEmptyList } from './UserLinkedSportsEmptyList';
import { DialogAddUserLinkedSports } from './DialogAddUserLinkedSports';
import { UserLinkedSportsDeleteButton } from './UserLinkedSportsDeleteButton';
import { UserHashContext } from '../../../../core/context/userHashContext';

export const UserLinkedSportsList = () => {
  const { setHeaderTitle } = useContext(HeaderTitleContext);
  const [showDialog, setShowDialog] = useState(false);
  const { userHash } = useContext(UserHashContext);

  useEffect(() => {
    setHeaderTitle('resources.configuration.sports.title');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <List
        resource="users/sports"
        sort={{ field: 'sport.name', order: 'DESC' }}
        exporter={false}
        actions={
          <UserLinkedSportsListActions
            showDialog={showDialog}
            setShowDialog={setShowDialog}
          />
        }
        perPage={Number(process.env.REACT_APP_LIST_SIZE)}
        pagination={<CustomPagination />}
        empty={
          <UserLinkedSportsEmptyList
            buttonLabel={'resources.configuration.sports.list.actions.create'}
            message={'resources.list.empty.title'}
            setShowDialog={setShowDialog}
          />
        }
      >
        <Datagrid bulkActionButtons={false}>
          <TextField
            source="sport.name"
            sortBy="sport.name"
            label={'resources.configuration.sports.fields.sport'}
            emptyText="-"
          />
          <FunctionField
            label="resources.configuration.sports.fields.icon"
            sortable={false}
            render={(record: UserSport) => (
              <img alt="" width="20" src={record.sport.icon.src} />
            )}
          />
          <UserLinkedSportsDeleteButton
            isFavouriteSport={false}
            userHash={userHash}
          />
        </Datagrid>
      </List>
      <DialogAddUserLinkedSports
        showDialog={showDialog}
        setShowDialog={setShowDialog}
      />
    </>
  );
};
