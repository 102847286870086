import React from 'react';
import { useLocation } from 'react-router-dom';
import { UserVouchersList } from './userVouchersList/UserVouchersList';

export const UserVoucherOverview = () => {
  const location = useLocation();
  const parts = location.pathname.split('/');
  const userHash = parts.pop();

  return <UserVouchersList userHash={userHash} />;
};
