import React from 'react';
import { useListContext, usePermissions, useTranslate } from 'react-admin';
import XLSX from 'xlsx';
import { Button } from '../../../../components/shared/buttons/Button';
import {
  PRIVILEGE_ADMIN,
  PRIVILEGE_CLUB,
  PRIVILEGE_TEACHER
} from '../../../../core/constants/privilege.constants';
import { getGlobalOperations } from '../../../../core/services/globalOperation-api.service';
import { getCurrentMillis } from '../../../../core/utils/date.utils';
import { round } from '../../../../core/utils/number-utils';

export const GlobalOperationsExport = ({ userHash, setIsLoading }) => {
  const translate = useTranslate();
  const permissions = usePermissions();
  const isTeacher = permissions.permissions === PRIVILEGE_TEACHER;
  const isClub = permissions.permissions === PRIVILEGE_CLUB;
  const isAdmin = permissions.permissions === PRIVILEGE_ADMIN;
  const { filterValues, sort } = useListContext();
  const getDate = (date: number) => {
    return date ? new Date(date).toLocaleDateString() : '';
  };

  const onExport = async () => {
    setIsLoading(true);
    const recordsToExport = await getGlobalOperations(
      0,
      0,
      sort.field,
      filterValues.user,
      isAdmin ? undefined : isTeacher ? userHash : filterValues.teacher,
      isAdmin ? undefined : isClub ? userHash : filterValues.club,
      filterValues
    ).then((data) => {
      return data.data;
    });
    const exports = recordsToExport.map((globalOperation) => {
      const {
        id,
        user,
        teacher,
        title,
        sport,
        club,
        voucher,
        bookingStatus,
        booking,
        price,
        equipmentRent,
        feeImport,
        teacherFee,
        teacherPayment,
        bookingHash,
        ...globalOperationForExport
      } = globalOperation; // omit

      globalOperationForExport.student = `${user && user?.firstName} ${
        user?.lastName || ''
      }`;
      globalOperationForExport.sport = sport?.name || '';
      globalOperationForExport.teacher = teacher
        ? `${teacher && teacher?.firstName} ${
            teacher?.lastName ? teacher?.lastName : ''
          }`
        : '';
      globalOperationForExport.title =
        globalOperationForExport.type?.toLowerCase() ===
        translate(
          'resources.invoicing.globalOperations.types.voucher'
        )?.toLowerCase()
          ? voucher.title
          : title;
      globalOperationForExport.price = round(price);
      globalOperation.fee =
        isClub || isTeacher ? round(teacherFee) : round(feeImport);
      globalOperationForExport.fee = globalOperation.fee;
      globalOperationForExport.status = bookingHash ? bookingStatus.name : '';
      if (!isClub) globalOperationForExport.club = club?.firstName;
      if (!(isClub || isTeacher)) {
        globalOperationForExport.teacherFee = round(teacherFee);
        globalOperationForExport.teacherPayment = round(teacherPayment);
      }
      globalOperationForExport.bookingDate = getDate(
        globalOperationForExport.bookingDate
      );
      globalOperationForExport.lessonDate = getDate(
        globalOperationForExport.lessonDate
      );
      globalOperationForExport.paymentDate = getDate(
        globalOperationForExport.paymentDate
      );
      globalOperationForExport.vat = round(teacherFee * 0.21);
      globalOperationForExport.publicPrice = round(
        globalOperationForExport.publicPrice
      );
      return globalOperationForExport;
    });

    Promise.all(exports).then((data) => {
      // eslint-disable-next-line array-callback-return
      data.map((value, index) => {
        renameKeys(
          value,
          'serialCode',
          translate('resources.invoicing.globalOperations.list.serialCode')
        );
        renameKeys(
          value,
          'paymentIntentId',
          translate('resources.invoicing.globalOperations.list.stripeId')
        );
        renameKeys(
          value,
          'title',
          translate('resources.invoicing.globalOperations.list.title')
        );
        renameKeys(
          value,
          'teacher',
          translate('resources.invoicing.globalOperations.list.teacher')
        );
        renameKeys(
          value,
          'sport',
          translate('resources.invoicing.globalOperations.list.sport')
        );
        if (!isClub)
          renameKeys(
            value,
            'club',
            translate('resources.invoicing.globalOperations.list.club')
          );
        renameKeys(
          value,
          'student',
          translate('resources.invoicing.globalOperations.list.student')
        );
        renameKeys(
          value,
          'lessonType',
          translate('resources.users.lessons.list.lessonType')
        );
        renameKeys(
          value,
          'numberOfStudents',
          translate(
            'resources.invoicing.globalOperations.list.numberOfStudents'
          )
        );
        renameKeys(
          value,
          'bookingDate',
          translate('resources.invoicing.globalOperations.list.bookingDate')
        );
        renameKeys(
          value,
          'lessonDate',
          translate('resources.invoicing.globalOperations.list.lessonDate')
        );
        renameKeys(
          value,
          'type',
          translate('resources.invoicing.globalOperations.list.type')
        );
        renameKeys(
          value,
          'price',
          translate('resources.invoicing.globalOperations.list.price')
        );
        renameKeys(
          value,
          'fee',
          translate('resources.invoicing.globalOperations.list.fee')
        );
        renameKeys(
          value,
          'status',
          translate('resources.invoicing.globalOperations.list.status')
        );
        renameKeys(
          value,
          'paymentDate',
          translate('resources.invoicing.globalOperations.list.paymentDate')
        );
        renameKeys(
          value,
          'vat',
          translate('resources.invoicing.globalOperations.list.vat')
        );
        renameKeys(
          value,
          'publicPrice',
          translate('resources.invoicing.globalOperations.list.publicPrice')
        );
        if (!(isClub || isTeacher)) {
          renameKeys(
            value,
            'teacherFee',
            translate('resources.invoicing.globalOperations.list.teacherFee')
          );
          renameKeys(
            value,
            'teacherPayment',
            translate(
              'resources.invoicing.globalOperations.list.teacherPayment'
            )
          );
        }
      });
      let ws = XLSX.utils.json_to_sheet(data);
      let wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'sheet');
      XLSX.writeFile(
        wb,
        `${translate(
          'resources.invoicing.globalOperations.csv.title'
        )}_${getCurrentMillis()}.xlsx`
      );
    });
    setIsLoading(false);
  };

  const renameKeys = (obj, oldKey, newKey) => {
    obj[newKey] = obj[oldKey];
    delete obj[oldKey];
  };

  return (
    <Button
      label="resources.invoicing.globalOperations.csv.export"
      onClick={() => onExport()}
    />
  );
};
