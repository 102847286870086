import moment from 'moment';
import React, { useCallback, useRef, useState } from 'react';
import { useLocaleState, useTranslate } from 'react-admin';
import {
  Calendar,
  Views,
  momentLocalizer
} from 'react-big-calendar/dist/react-big-calendar.esm';
import {
  CALENDAR_AVAILABILITY_COLOR,
  CALENDAR_END_HOUR,
  CALENDAR_END_MINUTES,
  CALENDAR_LESSON_COLOR,
  CALENDAR_SCROLL_HOUR,
  CALENDAR_SCROLL_MINUTES,
  CALENDAR_START_HOUR,
  CALENDAR_START_MINUTES,
  translateMessages
} from '../../../core/constants/calendar.constants';
import { EventSlot } from '../../../core/models/event/eventSlot';
import { getCurrentDate } from '../../../core/utils/date.utils';
import overlap from '../overlap';
import { CalendarEvent } from './calendar.utils';
import { CalendarAddEventDialog } from './dialog/calendarAddEvent/CalendarAddEventDialog';
import { CalendarAvailableDialog } from './dialog/calendarAvailableDialog/CalendarAvailableDialog';
import { CalendarCourseEventDialog } from './dialog/calendarCourseEventDialog/CalendarCourseEventDialog';
import { CalendarEquipmentRentEventDialog } from './dialog/calendarEquipmentRentEventDialog/CalendarEquipmentRentEventDialog';
import { CalendarLessonEventDialog } from './dialog/calendarLessonEventDialog/CalendarLessonEventDialog';

type CalendarViewProps = {
  locale;
  date;
  view;
  events;
  setOpenAddEventDialog;
  openAddEventDialog;
  setOpenAvailableDialog;
  openAvailableDialog;
  setOpenLessonEventDialog;
  openLessonEventDialog;
  setOpenCourseEventDialog;
  openCourseEventDialog;
  setOpenEquipmentRentEventDialog;
  openEquipmentRentEventDialog;
  setRefresh;
  setDate;
  setView;
  isClub;
  userHash;
  fromTabs;
};
const localizer = momentLocalizer(moment); // or globalizeLocalizer

const CalendarView = (props: CalendarViewProps) => {
  const CustomCalendar = Calendar as Calendar;
  const clickRef = useRef(null);
  const [locale] = useLocaleState();
  const translate = useTranslate();
  const [slotInfo, setSlotInfo] = useState(null);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const calendarMinDate = getCurrentDate();
  const calendarMaxDate = getCurrentDate();
  const calendarScrollDate = getCurrentDate();
  const views = [Views.DAY, Views.WEEK, Views.MONTH];
  const translatedMessages = translateMessages(translate);

  calendarMinDate.setHours(CALENDAR_START_HOUR, CALENDAR_START_MINUTES);
  calendarMaxDate.setHours(CALENDAR_END_HOUR, CALENDAR_END_MINUTES);
  calendarScrollDate.setHours(CALENDAR_SCROLL_HOUR, CALENDAR_SCROLL_MINUTES);

  const handleNavigate = (newDate) => {
    props.setDate(newDate);
  };

  const handleView = (newView) => {
    props.setView(newView);
  };

  const eventStyleGetter = (event: CalendarEvent) => {
    const style = {
      backgroundColor: event?.available ? CALENDAR_AVAILABILITY_COLOR : '',
      color: event?.lessonEventHash != null ? CALENDAR_LESSON_COLOR : ''
    };
    return { style };
  };

  const updateSlotInfo = (event: any, setSlotInfo: React.Dispatch<any>) => {
    const slot: EventSlot = {
      start: event.start,
      end: event.end,
      action: '',
      box: {
        clientX: 0,
        clientY: 0,
        x: 0,
        y: 0
      },
      slots: []
    };
    setSlotInfo(slot);
  };

  const updateSelectUsers = (
    props: CalendarViewProps,
    slotInfo: EventSlot,
    setSelectedUsers: React.Dispatch<React.SetStateAction<any[]>>
  ) => {
    const eventsInSlot = props.events.filter((event) => {
      return (
        ((slotInfo.start >= event.start && slotInfo.start <= event.end) ||
          (slotInfo.end >= event.start && slotInfo.end <= event.end) ||
          (slotInfo.start <= event.start && slotInfo.end >= event.end)) &&
        event.available
      );
    });
    const usersInSlotSet = new Set<string>();
    eventsInSlot.forEach((event) => {
      usersInSlotSet.add(event.userHash);
    });
    const usersInSlot = Array.from(usersInSlotSet);
    setSelectedUsers(usersInSlot);
  };

  const onSelectSlot = useCallback(
    (slotInfo: EventSlot) => {
      window.clearTimeout(clickRef?.current);
      clickRef.current = window.setTimeout(() => {
        setSlotInfo(slotInfo);
        updateSelectUsers(props, slotInfo, setSelectedUsers);
        props.setOpenAddEventDialog(true);
      }, 250);
    },
    [props]
  );

  const onSelectEvent = (event) => {
    setSelectedEvent(event);
    if (event.userHash) {
      setSelectedUsers([event.userHash]);
    }

    if (event.available && props.isClub) {
      updateSlotInfo(event, setSlotInfo);
      props.setOpenAddEventDialog(true);
    }

    if (event.available && !props.isClub) {
      props.setOpenAvailableDialog(true);
    }

    if (event.lessonEventHash) {
      props.setOpenLessonEventDialog(true);
    }

    if (event.courseHash) {
      props.setOpenCourseEventDialog(true);
    }

    if (event.equipmentRentHash) {
      props.setOpenEquipmentRentEventDialog(true);
    }
  };

  const eventsGroupedByUser = props.events.reduce((grouped, event) => {
    const userHash = event.userHash || '';
    grouped[userHash] = [...(grouped[userHash] || []), event];
    return grouped;
  }, {});

  const sortedEvents = Object.values(eventsGroupedByUser).flatMap((group: []) =>
    group.sort((a: CalendarEvent, b: CalendarEvent) => a.start - b.start)
  );

  return (
    <div className="calendar-container-fullwidth">
      <CustomCalendar
        className="custom-calendar"
        culture={locale}
        onNavigate={handleNavigate}
        onView={handleView}
        defaultDate={props.date}
        localizer={localizer}
        events={sortedEvents}
        style={{
          height: 500,
          flex: '1 auto'
        }}
        dayLayoutAlgorithm={overlap}
        startAccessor="start"
        view={props.view}
        views={views}
        messages={translatedMessages}
        onSelectSlot={onSelectSlot}
        selectable
        eventPropGetter={eventStyleGetter}
        onSelectEvent={onSelectEvent}
        scrollToTime={calendarScrollDate}
        max={calendarMaxDate}
        min={calendarMinDate}
      />
      {slotInfo && (
        <CalendarAddEventDialog
          slotInfo={slotInfo}
          openDialog={props.openAddEventDialog}
          setOpenDialog={props.setOpenAddEventDialog}
          id={props.userHash}
          view={props.view}
          fromTabs={props.fromTabs}
          setRefresh={props.setRefresh}
          selectedUsers={selectedUsers}
        />
      )}

      {selectedEvent && (
        <CalendarAvailableDialog
          event={selectedEvent}
          openDialog={props.openAvailableDialog}
          setOpenDialog={props.setOpenAvailableDialog}
          view={props.view}
          setRefresh={props.setRefresh}
        />
      )}

      {selectedEvent && (
        <CalendarLessonEventDialog
          event={selectedEvent}
          openDialog={props.openLessonEventDialog}
          setOpenDialog={props.setOpenLessonEventDialog}
          view={props.view}
          setRefresh={props.setRefresh}
          userHash={props.userHash}
        />
      )}

      {selectedEvent && (
        <CalendarCourseEventDialog
          event={selectedEvent}
          openDialog={props.openCourseEventDialog}
          setOpenDialog={props.setOpenCourseEventDialog}
          setRefresh={props.setRefresh}
          userHash={props.userHash}
        />
      )}
      {selectedEvent && (
        <CalendarEquipmentRentEventDialog
          event={selectedEvent}
          openDialog={props.openEquipmentRentEventDialog}
          setOpenDialog={props.setOpenEquipmentRentEventDialog}
          setRefresh={props.setRefresh}
          userHash={props.userHash}
        />
      )}
    </div>
  );
};

export default CalendarView;
