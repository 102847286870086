import React, { useContext } from 'react';
import {
  Create,
  Loading,
  SimpleForm,
  useCreate,
  useGetList,
  useNotify,
  usePermissions,
  useRecordContext
} from 'react-admin';
import { useNavigate } from 'react-router-dom';
import { PRIVILEGE_CLUB } from '../../../../core/constants/privilege.constants';
import { UserHashContext } from '../../../../core/context/userHashContext';
import { LessonForm } from '../lessonForm/LessonForm';
import { LessonFormToolbar } from '../lessonForm/LessonFormToolbar';
import { lessonFormValidator } from '../lessonForm/lessonFormValidator';

export const LessonCreateShared = (props) => {
  const navigate = useNavigate();
  const { userHash: parentHash } = useContext(UserHashContext);
  const [create, { isLoading: createIsLoading, isError }] = useCreate('users');
  const notify = useNotify();
  const permissions = usePermissions();
  const isClub = permissions.permissions === PRIVILEGE_CLUB;

  const record = useRecordContext();

  const { data, isLoading } = useGetList('lessons/lesson-time-types', {
    pagination: { page: 1, perPage: 10 },
    sort: { field: 'time', order: 'DESC' }
  });

  const hours = isLoading
    ? []
    : data.map((item) => ({ time: item.name, value: item.time }));

  const lessonDefaultValue = () => ({
    level: [{}],
    price: hours
  });

  const lessonSave = (data) => {
    if (isClub) {
      data.parentHash = parentHash;
    }

    if (props.isClub) {
      data.parentHash = record.id;
    }

    data = {
      ...data,
      prices: [data.price],
      userHash: props.isClub
        ? data.userHash
          ? data.userHash
          : record.userHash
        : props.userHash,
      disabled: !data.isEnabled
    };
    create(
      'lessons',
      { data },
      {
        onSuccess: () => {
          notify('resources.users.notifications.lessonCreate.success', {
            type: 'success'
          });
          if (props.setSection) {
            props.setSection(props.sectionList);
          }

          if (props.route) {
            navigate(-1);
          }
        },
        onError(error: any) {
          notify(error.message, { type: 'warning' });
        }
      }
    );
  };

  if (isLoading) return <Loading />;

  if (!isLoading) {
    return (
      <Create resource={'lessons'}>
        <SimpleForm
          onSubmit={lessonSave}
          defaultValues={lessonDefaultValue}
          validate={(e) => lessonFormValidator(e)}
          className={'form'}
          toolbar={
            <LessonFormToolbar
              setSection={props.setSection}
              sectionList={props.sectionList}
              route={props.route}
              isLoading={createIsLoading}
              isError={isError}
            />
          }
        >
          <LessonForm
            isClub={props.isClub}
            userHash={isClub ? parentHash : props.userHash}
          />
        </SimpleForm>
      </Create>
    );
  }
};
