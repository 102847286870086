import Typography from '@mui/material/Typography';
import React from 'react';
import { ReferenceInput, SelectInput, useTranslate } from 'react-admin';

export const LinkedTeacherForm = () => {
  const translate = useTranslate();

  const userOptionRenderer = (choice) =>
    [choice.firstName, choice.lastName, choice.secondLastName]
      .filter(Boolean)
      .join(' ');

  return (
    <>
      <Typography variant="h6" gutterBottom className={'title'}>
        {translate('resources.users.linkedTeachers.create.form.title')}
      </Typography>
      <ReferenceInput
        source="name"
        reference="users"
        filter={{ teachers: true }}
        sort={{ field: 'firstName', order: 'ASC' }}
      >
        <SelectInput
          optionText={userOptionRenderer}
          label="resources.users.linkedTeachers.create.form.name"
        ></SelectInput>
      </ReferenceInput>
    </>
  );
};
