import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import jsonServerProvider from 'ra-data-json-server';
import { default as React, useState } from 'react';
import { Admin, CustomRoutes } from 'react-admin';
import { Route } from 'react-router-dom';
import { GeneratePassword } from './auth/GeneratePassword';
import { CustomLoginPage } from './auth/LoginPage';
import { PasswordRecovery } from './auth/PasswordRecovery';
import { ResetPassword } from './auth/ResetPassword';
import { CustomLayout } from './components/general/Layout';
import { ProtectedRoute } from './components/general/ProtectedRoutes';
import HomePageRedirect from './components/general/home/HomePageRedirect';
import CustomNotification from './components/general/notifications/Notification';
import {
  PRIVILEGE_ADMIN,
  PRIVILEGE_CLUB,
  PRIVILEGE_TEACHER
} from './core/constants/privilege.constants';
import { HeaderTitleContext } from './core/context/headerTitleContext';
import { UserHashContext } from './core/context/userHashContext';
import authProvider from './core/providers/authProvider';
import httpClient from './core/providers/httpClient';
import { i18nProvider } from './core/providers/i18nProvider';
import jwtManager from './core/providers/jwtManager';
import { ActiveVouchersList } from './dashboard/activeVouchers/ActiveVouchersList';
import { CustomCalendar } from './dashboard/calendar/Calendar';
import { InvoiceDataSetting } from './dashboard/configuration/invoiceDataSetting/InvoiceDataSetting';
import { InvoicingData } from './dashboard/configuration/invoincingData/invoicingData';
import { PrivacyPoliciesCreate } from './dashboard/configuration/privacyPolicies/privacyPoliciesCreate/PrivacyPoliciesCreate';
import { PrivacyPoliciesList } from './dashboard/configuration/privacyPolicies/privacyPoliciesList/PrivacyPoliciesList';
import { PrivacyPoliciesView } from './dashboard/configuration/privacyPolicies/privacyPoliciesView/PrivacyPoliciesView';
import { Share } from './dashboard/configuration/share/Share';
import { SportsCreate } from './dashboard/configuration/sports/sportsCreate/SportsCreate';
import { SportsEdit } from './dashboard/configuration/sports/sportsEdit/SportsEdit';
import { SportsList } from './dashboard/configuration/sports/sportsList/SportsList';
import { TermsAndConditionsCreate } from './dashboard/configuration/termsAndConditions/termsAndConditionsCreate/TermsAndConditionsCreate';
import { TermsAndConditionsList } from './dashboard/configuration/termsAndConditions/termsAndConditionsList/TermsAndConditionsList';
import { TermsAndConditionsView } from './dashboard/configuration/termsAndConditions/termsAndConditionsView/TermsAndConditionsView';
import { UserLinkedSportsList } from './dashboard/configuration/userLinkedSports/userLinkedSportsList/UserLinkedSportsList';
import { CourseCreate } from './dashboard/courses/courseCreate/CourseCreate';
import { CourseEdit } from './dashboard/courses/courseEdit/CourseEdit';
import { CoursesList } from './dashboard/courses/coursesList/CoursesList';
import { EquipmentRentsCreate } from './dashboard/equipmentRent/equipmentRentsCreate/EquipmentRentsCreate';
import { EquipmentRentsEdit } from './dashboard/equipmentRent/equipmentRentsEdit/EquipmentRentsEdit';
import { EquipmentRentsList } from './dashboard/equipmentRent/equipmentRentsList/EquipmentRentsList';
import { EquipmentRentsCalendar } from './dashboard/equipmentRentsCalendar/EquipmentRentsCalendar';
import { EquipmentRentsScheduler } from './dashboard/equipmentRentsScheduler/EquipmentRentsScheduler';
import { GlobalOperationView } from './dashboard/invoicing/globalOperations/globalOperationView/GlobalOperationView';
import { GlobalOperationsList } from './dashboard/invoicing/globalOperations/globalOperationsList/GlobalOperationsList';
import { InvoicesOverview } from './dashboard/invoicing/invoices/InvoicesOverview';
import { PaymentRequestsOverview } from './dashboard/invoicing/paymentRequest/PaymentRequestsOverview';
import { PaymentsOverView } from './dashboard/invoicing/payments/PaymentsOverView';
import { LessonCreate } from './dashboard/lessons/lessonCreate/LessonCreate';
import { LessonEdit } from './dashboard/lessons/lessonEdit/LessonEdit';
import { LessonsList } from './dashboard/lessons/lessonsList/LessonsList';
import { LinkedClubsList } from './dashboard/linkedClubs/LinkedClubList';
import { NotificationsList } from './dashboard/notifications/notificationsList/notificationsList';
import { RoomCreate } from './dashboard/rooms/roomCreate/RoomCreate';
import { RoomEdit } from './dashboard/rooms/roomEdit/RoomEdit';
import { RoomList } from './dashboard/rooms/roomsList/RoomList';
import { Schedule } from './dashboard/schedule/Schedule';
import { UserData } from './dashboard/userData/UserData';
import { UserCreate } from './dashboard/users/userCreate/UserCreate';
import { UserLinkedTeacherCreate } from './dashboard/users/userLinkedTeacherCreate/UserLinkedTeacherCreate';
import { UserList } from './dashboard/users/userList/UserList';
import { UserOverview } from './dashboard/users/userOverview/UserOverview';
import { VoucherCreate } from './dashboard/vouchers/voucherCreate/VoucherCreate';
import { VoucherEdit } from './dashboard/vouchers/voucherEdit/VoucherEdit';
import { VoucherList } from './dashboard/vouchers/vouchersList/VoucherList';
import { Register } from './register/Register';
import { theme } from './style/theme';

const apiUrl = process.env.REACT_APP_API_URL;
const data = jsonServerProvider(apiUrl, httpClient);
jwtManager.setRefreshTokenEndpoint(`${apiUrl}/auth/token`);
const auth = authProvider;

const App = () => {
  const [headerTitle, setHeaderTitle] = useState<string>('');
  const value = { headerTitle, setHeaderTitle };

  const [userHash, setUserHash] = useState<string>('');
  const userHashValue = { userHash, setUserHash };

  return (
    <UserHashContext.Provider value={userHashValue}>
      <HeaderTitleContext.Provider value={value}>
        <Admin
          dataProvider={data}
          authProvider={auth}
          i18nProvider={i18nProvider}
          loginPage={CustomLoginPage}
          layout={CustomLayout}
          theme={theme}
          notification={CustomNotification}
        >
          <CustomRoutes noLayout>
            <Route path="/" element={<HomePageRedirect />} />
            <Route path="/password-recovery" element={<PasswordRecovery />} />
            <Route path="/register" element={<Register />} />
            <Route path="/reset-password/:id" element={<ResetPassword />} />
            <Route
              path="/generate-password/:id"
              element={<GeneratePassword />}
            />
          </CustomRoutes>
          <CustomRoutes>
            <Route path="/:privilege" element={<UserList />} key="usuarios" />
            <Route
              path="/:privilege/create"
              element={
                <ProtectedRoute privileges={[PRIVILEGE_ADMIN]}>
                  <UserCreate />
                </ProtectedRoute>
              }
            />
            <Route path="/:privilege/:id" element={<UserOverview />} />
            <Route
              path="/:privilege/create-linked-teachers"
              element={<UserLinkedTeacherCreate />}
            />
            <Route
              path="/terms-and-conditions"
              element={
                <ProtectedRoute privileges={[PRIVILEGE_ADMIN]}>
                  <TermsAndConditionsList />
                </ProtectedRoute>
              }
            />
            <Route
              path="/terms-and-conditions/create"
              element={<TermsAndConditionsCreate />}
            />
            <Route
              path="/terms-and-conditions/:id"
              element={<TermsAndConditionsView />}
            />
            <Route
              path="/privacy-policies"
              element={
                <ProtectedRoute privileges={[PRIVILEGE_ADMIN]}>
                  <PrivacyPoliciesList />
                </ProtectedRoute>
              }
            />
            <Route
              path="/privacy-policies/create"
              element={<PrivacyPoliciesCreate />}
            />
            <Route
              path="/privacy-policies/:id"
              element={<PrivacyPoliciesView />}
            />
            <Route
              path="/sports"
              element={
                <ProtectedRoute privileges={[PRIVILEGE_ADMIN]}>
                  <SportsList />
                </ProtectedRoute>
              }
            />
            <Route
              path="/sports/create"
              element={
                <ProtectedRoute privileges={[PRIVILEGE_ADMIN]}>
                  <SportsCreate />
                </ProtectedRoute>
              }
            />
            <Route
              path="/sports/:id"
              element={
                <ProtectedRoute privileges={[PRIVILEGE_ADMIN]}>
                  <SportsEdit />
                </ProtectedRoute>
              }
            />
            <Route
              path="/equipment-rents"
              element={
                <ProtectedRoute
                  privileges={[PRIVILEGE_CLUB, PRIVILEGE_TEACHER]}
                >
                  <EquipmentRentsList />
                </ProtectedRoute>
              }
            />
            <Route
              path="/equipment-rents/create"
              element={<EquipmentRentsCreate />}
            />
            <Route
              path="/equipment-rents/:id"
              element={<EquipmentRentsEdit />}
            />
            <Route
              path="/vouchers"
              element={
                <ProtectedRoute
                  privileges={[PRIVILEGE_TEACHER, PRIVILEGE_CLUB]}
                >
                  <VoucherList />
                </ProtectedRoute>
              }
            />
            <Route path="/vouchers/create" element={<VoucherCreate />} />
            <Route path="/vouchers/:id" element={<VoucherEdit />} />

            <Route
              path="/linked-sports"
              element={
                <ProtectedRoute privileges={[PRIVILEGE_CLUB]}>
                  <UserLinkedSportsList />
                </ProtectedRoute>
              }
            />
            <Route path="/share" element={<Share />} />
            <Route
              path="/calendar"
              element={
                <ProtectedRoute
                  privileges={[PRIVILEGE_TEACHER, PRIVILEGE_CLUB]}
                >
                  <CustomCalendar />
                </ProtectedRoute>
              }
            />
            <Route
              path="/equipment-rents-calendar"
              element={
                <ProtectedRoute
                  privileges={[
                    PRIVILEGE_TEACHER,
                    PRIVILEGE_CLUB,
                    PRIVILEGE_ADMIN
                  ]}
                >
                  <EquipmentRentsCalendar />
                </ProtectedRoute>
              }
            />
            <Route
              path="/equipment-rents-scheduler"
              element={
                <ProtectedRoute
                  privileges={[
                    PRIVILEGE_TEACHER,
                    PRIVILEGE_CLUB,
                    PRIVILEGE_ADMIN
                  ]}
                >
                  <EquipmentRentsScheduler />
                </ProtectedRoute>
              }
            />
            <Route path="/active-vouchers" element={<ActiveVouchersList />} />
            <Route path="/notifications" element={<NotificationsList />} />
            <Route path="/invoicing-data" element={<InvoicingData />} />
            <Route
              path="/schedule"
              element={
                <ProtectedRoute
                  privileges={[
                    PRIVILEGE_TEACHER,
                    PRIVILEGE_CLUB,
                    PRIVILEGE_ADMIN
                  ]}
                >
                  <Schedule />
                </ProtectedRoute>
              }
            />
            <Route
              path="/payment-request"
              element={
                <ProtectedRoute
                  privileges={[
                    PRIVILEGE_TEACHER,
                    PRIVILEGE_CLUB,
                    PRIVILEGE_ADMIN
                  ]}
                >
                  <PaymentRequestsOverview />
                </ProtectedRoute>
              }
            />
            <Route
              path="/payment-request/:id"
              element={
                <ProtectedRoute
                  privileges={[
                    PRIVILEGE_TEACHER,
                    PRIVILEGE_CLUB,
                    PRIVILEGE_ADMIN
                  ]}
                >
                  <PaymentRequestsOverview />
                </ProtectedRoute>
              }
            />
            <Route path="/lessons" element={<LessonsList />} />
            <Route path="/lessons/:id" element={<LessonEdit />} />
            <Route path="/lessons/create" element={<LessonCreate />} />
            <Route path="/yearly-course" element={<CoursesList />} />
            <Route path="/yearly-course/:id" element={<CourseEdit />} />
            <Route
              path="/yearly-course/create"
              element={<CourseCreate smallCourses={false} />}
            />
            <Route
              path="/small-course"
              element={<CoursesList smallCourses={true} />}
            />
            <Route
              path="/small-course/:id"
              element={<CourseEdit smallCourses={true} />}
            />
            <Route
              path="/small-course/create"
              element={<CourseCreate smallCourses={true} />}
            />
            <Route path="/my-data" element={<UserData />} />
            <Route
              path="/rooms"
              element={
                <ProtectedRoute
                  privileges={[PRIVILEGE_CLUB, PRIVILEGE_TEACHER]}
                >
                  <RoomList />
                </ProtectedRoute>
              }
            />
            <Route path="/rooms/:id" element={<RoomEdit />} />
            <Route path="/rooms/create" element={<RoomCreate />} />
            <Route
              path="/global-operations"
              element={<GlobalOperationsList />}
            />
            <Route
              path="/global-operations/:id"
              element={<GlobalOperationView />}
            />
            <Route path="/invoices" element={<InvoicesOverview />} />
            <Route
              path="/payments"
              element={
                <ProtectedRoute privileges={[PRIVILEGE_ADMIN]}>
                  <PaymentsOverView />
                </ProtectedRoute>
              }
            />
            <Route
              path="/invoice-data-settings"
              element={
                <ProtectedRoute privileges={[PRIVILEGE_ADMIN]}>
                  <InvoiceDataSetting />
                </ProtectedRoute>
              }
            />
            <Route
              path="/linked-clubs"
              element={
                <ProtectedRoute privileges={[PRIVILEGE_TEACHER]}>
                  <LinkedClubsList />
                </ProtectedRoute>
              }
            />
          </CustomRoutes>
        </Admin>
      </HeaderTitleContext.Provider>
    </UserHashContext.Provider>
  );
};

export default App;
