import React from 'react';
import { CalendarCourseEventDialogGrouped } from './calendarCourseEventDialogGrouped/CalendarCourseEventDialogGrouped';
import { CalendarCourseEventDialogSimple } from './calendarCourseEventDialogSimple/CalendarCourseEventDialogSimple';

export const CalendarCourseEventDialog = (props) => {
  return (
    <>
      {props.event.group.length > 1 ? (
        <CalendarCourseEventDialogGrouped
          event={props.event}
          setOpenDialog={props.setOpenDialog}
          openDialog={props.openDialog}
          setRefresh={props.setRefresh}
        ></CalendarCourseEventDialogGrouped>
      ) : (
        <CalendarCourseEventDialogSimple
          setOpenDialog={props.setOpenDialog}
          openDialog={props.openDialog}
          event={props.event}
          setRefresh={props.setRefresh}
        ></CalendarCourseEventDialogSimple>
      )}
    </>
  );
};
