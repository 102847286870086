import React from 'react';
import {
  Loading,
  SimpleForm,
  useGetOne,
  useNotify,
  useRecordContext,
  useRedirect,
  useUpdate
} from 'react-admin';
import { useParams } from 'react-router-dom';
import { IDENTIFICATION_TYPE_CIF } from '../../../../../core/constants/IdentificationType.constants';
import { User } from '../../../../../core/models/user/user';
import { convertFileToBase64 } from '../../../../../core/utils/file-utils';
import { removeWhiteSpaces } from '../../../../../core/utils/string-utils';
import { createFormValidators } from '../../../userCreate/createFormValidator';
import UserForm from '../../../userShared/UserForm';
import { RestorePasswordButton } from './RestorePasswordButton';
import { UserToolbar } from './toolbar/UserToolbar';

export const UserEdit = () => {
  const record: User = useRecordContext();
  const [update] = useUpdate();
  const redirect = useRedirect();
  const notify = useNotify();
  const { privilege } = useParams();
  const { data: user, isLoading } = useGetOne('users', {
    id: record.id
  });

  const isClub = privilege === 'clubs';
  const isStudent = privilege === 'students';
  const isTeacher = privilege === 'teachers';

  const getImage = (data) => {
    if (data.image?.rawFile instanceof File) {
      data.image = convertFileToBase64(data.image);
    } else {
      data.image =
        data.image === null ? null : record.image ? record.image.title : null;
    }
    return data.image;
  };

  const getIdentificationType = (data) => {
    return isClub ? IDENTIFICATION_TYPE_CIF : data['identificationType'];
  };

  const generalDataSave = async (data) => {
    data['identificationType'] = getIdentificationType(data);
    const image = await getImage(data);
    const id = data.id;

    data['accountNumber'] = removeWhiteSpaces(data['accountNumber']);

    update(
      'users',
      { id, data: { ...data, image } },
      {
        onSuccess: () => {
          notify('resources.users.notifications.userEdit.success', {
            type: 'success'
          });
          redirect(`/${privilege}`);
        },
        onError(error: any) {
          notify(error.message, { type: 'warning' });
        }
      }
    );
  };

  if (isLoading) return <Loading />;

  return (
    <SimpleForm
      toolbar={<UserToolbar />}
      onSubmit={generalDataSave}
      validate={(e) => createFormValidators(e, isClub, isStudent, isTeacher)}
      record={user}
      className={'form'}
    >
      <UserForm
        isClub={isClub}
        isTeacher={isTeacher}
        isStudent={isStudent}
        isAdmin={false}
      />
      <RestorePasswordButton />
    </SimpleForm>
  );
};
