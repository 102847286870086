import { get, post } from './httpClient.service';
const DEFAULT_ENTRY_POINT = 'users';

export async function getUserParent(userHash, parentHash): Promise<any> {
  return get(
    `${DEFAULT_ENTRY_POINT}/${userHash}/parent/${parentHash}/user-parent`,
    {}
  );
}

export async function getUsers(
  _start: number,
  _end: number,
  _sort: string,
  teachers: boolean,
  clubs: boolean
): Promise<any> {
  return get(`${DEFAULT_ENTRY_POINT}`, {
    _start,
    _end,
    _sort,
    teachers,
    clubs
  });
}

export async function getEvents(
  userHash,
  startDate,
  endDate,
  parentHash,
  lessonTypeCode,
  teacherHash,
  roomHash
): Promise<any> {
  return get(`${DEFAULT_ENTRY_POINT}/${userHash}/events`, {
    startDate,
    endDate,
    parentHash,
    lessonTypeCode,
    teacherHash,
    roomHash
  });
}

export async function getUserInvoiceData(userHash): Promise<any> {
  return get(`${DEFAULT_ENTRY_POINT}/${userHash}/invoice-data`, {});
}

export async function getUserEventBookings(
  userHash,
  startDate,
  endDate,
  parentHash
): Promise<any> {
  return get(`${DEFAULT_ENTRY_POINT}/${userHash}/event-bookings`, {
    startDate,
    endDate,
    parentHash
  });
}

export async function getUserBalance(userHash): Promise<any> {
  return get(`${DEFAULT_ENTRY_POINT}/${userHash}/balance`, {});
}

export async function requestPayment(userHash): Promise<any> {
  return post(`${DEFAULT_ENTRY_POINT}/${userHash}/payment-request`, {});
}

export async function sendDeleteMessage(userHash, text): Promise<any> {
  return post(`${DEFAULT_ENTRY_POINT}/${userHash}/send-delete-message`, {
    text
  });
}
