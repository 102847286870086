import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import React from 'react';
import { Create, SimpleForm, Toolbar, useCreate, useNotify } from 'react-admin';
import { formatMillis, getMillis } from '../../../../../core/utils/date.utils';
import { EquipmentRentsCalendarEventForm } from './EquipmentRentsCalendarEventForm';
import { EquipmentRentsCalendarEventFormValidator } from './EquipmentRentsCalendarEventFormValidator';

export type EquipmentRentEventFormType = {
  equipmentRentHash;
  startDate;
  endDate;
  dayTypes;
  timeType;
  timeIteration;
  iterationEnd;
  iterationDateEnd;
  iterationType;
};

export const EquipmentRentsCalendarAddEventDialog = (props: {
  slotInfo;
  openDialog;
  setOpenDialog;
  equipmentRent;
  setEquipmentRent;
  view;
  setRefresh;
  selectedUsers;
  userHash;
}) => {
  const [create] = useCreate();
  const notify = useNotify();

  const onCloseDialog = () => {
    props.setOpenDialog(false);
  };

  const getTitle = () => {
    return formatMillis('dddd, D MMMM', getMillis(props.slotInfo.start));
  };

  const getDate = (slotDate: Date, formDate: Date): Date => {
    const year = slotDate.getFullYear();
    const month = slotDate.getMonth();
    const day = slotDate.getDate();
    const hour = formDate.getHours();
    const minutes = formDate.getMinutes();

    return new Date(year, month, day, hour, minutes);
  };

  const saveEquipmentRentEvent = (data: EquipmentRentEventFormType) => {
    createEquipmentRentEvent(data);
  };

  const createEquipmentRentEvent = (data: EquipmentRentEventFormType) => {
    const startDate: Date = getDate(props.slotInfo.start, data.startDate);
    const endDate: Date = getDate(props.slotInfo.end, data.endDate);

    const body = {
      startDate: getMillis(startDate),
      endDate: getMillis(endDate),
      timeType: data.timeType,
      dayTypes: data.timeType === 1 ? null : data.dayTypes,
      iterationEnd:
        !data.iterationEnd && !data.iterationDateEnd ? 1 : data.iterationEnd,
      iterationDateEnd: data.iterationDateEnd
        ? getMillis(data.iterationDateEnd)
        : null,
      iterationType: data.iterationType,
      timeIteration: data.timeIteration != null ? data.timeIteration : 1
    };

    create(
      `equipment-rents/${data.equipmentRentHash}/events`,
      { data: body },
      {
        onSuccess: () => {
          notify(
            'resources.equipmentRentsCalendar.notifications.create.success',
            {
              type: 'success'
            }
          );
          props.setRefresh(true);
          onCloseDialog();
        },
        onError(error: any) {
          console.error(error);
          notify(error.message, { type: 'warning' });
        }
      }
    );
  };

  const addEventDefaultEvent: EquipmentRentEventFormType = {
    equipmentRentHash: null,
    startDate: props.slotInfo.start ?? null,
    endDate: props.slotInfo.end ?? null,
    dayTypes: null,
    timeType: null,
    iterationEnd: null,
    iterationDateEnd: null,
    iterationType: null,
    timeIteration: null
  };

  return (
    <>
      <Dialog
        fullWidth
        open={props.openDialog}
        onClose={onCloseDialog}
        className="calendar-add-event-dialog"
      >
        <DialogTitle>{getTitle()}</DialogTitle>
        <DialogContent>
          <Create resource="equipment-rents">
            <SimpleForm
              toolbar={<Toolbar></Toolbar>}
              onSubmit={saveEquipmentRentEvent}
              className={'calendar-add-event-dialog-form'}
              validate={EquipmentRentsCalendarEventFormValidator}
              defaultValues={addEventDefaultEvent}
            >
              <EquipmentRentsCalendarEventForm
                slotInfo={props.slotInfo}
                equipmentRent={props.equipmentRent}
                setEquipmentRent={props.setEquipmentRent}
                view={props.view}
                userHash={props.userHash}
              ></EquipmentRentsCalendarEventForm>
            </SimpleForm>
          </Create>
        </DialogContent>
      </Dialog>
    </>
  );
};
