import React, { useContext, useEffect } from 'react';
import { EmptyList } from '../../../../components/general/EmptyList';
import { HeaderTitleContext } from '../../../../core/context/headerTitleContext';
import { CustomPagination } from '../../../../components/general/Pagination';
import { Datagrid, FunctionField, List, TextField } from 'react-admin';
import { Sport } from '../../../../core/models/sport/sport';
import { ColorField } from 'react-admin-color-picker';
import { TableEditButton } from '../../../../components/shared/table/TableEditButton';
import { SportsListActions } from './SportsListActions';
import { SportsDeleteButton } from './SportsDeleteButton';

export const SportsList = () => {
  const { setHeaderTitle } = useContext(HeaderTitleContext);

  useEffect(() => {
    setHeaderTitle('resources.configuration.sports.title');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <List
        resource="sports"
        exporter={false}
        actions={<SportsListActions />}
        perPage={Number(process.env.REACT_APP_LIST_SIZE)}
        pagination={<CustomPagination />}
        empty={
          <EmptyList
            buttonLabel={'resources.configuration.sports.list.actions.create'}
            route={'/sports/create'}
          />
        }
      >
        <Datagrid bulkActionButtons={false}>
          <TextField
            source="name"
            sortBy="name"
            label={'resources.configuration.sports.fields.sport'}
            emptyText="-"
          />
          <ColorField
            source="color"
            label="resources.configuration.sports.fields.color"
          />
          <FunctionField
            label="resources.configuration.sports.fields.icon"
            sortBy="icon"
            render={(record: Sport) => (
              <img alt="" width="20" src={record.icon.src} />
            )}
          />
          <SportsDeleteButton />
          <TableEditButton />
        </Datagrid>
      </List>
    </>
  );
};
