import {
  VALIDATION_POSITIVE_NUMBER,
  VALIDATION_REQUIRED
} from '../../../../core/constants/validation.constants';

export function roomFormValidator(values: any) {
  const errors: any = {};

  if (!values.name) {
    errors.title = VALIDATION_REQUIRED;
  }

  if (!values.userLimit) {
    errors.userLimit = VALIDATION_REQUIRED;
  }
  if (values.userLimit <= 0) {
    errors.userLimit = VALIDATION_POSITIVE_NUMBER;
  }

  return errors;
}
