import { useRecordContext } from 'react-admin';
import { useNavigate } from 'react-router-dom';
import { IconButton } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import React from 'react';

export const TableEditButton = (props) => {
  const record = useRecordContext(props);
  const navigate = useNavigate();

  const handleEdit = () => {
    navigate(`${record.id}`);
  };

  return (
    <IconButton onClick={() => handleEdit()}>
      <ChevronRightIcon />
    </IconButton>
  );
};
