import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import React from 'react';
import { UserView } from '../userView/Userview';
import { useTranslate } from 'react-admin';

export const ClubTeachersTabListNotLinked = ({ handleClickActiveTab }) => {
  const translate = useTranslate();
  return (
    <Tabs className={'user-tabs-list'}>
      <TabList>
        <Tab
          onClick={() =>
            handleClickActiveTab('resources.users.tabs.generalData')
          }
        >
          {translate('resources.users.tabs.generalData')}
        </Tab>
      </TabList>
      <TabPanel>
        <UserView />
      </TabPanel>
    </Tabs>
  );
};
