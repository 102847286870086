import React from 'react';
import {
  Datagrid,
  FunctionField,
  List,
  TextField,
  usePermissions,
  useRecordContext
} from 'react-admin';
import { EmptyList } from '../../../../../components/general/EmptyList';
import { PRIVILEGE_ADMIN } from '../../../../../core/constants/privilege.constants';
import { Sport } from '../../../../../core/models/sport/sport';
import { UserLinkedSportsDeleteButton } from '../../../../configuration/userLinkedSports/userLinkedSportsList/UserLinkedSportsDeleteButton';

export const UserFavouriteSports = () => {
  const record = useRecordContext();
  const permissions = usePermissions();
  const isAdmin = permissions.permissions === PRIVILEGE_ADMIN;

  return (
    <List
      resource={`users/${record.id}/favourite-sports`}
      perPage={Number(process.env.REACT_APP_LIST_SIZE)}
      empty={<EmptyList />}
      actions={false}
    >
      <Datagrid bulkActionButtons={false}>
        <TextField
          source="name"
          label="resources.users.userFavouriteSports.list.name"
          emptyText="-"
        />
        <FunctionField
          label="resources.users.userFavouriteSports.list.icon"
          sortBy="icon"
          render={(record: Sport) => (
            <img alt="" width="20" src={record.icon.src} />
          )}
        />
        {isAdmin && (
          <UserLinkedSportsDeleteButton
            isFavouriteSport={true}
            userHash={record.id}
          />
        )}
      </Datagrid>
    </List>
  );
};
