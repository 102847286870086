const defaultMessages = {
  auth: {
    auth_check_error: 'Por favor inicie sesión para continuar',
    logout: 'Cerrar Sesión',
    password: 'Contraseña',
    sign_in: 'Acceder',
    sign_in_error: 'La autenticación falló, por favor, vuelva a intentarlo',
    user_menu: 'Perfil',
    username: 'Usuario'
  },
  boolean: {
    false: 'No',
    null: ' ',
    true: 'Sí'
  },
  configurable: {
    customize: 'Personalizar'
  },
  input: {
    file: {
      upload_several:
        'Arrastre algunos archivos para subir o haga clic para seleccionarlos.',
      upload_single:
        'Arrastre un archivo para subir o haga clic para seleccionarlo.'
    },
    image: {
      upload_several:
        'Arrastre algunas imagénes para subir o haga clic para seleccionarlas.',
      upload_single:
        'Arrastre alguna imagen para subir o haga clic para seleccionarla.'
    },
    password: {
      toggle_hidden: 'Mostrar contraseña',
      toggle_visible: 'Ocultar contraseña'
    },
    references: {
      all_missing: 'No se pueden encontrar datos de referencias.',
      many_missing:
        'Al menos una de las referencias asociadas parece no estar disponible.',
      single_missing: 'La referencia asociada no parece estar disponible.'
    }
  },
  message: {
    about: 'Acerca de',
    are_you_sure: '¿Está seguro?',
    bulk_delete_content:
      '¿Seguro que quiere eliminar este %{name}? |||| ¿Seguro que quiere eliminar estos %{smart_count} items?',
    bulk_delete_title:
      'Eliminar %{name} |||| Eliminar %{smart_count} %{name} items',
    bulk_update_content:
      '¿Seguro que quiere actualizar este %{name}? |||| ¿Seguro que quiere actualizar estos %{smart_count} items?',
    bulk_update_title:
      'Actualizar %{name} |||| Actualizar %{smart_count} %{name} items',
    delete_content: '¿Seguro que quiere eliminar este item?',
    delete_title: 'Eliminar %{name} #%{id}',
    details: 'Detalles',
    error:
      'Se produjo un error en el cliente y su solicitud no se pudo completar',
    invalid_form:
      'El formulario no es válido. Por favor verifique si hay errores',
    loading: 'La página se está cargando, espere un momento por favor',
    no: 'No',
    not_found:
      'O bien escribió una URL incorrecta o siguió un enlace incorrecto.',
    unsaved_changes:
      'Algunos de sus cambios no se guardaron. ¿Está seguro que quiere ignorarlos?',
    yes: 'Sí'
  },
  navigation: {
    next: 'Siguiente',
    no_more_results:
      'El número de página %{page} está fuera de los límites. Pruebe la página anterior.',
    no_results: 'No se han encontrado resultados',
    page_out_from_begin: 'No puede ir antes de la página 1',
    page_out_from_end: 'No puede ir después de la última página',
    page_out_of_boundaries: 'Número de página %{page} fuera de los límites',
    page_range_info: '%{offsetBegin} - %{offsetEnd} de %{total}',
    page_rows_per_page: 'Filas por página:',
    prev: 'Anterior',
    skip_nav: 'Saltar al contenido'
  },
  notification: {
    bad_item: 'Elemento incorrecto',
    canceled: 'Acción cancelada',
    created: 'Elemento creado',
    data_provider_error:
      'Error del proveedor de datos. Consulte la consola para más detalles.',
    deleted: 'Elemento borrado |||| %{smart_count} elementos borrados.',
    http_error: 'Error de comunicación con el servidor',
    i18n_error:
      'No se pudieron cargar las traducciones para el idioma especificado',
    item_doesnt_exist: 'El elemento no existe',
    logged_out: 'Su sesión ha finalizado, vuelva a conectarse.',
    not_authorized: 'No tiene autorización para acceder a este recurso.',
    updated: 'Elemento actualizado |||| %{smart_count} elementos actualizados'
  },
  page: {
    create: 'Crear %{name}',
    dashboard: 'Tablero',
    edit: '%{name} #%{id}',
    empty: 'Sin %{name} todavía.',
    error: 'Algo salió mal',
    invite: '¿Quiere agregar una?',
    list: 'Lista de %{name}',
    loading: 'Cargando',
    not_found: 'No encontrado',
    show: '%{name} #%{id}'
  },
  ra: {
    action: {
      add: 'Añadir',
      add_filter: 'Añadir filtro',
      back: 'Ir atrás',
      bulk_actions:
        '1 item seleccionado |||| %{smart_count} items seleccionados',
      cancel: 'Cancelar',
      clear_input_value: 'Limpiar valor',
      clone: 'Clonar',
      close: 'Cerrar',
      close_menu: 'Cerrar menú',
      confirm: 'Confirmar',
      create: 'Crear',
      create_item: 'Crear %{item}',
      delete: 'Eliminar',
      edit: 'Editar',
      expand: 'Expandir',
      export: 'Exportar',
      list: 'Listar',
      move_down: 'Mover abajo',
      move_up: 'Mover arriba',
      open_menu: 'Abrir menú',
      refresh: 'Refrescar',
      remove: 'Borrar',
      remove_filter: 'Borrar filtro',
      remove_all_filters: 'Borrar todos los filtros',
      save: 'Guardar',
      search: 'Buscar',
      select_all: 'Seleccionar todo',
      select_row: 'Seleccionar esta fila',
      show: 'Mostrar',
      sort: 'Ordenar',
      undo: 'Deshacer',
      unselect: 'Deseleccionar',
      update: 'Actualizar'
    },
    auth: {
      auth_check_error: 'Por favor inicie sesión para continuar',
      logout: 'Cerrar Sesión',
      password: 'Contraseña',
      sign_in: 'Acceder',
      sign_in_error: 'La autenticación falló, por favor, vuelva a intentarlo',
      user_menu: 'Perfil',
      username: 'Usuario'
    },
    boolean: {
      false: 'No',
      null: ' ',
      true: 'Sí'
    },
    configurable: {
      customize: 'Personalizar'
    },
    input: {
      file: {
        upload_several:
          'Arrastre algunos archivos para subir o haga clic para seleccionarlos.',
        upload_single:
          'Arrastre un archivo para subir o haga clic para seleccionarlo.'
      },
      image: {
        upload_several:
          'Arrastre algunas imagénes para subir o haga clic para seleccionarlas.',
        upload_single:
          'Arrastre alguna imagen para subir o haga clic para seleccionarla.'
      },
      password: {
        toggle_hidden: 'Mostrar contraseña',
        toggle_visible: 'Ocultar contraseña'
      },
      references: {
        all_missing: 'No se pueden encontrar datos de referencias.',
        many_missing:
          'Al menos una de las referencias asociadas parece no estar disponible.',
        single_missing: 'La referencia asociada no parece estar disponible.'
      }
    },
    message: {
      about: 'Acerca de',
      are_you_sure: '¿Está seguro?',
      bulk_delete_content:
        '¿Seguro que quiere eliminar este %{name}? |||| ¿Seguro que quiere eliminar estos %{smart_count} items?',
      bulk_delete_title:
        'Eliminar %{name} |||| Eliminar %{smart_count} %{name} items',
      bulk_update_content:
        '¿Seguro que quiere actualizar este %{name}? |||| ¿Seguro que quiere actualizar estos %{smart_count} items?',
      bulk_update_title:
        'Actualizar %{name} |||| Actualizar %{smart_count} %{name} items',
      delete_content: '¿Seguro que quiere eliminar este item?',
      delete_title: 'Eliminar %{name} #%{id}',
      details: 'Detalles',
      error:
        'Se produjo un error en el cliente y su solicitud no se pudo completar',
      invalid_form:
        'El formulario no es válido. Por favor verifique si hay errores',
      loading: 'La página se está cargando, espere un momento por favor',
      no: 'No',
      not_found:
        'O bien escribió una URL incorrecta o siguió un enlace incorrecto.',
      unsaved_changes:
        'Algunos de sus cambios no se guardaron. ¿Está seguro que quiere ignorarlos?',
      yes: 'Sí'
    },
    navigation: {
      next: 'Siguiente',
      no_more_results:
        'El número de página %{page} está fuera de los límites. Pruebe la página anterior.',
      no_results: 'No se han encontrado resultados',
      page_out_from_begin: 'No puede ir antes de la página 1',
      page_out_from_end: 'No puede ir después de la última página',
      page_out_of_boundaries: 'Número de página %{page} fuera de los límites',
      page_range_info: '%{offsetBegin} - %{offsetEnd} de %{total}',
      page_rows_per_page: 'Filas por página:',
      prev: 'Anterior',
      skip_nav: 'Saltar al contenido'
    },
    notification: {
      bad_item: 'Elemento incorrecto',
      canceled: 'Acción cancelada',
      created: 'Elemento creado',
      data_provider_error:
        'Error del proveedor de datos. Consulte la consola para más detalles.',
      deleted: 'Elemento borrado |||| %{smart_count} elementos borrados.',
      http_error: 'Error de comunicación con el servidor',
      i18n_error:
        'No se pudieron cargar las traducciones para el idioma especificado',
      item_doesnt_exist: 'El elemento no existe',
      logged_out: 'Su sesión ha finalizado, vuelva a conectarse.',
      not_authorized: 'No tiene autorización para acceder a este recurso.',
      updated: 'Elemento actualizado |||| %{smart_count} elementos actualizados'
    },
    page: {
      create: 'Crear %{name}',
      dashboard: 'Tablero',
      edit: '%{name} #%{id}',
      empty: 'Sin %{name} todavía.',
      error: 'Algo salió mal',
      invite: '¿Quiere agregar una?',
      list: 'Lista de %{name}',
      loading: 'Cargando',
      not_found: 'No encontrado',
      show: '%{name} #%{id}'
    },
    saved_queries: {
      new_label: 'Guardar la búsqueda actual',
      remove_label_with_name: 'Eliminar la búsqueda "%{name}"',
      new_dialog_title: 'Guardar la búsqueda actual',
      query_name: 'Nombre de la búsqueda',
      remove_dialog_title: 'Eliminar la búsqueda',
      remove_message: '¿Está seguro que quiere eliminar esta búsqueda?'
    },
    sort: {
      ASC: 'ascendente',
      DESC: 'descendente',
      sort_by: 'Ordenar por %{field} %{order}'
    },
    validation: {
      email: 'Debe ser un correo electrónico válido',
      emails:
        'Todos los correos electrónicos deben ser válidos, separados por ";"',
      maxLength: 'Debe contener %{max} caracteres o menos',
      maxValue: 'Debe ser %{max} o menos',
      minLength: 'Debe contener %{min} caracteres al menos',
      minValue: 'Debe ser al menos %{min}',
      number: 'Debe ser un número',
      oneOf: 'Debe ser uno de: %{options}',
      passwordMismatched: 'Las contraseñas no coinciden',
      regex: 'Debe coincidir con un formato específico (regexp): %{pattern}',
      required: 'Requerido'
    }
  },
  sort: {
    ASC: 'ascendente',
    DESC: 'descendente',
    sort_by: 'Ordenar por %{field} %{order}'
  }
};

const esMessages = {
  ...defaultMessages,
  validation: {
    email: 'Debe ser un correo electrónico válido',
    maxLength: 'Debe contener %{max} caracteres o menos',
    maxValue: 'Debe ser %{max} o menos',
    minLength: 'Debe contener %{min} caracteres al menos',
    minValue: 'Debe ser al menos %{min}',
    number: 'Debe ser un número',
    oneOf: 'Debe ser uno de: %{options}',
    passwordMismatched: 'Las contraseñas no coinciden',
    regex: 'Debe coincidir con un formato específico (regexp): %{pattern}',
    required: 'Requerido',
    isGreater: 'Debe ser menor',
    moreThanZero: 'Debe ser mayor que 0',
    moreThanOne: 'Debe ser mayor que 1',
    positiveNumber: 'Debe ser un número positivo',
    dateTodayOrGreater: 'Debe ser una fecha igual o posterior a hoy',
    nifNotValid: 'El DNI no es válido',
    dateOneYearOrLess: 'Debe ser una fecha menor de un año',
    onePriceRequired: 'Debe haber al menos un precio',
    noSpaces: 'No puede contener espacios'
  },
  errors: {
    existing_valid_voucher: 'Ya has comprado este bono y todavía no ha vencido',
    payment_intent_failed: 'Error al realizar el pago',
    payment_method_not_found: 'Ha habido un error con el método de pago',
    limit_to_book_exception:
      'El plazo de reserva para este curso ha finalizado',
    user_hash_booking: 'Ya has adquirido este evento anteriormente',
    event_price_outdated:
      'El precio de tu reserva actual ha cambiado. Prueba a reservar de nuevo para ver el nuevo precio',
    voucher_price_outdated:
      'El precio de tu bono actual ha cambiado. Prueba a reservar de nuevo para ver el nuevo precio',
    event_maximum_students: 'El evento ha llegado al máximo de reservas',
    email_exist: 'El correo ya existe',
    email_is_deleted_exception: 'El correo ya ha sido usado',
    invalid_file: 'Fichero inválido',
    is_not_club_user_exception: 'El club que estás asociando no es un club',
    login: 'Usuario o contraseña incorrectos o pendientes de validar',
    not_privilege_exception: 'No tienes privilegios suficientes',
    password_mismatch: 'Discrepancia en la contraseña',
    province_not_found: 'Provincia no encontrada',
    recover_password_not_found:
      'La contraseña de recuperación no se ha encontrado',
    sport_not_found: 'Deporte no encontrado',
    unrecognized_error:
      'Ha ocurrido un error. Por favor, contacte con el administrador de la web',
    user_not_found: 'Usuario no encontrado',
    user_is_not_a_teacher: 'El usuario no es profesor',
    lesson_not_found: 'La clase no se ha encontrado',
    user_parent_not_found: 'Usuario vinculado no encontrado',
    course_minimum_students_greater_exception:
      'El mínimo de alumnos es mayor que el máximo',
    course_date_start_greater_than_date_end_exception:
      'La fecha de inicio es mayor que la fecha de fin',
    course_registration_deadline_greater_than_date_start_exception:
      'La fecha de registro es mayor a la fecha de inicio',
    course_registration_deadline_greater_than_date_end_exception:
      'La fecha de registro es mayor a la fecha de fin',
    user_parent_exist_exception: 'El usuario vinculado ya existe',
    course_not_found: 'El curso no se ha encontrado',
    extension_not_valid: 'Extensión no válida',
    voucher_not_found: 'Bono no encontrado',
    user_is_student_exception: 'El usuario es alumno',
    country_not_found: 'País no encontrado',
    region_not_found: 'Región no encontrada',
    city_not_found: 'Ciudad no encontrada',
    equipment_rent_not_found_exception:
      'No se ha encontrado el material alquilado',
    room_not_found: 'Espacio no encontrado',
    availability_not_found: 'Disponibilidad no encontrada',
    event_not_found: 'Evento no encontrado',
    lesson_event_not_found: 'Evento de clase no encontrado',
    not_availability: 'No hay disponibilidad',
    user_voucher_not_found: 'Bono de usuario no encontrado',
    notification_not_found: 'Notificación no encontrada',
    user_payment_method_is_favorite:
      'El método de pago está marcado como favorito',
    user_payment_method_has_pending_payments:
      'El método de pago tiene pagos pendientes',
    lesson_maximum_duration_greater_exception:
      'La duración máxima de la clase no puede ser mayor de 4 horas',
    lesson_has_not_available_price: 'La clase no tiene precio disponible',
    lesson_has_not_set_any_price: 'La clase no tiene ningún precio establecido',
    lesson_minimum_students_greater_exception:
      'El mínimo de alumnos tiene que ser mayor',
    create_zip_error: 'Error al crear el zip',
    course_price_not_set: 'El precio del curso no está establecido',
    user_is_not_a_teacher_or_club: 'El usuario no es profesor ni club',
    lesson_maximum_students_smaller_exception: 'Límite de alumnos superado',
    lesson_level_not_found: 'Nivel de clase no encontrado',
    lesson_level_price_not_found: 'Precio del nivel de clase no encontrado',
    term_and_conditions_not_found: 'Términos y condiciones no encontrados',
    length_not_valid: 'Longitud no válida',
    user_is_disabled_exception: 'El usuario está deshabilitado',
    user_is_candidate_exception: 'El usuario es un candidato',
    user_has_not_account_number: 'El usuario no tiene número de cuenta',
    booking_not_found: 'Reserva no encontrada',
    payment_session_not_found: 'Sesión de pago no encontrada',
    payment_not_found: 'Pago no encontrado',
    payment_customer_failed: 'Fallo en el pago del cliente',
    user_has_not_country: 'El usuario no tiene país',
    user_has_not_permission: 'El usuario no tiene permiso',
    user_is_not_a_student: 'El usuario no es un estudiante',
    global_operation_not_found: 'Operación global no encontrada',
    invoice_not_found: 'Factura no encontrada',
    event_and_course_not_found: 'Evento y curso no encontrados',
    invoice_data_setting_not_found: 'Datos de facturación no encontrados',
    user_parent_not_linked: 'No vinculado',
    event_hidden: 'Evento oculto',
    voucher_not_valid: 'Bono no válido',
    overlapped_events_exception:
      'Horario ocupado. Para poner una clase, curso o disponibilidad en este horario se debe eliminar antes el evento o disponibilidad previo',
    not_club_privilege_exception:
      'No tienes privilegios suficientes. Esta acción sólo la puede llevar a cabo el Club',
    equipment_rent_event_maximum_duration_greater_exception:
      'La fecha de inicio no puede ser posterior a la fecha de finalización del evento',
    equipment_rent_event_not_minimum_duration:
      'La duración del evento no cumple con el tiempo mínimo del material seleccionado'
  },
  resources: {
    breadcrumbs: {
      home: 'Inicio',
      users: 'Usuarios',
      teachers: 'Profesores',
      students: 'Alumnos',
      clubs: 'Clubs',
      termsAndConditions: 'Terminos y condiciones',
      create: 'Nuevo',
      sports: 'Deportes',
      privacyPolicies: 'Politicas de privacidad',
      equipmentRents: 'Alquiler de material',
      equipmentRentsCalendar: 'Horarios Alquiler Material',
      equipmentRentsScheduler: 'Agenda Alquiler Material',
      vouchers: 'Bonos',
      createLinkedTeachers: 'Crear profesores vinculados',
      share: 'Compartir app',
      calendar: 'Horarios (programar)',
      activeVouchers: 'Bonos en activo',
      notifications: 'Notificaciones',
      invoicingData: 'Datos de facturación',
      schedule: 'Agenda',
      paymentRequest: 'Cartera virtual',
      lessons: 'Clases',
      smallCourse: 'Cursillos',
      yearlyCourse: 'Cursos anuales',
      myData: 'Mis datos',
      rooms: 'Espacios',
      globalOperations: 'Operaciones globales',
      invoices: 'Facturas',
      payments: 'Pagos',
      invoiceDataSettings: 'Datos de facturación por defecto',
      linkedClubs: 'Clubs vinculados'
    },
    navigation: {
      prev: 'Volver'
    },
    auth: {
      generate_password: {
        form: {
          confirmPassword: 'Repetir Contraseña',
          password: 'Contraseña'
        },
        subtitle: 'Generar contraseña',
        title: 'Nak Sports'
      },
      recovery_password: {
        register: ' Registrar Usuario',
        button: 'Enviar',
        link: 'aqui',
        message:
          'Por favor, indica tu email aquí abajo para recibir instrucciones sobre la forma de recuperar tu contraseña. Si has accedido a esta sección sin querer, puedes volver al inicio clicando ',
        title: 'Nak Sports'
      },
      remind_user: 'Mantener sesión',
      reset_password: {
        form: {
          confirmPassword: 'Repetir Contraseña',
          password: 'Contraseña'
        },
        subtitle: 'Actualizar contraseña',
        title: 'Nak Sports'
      },
      recovery_password_button: '¿Has olvidado tu contraseña?'
    },
    users: {
      header: {
        titleTeacher: 'Profesores',
        titleStudent: 'Alumnos',
        titleClub: 'Clubs'
      },
      calendar: {
        messages: {
          agenda: 'Agenda',
          allDay: 'Todo el día',
          month: 'Mes',
          day: 'Día',
          today: 'Hoy',
          previous: 'Anterior',
          next: 'Siguiente',
          date: 'Fecha',
          noEventsInRange: 'No hay eventos en este rango de fechas',
          time: 'Tiempo',
          tomorrow: 'Mañana',
          week: 'Semana',
          work_week: 'Werkweek',
          yesterday: 'Ayer',
          january: 'Enero',
          available: 'Disponible'
        },
        dialog: {
          fields: {
            category: 'Categoría',
            availability: 'Disponibilidad',
            lesson: 'Clase',
            fullLesson: 'Clase completa',
            lessonType: 'Tipo de clase',
            iteration: 'Repetir cada',
            dayType: 'Se repite el',
            end: 'Termina',
            afterDate: 'Después de fecha',
            afterIterations: 'Despúes de nº de repeticiones',
            day: 'Día',
            week: 'Semana',
            month: 'Mes',
            room: 'Espacio',
            course: 'Curso anual',
            smallCourse: 'Cursillo',
            teacher: 'Profesor',
            start: 'Empieza',
            timeIteration: 'Nº de repeticiones'
          },
          tooltip: {
            title: '+ Info',
            repeatEachMonth: 'Se repetirá cada mes los días indicados ',
            onlyForWeek: 'sólo de la semana en curso ',
            days: '(1ª, 2ª, 3ª, 4ª ó 5ª del mes).',
            toRepeat: 'Para repetir ',
            all: 'todas ',
            pleaseSelect: 'las semanas por favor selecciona “Repetir cada: ',
            week: 'Semana'
          }
        },
        availability: {
          title: 'Disponibilidad',
          start: 'Inicio: ',
          end: 'Fin: ',
          actions: {
            delete: 'Eliminar',
            deleteSeries: 'Eliminar serie'
          },

          delete: {
            title: 'Eliminar disponibilidad',
            message:
              '¿Estás seguro de que quieres eliminar esta disponibilidad?',
            success: 'Disponibilidad eliminada correctamente'
          }
        },
        equipmentRent: {
          title: 'Alquiler de material',
          start: 'Inicio: ',
          end: 'Fin: ',
          room: 'Espacio: ',
          club: 'Club: ',
          teacher: 'Profesor: ',
          actions: {
            delete: 'Eliminar'
          },
          delete: {
            title: 'Eliminar alquiler de material',
            message:
              '¿Estás seguro de que quieres eliminar este alquiler de material?',
            success: 'Alquiler de material eliminado correctamente'
          }
        },
        lessonEvent: {
          title: 'Clase',
          start: 'Inicio: ',
          end: 'Fin: ',
          room: 'Espacio: ',
          club: 'Club: ',
          teacher: 'Profesor: ',
          group: {
            title: 'Usuarios: '
          },
          actions: {
            oneEvent: 'Editar',
            allEvents: 'Editar Serie',
            delete: 'Eliminar',
            deleteSeries: 'Eliminar serie'
          },

          delete: {
            title: 'Eliminar evento de clase',
            message:
              '¿Estás seguro de que quieres eliminar este evento de clase?',
            success: 'Evento de clase eliminada correctamente'
          }
        },
        courseEvent: {
          title: 'Curso',
          start: 'Inicio: ',
          end: 'Fin: ',
          room: 'Espacio: ',
          club: 'Club: ',
          teacher: 'Profesor: ',
          actions: {
            delete: 'Eliminar',
            deleteSeries: 'Eliminar serie'
          },
          delete: {
            title: 'Eliminar evento de curso',
            message:
              '¿Estás seguro de que quieres eliminar este evento de curso?',
            success: 'Evento de curso eliminado correctamente'
          }
        },
        filter: {
          allLessonTypes: 'Todos los tipos de clase',
          aloneLessonType: 'Individual',
          collectiveLessonType: 'Colectiva',
          teacher: 'Profesor',
          room: 'Espacio'
        }
      },
      actions: {
        createClub: 'Nuevo Club',
        createStudent: 'Nuevo Alumno',
        createTeacher: 'Nuevo Profesor',
        delete: 'Eliminar cuenta',
        disable: 'Deshabilitar',
        disableAccount: 'Inhabilitar cuenta',
        enableAccount: 'Habilitar cuenta',
        enable: 'Habilitar',
        notValidateAndDelete: 'No validar y eliminar cuenta',
        restorePassword: 'Recuperar contraseña',
        validate: 'Validar candidato',
        viewAll: 'Ver Todos',
        viewCandidates: 'Ver Candidatos',
        viewValidated: 'Ver Validados',
        unlink: 'Desvincular',
        createLinkedTeachers: 'Crear profesores vinculados',
        validateCustom: {
          club: 'Validar Club',
          student: 'Validar Alumno',
          teacher: 'Validar Profesor',
          user: 'Validar Usuario'
        },
        cancel: 'Cancelar',
        saveChanges: 'Guardar cambios'
      },
      card: {
        entryDate: 'Fecha de incorcoporación: '
      },
      delete: {
        title: 'Eliminar cuenta',
        message: '¿Estás seguro de que quieres eliminar la cuenta?'
      },
      unlink: {
        title: 'Desvincular cuenta',
        message: '¿Estás seguro de que quieres desvincular la cuenta?'
      },
      tabs: {
        access: 'Acceso',
        annualCourses: 'Cursos Anuales',
        billingData: 'Datos para Facturación',
        calendar: 'Horarios (programar)',
        courses: 'Cursillos',
        class: 'Clases',
        equipmentRent: 'Alquiler de material',
        generalData: 'Datos Generales y de cuenta',
        schedule: 'Agenda',
        vouchers: 'Bonos',
        activeVouchers: 'Bonos en activo',
        favouriteSports: 'Deportes favoritos',
        historical: 'Historial',
        linkedClubs: 'Clubes vinculados',
        linkedTeachers: 'Profesores vinculados',
        rooms: 'Espacios',
        paymentMethods: 'Métodos de pago',
        equipmentRentsScheduler: 'Agenda Alquiler Material',
        equipmentRentsCalendar: 'Horarios Alquiler Material'
      },
      fields: {
        address: 'Dirección',
        category: 'Categoría',
        club: 'Club',
        clubName: 'Nombre del Club/Escuela',
        identification: 'Identificación',
        status: 'Estado',
        pending: 'Pendiente',
        linked: 'Vinculado',
        verified: 'Validado',
        disabled: 'Inhabilitado',
        region: 'Región',
        city: 'Ciudad',
        confirmPassword: 'Confirmar Contraseña',
        date: 'Fecha',
        email: 'Email',
        firstName: 'Nombre',
        firstSport: 'Deporte',
        lastName: 'Apellido',
        managerFirstName: 'Nombre del Responsable',
        nif: 'DNI',
        nifHelperText: 'El DNI debe tener 8 números y una letra: Ej: 12345678A',
        password: 'Contraseña',
        province: 'Provincia',
        secondLastName: 'Segundo Apellido',
        sport: 'Deporte',
        student: 'Alumno',
        surnames: 'Apellido',
        teacher: 'Profesor',
        telephone: 'Teléfono',
        text: 'Texto',
        generalDataTitle: 'Datos generales',
        image: 'Imagen',
        other: 'Otro',
        documentOption: 'Tipo de documento',
        cif: 'CIF',
        country: 'País',
        accept: 'Acepto los ',
        termsAndConditions: 'términos y condiciones ',
        privacyPolicies: 'y las políticas de privacidad',
        accountNumber: 'Número de cuenta',
        accountNumberHelperText:
          'No se cargará nada en esta cuenta. Sólo para recibir pagos',
        lessonsCount: 'Nº clases totales',
        lessonsCountInMonth: 'Nº clases último mes',
        lesson: {
          title: 'Título de la clase',
          minimumNotice: 'Antelación mínima para reservar',
          minimumNoticeDays: 'Días',
          minimumNoticeHours: 'Horas',
          userType: 'Dirigido a',
          email: 'Email',
          level: 'Nivel',
          fromYears: 'Desde (años)',
          toYears: 'Hasta (años)',
          years: 'años',
          material: 'Material disponible para la clase',
          minimumStudents: 'Número mínimo de alumnos',
          maximumStudents: 'Número máximo de alumnos',
          freeCancellationPeriod: 'Días antelación para cancelación sin cargo',
          paymentType: 'Forma de cobro',
          disable: 'Inactivo / Activo',
          price: 'Precio',
          lessonPrice: '€/clase',
          lessonType: 'Tipo de clase',
          lessonPriceAdvice:
            'No dejar ningún precio establecido puede provocar que no se pueda reservar la clase',
          place: 'Lugar',
          teacher: 'Profesor'
        }
      },
      filters: {
        name: 'Buscar por nombre completo',
        lastName: 'Buscar por apellido',
        phone: 'Buscar por teléfono',
        sport: 'Buscar por deporte',
        country: 'Buscar por país',
        region: 'Buscar por región',
        city: 'Buscar por ciudad',
        searchTerm: 'Buscar por texto',
        clubName: 'Buscar por club'
      },
      rooms: {
        actions: {
          create: 'Crear espacio'
        },
        list: {
          name: 'Nombre',
          userLimit: 'Limites de usuario',
          empty: 'No hay espacios'
        },
        notifications: {
          create: {
            success: 'Espacio creado correctamente'
          },
          editStatus: {
            success: 'Estado del espacio actualizado correctamente'
          },
          edit: {
            success: 'Espacio actualizado correctamente'
          },
          delete: {
            success: 'Espacio eliminado correctamente'
          }
        },
        delete: {
          title: 'Eliminar espacio',
          message: '¿Estas seguro que quieres eliminar este espacio?'
        },
        fields: {
          name: 'Nombre',
          userLimit: 'Limites de usuario'
        }
      },
      linkedClubs: {
        list: {
          clubName: 'Nombre de club',
          status: 'Estado',
          linkButton: 'Vincular club',
          firstName: 'Primer Apellido',
          secondName: 'Segundo Apellido',
          empty: {
            title: 'No hay clubs vinculados'
          }
        },
        delete: {
          title: 'Eliminar vincualación',
          message: '¿Estas seguro que quieres eliminar esta vinculación?'
        },
        link: {
          title: 'Vincular club',
          message: '¿Estas seguro que quieres vincularte con el club?'
        }
      },
      linkedTeachers: {
        list: {
          clubName: 'Nombre de club',
          status: 'Estado',
          linkButton: 'Vincular club',
          firstName: 'Primer Apellido',
          secondName: 'Segundo Apellido',
          empty: 'No hay profesores vinculados'
        },
        create: {
          form: {
            name: 'Nombre',
            title: 'Datos del nuevo profesor',
            buttons: {
              back: 'Cancelar',
              save: 'Vincular',
              saveAndAdd: 'Vincular y añadir otro'
            }
          }
        },
        delete: {
          title: 'Eliminar vincualación',
          message: '¿Estas seguro que quieres eliminar esta vinculación?'
        },
        link: {
          title: 'Vincular club',
          message: '¿Estas seguro que quieres vincularte con el club?'
        },
        actions: {
          create: 'Vincular profesor'
        }
      },
      menu: {
        clubs: 'Clubs',
        students: 'Alumnos',
        teachers: 'Profesores'
      },
      disableModal: {
        field: {
          reason: 'Motivo'
        },
        title: 'Deshabilitar usuario'
      },
      deleteModal: {
        field: {
          reason: 'Motivo'
        },
        title: 'Eliminar usuario'
      },
      enableModal: {
        field: {
          reason: 'Motivo'
        },
        title: 'Habilitar usuario'
      },
      validateModal: {
        title: 'Validar candidato',
        message: '¿Seguro que quiere validar el candidato?'
      },
      name: 'Usuario |||| Usuarios',
      notifications: {
        generatePassword: {
          success: 'Contraseña de usuario generada correctamente'
        },
        passwordRecovery: {
          success:
            'Se ha enviado un correo a tu email para recuperar la contraseña'
        },
        resetPassword: {
          success: 'Se ha actualizado la contraseña correctamente'
        },
        userCreate: {
          success: 'Usuario creado correctamente'
        },
        userDisable: {
          success: 'Usuario deshabilitado correctamente'
        },
        userEnable: {
          success: 'Usuario habilitado correctamente'
        },
        userValidate: {
          success: 'Usuario validado correctamente'
        },
        regionsNotFound: 'No se han encontrado regiones',
        citiesNotFound: 'No se han encontrado ciudades',
        userEdit: {
          success: 'Usuario actualizado correctamente',
          lesson: {
            status: {
              success: 'Estado de la clase actualizado correctamente'
            },
            delete: {
              success: 'Clase eliminada correctamente'
            }
          },
          linkedClubs: {
            delete: {
              success: 'Vinculación eliminada correctamente'
            },
            link: {
              success: 'Vinculación aprobada con exito'
            }
          },
          linkedTeachers: {
            delete: {
              success: 'Vinculación eliminada correctamente'
            },
            create: {
              success: 'Vinculación creada correctamente'
            }
          }
        },
        imageSize: {
          error: 'La imagen no puede superar %{max}MB'
        },
        extension: {
          error: 'Extensión de la imagen no válida'
        },
        userRegister: {
          success: 'Usuario registrado correctamente'
        },
        useRestore: {
          success: 'Enviado con exito el correo de recuperación al usuario'
        },
        userDelete: {
          success: 'Usuario eliminado correctamente'
        },
        lessonCreate: {
          success: 'Clase creada correctamente'
        },
        lessonUpdate: {
          success: 'Clase actualizada correctamente'
        },
        availabilityCreate: {
          success: 'Disponibilidad creada correctamente'
        }
      },
      restorePasswordModal: {
        title: 'Restablecer contraseña',
        message: '¿Quieres que el usuario restablezca la contraseña?',
        acceptLabel: 'Aceptar',
        cancelLabel: 'Cancelar'
      },
      termsAndConditionsModal: {
        title: 'Términos y condiciones',
        acceptLabel: 'Aceptar'
      },
      privacyPoliciesModal: {
        title: 'Política de privacidad',
        acceptLabel: 'Aceptar'
      },
      generalData: {
        security: {
          title: 'Seguridad'
        }
      },
      lessons: {
        actions: {
          create: 'Añadir nuevo tipo de clase'
        },
        list: {
          lessonTitle: 'Título de la clase',
          target: 'Dirigido a',
          level: 'Nivel',
          lessonType: 'Tipo de clase',
          activeInactive: 'Inactivo / Activo',
          years: 'años',
          empty: 'No hay clases creadas',
          teacher: 'Profesor'
        },
        popover: {
          disabled: 'Inactivo',
          enabled: 'Activo'
        },
        delete: {
          title: 'Eliminar clase',
          message: '¿Estás seguro de que quieres eliminar la clase?'
        }
      },
      course: {
        fields: {
          title: 'Título del curso',
          userType: 'Dirigido a',
          fromYears: 'Desde (años)',
          toYears: 'Hasta (años)',
          level: 'Nivel',
          minimumStudents: 'Número mínimo de alumnos',
          maximumStudents: 'Número máximo de alumnos',
          dateStart: 'Fecha de inicio',
          dateEnd: 'Fecha fin',
          registrationDeadline: 'Fecha límite para inscribirse',
          timeStart: 'Hora de inicio',
          timeEnd: 'Hora fin',
          place: 'Espacio',
          price: 'Precio',
          paymentType: 'Forma de cobro',
          freeCancellationPeriod: 'Días antelación para cancelación sin cargo',
          disable: 'Inactivo / Activo',
          dayType: 'Días del curso',
          quarterlyPrice: 'Precio trimestral',
          monthlyPrice: 'Precio mensual',
          annualPrice: 'Precio anual'
        },
        list: {
          courseTitle: 'Título de curso',
          target: 'Dirigido a',
          level: 'Nivel',
          activeInactive: 'Inactivo / Activo',
          years: 'años',
          empty: 'No hay cursos creados',
          dateStart: 'Fecha de inicio',
          dateEnd: 'Fecha fin',
          courseDays: 'Días de curso',
          timing: 'Horario'
        },
        popover: {
          disabled: 'Inactivo',
          enabled: 'Activo'
        },
        actions: {
          create: 'Añadir nuevo curso',
          createSmallCourse: 'Añadir nuevo cursillo'
        },
        delete: {
          title: 'Eliminar curso',
          smallCourseTitle: 'Eliminar cursillo',
          message: '¿Estás seguro de que quieres eliminar el curso?',
          smallCourseMessage:
            '¿Estás seguro de que quieres eliminar el cursillo?'
        },
        notifications: {
          courseCreate: {
            success: 'Curso creado correctamente'
          },
          editStatus: {
            success: 'Estado del curso actualizado correctamente'
          },
          editCourse: {
            success: 'Curso actualizado correctamente'
          },
          deleteCourse: {
            success: 'Curso eliminado correctamente'
          }
        }
      },
      vouchers: {
        actions: {
          create: 'Añadir nuevo bono'
        },
        fields: {
          title: 'Título del bono',
          lessonsNumber: 'Número de clases',
          price: 'Precio del bono',
          disable: 'Inactivo / Activo',
          time: 'Tiempo',
          timeRange: 'Rango de tiempo',
          category: 'Tipo de clases',
          conditions: 'Condiciones',
          lessons: 'Clases'
        },
        list: {
          title: 'Título del bono',
          lessons: 'Clases',
          lessonNumber: 'Número de clases',
          price: 'Precio del bono',
          activeInactive: 'Activo/Inactivo',
          currency: '€',
          empty: {
            title: 'No hay bonos creados',
            buttonLabel: 'Añadir bono'
          }
        },
        popover: {
          disabled: 'Inactivo',
          enabled: 'Activo'
        },
        delete: {
          title: 'Eliminar bono',
          message: '¿Estás seguro de que quieres eliminar el bono?'
        },
        notifications: {
          create: {
            success: 'Bono creado correctamente'
          },
          editStatus: {
            success: 'Estado del bono actualizado correctamente'
          },
          edit: {
            success: 'Bono actualizado correctamente'
          },
          delete: {
            success: 'Bono eliminado correctamente'
          }
        }
      },
      userVouchers: {
        list: {
          title: 'Título del bono',
          club: 'Club',
          teacher: 'Profesor',
          lessonNumber: 'Número de clases',
          recurrent: 'Renovación automática',
          progress: 'Consumo',
          pending: 'Pendiente: ',
          totalLessons: 'Total: ',
          usedLessons: 'Usadas: ',
          empty: {
            title: 'No hay bonos'
          }
        },
        delete: {
          title: 'Eliminar bono',
          message: '¿Estás seguro de que quieres eliminar el bono?'
        },
        notifications: {
          delete: {
            success: 'Bono eliminado correctamente'
          },
          editRecurrent: {
            success: 'Bono recurrente actualizado correctamente'
          }
        },
        popover: {
          disabled: 'No recurrente',
          enabled: 'Recurrente'
        }
      },
      equipmentRents: {
        actions: {
          create: 'Añadir material para alquilar'
        },
        list: {
          name: 'Material',
          time: 'Duración',
          price: 'Precio',
          currency: '€',
          empty: {
            title: 'No hay material para alquilar',
            buttonLabel: 'Añadir material para alquilar'
          }
        },
        fields: {
          name: 'Nombre del material',
          time: 'Duración',
          price: 'Precio del alquiler (€)'
        },
        notifications: {
          create: {
            success: 'Alquiler de material creado correctamente'
          },
          edit: {
            success: 'Alquiler de material actualizado correctamente'
          },
          delete: {
            success: 'Alquiler de material eliminado correctamente'
          }
        },
        delete: {
          title: 'Eliminar alquiler de material',
          message:
            '¿Estás seguro de que quieres eliminar el alquiler de material?'
        }
      },
      invoiceData: {
        fields: {
          accountNumber: 'Número de cuenta (IBAN)',
          fixedMonthFee: 'Cuota fija mensual',
          bookingFee: 'Comisión de reserva (%)',
          bookingFixFee: 'Comisión fija de reserva (€)'
        },
        notifications: {
          update: {
            success: 'Datos de facturación actualizados correctamente'
          }
        }
      },
      bookings: {
        list: {
          name: 'Nombre de la reserva',
          date: 'Fecha de la reserva',
          startDate: 'Fecha de inicio',
          endDate: 'Fecha de fin',
          bookingType: 'Tipo de reserva',
          teacherName: 'Profesor',
          back: 'Volver',
          type: {
            course: 'Curso',
            smallCourse: 'Cursillo',
            lesson: 'Clase',
            equipmentRent: 'Alquiler de material'
          },
          weekDay: 'Días',
          time: 'Horario'
        }
      },
      userPaymentMethods: {
        list: {
          brand: 'Tipo de tarjeta',
          cardNumber: 'Número de tarjeta',
          expirationDate: 'Fecha de caducidad'
        },
        notifications: {
          delete: {
            success: 'Método de pago eliminado correctamente'
          },
          favourite: {
            success: 'Método de pago marcado como favorito correctamente'
          }
        },
        delete: {
          title: 'Eliminar método de pago',
          message: '¿Estas seguro que quieres eliminar este método de pago?'
        },
        cantDelete: {
          title: 'Eliminar método de pago',
          message:
            'No puedes eliminar este método de pago porque tiene pagos pendientes',
          buttonLabel: 'Aceptar'
        },
        cantUpdateStatus: {
          title: 'Desmarcar como favorito',
          message:
            'No puedes desmarcar este método de pago como favorito porque es el único que tienes',
          buttonLabel: 'Aceptar'
        }
      },
      userFavouriteSports: {
        list: {
          name: 'Nombre del deporte',
          icon: 'Icono',
          firstSport: 'Deporte principal'
        }
      }
    },
    list: {
      empty: {
        title: 'No hay datos disponibles'
      }
    },
    activeVouchers: {
      header: {
        title: 'Bonos en activo'
      },
      menu: {
        title: 'Bonos en activo'
      },
      list: {
        user: 'Usuario'
      }
    },
    configuration: {
      menu: {
        termsAndConditions: 'Términos y condiciones',
        sports: 'Deportes',
        privacyPolicies: 'Políticas de privacidad',
        share: 'Compartir app',
        invoicingData: 'Datos de facturación',
        invoiceDataSettings: 'Datos de facturación'
      },
      header: {
        title: 'Configuración'
      },
      termsAndConditions: {
        title: 'Términos y condiciones',
        delete: {
          title: 'Eliminar términos y condiciones',
          message:
            '¿Estás seguro de que quieres eliminar los términos y condiciones?'
        },
        notifications: {
          delete: {
            success: 'Términos y condiciones eliminados correctamente'
          },
          accept: {
            success: 'Términos y condiciones aceptados correctamente'
          }
        },
        list: {
          actions: {
            create: 'Añadir nuevo término y condiciones'
          }
        },
        fields: {
          title: 'Título',
          date: 'Fecha',
          text: 'Texto',
          privilege: 'Privilegio',
          student: 'Alumno',
          teacher: 'Profesor',
          club: 'Club'
        },
        create: {
          success: 'Términos y condiciones creados correctamente'
        },
        edit: {
          success: 'Términos y condiciones actualizados correctamente'
        }
      },
      privacyPolicies: {
        title: 'Políticas de privacidad',
        delete: {
          title: 'Eliminar política de privacidad',
          message:
            '¿Estás seguro de que quieres eliminar la politica de privacidad?'
        },
        notifications: {
          delete: {
            success: 'Politica de privacidad eliminada correctamente'
          },
          accept: {
            success: 'Política de privacidad aceptada correctamente'
          }
        },
        list: {
          actions: {
            create: 'Añadir nueva política de privacidad'
          }
        },
        fields: {
          title: 'Título',
          date: 'Fecha',
          text: 'Texto',
          privilege: 'Privilegio',
          student: 'Alumno',
          teacher: 'Profesor',
          club: 'Club'
        },
        create: {
          success: 'Política de privacidad creada correctamente'
        },
        edit: {
          success: 'Política de privacidad actualizada correctamente'
        }
      },
      sports: {
        title: 'Deportes',
        fields: {
          name: 'Nombre deporte',
          color: 'Color asignado',
          icon: 'Icono',
          sport: 'Deporte'
        },
        list: {
          actions: {
            create: 'Añadir nuevo tipo de deporte'
          }
        },
        delete: {
          title: 'Eliminar tipo de deporte',
          message: '¿Estás seguro de que quieres eliminar el tipo de deporte?'
        },
        create: {
          title: 'Crear tipo de deporte'
        },
        notifications: {
          create: {
            success: 'Tipo de deporte creado correctamente'
          },
          delete: {
            success: 'Tipo de deporte eliminado correctamente'
          },
          edit: {
            success: 'Tipo de deporte actualizado correctamente'
          }
        }
      },
      share: {
        title: 'Compartir app',
        whatsapp: 'Whatsapp',
        facebookIos: 'Facebook (iOS)',
        facebookAndroid: 'Facebook (Android)',
        twitter: 'Twitter',
        email: 'Email',
        message:
          'Descárgate la aplicación de NAK, IOS: %{iosUrl}, Android: %{androidUrl}',
        emailDialog: {
          title: 'Compartir app por email',
          sendEmail: {
            success: 'Emails enviados correctamente'
          }
        }
      },
      invoicingDataSettings: {
        title: 'Datos de facturación',
        fields: {
          fixedMonthFee: 'Cuota fija mensual',
          bookingFee: 'Comisión de reserva (%)',
          bookingFixFee: 'Comisión fija de reserva (€)',
          iban: 'IBAN',
          bic: 'BIC',
          debtorId: 'ID deudor'
        },
        notifications: {
          update: {
            success: 'Datos de facturación actualizados correctamente'
          }
        }
      }
    },
    shared: {
      form: {
        toolbar: {
          actions: {
            cancel: 'Cancelar',
            saveChanges: 'Guardar cambios'
          }
        }
      }
    },
    termsAndConditionsAndPrivacyPolicy: {
      titleTerms: 'Nuevos términos y condiciones',
      titlePrivacy: 'Nueva política de privacidad',
      buttonLabel:
        'Aceptar nuevos términos y condiciones y política de privacidad',
      notifications: {
        accept: {
          success: 'Términos y políticas aceptados correctamente',
          error: 'Error al aceptar los términos y políticas'
        }
      }
    },
    sidebar: {
      users: 'Usuarios',
      language: 'Idioma',
      configuration: 'Configuración',
      invoicing: 'Facturación'
    },
    equipmentRents: {
      menu: {
        title: 'Alquiler de material'
      },
      header: {
        title: 'Alquiler de material'
      }
    },
    equipmentRentsScheduler: {
      boughtBy: 'Comprado por: ',
      header: {
        title: 'Agenda Alquiler Material'
      },
      menu: {
        title: 'Agenda Alquiler Material'
      },
      start: 'Comienza: ',
      end: 'Finaliza: ',
      group: {
        title: 'Reservas'
      },
      dialog: {
        fields: {
          equipmentRent: 'Alquiler de material'
        },
        delete: {
          actions: {
            delete: 'Cancelar reserva',
            success: 'Reserva de alquiler de material cancelada correctamente',
            title: 'Cancelar reserva',
            message: '¿Estás seguro de que quieres cancelar la reserva?'
          }
        }
      }
    },
    equipmentRentsCalendar: {
      header: {
        title: 'Horarios Alquiler Material'
      },
      notifications: {
        create: {
          success: 'Alquiler de material creado correctamente'
        }
      },
      menu: {
        title: 'Horarios Alquiler Material'
      },
      start: 'Inicio',
      end: 'Fin',
      group: {
        title: 'Alquiler de materiales'
      },
      dialog: {
        delete: {
          actions: {
            delete: 'Eliminar',
            deleteSeries: 'Eliminar serie',
            success: 'Alquiler de material eliminado correctamente',
            title: 'Eliminar alquiler de material',
            message:
              '¿Estás seguro de que quieres eliminar el alquiler de material?'
          }
        },
        fields: {
          equipmentRent: 'Alquiler de material',
          iteration: 'Repetir cada',
          dayType: 'Se repite el',
          end: 'Termina',
          afterDate: 'Después de fecha',
          afterIterations: 'Despúes de nº de repeticiones',
          day: 'Día',
          week: 'Semana',
          month: 'Mes',
          start: 'Empieza',
          timeIteration: 'Nº de repeticiones'
        },
        tooltip: {
          title: '+ Info',
          repeatEachMonth: 'Se repetirá cada mes los días indicados ',
          onlyForWeek: 'sólo de la semana en curso ',
          days: '(1ª, 2ª, 3ª, 4ª ó 5ª del mes).',
          toRepeat: 'Para repetir ',
          all: 'todas ',
          pleaseSelect: 'las semanas por favor selecciona “Repetir cada: ',
          week: 'Semana'
        },
        event: {
          title: 'Nombre :',
          time: 'Duración :',
          timeType: 'Tipo de tiempo :'
        }
      }
    },
    vouchers: {
      menu: {
        title: 'Bonos'
      },
      header: {
        title: 'Bonos'
      }
    },
    calendar: {
      header: {
        title: 'Horarios (programar)'
      },
      menu: {
        title: 'Horarios (programar)'
      }
    },
    notifications: {
      menu: {
        title: 'Notificaciones'
      },
      header: {
        title: 'Notificaciones'
      },
      list: {
        notification: 'Notificación',
        notifications: 'Notificaciones',
        date: 'Fecha',
        time: 'Hora'
      },
      delete: {
        title: 'Eliminar notificación',
        message: '¿Estás seguro de que quieres eliminar la notificación?'
      },
      view: {
        close: 'Cerrar'
      },
      notifications: {
        delete: {
          success: 'Notificación eliminada correctamente'
        }
      }
    },
    schedule: {
      menu: {
        title: 'Agenda'
      },
      header: {
        title: 'Agenda'
      },
      dialog: {
        lessonTitle: 'Clase',
        courseTitle: 'Curso',
        equipmentRentTitle: 'Alquiler de material',
        event: {
          start: 'Inicio:',
          end: 'Fin:',
          user: 'Usuario:',
          lessonType: 'Tipo de clase:',
          duration: 'Duración:',
          place: 'Espacio:',
          club: 'Club:',
          teacher: 'Profesor:',
          assistants: 'Asistentes:'
        },
        actions: {
          cancel: 'Cancelar reserva'
        }
      },
      cancel: {
        title: 'Cancelar reserva',
        message: '¿Estás seguro de que quieres cancelar la reserva?',
        success: 'Reserva cancelada correctamente',
        cancelLabel: 'Volver'
      }
    },
    invoicing: {
      menu: {
        paymentRequest: 'Cartera virtual',
        globalOperations: 'Operaciones globales',
        invoices: 'Facturas',
        payments: 'Pagos'
      },
      paymentRequest: {
        header: {
          title: 'Cartera virtual'
        },
        tabs: {
          emitted: 'Ordenados',
          pending: 'Solicitados',
          paid: 'Pagados'
        },
        list: {
          paymentRequestButton: 'Solicitar saldo',
          date: 'Fecha',
          amount: 'Cantidad',
          totalAmount: 'Total',
          partialAmount: 'Parcial',
          serialCode: 'Nº de operación',
          balanceAvailable: 'Saldo disponible: %{balance} €',
          user: 'Usuario',
          currency: '%{value}€',
          download: 'Descargar',
          downloaded: 'Descargado',
          title: 'Titulo',
          student: 'Estudiante',
          notPaid: 'No pagado',
          status: 'Marcar como',
          paymentRequestStatus: 'Estado',
          lessonDate: 'Fecha de clase',
          chargeDate: 'Fecha de cobro',
          emitted: 'Ordenado',
          pending: 'Solicitado',
          paid: 'Pagado'
        },
        expandedRow: {
          title: 'Título',
          date: 'Fecha de clase',
          serialCode: 'Nº de operación',
          student: 'Alumno',
          amount: 'Cantidad',
          eventType: 'Evento',
          eventTypes: {
            lesson: 'Clase',
            course: 'Curso',
            equipmentRent: 'Alquiler  de material',
            voucher: 'Bono'
          }
        },
        notifications: {
          request: {
            success: 'Solicitud de saldo realizada correctamente'
          },
          editStatus: {
            success: 'Estado de pago actualizado correctamente'
          },
          editStatuses: {
            success: 'Estados de pago actualizados correctamente'
          }
        },
        csv: {
          title: 'payment_requests',
          export: 'EXPORTAR A EXCEL'
        },
        popover: {
          paid: 'Pagado',
          notPaid: 'No pagado'
        }
      },
      payments: {
        header: {
          title: 'Listado de pagos'
        },
        tabs: {
          emitted: 'Ordenados',
          pending: 'Solicitados',
          paid: 'Pagados'
        },
        list: {
          date: 'Fecha',
          amount: 'Cantidad',
          totalAmount: 'Total',
          partialAmount: 'Parcial',
          balanceAvailable: 'Saldo disponible: %{balance} €',
          user: 'Usuario',
          currency: '%{value}€',
          notPaid: 'No pagado',
          status: 'Marcar como',
          statusExport: 'Estado',
          title: 'Nombre evento',
          emitted: 'Ordenado',
          pending: 'Solicitado',
          paid: 'Pagado',
          student: 'Alumno',
          lessonDate: 'Fecha de clase',
          chargeDate: 'Fecha de Cobro'
        },
        filters: {
          user: 'Buscar por usuario',
          date: 'Buscar por fecha'
        },
        csv: {
          title: 'Pagos',
          export: 'Exportar a EXCEL'
        },
        popover: {
          paid: 'Pagado',
          notPaid: 'No pagado'
        },
        notifications: {
          editStatus: {
            success: 'Estado de pago actualizado correctamente'
          },
          editStatuses: {
            success: 'Estados de pago actualizados correctamente'
          }
        }
      },
      globalOperations: {
        header: {
          title: 'Operaciones globales'
        },
        csv: {
          title: 'Operaciones Globales',
          export: 'Exportar a EXCEL'
        },
        list: {
          sport: 'Deporte',
          club: 'Club',
          teacher: 'Profesor',
          student: 'Alumno',
          LessonType: 'Tipo de clase',
          lessonPrice: 'Precio clase',
          feeImport: 'importe comisión',
          status: 'Estado',
          price: 'Precio',
          currency: '€',
          title: 'Título',
          paymentDate: 'Fecha de cobro',
          lessonDate: 'Fecha clase',
          fee: 'Comisión',
          teacherFee: 'Comisión profesor (50%)',
          teacherPayment: 'Pago a profesor',
          publicPrice: 'PVP',
          vat: 'IVA',
          type: 'Tipo',
          actions: {
            back: 'Volver',
            cancelBooking: 'Cancelar reserva'
          },
          numberOfStudents: 'Nº de alumnos',
          bookingDate: 'Fecha de reserva',
          serialCode: 'Nº de operación',
          stripeId: 'ID Stripe'
        },
        filters: {
          sport: 'Buscar por deporte',
          club: 'Buscar por club',
          teacher: 'Buscar por profesor',
          user: 'Buscar por alumno',
          lessonType: 'Buscar por tipo de clase',
          bookingStatus: 'Buscar por estado',
          searchTerm: 'Buscar por texto',
          active: 'Reservada',
          cancel: 'Cancelada',
          invoiced: 'Facturada',
          end: 'Finalizada'
        },
        cancelBooking: {
          success: 'Reserva cancelada correctamente'
        },
        types: {
          voucher: 'Bono'
        },
        pendingPayment: 'Pago pendiente'
      },
      invoices: {
        header: {
          title: 'Facturas'
        },
        tabs: {
          pending: 'Pendientes',
          invoiced: 'Facturadas',
          emitted: 'Emitidas'
        },
        list: {
          serialCode: 'Nº de factura',
          date: 'Fecha',
          total: 'Total €',
          subtotal: 'Subtotal €',
          taxRate: 'Tipo Impositivo %',
          vat: 'I.V.A €',
          currency: '€',
          country: 'País',
          region: 'Región',
          identification: 'Identificación',
          user: 'Cliente',
          address: 'Dirección',
          status: 'Estado',
          emitted: 'Emitida',
          invoiced: 'Facturada',
          pending: 'Pendiente'
        },
        filters: {
          emitted: 'Emitidas',
          invoiced: 'Facturadas',
          status: 'Buscar por estado',
          user: 'Buscar por cliente',
          serialCode: 'Buscar por número de factura',
          country: 'Buscar por país',
          region: 'Buscar por región',
          identification: 'Buscar por identificación',
          date: 'Buscar por fecha',
          from: 'Buscar desde',
          to: 'Buscar hasta'
        },
        notifications: {
          status: {
            success: 'Estado de la factura actualizado correctamente'
          }
        },
        csv: {
          title: 'Facturas',
          export: 'Exportar a EXCEL'
        }
      }
    },
    lessons: {
      header: {
        title: 'Clases'
      },
      menu: {
        title: 'Clases'
      }
    },
    courses: {
      yearly: {
        header: {
          title: 'Cursos anuales'
        },
        menu: {
          title: 'Cursos anuales'
        }
      },
      small: {
        header: {
          title: 'Cursillos'
        },
        menu: {
          title: 'Cursillos'
        }
      }
    },
    userData: {
      header: {
        title: 'Mis datos'
      },
      menu: {
        title: 'Mis datos'
      }
    },
    rooms: {
      header: {
        title: 'Espacios'
      },
      menu: {
        title: 'Espacios'
      }
    },
    linkedClubs: {
      header: {
        title: 'Clubs vinculados'
      },
      menu: {
        title: 'Clubs vinculados'
      }
    },
    register: {
      helpText:
        'Tu cuenta no estará disponible hasta que sea validada. Esto puede tardar hasta 24 horas',
      actions: {
        send: 'Enviar'
      }
    }
  }
};

export default esMessages;
