import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@mui/material';
import React from 'react';
import { useTranslate } from 'react-admin';

import parse from 'html-react-parser';
import { Button } from '../../components/shared/buttons/Button';

export const DialogTermsAndConditions = (props) => {
  const translate = useTranslate();

  const onCloseDialog = () => {
    props.setShowTermsModal(false);
  };

  return (
    <Dialog fullWidth open={props.showTermsModal} onClose={onCloseDialog}>
      <DialogTitle>
        {translate('resources.users.termsAndConditionsModal.title')}
      </DialogTitle>
      <DialogContent>{parse(props.termAndCondition.text)}</DialogContent>
      <DialogActions>
        <Button
          onClick={onCloseDialog}
          color="primary"
          label="resources.users.termsAndConditionsModal.acceptLabel"
        ></Button>
      </DialogActions>
    </Dialog>
  );
};
