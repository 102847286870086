import { get } from './httpClient.service';

const DEFAULT_ENTRY_POINT = 'user-parents';

export async function getLinkedTeachers(
  _start,
  _end,
  _sort,
  user_id
): Promise<any> {
  return get(`${DEFAULT_ENTRY_POINT}/linked-teachers/`, {
    _start,
    _end,
    _sort,
    user_id
  });
}
