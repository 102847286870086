import {
  VALIDATION_IS_GREATHER,
  VALIDATION_MORE_THAN_ZERO,
  VALIDATION_POSITIVE_NUMBER,
  VALIDATION_REQUIRED
} from '../../../../core/constants/validation.constants';
import {
  getMillis,
  getTimeMillisOfDate
} from '../../../../core/utils/date.utils';
import { isNumber, isObject } from '../../../../core/utils/object-utils';

export function courseFormValidator(values: any, smallCourse: boolean) {
  const errors: any = {};

  if (!values.title) {
    errors.title = VALIDATION_REQUIRED;
  }

  if (values.minimumStudents < 1 && typeof values.minimumStudents == 'number') {
    errors.minimumStudents = VALIDATION_MORE_THAN_ZERO;
  }

  if (!values.minimumStudents && typeof values.minimumStudents == 'object') {
    errors.minimumStudents = VALIDATION_REQUIRED;
  }

  if (values.maximumStudents < 1 && typeof values.maximumStudents == 'number') {
    errors.maximumStudents = VALIDATION_MORE_THAN_ZERO;
  }

  if (!values.maximumStudents && typeof values.maximumStudents == 'object') {
    errors.maximumStudents = VALIDATION_REQUIRED;
  }

  if (values.minimumStudents > values.maximumStudents) {
    errors.minimumStudents = VALIDATION_IS_GREATHER;
  }

  if (!values.dateStart) {
    errors.dateStart = VALIDATION_REQUIRED;
  }

  if (!values.dateEnd) {
    errors.dateStart = VALIDATION_REQUIRED;
  }

  if (getMillis(values.dateStart) > getMillis(values.dateEnd)) {
    errors.dateStart = VALIDATION_IS_GREATHER;
  }

  if (!values.registrationDeadline) {
    errors.registrationDeadline = VALIDATION_REQUIRED;
  }

  if (!values.dayTypes) {
    errors.dayTypes = VALIDATION_REQUIRED;
  }

  if (getMillis(values.registrationDeadline) > getMillis(values.dateStart)) {
    errors.registrationDeadline = VALIDATION_IS_GREATHER;
  }

  if (
    ((typeof values.timeStart === 'number' && values.timeStart < 0) ||
      !values.timeStart) &&
    typeof values.timeStart !== 'undefined' &&
    values.timeStart < 0
  ) {
    errors.timeStart = VALIDATION_REQUIRED;
  }

  if (
    ((typeof values.timeEnd === 'number' && values.timeStart < 0) ||
      !values.timeEnd) &&
    typeof values.timeEnd !== 'undefined' &&
    values.timeEnd < 0
  ) {
    errors.timeEnd = VALIDATION_REQUIRED;
  }

  if (
    (typeof values.timeStart === 'object' &&
      typeof values.timeEnd === 'object' &&
      getTimeMillisOfDate(values.timeStart) >=
        getTimeMillisOfDate(values.timeEnd)) ||
    (typeof values.timeStart === 'object'
      ? getTimeMillisOfDate(values.timeStart) >= values.timeEnd
      : typeof values.timeEnd === 'object'
      ? values.timeStart >= getTimeMillisOfDate(values.timeEnd)
      : values.timeStart >= values.timeEnd)
  ) {
    errors.timeStart = VALIDATION_IS_GREATHER;
  }

  if (
    values.freeCancellationPeriod < 0 &&
    isNumber(values.freeCancellationPeriod)
  ) {
    errors.freeCancellationPeriod = VALIDATION_POSITIVE_NUMBER;
  }

  if (
    !values.freeCancellationPeriod &&
    isObject(values.freeCancellationPeriod)
  ) {
    errors.freeCancellationPeriod = VALIDATION_REQUIRED;
  }

  if (values.uniquePrice < 0 && isNumber(values.uniquePrice)) {
    errors.uniquePrice = VALIDATION_POSITIVE_NUMBER;
  }

  if (values.quarterlyPrice < 0 && isNumber(values.quarterlyPrice)) {
    errors.quarterlyPrice = VALIDATION_POSITIVE_NUMBER;
  }

  if (values.monthlyPrice < 0 && isNumber(values.monthlyPrice)) {
    errors.monthlyPrice = VALIDATION_POSITIVE_NUMBER;
  }

  if (smallCourse && !values.annualPrice && isObject(values.annualPrice)) {
    errors.annualPrice = VALIDATION_REQUIRED;
  }

  if (
    !smallCourse &&
    !values.uniquePrice &&
    isObject(values.uniquePrice) &&
    !values.quarterlyPrice &&
    isObject(values.quarterlyPrice) &&
    !values.monthlyPrice &&
    isObject(values.monthlyPrice)
  ) {
    errors.uniquePrice = 'validation.onePriceRequired';
    errors.quarterlyPrice = 'validation.onePriceRequired';
    errors.monthlyPrice = 'validation.onePriceRequired';
  }

  if (
    values.fromYears > values.toYears ||
    values.fromYears < 0 ||
    values.toYears < 0
  ) {
    errors.fromYears = VALIDATION_IS_GREATHER;
  }

  if (values.fromYears < 0) {
    errors.fromYears = VALIDATION_MORE_THAN_ZERO;
  }

  if (values.toYears < 0) {
    errors.toYears = VALIDATION_MORE_THAN_ZERO;
  }

  if (values.userTypes.length === 0) {
    errors.userTypes = VALIDATION_REQUIRED;
  }

  return errors;
}
