import { DialogActions } from '@mui/material';
import React from 'react';
import { SaveButton } from 'react-admin';
import { Button } from '../../../components/shared/buttons/Button';

export const ShareEmailDialogToolBar = (props) => {
  const oncloseEmailDialog = () => {
    props.setShowEmailDialog(false);
  };

  return (
    <DialogActions>
      <Button
        onClick={oncloseEmailDialog}
        color="secondary"
        label="resources.users.restorePasswordModal.cancelLabel"
        style={{ marginLeft: '1rem' }}
      ></Button>
      <SaveButton
        color="primary"
        label="resources.users.restorePasswordModal.acceptLabel"
        style={{ marginLeft: '1rem' }}
      />
    </DialogActions>
  );
};
