import React, { useContext, useEffect, useState } from 'react';
import { UserHashContext } from '../../../../core/context/userHashContext';
import {
  Datagrid,
  DateField,
  FilterButton,
  FunctionField,
  List,
  Loading,
  TextField,
  TopToolbar,
  usePermissions,
  useTranslate
} from 'react-admin';
import { CustomPagination } from '../../../../components/general/Pagination';
import { EmptyList } from '../../../../components/general/EmptyList';
import { HeaderTitleContext } from '../../../../core/context/headerTitleContext';
import { PRIVILEGE_ADMIN } from '../../../../core/constants/privilege.constants';
import { PaymentDownloadXMLButton } from './PaymentDownloadXMLButton';
import { PaymentExport } from './paymentExport';
import { PaymentFilters } from './paymentFilter/PaymentFilters';
import { PaymentsUpdateStatusButton } from './PaymentsUpdateStatusButton';
import { StatusField } from './StatusField';
import { PaymentDownloadXMLBulkActionButton } from './PaymentDownloadXMLBulkActionButton';
import { formatDecimal } from '../../../../core/utils/number-utils';
import PaymentView from '../paymentsView/PaymentsView';

const PaymentBulkActions = (props) => (
  <>
    <PaymentsUpdateStatusButton />
    <PaymentDownloadXMLBulkActionButton />
  </>
);

export const PaymentsList = ({ status }) => {
  const { userHash } = useContext(UserHashContext);
  const { setHeaderTitle } = useContext(HeaderTitleContext);
  const translate = useTranslate();
  const permissions = usePermissions();
  const isAdmin = permissions.permissions === PRIVILEGE_ADMIN;
  const [isLoading, setisLoading] = useState(false);

  useEffect(() => {
    setHeaderTitle('resources.invoicing.payments.header.title');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAmount = (record) => {
    return translate('resources.invoicing.payments.list.currency', {
      value: formatDecimal(record.amount)
    });
  };

  const PaymentListActions = (props) => (
    <TopToolbar>
      <FilterButton />
      <PaymentExport setisLoading={setisLoading} status={status} />
    </TopToolbar>
  );

  if (!userHash || isLoading) return <Loading />;

  return (
    <>
      <List
        resource={'payments-request'}
        sort={{ field: 'requestDate', order: 'DESC' }}
        exporter={false}
        actions={<PaymentListActions />}
        perPage={Number(process.env.REACT_APP_LIST_SIZE)}
        pagination={<CustomPagination />}
        empty={<EmptyList />}
        filters={PaymentFilters}
        filter={{ status }}
      >
        <Datagrid
          bulkActionButtons={<PaymentBulkActions />}
          rowClick="expand"
          expand={<PaymentView />}
        >
          {isAdmin && (
            <TextField
              source="user.firstName"
              sortBy="user.firstName"
              label={'resources.invoicing.payments.list.user'}
              emptyText="-"
            />
          )}
          <FunctionField
            sortBy="amount"
            label="resources.invoicing.payments.list.amount"
            render={(record) => (
              <>
                Total: <b>{getAmount(record)}</b>
              </>
            )}
          />
          <DateField
            source="dateRequest"
            sortBy="requestDate"
            label={'resources.invoicing.payments.list.date'}
            emptyText="-"
          />
          <StatusField label={'resources.invoicing.payments.list.status'} />
          <PaymentDownloadXMLButton />
        </Datagrid>
      </List>
    </>
  );
};
