import defaultMessages from 'ra-language-english';
import { TranslationMessages } from 'react-admin';

const enMessages: TranslationMessages = {
  ...defaultMessages,
  validation: {
    email: 'Enter a valid email',
    emails: 'Enter valid emails separated by ";"',
    maxLength: 'Max length %{max} characters or less',
    maxValue: 'Must be %{max} or less',
    minLength: 'Must contain %{min} characters at least',
    minValue: 'It must be at least %{min}',
    number: 'Must be a number',
    oneOf: 'Must be one of: %{options}',
    passwordMismatched: 'Passwords do not match',
    regex: 'Must match a specific format (regexp): %{pattern}',
    required: 'Required',
    isGreater: 'Must be less',
    moreThanZero: 'Must be greater than 0',
    moreThanOne: 'Must be greater than 1',
    positiveNumber: 'Must be a positive number',
    dateTodayOrGreater: 'Must be a date equal to or later than today',
    nifNotValid: 'The ID is not valid',
    dateOneYearOrLess: 'Must be a date less than one year',
    onePriceRequired: 'There must be at least one price',
    noSpaces: 'No spaces allowed'
  },
  errors: {
    email_exist: 'Email already exists',
    email_is_deleted_exception: 'This email has been used',
    invalid_file: 'Invalid file',
    is_not_club_user_exception: 'The club you are associating is not a club',
    login: 'Wrong email or password or pending validation',
    not_privilege_exception: 'You do not have enough privileges',
    password_mismatch: 'Password mismatch',
    province_not_found: 'Region not found',
    recover_password_not_found: 'Recovery password not found',
    sport_not_found: 'Sport not found',
    unrecognized_error:
      'An error has occurred, please contact the web administrator',
    user_not_found: 'User not found',
    user_is_not_a_teacher: 'User is not an instructor',
    lesson_not_found: 'Lesson not found',
    user_parent_not_found: 'Linked user not found',
    course_minimum_students_greater_exception:
      'The minimum number of students is greater than the maximum',
    course_date_start_greater_than_date_end_exception:
      'The start date is greater than the end date',
    course_registration_deadline_greater_than_date_start_exception:
      'The registration date is greater than the start date',
    course_registration_deadline_greater_than_date_end_exception:
      'The registration date is greater than the end date',
    user_parent_exist_exception: 'Linked user already exists',
    course_not_found: 'Course not found',
    extension_not_valid: 'Invalid extension',
    voucher_not_found: 'Voucher not found',
    user_is_student_exception: 'User is student',
    room_not_found: 'Space not found',
    availability_not_found: 'Availability not found',
    event_not_found: 'Event not found',
    lesson_event_not_found: 'Lesson event not found',
    not_availability: 'No availability',
    user_voucher_not_found: 'User voucher not found',
    notification_not_found: 'Notification not found',
    user_payment_method_is_favorite: 'The payment method is marked as favorite',
    user_payment_method_has_pending_payments:
      'The payment method has pending payments',
    lesson_maximum_duration_greater_exception:
      'Maximum duration of the class cannot be more than 4 hours',
    lesson_has_not_available_price: 'The class has no price available',
    lesson_has_not_set_any_price: 'The lesson does not have any set price',
    lesson_minimum_students_greater_exception:
      'The minimum number of students must be greater',
    create_zip_error: 'Error creating zip',
    course_price_not_set: 'The price of the course is not established',
    user_is_not_a_teacher_or_club: 'User is not an instructor or club',
    lesson_maximum_students_smaller_exception:
      'Limit of students reached for the lesson',
    lesson_level_not_found: 'Lesson level not found',
    lesson_level_price_not_found: 'Lesson level price not found',
    term_and_conditions_not_found: 'Terms and Conditions not found',
    length_not_valid: 'Invalid length',
    user_is_disabled_exception: 'The user is disabled',
    user_is_candidate_exception: 'The user is a candidate',
    user_has_not_account_number: 'The user does not have an account number',
    booking_not_found: 'Booking not found',
    payment_session_not_found: 'Payment session not found',
    payment_method_not_found: 'Payment method not found',
    payment_not_found: 'Payment not found',
    payment_customer_failed: 'Customer payment failure',
    user_has_not_country: 'User has no country',
    user_has_not_permission: 'The user does not have permission',
    user_is_not_a_student: 'The user is not a student',
    global_operation_not_found: 'Global operation not found',
    invoice_not_found: 'Invoice not found',
    event_and_course_not_found: 'Event and course not found',
    existing_valid_voucher: 'Existing valid voucher',
    invoice_data_setting_not_found: 'Billing data not found',
    user_parent_not_linked: 'Not linked',
    event_maximum_students: 'Maximum number of students for the event',
    event_hidden: 'Hidden event',
    user_hash_booking: 'User already has reservation',
    limit_to_book_exception: 'Limit exception to book',
    voucher_not_valid: 'Invalid voucher',
    overlapped_events_exception:
      'Busy schedule. To put a class, course or availability on this schedule it must be removed before the previous event or availability.',
    not_club_privilege_exception:
      'You do not have enough privileges. This action can only be carried out by the Club',
    equipment_rent_event_maximum_duration_greater_exception:
      'The start date cannot be later than the end date of the event',
    equipment_rent_event_not_minimum_duration:
      'The event duration does not meet the minimum time requirement for the selected equipment'
  },
  resources: {
    breadcrumbs: {
      home: 'Home',
      users: 'Users',
      teachers: 'Instructors',
      students: 'Students',
      clubs: 'Clubs',
      termsAndConditions: 'Terms and Conditions',
      create: 'New',
      sports: 'Sports',
      privacyPolicies: 'Privacy Policies',
      equipmentRents: 'Equipment rental',
      equipmentRentsCalendar: 'Equipment rental calendar',
      equipmentRentsScheduler: 'Equipment rental schedule',
      vouchers: 'Vouchers',
      createLinkedTeachers: 'Create linked instructors',
      share: 'Share app',
      calendar: 'Schedule settings',
      activeVouchers: 'Active vouchers',
      notifications: 'Notifications',
      invoicingData: 'Billing information',
      schedule: 'Calendar',
      paymentRequest: 'Virtual wallet',
      lessons: 'Lessons',
      smallCourse: 'Mini Courses',
      yearlyCourse: 'Courses',
      myData: 'My data',
      rooms: 'Spaces',
      globalOperations: 'Global operations',
      invoices: 'Invoices',
      payments: 'Payments',
      invoiceDataSettings: 'Default billing information',
      linkedClubs: 'Linked Clubs'
    },
    navigation: {
      prev: 'Back'
    },
    auth: {
      generate_password: {
        form: {
          confirmPassword: 'Repeat password',
          password: 'Password'
        },
        subtitle: 'Generate password',
        title: 'Nak Sports'
      },
      recovery_password: {
        register: 'User sign up',
        button: 'Send',
        link: 'here',
        message:
          'Please enter your email below to receive instructions on how to recover your password. If you have accessed this section inadvertently, you can return to the beginning by clicking ',
        title: 'Nak Sports'
      },
      remind_user: 'Keep me logged in',
      reset_password: {
        form: {
          confirmPassword: 'Confirm password',
          password: 'Password'
        },
        subtitle: 'Update password',
        title: 'Nak Sports'
      },
      recovery_password_button: 'Have you forgotten your password?'
    },
    users: {
      header: {
        titleTeacher: 'Instructors',
        titleStudent: 'Students',
        titleClub: 'Clubs'
      },
      calendar: {
        messages: {
          agenda: 'Calendar',
          allDay: 'All day',
          month: 'Month',
          day: 'Day',
          today: 'Today',
          previous: 'Previous',
          next: 'Next',
          date: 'Date',
          noEventsInRange: 'There are no events in this date range',
          time: 'Time',
          tomorrow: 'Tomorrow',
          week: 'Week',
          work_week: 'Work week',
          yesterday: 'Yesterday',
          january: 'January',
          available: 'Available',
          room: 'Room'
        },
        dialog: {
          fields: {
            category: 'Category',
            availability: 'Availability',
            lesson: 'Lesson',
            fullLesson: 'Full lesson',
            lessonType: 'Class type',
            iteration: 'Repeat every',
            dayType: 'Repeats on',
            end: 'Ends',
            afterDate: 'After date',
            afterIterations: 'After num. of repetitions',
            day: 'Day',
            week: 'Week',
            month: 'Months',
            room: 'Space',
            course: 'Course',
            smallCourse: 'Mini course',
            teacher: 'Instructor',
            start: 'Starts',
            timeIteration: 'Num. of repetitions'
          },
          tooltip: {
            title: '+ Info',
            repeatEachMonth: 'EN_Se repetirá cada mes los días indicados ',
            onlyForWeek: 'EN_sólo de la semana en curso ',
            days: 'EN_(1ª, 2ª, 3ª, 4ª ó 5ª del mes).',
            toRepeat: 'EN_Para repetir ',
            all: 'EN_todas ',
            pleaseSelect: 'EN_las semanas por favor selecciona “Repetir cada: ',
            week: 'EN_Semana'
          }
        },
        availability: {
          title: 'Availability',
          start: 'Start: ',
          end: 'End: ',
          actions: {
            delete: 'Delete',
            deleteSeries: 'Delete all'
          },
          delete: {
            title: 'Remove availability',
            message: 'Are you sure you want to remove this availability?',
            success: 'Availability successfully removed'
          }
        },
        equipmentRent: {
          title: 'Equipment rent',
          start: 'Start: ',
          end: 'End: ',
          room: 'Room: ',
          actions: {
            delete: 'Delete'
          },
          delete: {
            title: 'Remove equipment rental',
            message: 'Are you sure you want to remove this equipment rental?',
            success: 'Equipment rental successfully removed'
          }
        },
        lessonEvent: {
          title: 'Lesson',
          start: 'Start: ',
          end: 'End: ',
          room: 'Room: ',
          club: 'Club: ',
          teacher: 'Teacher: ',
          group: {
            title: 'Users: '
          },
          actions: {
            oneEvent: 'Edit',
            allEvents: 'Edit all',
            delete: 'Delete',
            deleteSeries: 'Delete all'
          },
          delete: {
            title: 'Delete lesson event',
            message: 'Are you sure you want to remove this lesson event?',
            success: 'Lesson event successfully removed'
          }
        },
        courseEvent: {
          title: 'Course',
          start: 'Start: ',
          end: 'End: ',
          room: 'Room: ',
          club: 'Club: ',
          teacher: 'Teacher: ',
          actions: {
            delete: 'Delete',
            deleteSeries: 'Delete all'
          },
          delete: {
            title: 'Delete course event',
            message: 'Are you sure you want to delete this course event?',
            success: 'Course event successfully removed'
          }
        },
        filter: {
          allLessonTypes: 'All lesson types',
          aloneLessonType: 'Private',
          collectiveLessonType: 'Collective',
          teacher: 'Instructor',
          room: 'Space'
        }
      },
      actions: {
        createClub: 'New Club',
        createStudent: 'New student',
        createTeacher: 'New instructor',
        delete: 'Delete account',
        disable: 'Disable',
        disableAccount: 'Disable account',
        enableAccount: 'Enable account',
        enable: 'Enable',
        notValidateAndDelete: 'Do not validate and delete account',
        restorePassword: 'Recover password',
        validate: 'Validate candidate',
        viewAll: 'See all',
        viewCandidates: 'See Candidates',
        viewValidated: 'See Validated',
        unlink: 'Unlink',
        createLinkedTeachers: 'Create linked instructors',
        validateCustom: {
          club: 'Validate Club',
          student: 'Validate Student',
          teacher: 'Validate instructor',
          user: 'Validate user'
        },
        cancel: 'Cancel',
        saveChanges: 'Save changes'
      },
      card: {
        entryDate: 'Entry date: '
      },
      delete: {
        title: 'Delete account',
        message: 'Are you sure you want to delete this account?'
      },
      unlink: {
        title: 'Unlink account',
        message: 'Are you sure you want to unlink the account?'
      },
      tabs: {
        access: 'Access',
        annualCourses: 'Courses',
        billingData: 'Billing information',
        calendar: 'Schedule settings',
        courses: 'Mini courses',
        class: 'Lessons',
        equipmentRent: 'Equipment rental',
        generalData: 'General and account data',
        schedule: 'Calendar',
        vouchers: 'Vouchers',
        activeVouchers: 'Active vouchers',
        favouriteSports: 'Favorite sports',
        historical: 'History',
        linkedClubs: 'Linked Clubs',
        linkedTeachers: 'Linked instructors',
        rooms: 'Spaces',
        paymentMethods: 'Payment methods',
        equipmentRentsScheduler: 'Equipment rental Calendar'
      },
      fields: {
        address: 'Address',
        category: 'Category',
        club: 'Club',
        clubName: 'Club/School name',
        identification: 'Identification',
        status: 'Status',
        pending: 'Pending',
        linked: 'Linked',
        verified: 'Verified',
        disabled: 'Disabled',
        region: 'Region',
        city: 'City',
        confirmPassword: 'Confirm password',
        date: 'Date',
        email: 'Email',
        firstName: 'Name',
        firstSport: 'Sport',
        lastName: 'Surname',
        managerFirstName: 'Manager name',
        nif: 'ID',
        nifHelperText:
          'The DNI must have 8 numbers and a letter: Ej: 12345678A',
        password: 'Password',
        province: 'Region',
        secondLastName: 'Surname 2',
        sport: 'Sport',
        student: 'Student',
        surnames: 'Surname',
        teacher: 'Instructor',
        telephone: 'Telephone',
        text: 'Text',
        generalDataTitle: 'General data',
        image: 'Image',
        other: 'Other',
        documentOption: 'Document type',
        cif: 'ID',
        country: 'Country',
        accept: 'I accept the ',
        termsAndConditions: 'Terms and Conditions ',
        privacyPolicies: 'and Privacy Policies',
        accountNumber: 'Account number',
        accountNumberHelperText:
          'Nothing will be charged to this account. Just to receive payments.',
        lessonsCount: 'Total number of lessons',
        lessonsCountInMonth: 'Total number of lessons in last month',
        lesson: {
          title: 'Lesson title',
          minimumNotice: 'Minimum advance notice to book',
          minimumNoticeDays: 'Days',
          minimumNoticeHours: 'Hours',
          userType: 'Addressed to',
          email: 'Email',
          level: 'Level',
          fromYears: 'From (years old)',
          toYears: 'To (years old)',
          years: 'Years old',
          material: 'Available material for lesson',
          minimumStudents: 'Minimum number of students',
          maximumStudents: 'Maximum number of students',
          freeCancellationPeriod:
            'Days in advance for cancellation without charge',
          paymentType: 'Payment method',
          disable: 'Inactive / Active',
          price: 'Price',
          lessonPrice: '€/lesson',
          lessonType: 'Lesson type',
          lessonPriceAdvice:
            'Not leaving any set price may result in the lesson not being able to be booked',
          place: 'Place',
          teacher: 'Teacher'
        }
      },
      filters: {
        name: 'Search by complete name',
        lastName: 'Search by last name',
        phone: 'Search by phone',
        sport: 'Search by sport',
        country: 'Search by country',
        region: 'Search by region',
        city: 'Search by city',
        searchTerm: 'Search by text',
        clubName: 'Search by club'
      },
      rooms: {
        actions: {
          create: 'Create space'
        },
        list: {
          name: 'Name',
          userLimit: 'User limit',
          empty: 'No spaces found'
        },
        notifications: {
          create: {
            success: 'Space created successfully'
          },
          editStatus: {
            success: 'Space status updated successfully'
          },
          edit: {
            success: 'Space updated successfully'
          },
          delete: {
            success: 'Space deleted successfully'
          }
        },
        delete: {
          title: 'Delete space',
          message: 'Are you sure you want to delete this space?'
        },
        fields: {
          name: 'Name',
          userLimit: 'User limit'
        }
      },
      linkedClubs: {
        list: {
          clubName: 'Club name',
          status: 'Status',
          linkButton: 'Link club',
          firstName: 'First name',
          secondName: 'Second name',
          empty: {
            title: 'There are no linked clubs'
          }
        },
        delete: {
          title: 'Remove link',
          message: 'Are you sure you want to remove this link?'
        },
        link: {
          title: 'Link club',
          message: 'Are you sure you want to link with this club?'
        }
      },
      linkedTeachers: {
        list: {
          clubName: 'Club name',
          status: 'Status',
          linkButton: 'Link club',
          firstName: 'First name',
          secondName: 'Second name',
          empty: 'There are no linked instructors'
        },
        create: {
          form: {
            name: 'Name',
            title: 'Data of the new instructor',
            buttons: {
              back: 'Cancel',
              save: 'Link',
              saveAndAdd: 'Link and add another'
            }
          }
        },
        delete: {
          title: 'Remove link',
          message: 'Are you sure you want to remove this link?'
        },
        link: {
          title: 'Link club',
          message: 'Are you sure you want to link with this club?'
        },
        actions: {
          create: 'Link instructor'
        }
      },
      menu: {
        clubs: 'Clubs',
        students: 'Students',
        teachers: 'Instructors'
      },
      disableModal: {
        field: {
          reason: 'Reason'
        },
        title: 'Disable user'
      },
      deleteModal: {
        field: {
          reason: 'Reason'
        },
        title: 'Delete user'
      },
      enableModal: {
        field: {
          reason: 'Reason'
        },
        title: 'Enable user'
      },
      validateModal: {
        title: 'Validate candidate',
        message: 'Are you sure you want to validate the candidate?'
      },
      name: 'User |||| Users',
      notifications: {
        generatePassword: {
          success: 'User password generated successfully'
        },
        passwordRecovery: {
          success:
            'An email has been sent to your email to recover the password'
        },
        resetPassword: {
          success: 'Password updated successfully'
        },
        userCreate: {
          success: 'User created successfully'
        },
        userDisable: {
          success: 'User successfully disabled'
        },
        userEnable: {
          success: 'User enabled successfully'
        },
        userValidate: {
          success: 'User validated successfully'
        },
        regionsNotFound: 'No regions found',
        citiesNotFound: 'No cities found',
        userEdit: {
          success: 'User updated successfully',
          lesson: {
            status: {
              success: 'Lesson status updated successfully'
            },
            delete: {
              success: 'Lesson successfully deleted'
            }
          },
          linkedClubs: {
            delete: {
              success: 'Linking removed successfully'
            },
            link: {
              success: 'Linking successfully approved'
            }
          },
          linkedTeachers: {
            delete: {
              success: 'Linking removed successfully'
            },
            create: {
              success: 'Linking created successfully'
            }
          }
        },
        imageSize: {
          error: 'Image cannot be larger than %{max}MB'
        },
        extension: {
          error: 'Invalid image extension'
        },
        userRegister: {
          success: 'User successfully registered'
        },
        useRestore: {
          success: 'Successfully sent the recovery email to the user'
        },
        userDelete: {
          success: 'User deleted successfully'
        },
        lessonCreate: {
          success: 'Lesson created successfully'
        },
        lessonUpdate: {
          success: 'Lesson updated successfully'
        },
        availabilityCreate: {
          success: 'Availability created successfully'
        }
      },
      restorePasswordModal: {
        title: 'Restore password',
        message: 'Do you want the user to reset the password?',
        acceptLabel: 'Accept',
        cancelLabel: 'Cancel'
      },
      termsAndConditionsModal: {
        title: 'Terms and Conditions',
        acceptLabel: 'Accept'
      },
      privacyPoliciesModal: {
        title: 'Privacy Policy',
        acceptLabel: 'Accept'
      },
      generalData: {
        security: {
          title: 'Security'
        }
      },
      lessons: {
        actions: {
          create: 'Add new lesson type'
        },
        list: {
          lessonTitle: 'Lesson title',
          target: 'Addressed to',
          level: 'Level',
          lessonType: 'Lesson type',
          activeInactive: 'Disabled / Enabled',
          years: 'Years old',
          empty: 'No lessons created',
          teacher: 'Teacher'
        },
        popover: {
          disabled: 'Disabled',
          enabled: 'Enabled'
        },
        delete: {
          title: 'Delete lesson',
          message: 'Are you sure you want to delete the lesson?'
        }
      },
      course: {
        fields: {
          title: 'Course Title',
          userType: 'Addressed to',
          fromYears: 'From (years old)',
          toYears: 'to (years old)',
          level: 'Level',
          minimumStudents: 'Minimum number of students',
          maximumStudents: 'Maximum number of students',
          dateStart: 'Start date',
          dateEnd: 'End date',
          registrationDeadline: 'Deadline to sign up',
          timeStart: 'Start time',
          timeEnd: 'End time',
          place: 'Space',
          price: 'Price',
          paymentType: 'Payment method',
          freeCancellationPeriod:
            'Days in advance for cancellation without charge.',
          disable: 'Disabled / Enabled',
          dayType: 'Course days',
          quarterlyPrice: 'Quarterly price',
          monthlyPrice: 'Monthly price',
          annualPrice: 'Annual price'
        },
        list: {
          courseTitle: 'Course Title',
          target: 'Addressed to',
          level: 'Level',
          activeInactive: 'Disabled / Enabled',
          years: 'Years old',
          empty: 'No courses created',
          dateStart: 'Start date',
          dateEnd: 'End date',
          courseDays: 'Course days',
          timing: 'Schedule'
        },
        popover: {
          disabled: 'Disabled',
          enabled: 'Endabled'
        },
        actions: {
          create: 'Add new course',
          createSmallCourse: 'add new Mini course'
        },
        delete: {
          title: 'Delete course',
          smallCourseTitle: 'Delete Mini course',
          message: 'Are you sure you want to delete the course?',
          smallCourseMessage: 'Are you sure you want to delete the Mini course?'
        },
        notifications: {
          courseCreate: {
            success: 'Course created successfully'
          },
          editStatus: {
            success: 'Course status updated successfully'
          },
          editCourse: {
            success: 'Course updated successfully'
          },
          deleteCourse: {
            success: 'Course successfully deleted'
          }
        }
      },
      vouchers: {
        actions: {
          create: 'Add new voucher'
        },
        fields: {
          title: 'Voucher title',
          lessonsNumber: 'Number of lessons',
          price: 'Voucher price',
          disable: 'Disabled / Enabled',
          time: 'Time',
          timeRange: 'Time range',
          category: 'Type of lessons',
          conditions: 'Voucher details'
        },
        list: {
          title: 'Voucher title',
          lessons: 'Lessons',
          lessonNumber: 'Number of lessons',
          price: 'Voucher price',
          activeInactive: 'Disabled / Enabled',
          currency: '€',
          empty: {
            title: 'No vouchers created',
            buttonLabel: 'Add voucher'
          }
        },
        popover: {
          disabled: 'Disabled',
          enabled: 'Enabled'
        },
        delete: {
          title: 'Delete voucher',
          message: 'Are you sure you want to remove this voucher?'
        },
        notifications: {
          create: {
            success: 'Voucher created successfully'
          },
          editStatus: {
            success: 'Voucher status updated successfully'
          },
          edit: {
            success: 'Voucher updated successfully'
          },
          delete: {
            success: 'Voucher successfully removed'
          }
        }
      },
      userVouchers: {
        list: {
          title: 'Voucher title',
          club: 'Club',
          teacher: 'Instructor',
          lessonNumber: 'Number of lessons',
          recurrent: 'Automatic renewal',
          progress: 'Progress',
          pending: 'Pending: ',
          totalLessons: 'Total: ',
          usedLessons: 'Used: ',
          empty: {
            title: 'No vouchers'
          }
        },
        delete: {
          title: 'Delete voucher',
          message: 'Are you sure you want to remove this voucher?'
        },
        notifications: {
          delete: {
            success: 'Voucher successfully removed'
          },
          editRecurrent: {
            success: 'Successfully Updated Recurring voucher'
          }
        },
        popover: {
          disabled: 'Non-recurring',
          enabled: 'Recurring'
        }
      },
      equipmentRents: {
        actions: {
          create: 'Add equipment to rent'
        },
        list: {
          name: 'Equipment',
          time: 'Rental duration',
          price: 'Price',
          currency: '€',
          empty: {
            title: 'There is no equipment to rent',
            buttonLabel: 'Add equipment to rent'
          }
        },
        fields: {
          name: 'Equipment name',
          time: 'Duration',
          price: 'Rental price (€)'
        },
        notifications: {
          create: {
            success: 'Equipment Rental created successfully'
          },
          edit: {
            success: 'Equipment Rental successfully updated'
          },
          delete: {
            success: 'Equipment Rental successfully deleted'
          }
        },
        delete: {
          title: 'Delete equipment rental',
          message: 'Are you sure you want to remove this equipment rental?'
        }
      },
      invoiceData: {
        fields: {
          accountNumber: 'Account number (IBAN)',
          fixedMonthFee: 'Monthly fee',
          bookingFee: 'Booking fee (%)',
          bookingFixFee: 'Fixed booking fee (€)'
        },
        notifications: {
          update: {
            success: 'Billing information updated correctly'
          }
        }
      },
      bookings: {
        list: {
          name: 'Booking name',
          date: 'Booking date',
          startDate: 'Start date',
          endDate: 'End date',
          bookingType: 'Booking type',
          teacherName: 'Instructor',
          back: 'Back',
          type: {
            course: 'Course',
            smallCourse: 'Mini Courses',
            lesson: 'Lesson',
            equipmentRent: 'Equipment Rental'
          },
          weekDay: 'Week day',
          time: 'Time'
        }
      },
      userPaymentMethods: {
        list: {
          brand: 'Card type',
          cardNumber: 'Card number',
          expirationDate: 'Expiration date'
        },
        notifications: {
          delete: {
            success: 'Payment method successfully removed'
          },
          favourite: {
            success: 'Payment method successfully marked as favorite'
          }
        },
        delete: {
          title: 'Delete payment method',
          message: 'Are you sure you want to delete this payment method?'
        },
        cantDelete: {
          title: 'Delete payment method',
          message:
            'You can not delete this payment method because it has pending payments',
          buttonLabel: 'Accept'
        },
        cantUpdateStatus: {
          title: 'Unmark as favorite',
          message:
            'You can not unmark this payment method as a favorite because it is the only one you have',
          buttonLabel: 'Accept'
        }
      },
      userFavouriteSports: {
        list: {
          name: 'Sport name',
          icon: 'Icon',
          firstSport: 'First sport'
        }
      }
    },
    list: {
      empty: {
        title: 'No data available'
      }
    },
    activeVouchers: {
      header: {
        title: 'Active vouchers'
      },
      menu: {
        title: 'Active vouchers'
      },
      list: {
        user: 'User'
      }
    },
    configuration: {
      menu: {
        termsAndConditions: 'Terms and Conditions',
        sports: 'Sports',
        privacyPolicies: 'Privacy Policies',
        share: 'Share app',
        invoicingData: 'Billing information',
        invoiceDataSettings: 'Billing information'
      },
      header: {
        title: 'Settings'
      },
      termsAndConditions: {
        title: 'Terms and Conditions',
        delete: {
          title: 'Delete Terms and Conditions',
          message: 'Are you sure you want to remove the Terms and Conditions?'
        },
        notifications: {
          delete: {
            success: 'Terms and Conditions successfully removed'
          },
          accept: {
            success: 'Terms and Conditions accepted correctly'
          }
        },
        list: {
          actions: {
            create: 'Add new Term and Conditions'
          }
        },
        fields: {
          title: 'Title',
          date: 'Date',
          text: 'Text',
          privilege: 'Privilege',
          student: 'Student',
          teacher: 'Instructor',
          club: 'Club'
        },
        create: {
          success: 'Terms and Conditions created successfully'
        },
        edit: {
          success: 'Terms and Conditions Updated successfully'
        }
      },
      privacyPolicies: {
        title: 'Privacy Policies',
        delete: {
          title: 'Delete Privacy Policy',
          message: 'Are you sure you want to delete the Privacy Policy?'
        },
        notifications: {
          delete: {
            success: 'Privacy Policy successfully removed'
          },
          accept: {
            success: 'Privacy Policy accepted correctly'
          }
        },
        list: {
          actions: {
            create: 'Add new Privacy policy'
          }
        },
        fields: {
          title: 'Title',
          date: 'Date',
          text: 'Text',
          privilege: 'Privilege',
          student: 'Student',
          teacher: 'Instructor',
          club: 'Club',
          lessons: 'Lessons'
        },
        create: {
          success: 'Privacy Policy created successfully'
        },
        edit: {
          success: 'Privacy Policy updated successfully'
        }
      },
      sports: {
        title: 'Sports',
        fields: {
          name: 'Sport name',
          color: 'Assigned color',
          icon: 'Icon',
          sport: 'Sport'
        },
        list: {
          actions: {
            create: 'Add new sport type'
          }
        },
        delete: {
          title: 'Delete sport type',
          message: 'Are you sure you want to delete this sport type?'
        },
        create: {
          title: 'Create sport type'
        },
        notifications: {
          create: {
            success: 'Sport type created successfully'
          },
          delete: {
            success: 'Sport type removed successfully'
          },
          edit: {
            success: 'Sport type updated successfully'
          }
        }
      },
      share: {
        title: 'Share app',
        whatsapp: 'Whatsapp',
        facebookIos: 'Facebook (iOS)',
        facebookAndroid: 'Facebook (Android)',
        twitter: 'Twitter',
        email: 'Email',
        message: 'Download the NAK App, IOS: %{iosUrl}, Android: %{androidUrl}',
        emailDialog: {
          title: 'Share App by email',
          sendEmail: {
            success: 'Emails sent successfully'
          }
        }
      },
      invoicingDataSettings: {
        title: 'Billing information',
        fields: {
          fixedMonthFee: 'Monthly fee',
          bookingFee: 'Booking fee (%)',
          bookingFixFee: 'Fixed booking fee (€)',
          iban: 'IBAN',
          bic: 'BIC',
          debtorId: 'Debtor ID'
        },
        notifications: {
          update: {
            success: 'Billing information updated correctly'
          }
        }
      }
    },
    shared: {
      form: {
        toolbar: {
          actions: {
            cancel: 'Cancel',
            saveChanges: 'Save changes'
          }
        }
      }
    },
    termsAndConditionsAndPrivacyPolicy: {
      titleTerms: 'New Terms and Conditions',
      titlePrivacy: 'New Privacy Policy',
      buttonLabel: 'Accept new Terms and Conditions and Privacy Policy',
      notifications: {
        accept: {
          success: 'Terms and Policies accepted correctly',
          error: 'An error occurred while accepting the Terms and Policies'
        }
      }
    },
    sidebar: {
      users: 'Users',
      language: 'Language',
      configuration: 'Settings',
      invoicing: 'Billing'
    },
    equipmentRents: {
      menu: {
        title: 'Equipment rental'
      },
      header: {
        title: 'Equipment rental'
      }
    },
    equipmentRentsScheduler: {
      boughtBy: 'Bought by: ',
      header: {
        title: 'Equipment rental schedule'
      },
      menu: {
        title: 'Equipment rental schedule'
      },
      start: 'Start: ',
      end: 'End: ',
      group: {
        title: 'Bookings: '
      },
      dialog: {
        fields: {
          equipmentRent: 'Equipment rental'
        },
        delete: {
          actions: {
            delete: 'Delete booking',
            success: 'Equipment rental booking successfully removed',
            title: 'Delete equipment rental booking',
            message:
              'Are you sure you want to remove this equipment rental booking?'
          }
        }
      }
    },
    equipmentRentsCalendar: {
      header: {
        title: 'Equipment rental calendar'
      },
      notifications: {
        create: {
          success: 'Equipment rental created successfully'
        }
      },
      menu: {
        title: 'Equipment rental calendar'
      },
      dialog: {
        start: 'Start: ',
        end: 'End: ',
        group: {
          title: 'Equipment Rents: '
        },
        delete: {
          actions: {
            delete: 'Delete',
            deleteSeries: 'Delete series',
            title: 'Delete equipment rental',
            message: 'Are you sure you want to remove this equipment rental?',
            success: 'Equipment rental successfully removed'
          }
        },
        fields: {
          equipmentRent: 'Equipment rental',
          iteration: 'Repeat every',
          dayType: 'Repeats on',
          end: 'Ends',
          afterDate: 'After date',
          afterIterations: 'After num. of repetitions',
          day: 'Day',
          week: 'Week',
          month: 'Months',
          start: 'Starts',
          timeIteration: 'Num. of repetitions'
        },
        tooltip: {
          title: '+ Info',
          repeatEachMonth: 'EN_Se repetirá cada mes los días indicados ',
          onlyForWeek: 'EN_sólo de la semana en curso ',
          days: 'EN_(1ª, 2ª, 3ª, 4ª ó 5ª del mes).',
          toRepeat: 'EN_Para repetir ',
          all: 'EN_todas ',
          pleaseSelect: 'EN_las semanas por favor selecciona “Repetir cada: ',
          week: 'EN_Semana'
        },
        event: {
          title: 'Name: ',
          time: 'Time: ',
          timeType: 'Time type: '
        }
      }
    },
    vouchers: {
      menu: {
        title: 'Vouchers'
      },
      header: {
        title: 'Vouchers'
      }
    },
    calendar: {
      header: {
        title: 'Schedule settings'
      },
      menu: {
        title: 'Schedule settings'
      }
    },
    notifications: {
      menu: {
        title: 'Notifications'
      },
      header: {
        title: 'Notifications'
      },
      list: {
        notification: 'Notification',
        notifications: 'Notifications',
        date: 'Date',
        time: 'Time'
      },
      delete: {
        title: 'Delete notification',
        message: 'Are you sure you want to remove this notification?'
      },
      view: {
        close: 'Close'
      },
      notifications: {
        delete: {
          success: 'Notification successfully removed'
        }
      }
    },
    schedule: {
      menu: {
        title: 'Calendar'
      },
      header: {
        title: 'Calendar'
      },
      dialog: {
        lessonTitle: 'Lesson',
        courseTitle: 'Course',
        equipmentRentTitle: 'Equipment rental',
        event: {
          start: 'Start:',
          end: 'End:',
          user: 'User:',
          lessonType: 'Lesson type:',
          duration: 'Duration:',
          place: 'Space:',
          club: 'Club:',
          teacher: 'Teacher:',
          assistants: 'Assistants:'
        },
        actions: {
          cancel: 'Cancel booking'
        }
      },
      cancel: {
        title: 'Cancel booking',
        message: 'Are you sure you want to cancel this booking?',
        success: 'Booking successfully canceled',
        cancelLabel: 'Back'
      }
    },
    invoicing: {
      menu: {
        paymentRequest: 'Virtual wallet',
        globalOperations: 'Global operations',
        invoices: 'Invoices',
        payments: 'Payments'
      },
      paymentRequest: {
        header: {
          title: 'Virtual wallet'
        },
        tabs: {
          emitted: 'Ordered',
          pending: 'Requested',
          paid: 'Paid'
        },
        list: {
          paymentRequestButton: 'Request balance',
          date: 'Date',
          amount: 'Amount',
          totalAmount: 'Total',
          partialAmount: 'Partial',
          serialCode: 'Serial code',
          balanceAvailable: 'Available balance: %{balance} €',
          user: 'User',
          currency: '%{value}€',
          download: 'Download',
          downloaded: 'Downloaded',
          notPaid: 'Not paid',
          status: 'Mark as',
          paymentRequestStatus: 'Status',
          emitted: 'Ordered',
          pending: 'Requested',
          paid: 'Paid'
        },
        expandedRow: {
          title: 'Title',
          date: 'Lesson date',
          serialCode: 'Serial code',
          student: 'Student',
          amount: 'Amount',
          eventType: 'Event type',
          eventTypes: {
            lesson: 'Lesson',
            course: 'Course',
            equipmentRent: 'Equipment rent',
            voucher: 'Voucher'
          }
        },
        notifications: {
          request: {
            success: 'Balance request successful'
          },
          editStatus: {
            success: 'Payment status updated successfully'
          },
          editStatuses: {
            success: 'Successfully updated payment statuses'
          }
        },
        csv: {
          title: 'payment_requests',
          export: 'EXPORT AS EXCEL'
        },
        popover: {
          paid: 'Paid',
          notPaid: 'Not paid'
        }
      },
      payments: {
        header: {
          title: 'Payment list'
        },
        tabs: {
          emitted: 'Ordered',
          pending: 'Requested',
          paid: 'Paid'
        },
        list: {
          date: 'Date',
          amount: 'Amount',
          totalAmount: 'Total',
          partialAmount: 'Partial',
          balanceAvailable: 'Available balance: %{balance} €',
          user: 'User',
          currency: '%{value}€',
          statusExport: 'Status',
          title: 'Event name',
          notPaid: 'Not paid',
          status: 'Mark as',
          emitted: 'Ordered',
          pending: 'Requested',
          paid: 'Paid',
          student: 'Student',
          lessonDate: 'Lesson Date',
          chargeDate: 'Charge Date'
        },
        filters: {
          user: 'Search by user',
          date: 'Search by date'
        },
        csv: {
          title: 'Payments',
          export: 'Export to EXCEL'
        },
        popover: {
          paid: 'Paid',
          notPaid: 'Not paid'
        },
        notifications: {
          editStatus: {
            success: 'Payment status updated successfully'
          },
          editStatuses: {
            success: 'Successfully updated payment statuses'
          }
        }
      },
      globalOperations: {
        header: {
          title: 'Global operations'
        },
        csv: {
          title: 'Global Operations',
          export: 'Export to EXCEL'
        },
        list: {
          sport: 'Sport',
          club: 'Club',
          teacher: 'Instructor',
          student: 'Student',
          LessonType: 'Lesson type',
          lessonPrice: 'Lesson price',
          feeImport: 'Fee amount',
          status: 'Status',
          price: 'Price',
          currency: '€',
          title: 'Title',
          paymentDate: 'Payment date',
          lessonDate: 'Lesson date',
          fee: 'Fee',
          teacherFee: 'Instructor fee (50%)',
          teacherPayment: 'Payment to instructor',
          publicPrice: 'PVP',
          type: 'Type',
          vat: 'VAT',
          actions: {
            back: 'Back',
            cancelBooking: 'Cancel booking'
          },
          numberOfStudents: 'Number of students',
          bookingDate: 'Booking date',
          serialCode: 'Operation number',
          stripeId: 'Stripe ID'
        },
        filters: {
          sport: 'Search by sport',
          club: 'Search by club',
          teacher: 'Search by instructor',
          user: 'Search by student',
          lessonType: 'Search by lesson type',
          bookingStatus: 'Search by status',
          searchTerm: 'Search by text',
          active: 'Booked',
          cancel: 'Cancelled',
          invoiced: 'Invoiced',
          end: 'Finished'
        },
        cancelBooking: {
          success: 'Booking successfully canceled'
        },
        types: {
          voucher: 'Voucher'
        },
        pendingPayment: 'Pending payment'
      },
      invoices: {
        header: {
          title: 'Invoices'
        },
        tabs: {
          pending: 'Pending',
          invoiced: 'Invoiced',
          emitted: 'Issued'
        },
        list: {
          serialCode: 'Invoice number',
          date: 'Date',
          total: 'Total €',
          subtotal: 'Subtotal €',
          taxRate: 'Tax Rate %',
          vat: 'V.A.T',
          currency: '€',
          country: 'Country',
          region: 'Region',
          identification: 'Identification',
          user: 'Customer',
          address: 'Address',
          status: 'Status',
          emitted: 'Issued',
          invoiced: 'Invoiced',
          pending: 'Pending'
        },
        filters: {
          emitted: 'Issued',
          invoiced: 'Invoiced',
          status: 'Search by status',
          user: 'Search by customer',
          serialCode: 'Search by invoice number',
          country: 'Search by country',
          region: 'Search by region',
          identification: 'Search by identification',
          date: 'Search by date',
          from: 'Search from',
          to: 'Search to'
        },
        notifications: {
          status: {
            success: 'Invoice status updated successfully'
          }
        },
        csv: {
          title: 'Invoices',
          export: 'Export to EXCEL'
        }
      }
    },
    lessons: {
      header: {
        title: 'Lessons'
      },
      menu: {
        title: 'Lessons'
      }
    },
    courses: {
      yearly: {
        header: {
          title: 'Courses'
        },
        menu: {
          title: 'Courses'
        }
      },
      small: {
        header: {
          title: 'Mini courses'
        },
        menu: {
          title: 'Mini courses'
        }
      }
    },
    userData: {
      header: {
        title: 'My data'
      },
      menu: {
        title: 'My data'
      }
    },
    rooms: {
      header: {
        title: 'Spaces'
      },
      menu: {
        title: 'Spaces'
      }
    },
    linkedClubs: {
      header: {
        title: 'Linked clubs'
      },
      menu: {
        title: 'Linked clubs'
      }
    },
    register: {
      helpText:
        'Your account will not be available until it is validated. This can take up to 24 hours.',
      actions: {
        send: 'Send'
      }
    }
  }
};

export default enMessages;
