import React, { useContext, useEffect } from 'react';
import { HeaderTitleContext } from '../../../../core/context/headerTitleContext';
import { CustomPagination } from '../../../../components/general/Pagination';
import {
  Datagrid,
  DateField,
  FunctionField,
  List,
  TextField,
  useTranslate
} from 'react-admin';
import { TermsAndConditionsListActions } from './TermsAndConditionsListActions';
import { EmptyList } from '../../../../components/general/EmptyList';
import { TermAndConditionDeleteButton } from './TermAndConditionDeleteButton';
import { TermsAndConditionsViewButton } from './TermsAndConditionsViewButton';
import {
  PRIVILEGE_CLUB,
  PRIVILEGE_STUDENT,
  PRIVILEGE_TEACHER
} from '../../../../core/constants/privilege.constants';

export const TermsAndConditionsList = () => {
  const { setHeaderTitle } = useContext(HeaderTitleContext);
  const translate = useTranslate();

  useEffect(() => {
    setHeaderTitle('resources.configuration.termsAndConditions.title');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getPrivilege = (record) => {
    if (record.privilegeType === PRIVILEGE_STUDENT) {
      return translate(
        'resources.configuration.termsAndConditions.fields.student'
      );
    }

    if (record.privilegeType === PRIVILEGE_CLUB) {
      return translate(
        'resources.configuration.termsAndConditions.fields.club'
      );
    }

    if (record.privilegeType === PRIVILEGE_TEACHER) {
      return translate(
        'resources.configuration.termsAndConditions.fields.teacher'
      );
    }

    return '';
  };

  return (
    <List
      resource="terms-and-conditions"
      exporter={false}
      perPage={Number(process.env.REACT_APP_LIST_SIZE)}
      pagination={<CustomPagination />}
      actions={<TermsAndConditionsListActions />}
      empty={
        <EmptyList
          buttonLabel={
            'resources.configuration.termsAndConditions.list.actions.create'
          }
          route={'/terms-and-conditions/create'}
        />
      }
    >
      <Datagrid bulkActionButtons={false}>
        <TextField
          source="title"
          label={'resources.configuration.termsAndConditions.fields.title'}
          emptyText="-"
        />
        <DateField
          source="date"
          label={'resources.configuration.termsAndConditions.fields.date'}
          emptyText="-"
        />
        <FunctionField
          label="resources.configuration.termsAndConditions.fields.privilege"
          render={(record) => getPrivilege(record)}
        />
        <TermAndConditionDeleteButton />
        <TermsAndConditionsViewButton />
      </Datagrid>
    </List>
  );
};
