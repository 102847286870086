import React, { useContext, useEffect } from 'react';
import { Datagrid, FunctionField, List } from 'react-admin';
import { EmptyList } from '../../components/general/EmptyList';
import { CustomPagination } from '../../components/general/Pagination';
import { HeaderTitleContext } from '../../core/context/headerTitleContext';
import { UserHashContext } from '../../core/context/userHashContext';
import { ActiveVouchersListExpand } from './ActiveVoucherListExpand';

export const ActiveVouchersList = () => {
  const { setHeaderTitle } = useContext(HeaderTitleContext);
  const { userHash } = useContext(UserHashContext);

  useEffect(() => {
    setHeaderTitle('resources.activeVouchers.header.title');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!userHash) {
    return <></>;
  }

  return (
    <>
      <List
        resource={`users/${userHash}/active-user-vouchers`}
        filter={{ students: true }}
        exporter={false}
        perPage={Number(process.env.REACT_APP_LIST_SIZE)}
        pagination={<CustomPagination />}
        actions={<></>}
        empty={<EmptyList />}
      >
        <Datagrid
          bulkActionButtons={false}
          expand={<ActiveVouchersListExpand />}
        >
          <FunctionField
            source="firstName"
            label="resources.activeVouchers.list.user"
            render={(record) => `${record.firstName} ${record.lastName}`}
          />
        </Datagrid>
      </List>
    </>
  );
};
