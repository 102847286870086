import React from 'react';
import { NumberInput, required, TextInput } from 'react-admin';

export const RoomForm = () => {
  return (
    <>
      <TextInput
        source="name"
        validate={[required()]}
        label="resources.users.rooms.fields.name"
      />
      <NumberInput
        source="userLimit"
        validate={[required()]}
        label="resources.users.rooms.fields.userLimit"
      />
    </>
  );
};
