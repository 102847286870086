import {
  VALIDATION_IS_GREATHER,
  VALIDATION_POSITIVE_NUMBER,
  VALIDATION_REQUIRED
} from '../../../core/constants/validation.constants';
import { isNumber, isObject } from '../../../core/utils/object-utils';
import { validateNoSpaces } from '../../../core/utils/validation-utils';

export function InvoiceDataSettingFormValidator(values: any) {
  const errors: any = {};

  if (values.bookingFee < 0 && isNumber(values.bookingFee)) {
    errors.bookingFee = VALIDATION_POSITIVE_NUMBER;
  }

  if (values.fixedMonthFee < 0 && isNumber(values.fixedMonthFee)) {
    errors.fixedMonthFee = VALIDATION_POSITIVE_NUMBER;
  }

  if (values.bookingFixFee < 0 && isNumber(values.bookingFixFee)) {
    errors.bookingFixFee = VALIDATION_POSITIVE_NUMBER;
  }

  if (values.bookingFee > 100) {
    errors.bookingFee = VALIDATION_IS_GREATHER;
  }

  if (!values.bookingFixFee && isObject(values.bookingFixFee)) {
    errors.bookingFixFee = VALIDATION_REQUIRED;
  }

  if (
    !values.fixedMonthFee &&
    isObject(values.fixedMonthFee) &&
    !values.bookingFee &&
    isObject(values.bookingFee)
  ) {
    errors.bookingFee = VALIDATION_REQUIRED;
    errors.fixedMonthFee = VALIDATION_REQUIRED;
  }

  if (!values.iban) {
    errors.iban = VALIDATION_REQUIRED;
  }

  if (values.iban && !!validateNoSpaces(values.iban)) {
    errors.iban = validateNoSpaces(values.iban);
  }

  if (!values.bic) {
    errors.bic = VALIDATION_REQUIRED;
  }

  if (!values.debtorId) {
    errors.debtorId = VALIDATION_REQUIRED;
  }

  return errors;
}
