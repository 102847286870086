import React, { useEffect } from 'react';
import { SaveButton } from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../../components/shared/buttons/Button';

export const UserLinkedTeacherToolbar = (props) => {
  const navigate = useNavigate();
  const { reset, setValue } = useFormContext();

  useEffect(() => {
    if (props.submit) {
      reset();
      setValue('name', '');
    }
  }, [props.submit]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <Button
        label="resources.users.linkedTeachers.create.form.buttons.back"
        color="secondary"
        className="button-back"
        onClick={() => navigate(-1)}
        style={{ marginRight: '8px' }}
      ></Button>
      <SaveButton
        label="resources.users.linkedTeachers.create.form.buttons.saveAndAdd"
        onClick={() => props.setSaveAndAdd(true)}
        style={{ marginRight: '8px' }}
      />
      <SaveButton
        label="resources.users.linkedTeachers.create.form.buttons.save"
        style={{ marginRight: '8px' }}
      />
    </div>
  );
};
