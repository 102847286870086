import {
  Edit,
  SimpleForm,
  useNotify,
  useRefresh,
  useUpdate
} from 'react-admin';
import { equipmentRentFormValidator } from '../equipmentRentsForm/equipmentRentFormValidator';
import { EquipmentRentFormToolbar } from '../equipmentRentsForm/EquipmentRentFormToolbar';
import { EquipmentRentForm } from '../equipmentRentsForm/EquipmentRentForm';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

export const EquipmentRentsEditShared = (props) => {
  const [update, { isLoading, isError }] = useUpdate('equipment-rents');
  const notify = useNotify();
  const refresh = useRefresh();
  const params = useParams();
  const navigate = useNavigate();
  const equipmentRentSave = (data) => {
    const id = data.id;
    data = {
      ...data,
      userHash: props.userHash
    };
    update(
      'equipment-rents',
      { id, data },
      {
        onSuccess: () => {
          notify('resources.users.equipmentRents.notifications.edit.success', {
            type: 'success'
          });

          if (props.route) {
            navigate(props.route);
          }

          if (props.setSection) {
            props.setSection(props.sectionList);
          }

          refresh();
        },
        onError(error: any) {
          notify(error.message, { type: 'warning' });
        }
      }
    );
  };

  return (
    <Edit
      resource="equipment-rents"
      id={props.equipmentRentToEditId ? props.equipmentRentToEditId : params.id}
    >
      <SimpleForm
        onSubmit={equipmentRentSave}
        validate={(e) => equipmentRentFormValidator(e)}
        className={'form'}
        toolbar={
          <EquipmentRentFormToolbar
            setSection={props.setSection}
            sectionList={props.sectionList}
            route={props.route}
            isLoading={isLoading}
            isError={isError}
          />
        }
      >
        <EquipmentRentForm />
      </SimpleForm>
    </Edit>
  );
};
