import React from 'react';
import { useRecordContext } from 'react-admin';
import { EquipmentRentsCalendarShared } from '../../../../../components/shared/equipmentRents/equipmentRentsCalendar/EquipmentRentsCalendarShared';

export const UserEquipmentRentsCalendar = () => {
  const record = useRecordContext();
  return (
    <>
      <EquipmentRentsCalendarShared
        route={'/equipment-rents-calendar'}
        userHash={record?.id as string}
      ></EquipmentRentsCalendarShared>
    </>
  );
};
