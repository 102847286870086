import React from 'react';
import { useNotify, useDelete, Confirm, useRefresh } from 'react-admin';

export const DialogDeleteUserPaymentMethod = ({
  showDeleteDialog,
  setShowDeleteDialog,
  id
}) => {
  const [erase] = useDelete();
  const notify = useNotify();
  const refresh = useRefresh();
  const onCloseRoomDeleteDialog = () => {
    setShowDeleteDialog(false);
  };

  const onDeleteRoom = () => {
    erase(
      'payment-methods',
      {
        id
      },
      {
        onSuccess: () => {
          notify(
            'resources.users.userPaymentMethods.notifications.delete.success',
            {
              type: 'success'
            }
          );
          onCloseRoomDeleteDialog();
          refresh();
        },
        onError(error: any) {
          notify(error.message, { type: 'warning' });
        }
      }
    );
  };

  return (
    <Confirm
      title="resources.users.userPaymentMethods.delete.title"
      content="resources.users.userPaymentMethods.delete.message"
      onConfirm={onDeleteRoom}
      isOpen={showDeleteDialog}
      onClose={onCloseRoomDeleteDialog}
    />
  );
};
