import React, { useContext, useState } from 'react';
import {
  Create,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  useCreate,
  useNotify
} from 'react-admin';
import { useNavigate } from 'react-router-dom';
import { UserHashContext } from '../../../core/context/userHashContext';
import { UserLinkedTeacherToolbar } from './UserLinkedTeacherToolbar';
export const UserLinkedTeacherCreate = () => {
  const [create] = useCreate('user-parents');
  const notify = useNotify();
  const [saveAndAdd, setSaveAndAdd] = useState(false);
  const navigate = useNavigate();
  const [name, setName] = useState(null);
  const [submit, setSubmit] = useState(false);
  const { userHash } = useContext(UserHashContext);
  const userOptionRenderer = (choice) =>
    [choice.firstName, choice.lastName, choice.secondLastName]
      .filter(Boolean)
      .join(' ');

  const linkedTeacherSave = (data) => {
    data = {
      teacherHash: data.name,
      clubHash: userHash
    };
    create(
      'user-parents/request-link-teacher',
      { data },
      {
        onSuccess: () => {
          notify(
            'resources.users.notifications.userEdit.linkedTeachers.create.success',
            {
              type: 'success'
            }
          );
          setSubmit(true);

          if (!saveAndAdd) {
            navigate('/teachers');
          }
          setSaveAndAdd(false);
        },
        onError(error: any) {
          notify(error.message, { type: 'warning' });
          if (!saveAndAdd) {
            navigate('/teachers');
            setSaveAndAdd(false);
          }
        }
      }
    );
  };

  return (
    <>
      <Create resource={'user-parents'}>
        <SimpleForm
          onSubmit={linkedTeacherSave}
          className={'form'}
          toolbar={
            <UserLinkedTeacherToolbar
              setSaveAndAdd={setSaveAndAdd}
              submit={submit}
            />
          }
        >
          <ReferenceInput
            source="name"
            reference="users/teachers"
            sort={{ field: 'firstName', order: 'ASC' }}
          >
            <SelectInput
              optionText={userOptionRenderer}
              label="resources.users.linkedTeachers.create.form.name"
              resource="name"
              onChange={(e) => setName(e.target.value)}
              value={name}
            ></SelectInput>
          </ReferenceInput>
        </SimpleForm>
      </Create>
    </>
  );
};
