import { useNavigate } from 'react-router-dom';
import { SaveButton, Toolbar } from 'react-admin';
import React from 'react';
import { Button } from '../../buttons/Button';
import { useFormContext } from 'react-hook-form';

export const FormToolbar = (props) => {
  const {
    formState: { isSubmitted, isValid, isDirty }
  } = useFormContext();
  const navigate = useNavigate();
  const goToList = () => {
    navigate(-1);
  };

  return (
    <div>
      <Toolbar>
        <Button
          onClick={() => goToList()}
          label="resources.shared.form.toolbar.actions.cancel"
        ></Button>
        <SaveButton
          label="resources.shared.form.toolbar.actions.saveChanges"
          disabled={
            props.isLoading ||
            (isSubmitted && !props.isError && isValid && !isDirty)
          }
        />
      </Toolbar>
    </div>
  );
};
