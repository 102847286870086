import React from 'react';

import { CalendarLessonEventDialogGroupedEvent } from './calendarLessonEventDialogGrouped/CalendarLessonEventDialogGroupedEvent';
import { CalendarLessonEventDialogSimpleEvent } from './calendarLessonEventDialogSimple/CalendarLessonEventDialogSimpleEvent';

export const CalendarLessonEventDialog = (props) => {
  return (
    <>
      {props.event.group.length > 1 ? (
        <CalendarLessonEventDialogGroupedEvent
          event={props.event}
          setOpenDialog={props.setOpenDialog}
          openDialog={props.openDialog}
          setRefresh={props.setRefresh}
        ></CalendarLessonEventDialogGroupedEvent>
      ) : (
        <CalendarLessonEventDialogSimpleEvent
          setOpenDialog={props.setOpenDialog}
          openDialog={props.openDialog}
          event={props.event}
          setRefresh={props.setRefresh}
          userHash={props.userHash}
        ></CalendarLessonEventDialogSimpleEvent>
      )}
    </>
  );
};
