import React, { useContext } from 'react';
import { UserHashContext } from '../../../core/context/userHashContext';
import { CourseCreateShared } from '../../../components/shared/courses/courseCreate/CourseCreateShared';

export const CourseCreate = (props) => {
  const { userHash } = useContext(UserHashContext);
  const smallCourses = props.smallCourses ? props.smallCourses : false;
  return (
    <CourseCreateShared
      userHash={userHash}
      route={smallCourses ? '/small-course' : '/yearly-course'}
      smallCourses={props.smallCourses}
    />
  );
};
