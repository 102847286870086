import React, { useContext } from 'react';
import { InvoicesList } from './invoicesList/InvoicesList';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import {
  INVOICE_STATUS_EMITTED,
  INVOICE_STATUS_INVOICED,
  INVOICE_STATUS_PENDING
} from '../../../core/constants/invoice.constants';
import { usePermissions, useTranslate } from 'react-admin';
import { PRIVILEGE_ADMIN } from '../../../core/constants/privilege.constants';
import { UserHashContext } from '../../../core/context/userHashContext';

export const InvoicesOverview = () => {
  const translate = useTranslate();
  const permissions = usePermissions();
  const isAdmin = permissions.permissions === PRIVILEGE_ADMIN;
  const { userHash } = useContext(UserHashContext);

  if (!isAdmin) {
    return <InvoicesList status={null} user={userHash} />;
  }

  return (
    <>
      <Tabs>
        <TabList>
          <Tab>{translate('resources.invoicing.invoices.tabs.pending')}</Tab>
          <Tab>{translate('resources.invoicing.invoices.tabs.emitted')}</Tab>
          <Tab>{translate('resources.invoicing.invoices.tabs.invoiced')}</Tab>
        </TabList>
        <TabPanel>
          <InvoicesList status={INVOICE_STATUS_PENDING} user={null} />
        </TabPanel>
        <TabPanel>
          <InvoicesList status={INVOICE_STATUS_EMITTED} user={null} />
        </TabPanel>
        <TabPanel>
          <InvoicesList status={INVOICE_STATUS_INVOICED} user={null} />
        </TabPanel>
      </Tabs>
    </>
  );
};
