import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import React, { useState } from 'react';
import { usePermissions, useTranslate } from 'react-admin';
import {
  PRIVILEGE_ADMIN,
  PRIVILEGE_TEACHER
} from '../../../../../../core/constants/privilege.constants';
import { Button } from '../../../../buttons/Button';
import { DialogDeleteAvailability } from '../DialogDeleteAvailability';

export const CalendarAvailableDialogSimple = ({
  setOpenDialog,
  openDialog,
  event,
  setRefresh
}) => {
  const translate = useTranslate();
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [deleteSeries, setDeleteSeries] = useState(false);
  const permissions = usePermissions();

  const isTeacher = permissions.permissions === PRIVILEGE_TEACHER;
  const isAdmin = permissions.permissions === PRIVILEGE_ADMIN;

  const onCloseDialog = () => {
    setOpenDialog(false);
  };

  const onDeleteEvent = () => {
    setDeleteSeries(false);
    setShowDeleteDialog(true);
  };

  const onDeleteSeries = () => {
    setDeleteSeries(true);
    setShowDeleteDialog(true);
  };
  return (
    <>
      <Dialog fullWidth open={openDialog} onClose={onCloseDialog}>
        <DialogTitle>
          {translate('resources.users.calendar.availability.title')}
        </DialogTitle>
        <DialogContent className="event-dialog">
          <p>
            {translate('resources.users.calendar.availability.start')}
            {event.start.toLocaleString()}
          </p>
          <p>
            {translate('resources.users.calendar.availability.end')}
            {event.end.toLocaleString()}
          </p>

          {(isTeacher || isAdmin) && (
            <div className="dialog-actions">
              <Button
                onClick={onDeleteEvent}
                label={'resources.users.calendar.availability.actions.delete'}
              />
              {!event.createdBySystem && (
                <Button
                  onClick={onDeleteSeries}
                  label={
                    'resources.users.calendar.availability.actions.deleteSeries'
                  }
                />
              )}
            </div>
          )}
        </DialogContent>
      </Dialog>
      <DialogDeleteAvailability
        event={event}
        showDeleteDialog={showDeleteDialog}
        setShowDeleteDialog={setShowDeleteDialog}
        setOpenAvailableDialog={setOpenDialog}
        deleteSeries={deleteSeries}
        setRefresh={setRefresh}
      />
    </>
  );
};
