import React from 'react';
import { useNotify, useDelete, Confirm, useRefresh } from 'react-admin';

export const DialogDeleteSport = ({
  showDeleteDialog,
  setShowDeleteDialog,
  id
}) => {
  const [erase] = useDelete();
  const notify = useNotify();
  const refresh = useRefresh();
  const onCloseDeleteDialog = () => {
    setShowDeleteDialog(false);
  };

  const onDelete = () => {
    erase(
      'sports',
      {
        id
      },
      {
        onSuccess: () => {
          notify(
            'resources.configuration.sports.notifications.delete.success',
            {
              type: 'success'
            }
          );
          onCloseDeleteDialog();
          refresh();
        },
        onError(error: any) {
          notify(error.message, { type: 'warning' });
        }
      }
    );
  };

  return (
    <Confirm
      title="resources.configuration.sports.delete.title"
      content="resources.configuration.sports.delete.message"
      onConfirm={onDelete}
      isOpen={showDeleteDialog}
      onClose={onCloseDeleteDialog}
    />
  );
};
