import { refreshToken } from '../../auth-api.service';
import { getDefaultAnonymousRequestConfig } from '../axios.client';

export function getError(axiosInstance, error) {
  const { config } = error;
  if (error.response && error.response.status === 401) {
    if (!!!error.config.headers.HEADER_ANONYMOUS) {
      return unhautorizedHanlder(axiosInstance, config, error);
    }
  }
  return Promise.reject(error);
}

function unhautorizedHanlder(axiosInstance, config, error): any {
  return refreshToken().then(
    () =>
      axiosInstance.request({
        ...config,
        ...getDefaultAnonymousRequestConfig()
      }),
    () => Promise.reject(error)
  );
}
