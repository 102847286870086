import {
  RichTextInputToolbar,
  LevelSelect,
  FormatButtons,
  AlignmentButtons,
  ListButtons,
  LinkButtons,
  QuoteButtons,
  ClearButtons
} from 'ra-input-rich-text';
import React from 'react';
export const InputRichTextFieldToolbar = ({ size, ...props }) => {
  return (
    <RichTextInputToolbar {...props}>
      <LevelSelect size={size} />
      <FormatButtons size={size} />
      <AlignmentButtons size={size} />
      <ListButtons size={size} />
      <LinkButtons size={size} />
      <QuoteButtons size={size} />
      <ClearButtons size={size} />
      {/* TODO: NOT SUPPORT <ToggleButton
        aria-label="Add an horizontal rule"
        title="Add an horizontal rule"
        value="left"
        onClick={() => editor.commands().focus().setHorizontalRule().run()}
        selected={editor && editor.isActive('horizontalRule')}
      >
        <Remove fontSize="inherit" />
        </ToggleButton>*/}
    </RichTextInputToolbar>
  );
};
