import { useTranslate } from 'react-admin';
import React from 'react';

export const CheckPriceAdvice = () => {
  const translate = useTranslate();

  return (
    <p className="advice">
      {translate('resources.users.fields.lesson.lessonPriceAdvice')}
    </p>
  );
};
