import React, { useContext } from 'react';
import {
  Datagrid,
  FunctionField,
  List,
  Loading,
  TextField,
  useGetList,
  usePermissions,
  useTranslate
} from 'react-admin';
import { LessonListActions } from './LessonListActions';
import { LessonStatusButton } from './LessonStatusButton';
import { LessonEditButton } from './LessonEditButton';
import { LessonDeleteButton } from './LessonDeleteButton';
import { LessonEmptyList } from './LessonEmptyList';
import { UserHashContext } from '../../../../core/context/userHashContext';
import { PRIVILEGE_CLUB } from '../../../../core/constants/privilege.constants';
import { LessonLevel } from '../../../../core/models/lesson/lessonLevel';
import { CustomPagination } from '../../../general/Pagination';
import { Lesson } from '../../../../core/models/lesson/lesson';

export const LessonListShared = (props) => {
  const { userHash: parentHash } = useContext(UserHashContext);
  const translate = useTranslate();
  const { data: userTypes, isLoading } = useGetList('user-types');
  const lessonTypes = useGetList('lessons/lesson-types');
  const permissions = usePermissions();
  const isClub = permissions.permissions === PRIVILEGE_CLUB;

  const getLevelSource = (levels: LessonLevel[]) => {
    return levels.map((level) => {
      if (level.fromYears && level.toYears) {
        return `${level.fromYears} - ${level.toYears} ${translate(
          'resources.users.lessons.list.years'
        )}`;
      }

      if (level.levelTypeName) {
        return level.levelTypeName;
      }

      return '';
    });
  };

  const getTarget = (userTypeNumbers: number[]) => {
    const target = userTypeNumbers.map((userTypeNumber) => {
      return userTypes?.find((userType) => userType.id === userTypeNumber).name;
    });
    return target.join(', ');
  };

  const getLessonType = (lessonTypeNumber) => {
    var lessonType = lessonTypes.data?.find(
      (lessonType) => lessonType.id === lessonTypeNumber
    );
    return lessonType ? lessonType.name : '-';
  };

  const getFilters = () => {
    if (isClub) {
      return { parentHash: parentHash, parentInfo: true };
    }
    return { parentInfo: false };
  };

  const getFullName = (firstName: string, lastName: string) => {
    return `${firstName ?? ''} ${lastName ?? ''}`;
  };

  if (isLoading) return <Loading />;

  return (
    <List
      resource={`users/${props.userHash}/lessons`}
      sort={{ field: 'title', order: 'ASC' }}
      perPage={Number(process.env.REACT_APP_LIST_SIZE)}
      pagination={<CustomPagination />}
      filter={getFilters()}
      empty={
        <LessonEmptyList
          setSection={props.setSection}
          sectionCreate={props.sectionCreate}
          route={props.route}
        />
      }
      actions={
        <LessonListActions
          setSection={props.setSection}
          sectionCreate={props.sectionCreate}
          sectionEdit={props.sectionEdit}
          route={props.route}
        />
      }
    >
      <Datagrid bulkActionButtons={false}>
        {props.isClub && (
          <FunctionField
            label="resources.users.lessons.list.teacher"
            render={(record: Lesson) =>
              getFullName(record.userFirstName, record.userLastName)
            }
          />
        )}

        <TextField
          source="title"
          label="resources.users.lessons.list.lessonTitle"
          sortBy="title"
          emptyText="-"
        />

        <FunctionField
          label="resources.users.lessons.list.target"
          render={(record: Lesson) => getTarget(record.lessonUserTypes)}
        />
        <FunctionField
          label="resources.users.lessons.list.level"
          render={(record: Lesson) => getLevelSource(record.level)}
        />
        <FunctionField
          label="resources.users.lessons.list.lessonType"
          render={(record: Lesson) => getLessonType(record.lessonType)}
        />
        <LessonStatusButton
          label="resources.users.lessons.list.activeInactive"
          sortBy="disabled"
        />
        <LessonDeleteButton />
        <LessonEditButton
          setLessonToEditId={props.setLessonToEditId}
          sectionEdit={props.sectionEdit}
          setSection={props.setSection}
          route={props.route}
        />
      </Datagrid>
    </List>
  );
};
