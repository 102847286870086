import React from 'react';
import {
  Edit,
  SimpleForm,
  useNotify,
  useRefresh,
  useUpdate
} from 'react-admin';
import { voucherFormValidator } from '../vouchersForm/voucherFormValidator';
import { VoucherFormToolbar } from '../vouchersForm/VoucherFormToolbar';
import { VoucherForm } from '../vouchersForm/VoucherForm';
import { useNavigate } from 'react-router-dom';

export const VoucherEditShared = (props) => {
  const [update, { isLoading, isError }] = useUpdate();
  const notify = useNotify();
  const refresh = useRefresh();
  const navigate = useNavigate();
  const voucherSave = (data) => {
    const id = data.id;
    data = {
      ...data,
      userHash: props.userHash,
      disabled: !data.isEnabled
    };
    update(
      'vouchers',
      { id, data },
      {
        onSuccess: () => {
          notify('resources.users.vouchers.notifications.edit.success', {
            type: 'success'
          });
          if (props.route) {
            navigate(props.route);
          }

          if (props.setSection) {
            props.setSection(props.sectionList);
          }

          refresh();
        },
        onError(error: any) {
          notify(error.message, { type: 'warning' });
        }
      }
    );
  };

  return (
    <Edit resource="vouchers" id={props.voucherToEditId}>
      <SimpleForm
        onSubmit={voucherSave}
        validate={(e) => voucherFormValidator(e)}
        className={'form'}
        toolbar={
          <VoucherFormToolbar
            setSection={props.setSection}
            sectionList={props.sectionList}
            route={props.route}
            isLoading={isLoading}
            isError={isError}
          />
        }
      >
        <VoucherForm userHash={props.userHash} />
      </SimpleForm>
    </Edit>
  );
};
