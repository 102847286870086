import {
  IDENTIFICATION_TYPE_ID,
  IDENTIFICATION_TYPE_OTHER
} from '../core/constants/IdentificationType.constants';
import { VALIDATION_REQUIRED } from '../core/constants/validation.constants';
import { validateNoSpaces } from '../core/utils/validation-utils';
import {
  VALIDATION_NIF_NOT_VALID,
  VALIDATION_PASSWORD_MISMATCHED
} from './../core/constants/validation.constants';

export function registerFormValidator(values: any) {
  const errors: any = {};
  const isClub = values.category === 'club';
  const isTeacher = values.category === 'teacher';
  const nifRegex = new RegExp('^[0-9]{8}[A-Z]$');

  if (!values.password) {
    errors.password = VALIDATION_REQUIRED;
  }
  if (!values.firstName) {
    errors.firstName = VALIDATION_REQUIRED;
  }
  if (!isClub && !values.lastName) {
    errors.lastName = VALIDATION_REQUIRED;
  }

  if (
    !values.identification &&
    isTeacher &&
    values.identificationType === IDENTIFICATION_TYPE_ID
  ) {
    errors.identification = VALIDATION_REQUIRED;
  }

  if (
    values.identification &&
    isTeacher &&
    values.identificationType === IDENTIFICATION_TYPE_ID &&
    nifRegex.test(values.identification) === false
  ) {
    errors.identification = VALIDATION_NIF_NOT_VALID;
  }

  if (
    !values.identification &&
    isTeacher &&
    values.identificationType === IDENTIFICATION_TYPE_OTHER
  ) {
    errors.identification = VALIDATION_REQUIRED;
  }

  if (!values.identification && isClub) {
    errors.identification = VALIDATION_REQUIRED;
  }

  if (!values.address) {
    errors.address = VALIDATION_REQUIRED;
  }
  if (!values.email) {
    errors.email = VALIDATION_REQUIRED;
  }
  if (values.firstSport === '') {
    errors.firstSport = VALIDATION_REQUIRED;
  }
  if (!values.country) {
    errors.country = VALIDATION_REQUIRED;
  }

  if (!values.accountNumber) {
    errors.accountNumber = VALIDATION_REQUIRED;
  }

  if (values.accountNumber && !!validateNoSpaces(values.accountNumber)) {
    errors.accountNumber = validateNoSpaces(values.accountNumber);
  }

  if (!values.confirmPassword) {
    errors.confirmPassword = VALIDATION_REQUIRED;
  } else if (values.confirmPassword !== values.password) {
    errors.confirmPassword = VALIDATION_PASSWORD_MISMATCHED;
  }

  if (values.hasOwnProperty('term') && !values.term) {
    errors.term = VALIDATION_REQUIRED;
  }

  return errors;
}
