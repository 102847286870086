import React from 'react';
import { Toolbar } from 'react-admin';
import { Button } from '../../../../buttons/Button';

export const CalendarEquipmentRentDialogGroupedEventToolbar = ({
  onDeleteEvent,
  group
}) => {
  return (
    <div>
      <Toolbar>
        <Button
          label="ra.action.delete"
          onClick={onDeleteEvent}
          disabled={!group}
        />
      </Toolbar>
    </div>
  );
};
