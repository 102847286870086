import React, { useContext } from 'react';
import {
  Edit,
  Loading,
  SimpleForm,
  useGetOne,
  useNotify,
  usePermissions,
  useRefresh,
  useUpdate
} from 'react-admin';
import { lessonFormValidator } from '../lessonForm/lessonFormValidator';
import { LessonFormToolbar } from '../lessonForm/LessonFormToolbar';
import { LessonForm } from '../lessonForm/LessonForm';
import { useNavigate, useParams } from 'react-router-dom';
import { UserHashContext } from '../../../../core/context/userHashContext';
import { PRIVILEGE_CLUB } from '../../../../core/constants/privilege.constants';

export const LessonEditShared = (props) => {
  const [update, { isLoading: updateIsLoading, isError }] = useUpdate();
  const notify = useNotify();
  const refresh = useRefresh();
  const params = useParams();
  const { data: lesson, isLoading } = useGetOne('lessons', {
    id: props.lessonToEditId ? props.lessonToEditId : params.id
  });
  const navigate = useNavigate();
  const userContext = useContext(UserHashContext);
  const permissions = usePermissions();
  const isClub = permissions.permissions === PRIVILEGE_CLUB;

  const lessonSave = (data) => {
    const id = data.id;
    data = {
      ...data,
      prices: [data.price],
      userHash: props.isClub ? lesson.userHash : props.userHash,
      disabled: !data.isEnabled
    };
    update(
      'lessons',
      { id, data },
      {
        onSuccess: () => {
          notify('resources.users.notifications.lessonUpdate.success', {
            type: 'success'
          });
          if (props.setSection) {
            props.setSection(props.sectionList);
          }

          if (props.route) {
            navigate(-1);
          }
          refresh();
        },
        onError(error: any) {
          notify(error.message, { type: 'warning' });
        }
      }
    );
  };

  if (isLoading) return <Loading />;

  return (
    <Edit
      resource="lessons"
      id={props.lessonToEditId ? props.lessonToEditId : params.id}
    >
      <SimpleForm
        onSubmit={lessonSave}
        validate={(e) => lessonFormValidator(e)}
        record={lesson}
        className={'form'}
        toolbar={
          <LessonFormToolbar
            setSection={props.setSection}
            sectionList={props.sectionList}
            route={props.route}
            isLoading={updateIsLoading}
            isError={isError}
          />
        }
      >
        <LessonForm
          isClub={props.isClub}
          userHash={isClub ? userContext.userHash : props.userHash}
        ></LessonForm>
      </SimpleForm>
    </Edit>
  );
};
