import React from 'react';
import { SelectInput, SimpleForm, required, useGetList } from 'react-admin';

type EquipmentRentsCalendarFilterType = {
  setEquipmentRent;
  equipmentRent;
  userHash;
};

const EquipmentRentsCalendarFilters = (
  props: EquipmentRentsCalendarFilterType
) => {
  const { data: equipmentRents, isLoading } = useGetList(
    `users/${props.userHash}/equipment-rents`,
    {
      sort: { field: 'name', order: 'ASC' }
    }
  );

  if (isLoading) {
    return <div></div>;
  }

  const equipmentRentChoices = [{ id: '', name: '' }];
  equipmentRentChoices.push(
    ...equipmentRents.map((equipmentRent) => {
      return { id: equipmentRent.id, name: equipmentRent.name };
    })
  );

  return (
    <div className="calendar-filters">
      <SimpleForm toolbar={false}>
        <>
          {props.userHash ? (
            <SelectInput
              optionText={(record: any) => record.name}
              source="lesson"
              defaultValue=""
              validate={[required()]}
              label="resources.equipmentRentsCalendar.dialog.fields.equipmentRent"
              onChange={(e) => props.setEquipmentRent(e.target.value)}
              choices={equipmentRentChoices}
            />
          ) : null}
        </>
      </SimpleForm>
    </div>
  );
};

export default EquipmentRentsCalendarFilters;
