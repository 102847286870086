import React from 'react';
import { TopToolbar } from 'react-admin';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../buttons/Button';

export const EquipmentRentsListActions = ({
  setSection,
  sectionCreate,
  route
}) => {
  const navigate = useNavigate();
  const goToCreate = () => {
    if (route) {
      navigate(route);
    }

    if (setSection) {
      setSection(sectionCreate);
    }
  };

  return (
    <TopToolbar>
      <Button
        onClick={goToCreate}
        label="resources.users.equipmentRents.actions.create"
      />
    </TopToolbar>
  );
};
