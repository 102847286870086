import moment from 'moment';
import { Views } from 'react-big-calendar/dist/react-big-calendar.esm';
import { Booking } from '../../../../core/models/booking/booking';

export type ScheduleEvent = {
  id;
  title;
  start;
  end;
  allDay;
  equipmentRentHash;
  duration;
  user;
  allUserHashes;
  teacherName;
  group;
  modalTitle;
  createdBySystem;
};

export const getFirstMillisOfView = (date, view) => {
  if (view === Views.WEEK) {
    return moment(date).startOf('isoWeek').valueOf();
  }
  return moment(date).startOf(view).valueOf();
};

export const getLastMillisOfView = (date, view) => {
  if (view === Views.WEEK) {
    return moment(date).endOf('isoWeek').add(1, 'week').valueOf();
  }
  return moment(date).endOf(view).valueOf();
};

export const getEventTitle = (group: Booking[]) => {
  if (group.length > 1) {
    return `${
      '(' + group.length.toString() + ') ' + getMultiEventTitle(group)
    }`;
  }
  return getSingleEventTitle(group[0]);
};

export const getMultiEventTitle = (group: Booking[]) => {
  const uniqueEvents = group.reduce((unique: Booking[], event: Booking) => {
    const title = getSingleEventTitle(event);
    if (
      !unique.some((uniqueEvent) => getSingleEventTitle(uniqueEvent) === title)
    ) {
      unique.push(event);
    }
    return unique;
  }, []);

  return uniqueEvents.map((event) => getSingleEventTitle(event)).join(', ');
};

export const getSingleEventTitle = (booking: Booking) => {
  return `${booking?.event?.eventTitle} - ${booking?.event?.userName}`;
};

export const generateCalendarEventsFromBookings = (
  data: Booking[]
): ScheduleEvent[] => {
  const groupedEvents = {};

  data.forEach((booking) => {
    const key =
      `${booking?.event?.room?.id ?? 'null'}` +
      `-${booking?.event.equipmentRentHash ?? 'null'}` +
      `-${booking?.event.userHash ?? 'null'}` +
      `-${booking?.event.startDate}-${booking?.event.endDate}`;

    if (!groupedEvents[key]) {
      groupedEvents[key] = [];
    }

    groupedEvents[key].push(booking);
  });

  return Object.values(groupedEvents).map((group: any[]) => {
    const sampleBooking = group[0];
    const filterUsersDay = data
      .filter(
        (booking) =>
          moment(booking.event.startDate) >
            moment(sampleBooking.startDate).startOf('day') &&
          moment(booking.event.endDate) <
            moment(sampleBooking.startDate).endOf('day')
      )
      .sort((b1: Booking, b2: Booking) =>
        b1.event.userName.localeCompare(b2.event.userName)
      )
      .map((booking) => `${booking.event.userHash}`);

    const uniqueUsers = new Set(filterUsersDay);
    return {
      id: sampleBooking?.id,
      title: `${getEventTitle(group as Booking[])}`,
      start: moment(sampleBooking.event.startDate).toDate(),
      end: moment(sampleBooking.event.endDate).toDate(),
      allDay: false,
      equipmentRentHash: sampleBooking.event.equipmentRentHash,
      duration: sampleBooking.event.duration,
      user: sampleBooking.user,
      allUserHashes: Array.from(uniqueUsers),
      teacherName: sampleBooking.teacherName,
      group: Object.values(group),
      modalTitle: `${getEventTitle(group as Booking[])}`,
      createdBySystem: sampleBooking.createdBySystem
    };
  });
};
