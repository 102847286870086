import React from 'react';
import { RoomEditShared } from '../../../../../../components/shared/rooms/roomsEdit/RoomEditShared';

export const RoomEdit = ({
  setSection,
  sectionList,
  editToEditId,
  userHash
}) => {
  return (
    <RoomEditShared
      setSection={setSection}
      sectionList={sectionList}
      editToEditId={editToEditId}
      userHash={userHash}
    />
  );
};
