import { FunctionField, useRecordContext } from 'react-admin';

import React, { useContext, useEffect } from 'react';
import { User } from '../../../core/models/user/user';
import { UserHashContext } from '../../../core/context/userHashContext';
import { getUserParent } from '../../../core/services/user-api.service';

export const LinkedStatus = (props) => {
  const record = useRecordContext<User>();
  const { userHash } = useContext(UserHashContext);
  const [status, setStatus] = React.useState(null);

  useEffect(() => {
    getUserParent(record.id, userHash).then((res) => {
      setStatus(res.data.status);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getStatus = () => {
    return status?.translatedDisplay;
  };

  return (
    <FunctionField
      label="resources.users.fields.status"
      render={() => getStatus()}
      sortBy="candidate"
    />
  );
};
