import React from 'react';
import { useGetOne, useTranslate } from 'react-admin';
import { NavLink } from 'react-router-dom';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import { User } from '../../core/models/user/user';

export const Breadcrumbs = () => {
  const translate = useTranslate();
  const DynamicUserBreadcrumb = ({ match }) => {
    const data = useGetOne('users', { id: match.params.id });
    if (data && data.data) {
      const user = data.data as User;
      return (
        <span>
          {user?.firstName ? user.firstName : ''}{' '}
          {user?.lastName ? user.lastName : ''}{' '}
          {user?.secondLastName ? user.secondLastName : ''}{' '}
        </span>
      );
    }
  };
  const DynamicUserPrivilege = ({ match }) => (
    <span>{translate(`resources.breadcrumbs.${match.params.privilege}`)}</span>
  );

  const DynamicTermsAndConditionsBreadcrumb = ({ match }) => {
    const { data: termAndCondition } = useGetOne('terms-and-conditions', {
      id: match.params.id
    });
    if (termAndCondition) {
      return <span> {termAndCondition.title}</span>;
    }
  };

  const DynamicPrivacyPoliciesBreadcrumb = ({ match }) => {
    const { data: privacyPolicy } = useGetOne('privacy-policies', {
      id: match.params.id
    });
    if (privacyPolicy) {
      return <span> {privacyPolicy.title}</span>;
    }
  };

  const DynamicSportsBreadcrumb = ({ match }) => {
    const { data: sports } = useGetOne('sports', {
      id: match.params.id
    });
    if (sports) {
      return <span> {sports.name}</span>;
    }
  };

  const DynamicEquipmentRentsBreadcrumb = ({ match }) => {
    const { data: equipment } = useGetOne('equipment-rents', {
      id: match.params.id
    });
    if (equipment) {
      return <span> {equipment.name}</span>;
    }
  };

  const DynamicVouchersBreadcrumb = ({ match }) => {
    const { data: voucher } = useGetOne('vouchers', {
      id: match.params.id
    });
    if (voucher) {
      return <span> {voucher.title}</span>;
    }
  };

  const DynamicLessonsBreadcrumb = ({ match }) => {
    const { data: lesson } = useGetOne('lessons', {
      id: match.params.id
    });
    if (lesson) {
      return <span> {lesson.title}</span>;
    }
  };

  const DynamicCoursesBreadcrumb = ({ match }) => {
    const { data: course } = useGetOne('courses', {
      id: match.params.id
    });
    if (course) {
      return <span> {course.title}</span>;
    }
  };

  const DynamicRoomsBreadcrumb = ({ match }) => {
    const { data: room } = useGetOne('rooms', {
      id: match.params.id
    });
    if (room) {
      return <span> {room.name}</span>;
    }
  };

  const DynamicGlobalOperationsBreadcrumb = ({ match }) => {
    const { data: globalOperation } = useGetOne('global-operations', {
      id: match.params.id
    });
    if (globalOperation) {
      return <span> {globalOperation.title}</span>;
    }
  };

  const routes = [
    { path: '/', breadcrumb: translate('resources.breadcrumbs.home') },
    {
      path: '/:privilege',
      breadcrumb: DynamicUserPrivilege
    },
    { path: '/:privilege/:id', breadcrumb: DynamicUserBreadcrumb },
    {
      path: '/:privilege/create',
      breadcrumb: translate('resources.breadcrumbs.create')
    },
    {
      path: '/terms-and-conditions',
      breadcrumb: translate('resources.breadcrumbs.termsAndConditions')
    },
    {
      path: '/terms-and-conditions/create',
      breadcrumb: translate('resources.breadcrumbs.create')
    },
    {
      path: '/terms-and-conditions/:id',
      breadcrumb: DynamicTermsAndConditionsBreadcrumb
    },
    {
      path: '/sports',
      breadcrumb: translate('resources.breadcrumbs.sports')
    },
    {
      path: '/sports/create',
      breadcrumb: translate('resources.breadcrumbs.create')
    },
    {
      path: '/sports/:id',
      breadcrumb: DynamicSportsBreadcrumb
    },
    {
      path: '/privacy-policies',
      breadcrumb: translate('resources.breadcrumbs.privacyPolicies')
    },
    {
      path: '/privacy-policies/create',
      breadcrumb: translate('resources.breadcrumbs.create')
    },
    {
      path: '/privacy-policies/:id',
      breadcrumb: DynamicPrivacyPoliciesBreadcrumb
    },
    {
      path: '/equipment-rents',
      breadcrumb: translate('resources.breadcrumbs.equipmentRents')
    },
    {
      path: '/equipment-rents-scheduler',
      breadcrumb: translate('resources.breadcrumbs.equipmentRentsScheduler')
    },
    {
      path: '/equipment-rents-calendar',
      breadcrumb: translate('resources.breadcrumbs.equipmentRentsCalendar')
    },
    {
      path: '/equipment-rents/:id',
      breadcrumb: DynamicEquipmentRentsBreadcrumb
    },
    {
      path: '/vouchers',
      breadcrumb: translate('resources.breadcrumbs.vouchers')
    },
    {
      path: '/vouchers/:id',
      breadcrumb: DynamicVouchersBreadcrumb
    },
    {
      path: '/linked-sports',
      breadcrumb: translate('resources.breadcrumbs.sports')
    },
    {
      path: '/teachers/create-linked-teachers',
      breadcrumb: translate('resources.breadcrumbs.createLinkedTeachers')
    },
    {
      path: '/calendar',
      breadcrumb: translate('resources.breadcrumbs.calendar')
    },
    {
      path: '/active-vouchers',
      breadcrumb: translate('resources.breadcrumbs.activeVouchers')
    },
    {
      path: '/notifications',
      breadcrumb: translate('resources.breadcrumbs.notifications')
    },
    {
      path: '/invoicing-data',
      breadcrumb: translate('resources.breadcrumbs.invoicingData')
    },
    {
      path: '/payment-request',
      breadcrumb: translate('resources.breadcrumbs.paymentRequest')
    },
    {
      path: '/yearly-course',
      breadcrumb: translate('resources.breadcrumbs.yearlyCourse')
    },
    {
      path: '/small-course',
      breadcrumb: translate('resources.breadcrumbs.smallCourse')
    },
    {
      path: '/my-data',
      breadcrumb: translate('resources.breadcrumbs.myData')
    },
    {
      path: '/lessons/:id',
      breadcrumb: DynamicLessonsBreadcrumb
    },
    {
      path: '/yearly-course/:id',
      breadcrumb: DynamicCoursesBreadcrumb
    },
    {
      path: '/small-course/:id',
      breadcrumb: DynamicCoursesBreadcrumb
    },
    {
      path: '/rooms/:id',
      breadcrumb: DynamicRoomsBreadcrumb
    },
    {
      path: '/global-operations',
      breadcrumb: translate('resources.breadcrumbs.globalOperations')
    },
    {
      path: '/global-operations/:id',
      breadcrumb: DynamicGlobalOperationsBreadcrumb
    },
    {
      path: '/invoice-data-settings',
      breadcrumb: translate('resources.breadcrumbs.invoiceDataSettings')
    },
    {
      path: '/linked-clubs',
      breadcrumb: translate('resources.breadcrumbs.linkedClubs')
    }
  ];
  const breadcrumbs = useBreadcrumbs(routes);

  return (
    <>
      {breadcrumbs.map(({ match, breadcrumb }) => (
        <span key={match.pathname}>
          <NavLink to={match.pathname}>{breadcrumb}</NavLink>
        </span>
      ))}
    </>
  );
};
