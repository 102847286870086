import React from 'react';
import { SelectInput, SimpleForm, required, useGetList } from 'react-admin';

type EquipmentRentSchedulerEquipmentRentFiltersType = {
  setEquipmentRent;
  equipmentRent;
  userHash;
};

const EquipmentRentSchedulerEquipmentRentFilters = (
  props: EquipmentRentSchedulerEquipmentRentFiltersType
) => {
  const { data: equipmentRents, isLoading } = useGetList(
    `users/${props.userHash}/equipment-rents`,
    {
      sort: { field: 'name', order: 'ASC' }
    }
  );

  if (isLoading) {
    return <div></div>;
  }

  const equipmentRentChoices = [{ id: '', name: '' }];
  equipmentRentChoices.push(
    ...equipmentRents.map((equipmentRent) => {
      return { id: equipmentRent.id, name: equipmentRent.name };
    })
  );

  return (
    <div className="calendar-filters">
      {props.userHash ? (
        <SimpleForm toolbar={false}>
          <SelectInput
            optionText={(record: any) => record.name}
            source="lesson"
            defaultValue=""
            validate={[required()]}
            label="resources.equipmentRentsScheduler.dialog.fields.equipmentRent"
            onChange={(e) => props.setEquipmentRent(e.target.value)}
            choices={equipmentRentChoices}
          />
        </SimpleForm>
      ) : null}
    </div>
  );
};

export default EquipmentRentSchedulerEquipmentRentFilters;
