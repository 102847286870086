import React from 'react';
import { useRecordContext } from 'react-admin';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton } from '@mui/material';
import { DialogDeleteEquipmentRent } from './DialogDeleteEquipmentRent';
import { EquipmentRent } from '../../../../core/models/equipmentRent/equipmentRent';

export const EquipmentRentsDeleteButton = (props) => {
  const record: EquipmentRent = useRecordContext(props);
  const [showDeleteDialog, setShowDeleteDialog] = React.useState(false);

  const handleDeleteVoucher = () => setShowDeleteDialog(true);

  return (
    <>
      <IconButton onClick={() => handleDeleteVoucher()}>
        <DeleteIcon />
      </IconButton>
      <DialogDeleteEquipmentRent
        showDeleteDialog={showDeleteDialog}
        setShowDeleteDialog={setShowDeleteDialog}
        id={record.id}
      />
    </>
  );
};
