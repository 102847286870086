import { Box } from '@mui/material';
import React from 'react';
import {
  Datagrid,
  DateField,
  FunctionField,
  ListContextProvider,
  SimpleShowLayout,
  TextField,
  useList,
  useRecordContext,
  useTranslate
} from 'react-admin';
import { formatDecimal } from '../../../../core/utils/number-utils';

export default function PaymentRequestView(props) {
  const record = useRecordContext();
  const translate = useTranslate();
  const listContext = useList({
    data: record.balances.map((balance) => ({
      ...balance,
      paymentSerialCode: balance.booking
        ? balance.booking.paymentSerialCode
        : balance.userVoucher
        ? balance.userVoucher.paymentSerialCode
        : '',
      eventTitle: balance.booking
        ? balance.booking.event.eventTitle
        : balance.userVoucher
        ? balance.userVoucher.voucher.title
        : '',
      date: balance.booking
        ? balance.booking.date
        : balance.userVoucher
        ? balance.userVoucher.date
        : ''
    }))
  });

  const getAmount = (record) => {
    return translate('resources.invoicing.paymentRequest.list.currency', {
      value: formatDecimal(record.amount)
    });
  };

  const getCompleteName = (record: any) => {
    if (record.booking) {
      return `${record.booking.user.firstName} ${record.booking.user.lastName}`;
    }

    if (record.userVoucher) {
      return `${record.userVoucher.user.firstName} ${record.userVoucher.user.lastName}`;
    }

    return '-';
  };

  const sortedData = [...listContext.data].sort((a, b) => {
    if (a.paymentSerialCode > b.paymentSerialCode) {
      return -1;
    }
    if (a.paymentSerialCode < b.paymentSerialCode) {
      return 1;
    }
    return 0;
  });

  return (
    <Box sx={{ paddingY: '2px', marginY: '0px' }}>
      <SimpleShowLayout sx={{ paddingY: '0px' }}>
        <ListContextProvider value={{ ...listContext, data: sortedData }}>
          <Datagrid bulkActionButtons={false}>
            {!props.isAdmin && (
              <FunctionField
                label="resources.invoicing.paymentRequest.list.amount"
                render={(record) => getAmount(record)}
                sortable={false}
                textAlign="right"
              />
            )}
            <TextField
              source="paymentSerialCode"
              label={
                'resources.invoicing.paymentRequest.expandedRow.serialCode'
              }
              sortable={false}
            />
            {props.isAdmin && (
              <FunctionField
                label="resources.invoicing.paymentRequest.list.amount"
                render={(record) => getAmount(record)}
                sortable={false}
                textAlign="right"
              />
            )}
            <TextField
              source="eventTitle"
              label={'resources.invoicing.paymentRequest.expandedRow.title'}
              sortable={false}
            />
            <FunctionField
              render={(record) => getCompleteName(record)}
              label={'resources.invoicing.paymentRequest.expandedRow.student'}
              sortable={false}
            />
            <DateField
              showTime
              source="booking.event.lessonDate"
              label={'resources.invoicing.paymentRequest.expandedRow.date'}
              emptyText="-"
              sortable={false}
              options={{
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric'
              }}
            />
            <FunctionField
              label={'resources.invoicing.paymentRequest.expandedRow.eventType'}
              sortable={false}
              render={(record) =>
                translate(
                  record.booking
                    ? record.booking.isCourse
                      ? 'resources.invoicing.paymentRequest.expandedRow.eventTypes.course'
                      : record.booking.isLesson
                      ? 'resources.invoicing.paymentRequest.expandedRow.eventTypes.lesson'
                      : 'resources.invoicing.paymentRequest.expandedRow.eventTypes.equipmentRent'
                    : 'resources.invoicing.paymentRequest.expandedRow.eventTypes.voucher'
                )
              }
            />
          </Datagrid>
        </ListContextProvider>
      </SimpleShowLayout>
    </Box>
  );
}
