import {
  Grid,
  Tooltip,
  TooltipProps,
  Typography,
  styled,
  tooltipClasses
} from '@mui/material';
import React, { Fragment, useEffect, useState } from 'react';
import {
  CheckboxGroupInput,
  DateInput,
  Loading,
  NumberInput,
  RadioButtonGroupInput,
  ReferenceInput,
  SelectInput,
  TimeInput,
  maxValue,
  required,
  useGetList,
  useTranslate
} from 'react-admin';
import { Views } from 'react-big-calendar';
import { useFormContext } from 'react-hook-form';
import {
  TIME_TYPE_DAY,
  TIME_TYPE_MONTH,
  TIME_TYPE_WEEK
} from '../../../../../core/constants/timeType.constants';
import {
  formatMillis,
  getCurrentDate,
  getMillis
} from '../../../../../core/utils/date.utils';

export const EquipmentRentsCalendarEventForm = (props: {
  slotInfo;
  equipmentRent;
  setEquipmentRent;
  view;
  userHash;
}) => {
  const iterateEndDate = 1;
  const iterateEndIterations = 2;
  const { setValue } = useFormContext();
  const [timeType, setTimeType] = useState(null);
  const [selectedEquipmenRent, setSelectedEquipmenRent] = useState(null);
  const { data: timeTypesData, isLoading: isLoadingTimeTypes } =
    useGetList('time-types');
  const { data: equipmentRents, isLoading: isLoadingUserData } = useGetList(
    `users/${props.userHash}/equipment-rents`
  );
  const [iterateEndType, setIterateEndType] = useState<number>(iterateEndDate);
  const translate = useTranslate();
  const defaultTimeType = TIME_TYPE_DAY;
  const currentYear = getCurrentDate();
  const date = formatMillis('yyyy-MM-DD', getMillis(currentYear));
  const [selectedTimeType, setSelectedTimeType] =
    useState<number>(defaultTimeType);

  const choices = [
    {
      id: iterateEndDate,
      name: 'resources.equipmentRentsCalendar.dialog.fields.afterDate'
    },
    {
      id: iterateEndIterations,
      name: 'resources.equipmentRentsCalendar.dialog.fields.afterIterations'
    }
  ];

  const onChangeIterationNumberEnd = (value) => {
    if (value) {
      setValue('iterationDateEnd', null);
    }
  };

  currentYear.setFullYear(currentYear.getFullYear() + 1);

  useEffect(() => {
    if (timeTypesData) {
      const timeType = timeTypesData.find(
        (time) => time.id === selectedEquipmenRent?.timeType
      );
      if (timeType) {
        setTimeType(timeType);
      }
    }
  }, [timeTypesData, selectedEquipmenRent?.timeType, setTimeType]);

  useEffect(() => {
    const date = new Date();
    const dateStart = props.slotInfo.start;
    const dateEnd = props.slotInfo.end;

    if (props.view === Views.MONTH) {
      if (props.slotInfo.start && props.slotInfo.start.getHours() === 0) {
        dateStart.setHours(date.getHours());
        dateStart.setMinutes(0);
      }

      if (props.slotInfo.end && props.slotInfo.end.getHours() === 0) {
        dateEnd.setHours(date.getHours());
        dateEnd.setMinutes(30);
      }
    }

    setValue('startDate', dateStart);
    setValue('endDate', dateEnd);
  }, [
    props.slotInfo,
    props.slotInfo.start,
    props.slotInfo.end,
    props.view,
    setValue
  ]);

  const onChangeSelectedTimeType = (value: number) => {
    setSelectedTimeType(value);
    if (value === TIME_TYPE_DAY) {
      setValue('dayTypes', null);
    }
  };

  const handleIterationEndTypeChange = (e) => {
    const categoryNumber = Number(e.target.value);
    setIterateEndType(categoryNumber);
    if (categoryNumber === iterateEndDate) {
      setValue('iterationEnd', null);
    }

    if (categoryNumber === iterateEndIterations) {
      setValue('iterationEndDate', null);
    }
  };

  const onChangeIterationDateEnd = (value) => {
    if (value) {
      setValue('iterationEnd', null);
    }
  };

  const hanldeEquipemntRentChange = (e) => {
    const equipmentRent = equipmentRents.find(
      (equipmentRent) => equipmentRent.id === e.target.value
    );

    setSelectedEquipmenRent(equipmentRent);
    setValue('equipmentRent', equipmentRent.id);
  };

  const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: 'black',
      fontSize: 12,
      border: '1px solid #0F2C5E',
      width: '56%',
      borderRadius: '10px'
    }
  }));

  if (isLoadingTimeTypes || isLoadingUserData) {
    return <Loading />;
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={5} md={5}>
          <div className="repeat">
            <label>
              {translate(
                'resources.equipmentRentsCalendar.dialog.fields.equipmentRent'
              )}
            </label>
            <SelectInput
              source="equipmentRentHash"
              label=""
              optionText="name"
              choices={equipmentRents}
              validate={[required()]}
              onChange={hanldeEquipemntRentChange}
            />
          </div>
        </Grid>
        <Grid item xs={5} md={5}>
          {selectedEquipmenRent && (
            <div className="info">
              <Typography>
                <span style={{ fontWeight: 'bold' }}>
                  {translate(
                    'resources.equipmentRentsCalendar.dialog.event.title'
                  )}
                </span>{' '}
                {selectedEquipmenRent.name}
              </Typography>
              <Typography>
                <span style={{ fontWeight: 'bold' }}>
                  {translate(
                    'resources.equipmentRentsCalendar.dialog.event.time'
                  )}{' '}
                </span>
                {selectedEquipmenRent.time}
              </Typography>
              <Typography>
                <span style={{ fontWeight: 'bold' }}>
                  {translate(
                    'resources.equipmentRentsCalendar.dialog.event.timeType'
                  )}{' '}
                </span>
                {timeType?.name}
              </Typography>
            </div>
          )}
        </Grid>

        <Grid item>
          <div className="time">
            <TimeInput
              source="startDate"
              label="resources.equipmentRentsCalendar.dialog.fields.start"
            />
            <TimeInput
              source="endDate"
              label="resources.equipmentRentsCalendar.dialog.fields.end"
            />
          </div>
          <div
            style={
              selectedTimeType === TIME_TYPE_DAY ? { display: 'none' } : {}
            }
          >
            <ReferenceInput
              source="dayTypes"
              reference="day-types"
              sort={{ field: 'name', order: 'ASC' }}
              defaultValue={defaultTimeType}
            >
              <CheckboxGroupInput
                className={'dayTypes'}
                optionText="shortName"
                source="dayTypes"
                label="resources.users.calendar.dialog.fields.dayType"
              />
            </ReferenceInput>
          </div>
          <div className="repeat">
            <label>
              {translate(
                'resources.equipmentRentsCalendar.dialog.fields.iteration'
              )}
            </label>
            <div
              className="time"
              style={{ alignItems: 'center', width: '97%' }}
            >
              <SelectInput
                optionText="name"
                source="timeType"
                defaultValue={defaultTimeType}
                validate={[required()]}
                label=""
                onChange={(e) => {
                  onChangeSelectedTimeType(e.target.value);
                }}
                choices={[
                  {
                    id: TIME_TYPE_DAY,
                    name: 'resources.equipmentRentsCalendar.dialog.fields.day'
                  },
                  {
                    id: TIME_TYPE_WEEK,
                    name: 'resources.equipmentRentsCalendar.dialog.fields.week'
                  },
                  {
                    id: TIME_TYPE_MONTH,
                    name: 'resources.equipmentRentsCalendar.dialog.fields.month'
                  }
                ]}
                style={{ marginRight: 20 }}
              />
              <StyledTooltip
                title={
                  <Fragment>
                    <p>
                      {translate(
                        'resources.equipmentRentsCalendar.dialog.tooltip.repeatEachMonth'
                      )}
                      <b>
                        {translate(
                          'resources.equipmentRentsCalendar.dialog.tooltip.onlyForWeek'
                        )}
                      </b>
                      {translate(
                        'resources.equipmentRentsCalendar.dialog.tooltip.days'
                      )}
                    </p>
                    <p>
                      {translate(
                        'resources.equipmentRentsCalendar.dialog.tooltip.toRepeat'
                      )}
                      <b>
                        {translate(
                          'resources.equipmentRentsCalendar.dialog.tooltip.all'
                        )}
                      </b>
                      {translate(
                        'resources.equipmentRentsCalendar.dialog.tooltip.pleaseSelect'
                      )}
                      <b>
                        {translate(
                          'resources.equipmentRentsCalendar.dialog.tooltip.week'
                        )}
                      </b>
                      ".
                    </p>
                  </Fragment>
                }
                placement="right-end"
                hidden={selectedTimeType !== TIME_TYPE_MONTH}
              >
                <p style={{ paddingBottom: 20 }}>
                  {translate(
                    'resources.equipmentRentsCalendar.dialog.tooltip.title'
                  )}
                </p>
              </StyledTooltip>
            </div>
            <RadioButtonGroupInput
              source="iterationType"
              value={iterateEndType}
              validate={[required()]}
              className="radiogroup"
              label="resources.equipmentRentsCalendar.dialog.fields.end"
              choices={choices}
              onChange={(e) => handleIterationEndTypeChange(e)}
              defaultValue={choices[0].id}
            />
            <DateInput
              source="iterationDateEnd"
              label=""
              validate={maxValue(date, 'validation.dateOneYearOrLess')}
              onChange={(e) => onChangeIterationDateEnd(e.target.value)}
              defaultValue={null}
              className={iterateEndType === iterateEndIterations && 'hide'}
            />
            <NumberInput
              source="iterationEnd"
              label="resources.equipmentRentsCalendar.dialog.fields.afterIterations"
              onChange={(e) => onChangeIterationNumberEnd(e.target.value)}
              className={iterateEndType === iterateEndDate && 'hide'}
            />
          </div>
        </Grid>
      </Grid>
    </>
  );
};
