import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

const firebaseConfig = {
  apiKey: 'AIzaSyDpwy7soXyq6Qt8G7KWvlGAYE3iD4G7FW8',
  authDomain: 'nak-sports-dev.firebaseapp.com',
  projectId: 'nak-sports-dev',
  databaseUrl: 'https://nakdev-default-rtdb.firebaseio.com',
  storageBucket: 'nak-sports-dev.appspot.com/',
  messagingSenderId: '952042844684',
  appId: '1:952042844684:web:b2893117bd85f41096b429',
  measurementId: 'G-CCMYF5Q6DH'
};

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export async function requestForToken(): Promise<string> {
  try {
    const currentToken = await getToken(messaging, {
      vapidKey: messaging['vapidKey']
    });
    if (currentToken) {
      return currentToken;
    } else {
      return null;
    }
  } catch (err) {}
}

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
