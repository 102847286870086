import React from 'react';
import { EquipmentRentsCreateShared } from '../../../../../../components/shared/equipmentRents/equipmentRentsCreate/EquipmentRentsCreateShared';

export const EquipmentRentCreate = ({ setSection, sectionList, userHash }) => {
  return (
    <EquipmentRentsCreateShared
      setSection={setSection}
      sectionList={sectionList}
      userHash={userHash}
    />
  );
};
