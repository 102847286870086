import { Loading, required, SelectInput, useGetList } from 'react-admin';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { FilterRegion } from './FilterRegion';

export const FilterCountry = (props) => {
  const { getValues, setValue } = useFormContext();
  const { data: communities, isLoading } = useGetList('public/countries', {
    pagination: { page: 1, perPage: 300 },
    sort: { field: 'name', order: 'ASC' }
  });
  const handleCountryChange = () => {
    setValue('filterRegion', '');
    setValue('filterCity', '');
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <SelectInput
        source="filterCountry"
        optionText="name"
        label="resources.users.fields.country"
        validate={[required()]}
        defaultValue=""
        choices={communities}
        onChange={(e) => handleCountryChange()}
      />

      {getValues('filterCountry') && getValues('filterCountry') !== '' && (
        <FilterRegion />
      )}
    </>
  );
};
