import React from 'react';

import {
  Create,
  SaveButton,
  SimpleForm,
  Toolbar,
  useCreate,
  useNotify,
  useRedirect
} from 'react-admin';

import { useParams } from 'react-router-dom';
import {
  PRIVILEGE_CLUB,
  PRIVILEGE_STUDENT,
  PRIVILEGE_TEACHER
} from '../../../core/constants/privilege.constants';
import { createFormValidators } from './createFormValidator';
import UserForm from '../userShared/UserForm';
import { convertFileToBase64 } from '../../../core/utils/file-utils';
import { removeWhiteSpaces } from '../../../core/utils/string-utils';

export const UserCreate = () => {
  const [create] = useCreate('users');
  const notify = useNotify();
  const redirect = useRedirect();
  const { privilege } = useParams();

  const isClub = privilege === 'clubs';
  const isStudent = privilege === 'students';
  const isTeacher = privilege === 'teachers';

  const postSave = async (data: any) => {
    if (data.image?.rawFile instanceof File) {
      data.image = await convertFileToBase64(data.image);
    }
    data = {
      ...data,
      privilege: isClub
        ? PRIVILEGE_CLUB
        : isStudent
        ? PRIVILEGE_STUDENT
        : PRIVILEGE_TEACHER,
      accountNumber: removeWhiteSpaces(data['accountNumber'])
    };
    create(
      'users',
      { data },
      {
        onSuccess: () => {
          notify('resources.users.notifications.userCreate.success', {
            type: 'success'
          });
          redirect(`/${privilege}`);
        },
        onError(error: any) {
          notify(error.message, { type: 'warning' });
        }
      }
    );
  };

  return (
    <Create resource="users">
      <SimpleForm
        validate={(e) => createFormValidators(e, isClub, isStudent, isTeacher)}
        onSubmit={postSave}
        className="form"
        toolbar={
          <div>
            <Toolbar>
              <SaveButton />
            </Toolbar>
          </div>
        }
      >
        <UserForm
          isClub={isClub}
          isTeacher={isTeacher}
          isStudent={isStudent}
          isAdmin={false}
        />
      </SimpleForm>
    </Create>
  );
};
