import React from 'react';
import { useRecordContext } from 'react-admin';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton } from '@mui/material';
import { DialogDeleteUserPaymentMethod } from './DialogDeleteUserPaymentMethod';
import { UserPaymentMethod } from '../../../../../../core/models/paymentMethod/userPaymentMethod';
import { DialogCantDeleteUserPaymentMethod } from './DialogCantDeleteUserPaymentMethod';

export const UserPaymentMethodDeleteButton = (props) => {
  const record: UserPaymentMethod = useRecordContext(props);
  const [showDeleteDialog, setShowDeleteDialog] = React.useState(false);
  const [showCantDeleteDialog, setShowCantDeleteDialog] = React.useState(false);

  const handleDeleteRoom = () => {
    if (record.canDelete) {
      setShowDeleteDialog(true);
    } else {
      setShowCantDeleteDialog(true);
    }
  };

  return (
    <>
      <IconButton
        onClick={() => handleDeleteRoom()}
        style={record.canDelete ? {} : { opacity: 0.5 }}
      >
        <DeleteIcon />
      </IconButton>
      <DialogDeleteUserPaymentMethod
        showDeleteDialog={showDeleteDialog}
        setShowDeleteDialog={setShowDeleteDialog}
        id={record.id}
      />

      <DialogCantDeleteUserPaymentMethod
        showCantDeleteDialog={showCantDeleteDialog}
        setShowCantDeleteDialog={setShowCantDeleteDialog}
      />
    </>
  );
};
