import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import React, { useState } from 'react';
import { Button, useTranslate } from 'react-admin';
import { DeleteCalendarEquipmentRentDialog } from './DeleteCalendarEquipmentRentDialog';

export const EquipmentRentCalendarEquipmentRentDialogSimple = (props) => {
  const translate = useTranslate();
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [deleteSeries, setDeleteSeries] = useState(false);

  const formattedStart = props.event?.start.toLocaleString();
  const formattedEnd = props.event?.end.toLocaleString();

  const onCloseDialog = () => {
    props.setOpenDialog(false);
  };

  const onDeleteEvent = () => {
    setDeleteSeries(false);
    setShowDeleteDialog(true);
  };

  const onDeleteSeries = () => {
    setDeleteSeries(true);
    setShowDeleteDialog(true);
  };

  return (
    <>
      <Dialog fullWidth open={props.openDialog} onClose={onCloseDialog}>
        <DialogTitle>{props.event?.modalTitle}</DialogTitle>
        <DialogContent>
          <p>
            {translate('resources.equipmentRentsCalendar.start')}
            {formattedStart.slice(0, -3)}
          </p>
          <p>
            {translate('resources.equipmentRentsCalendar.end')}
            {formattedEnd.slice(0, -3)}
          </p>
          <div className="dialog-actions">
            <Button
              onClick={onDeleteEvent}
              label={translate(
                'resources.equipmentRentsCalendar.dialog.delete.actions.delete'
              )}
            />
            <Button
              onClick={onDeleteSeries}
              label={translate(
                'resources.equipmentRentsCalendar.dialog.delete.actions.deleteSeries'
              )}
            />
          </div>
        </DialogContent>
      </Dialog>
      <DeleteCalendarEquipmentRentDialog
        event={props.event}
        showDeleteDialog={showDeleteDialog}
        setShowDeleteDialog={setShowDeleteDialog}
        setOpenCourseEventDialog={props.setOpenDialog}
        deleteSeries={deleteSeries}
        setRefresh={props.setRefresh}
      />
    </>
  );
};
