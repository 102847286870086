import {
  Datagrid,
  FunctionField,
  List,
  TextField,
  useTranslate
} from 'react-admin';
import React from 'react';
import { VoucherListActions } from './VoucherListActions';
import { VoucherStatusButton } from './VoucherStatusButton';
import { VoucherEditButton } from './VoucherEditButton';
import { VoucherDeleteButton } from './VoucherDeleteButton';
import { Voucher } from '../../../../core/models/voucher/voucher';
import { CustomPagination } from '../../../general/Pagination';
import { EmptyList } from '../../../general/EmptyList';

export const VoucherListShared = (props) => {
  const translate = useTranslate();
  const getPrice = (voucher: Voucher) => {
    return `${voucher.price.toLocaleString()} ${translate(
      'resources.users.vouchers.list.currency'
    )}`;
  };

  const getLessonNumberOrTime = (voucher: Voucher) => {
    if (voucher.lessonNumber) {
      return `${voucher.lessonNumber} ${translate(
        'resources.users.vouchers.list.lessons'
      )}`;
    }

    if (voucher.timeRangeType) {
      return voucher.timeRangeType.name;
    }
  };

  return (
    <List
      resource={`users/${props.userHash}/vouchers`}
      sort={{ field: 'title', order: 'ASC' }}
      perPage={Number(process.env.REACT_APP_LIST_SIZE)}
      pagination={<CustomPagination />}
      empty={
        <EmptyList
          setSection={props.setSection}
          sectionCreate={props.sectionCreate}
          route={props.route}
          message="resources.users.vouchers.list.empty.title"
          buttonLabel="resources.users.vouchers.list.empty.buttonLabel"
        />
      }
      actions={
        <VoucherListActions
          setSection={props.setSection}
          sectionCreate={props.sectionCreate}
          route={props.route}
        />
      }
    >
      <Datagrid bulkActionButtons={false}>
        <TextField
          source="title"
          label="resources.users.vouchers.list.title"
          sortBy="title"
          emptyText="-"
        />
        <FunctionField
          label="resources.users.vouchers.list.lessonNumber"
          render={(record: Voucher) => getLessonNumberOrTime(record)}
        />
        <FunctionField
          source="price"
          label="resources.users.vouchers.list.price"
          sortBy="price"
          render={(record: Voucher) => getPrice(record)}
        />
        <VoucherStatusButton />
        <VoucherDeleteButton />
        <VoucherEditButton
          setVoucherToEditId={props.setVoucherToEditId}
          sectionEdit={props.sectionEdit}
          setSection={props.setSection}
          route={props.route}
        />
      </Datagrid>
    </List>
  );
};
