import React, { useRef } from 'react';
import {
  EditBase,
  ReferenceInput,
  SaveButton,
  SelectInput,
  SimpleForm,
  useNotify,
  useRefresh,
  useUpdate
} from 'react-admin';

export default function DialogEditRoomLessonEvent({
  event,
  id,
  onCloseDialog
}) {
  const [update] = useUpdate();

  const notify = useNotify();
  const refresh = useRefresh();
  var isEditManyEvents = useRef<Boolean>(false);
  const onUpdateEvent = (event) => {
    isEditManyEvents.current
      ? onUpdateManyEvents(event)
      : onUpdateOneEvent(event);
  };

  const onUpdateOneEvent = (event) => {
    update(
      'events',
      { id: event.id, data: { room: event.room.id } },
      {
        onSuccess: () => {
          refresh();
          onCloseDialog();
        },
        onError(error: any) {
          notify(error.message, { type: 'warning' });
        }
      }
    );
  };

  const onUpdateManyEvents = (event) => {
    update(
      'lesson-events',
      {
        id: event.lessonEventHash,
        data: { room: event.room.id }
      },
      {
        onSuccess: () => {
          refresh();
          onCloseDialog();
        },
        onError(error: any) {
          notify(error.message, { type: 'warning' });
        }
      }
    );
  };

  const saveOneEventRoomEdit = (event) => {
    isEditManyEvents.current = false;
  };

  const saveManyRoomEdit = (even) => {
    isEditManyEvents.current = true;
  };
  return (
    <div className="inline-form">
      <EditBase resource={'events'} id={event.id}>
        <SimpleForm
          onSubmit={onUpdateEvent}
          noValidate
          toolbar={
            <>
              <div>
                <SaveButton
                  onClick={saveOneEventRoomEdit}
                  label={
                    'resources.users.calendar.lessonEvent.actions.oneEvent'
                  }
                  style={{ marginRight: '8px' }}
                />

                <SaveButton
                  onClick={saveManyRoomEdit}
                  label={
                    'resources.users.calendar.lessonEvent.actions.allEvents'
                  }
                  style={{ marginRight: '8px' }}
                />
              </div>
            </>
          }
        >
          <ReferenceInput
            source="room.id"
            reference={`users/${id}/rooms`}
            sort={{ field: 'name', order: 'ASC' }}
            filter={{ lesson: event.lessonHash }}
          >
            <SelectInput
              optionText="name"
              source="room"
              parse={(value) => (value.id ? value.id : value)}
              defaultValue=""
              validate={[]}
              label="resources.users.calendar.dialog.fields.room"
            />
          </ReferenceInput>
        </SimpleForm>
      </EditBase>
    </div>
  );
}
