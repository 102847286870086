import React from 'react';
import { LinkedTeacherCreate } from './linkedTeacherCreate/LinkedTeacherCreate';
import { LinkedTeacherList } from './linkedTeacherList/LinkedTeacherList';

export const LinkedTeacherOverview = () => {
  const sectionList = 'list';
  const sectionCreate = 'create';

  const [section, setSection] = React.useState(sectionList);

  if (section === sectionCreate) {
    return (
      <LinkedTeacherCreate setSection={setSection} sectionList={sectionList} />
    );
  }

  if (section === sectionList) {
    return (
      <LinkedTeacherList
        setSection={setSection}
        sectionCreate={sectionCreate}
      ></LinkedTeacherList>
    );
  }
};
