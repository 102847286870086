import React from 'react';
import { Datagrid, List, TextField } from 'react-admin';
import { LinkedClubDeleteButton } from './LinkedClubDeleteButton';
import { LinkedClubLinkedButton } from './LinkedClubLinkedButton';
import { CustomPagination } from '../../../general/Pagination';
import { EmptyList } from '../../../general/EmptyList';

export const LinkedClubListShared = (props) => {
  return (
    <List
      resource="user-parents/linked-clubs"
      filter={{ user_id: props.id }}
      pagination={<CustomPagination />}
      perPage={Number(process.env.REACT_APP_LIST_SIZE)}
      exporter={false}
      actions={false}
      empty={
        <EmptyList message="resources.users.linkedClubs.list.empty.title" />
      }
    >
      <Datagrid bulkActionButtons={false}>
        <TextField
          source="parentName"
          label="resources.users.linkedClubs.list.clubName"
          emptyText="-"
          sortBy={'parent.firstName'}
        />
        <TextField
          source="status.translatedDisplay"
          label="resources.users.linkedClubs.list.status"
          emptyText="-"
          sortBy={'status'}
        />
        <LinkedClubLinkedButton />
        <LinkedClubDeleteButton />
      </Datagrid>
    </List>
  );
};
