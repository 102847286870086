import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import React, { useState } from 'react';
import { Button, useTranslate } from 'react-admin';
import { DeleteSchedulerEquipmentRentDialog } from './DeleteSchedulerEquipmentRentDialog';

export const EquipmentRentSchedulerEquipmentRentDialogSimple = ({
  setOpenDialog,
  openDialog,
  booking,
  setRefresh
}) => {
  const translate = useTranslate();
  const [showCancelDialog, setShowCancelDialog] = useState(false);

  const formattedStart = booking?.start.toLocaleString();
  const formattedEnd = booking?.end.toLocaleString();

  const onCloseDialog = () => {
    setOpenDialog(false);
  };

  const onDeleteEvent = () => {
    setShowCancelDialog(true);
  };

  return (
    <>
      <Dialog fullWidth open={openDialog} onClose={onCloseDialog}>
        <DialogTitle>{booking?.modalTitle}</DialogTitle>
        <DialogContent>
          <p>
            {translate('resources.equipmentRentsScheduler.boughtBy')}
            {`${booking?.user?.firstName} ${booking?.user?.lastName}`}
          </p>
          <p>
            {translate('resources.equipmentRentsScheduler.start')}
            {formattedStart.slice(0, -3)}
          </p>
          <p>
            {translate('resources.equipmentRentsScheduler.end')}
            {formattedEnd.slice(0, -3)}
          </p>
          <div className="dialog-actions">
            <Button
              onClick={onDeleteEvent}
              label={translate(
                'resources.equipmentRentsScheduler.dialog.delete.actions.delete'
              )}
            />
          </div>
        </DialogContent>
      </Dialog>
      <DeleteSchedulerEquipmentRentDialog
        booking={booking}
        showCancelDialog={showCancelDialog}
        setShowCancelDialog={setShowCancelDialog}
        setOpenScheduleBookingDialog={setOpenDialog}
        setRefresh={setRefresh}
      />
    </>
  );
};
