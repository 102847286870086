import React from 'react';
import { TopToolbar } from 'react-admin';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../buttons/Button';

export const RoomListActions = (props) => {
  const navigate = useNavigate();
  const goToCreate = () => {
    if (props.route) {
      navigate(props.route);
    }

    if (props.setSection) {
      props.setSection(props.sectionCreate);
    }
  };

  return (
    <TopToolbar>
      <Button
        onClick={goToCreate}
        label={'resources.users.rooms.actions.create'}
      ></Button>
    </TopToolbar>
  );
};
