import moment from 'moment';
import { Views } from 'react-big-calendar/dist/react-big-calendar.esm';
import { Booking } from '../../../core/models/booking/booking';

export type ScheduleEvent = {
  id;
  title;
  start;
  end;
  allDay;
  lessonEventHash;
  courseEventHash;
  equipmentRentHash;
  duration;
  room;
  userName;
  userLastName;
  userHash;
  allUserHashes;
  allTeacherHashes;
  lessonHash;
  teacherHash;
  teacherName;
  group;
  modalTitle;
  createdBySystem;
  isClub;
};

export const getFirstMillisOfView = (date, view) => {
  if (view === Views.WEEK) {
    return moment(date).startOf('isoWeek').valueOf();
  }
  return moment(date).startOf(view).valueOf();
};

export const getLastMillisOfView = (date, view) => {
  if (view === Views.WEEK) {
    return moment(date).endOf('isoWeek').add(1, 'week').valueOf();
  }
  return moment(date).endOf(view).valueOf();
};

export const getEventTitle = (group: Booking[]) => {
  if (group.length > 1) {
    return `${
      '(' + group.length.toString() + ') ' + getMultiEventTitle(group)
    }`;
  }
  return getSingleEventTitle(group[0]);
};

export const getMultiEventTitle = (group: Booking[]) => {
  const uniqueEvents = group.reduce((unique: Booking[], event: Booking) => {
    const title = getSingleEventTitle(event);
    if (
      !unique.some((uniqueEvent) => getSingleEventTitle(uniqueEvent) === title)
    ) {
      unique.push(event);
    }
    return unique;
  }, []);

  return uniqueEvents.map((event) => getSingleEventTitle(event)).join(', ');
};

export const getSingleEventTitle = (booking: Booking) => {
  return booking?.event?.eventTitle;
};

const groupEvents = (bookings: Booking[]) => {
  const groupedEvents = {};

  bookings.forEach((booking) => {
    const key =
      `${booking?.user?.id ?? 'null'}` +
      `-${booking?.teacherName ?? 'null'}` +
      `-${booking?.event?.room?.id ?? 'null'}` +
      `-${booking?.event?.courseHash ?? 'null'}` +
      `-${booking?.event?.lessonHash ?? 'null'}` +
      `-${booking?.event?.userHash ?? 'null'}` +
      `-${booking?.event?.startDate}-${booking?.event?.endDate}`;

    if (!groupedEvents[key]) {
      groupedEvents[key] = [];
    }

    groupedEvents[key].push(booking);
  });

  return groupedEvents;
};

const filterAndSortDays = (
  data: Booking[],
  sampleBooking: any,
  sortProperty: string[],
  mapProperty: string[]
) => {
  return data
    .filter(
      (booking) =>
        moment(booking.event.startDate).isAfter(
          moment(sampleBooking.startDate).startOf('day')
        ) &&
        moment(booking.event.endDate).isBefore(
          moment(sampleBooking.startDate).endOf('day')
        )
    )
    .sort((b1: Booking, b2: Booking) => {
      const prop1 = getPropertyKeys(b1, sortProperty);
      const prop2 = getPropertyKeys(b2, sortProperty);
      return prop1.localeCompare(prop2);
    })
    .map((booking) => getPropertyKeys(booking, mapProperty));
};

const getPropertyKeys = (booking: Booking, keys: string[]) => {
  return keys.reduce((acc, key) => (acc && acc[key] ? acc[key] : ''), booking);
};

export const generateCalendarEventsFromBookings = (
  data: Booking[],
  isClub: boolean
): ScheduleEvent[] => {
  const groupedEvents = groupEvents(data);

  return Object.values(groupedEvents).map((group: any[]) => {
    const sampleBooking = group[0];
    const filterUsersDay = filterAndSortDays(
      data,
      sampleBooking,
      ['user', 'firstName'],
      ['user', 'id']
    );
    const filterTeachersDay = filterAndSortDays(
      data,
      sampleBooking,
      ['teacherName'],
      ['teacherHash']
    );

    const uniqueUsers = new Set(filterUsersDay);
    const uniqueTeachers = new Set(filterTeachersDay);

    return {
      id: sampleBooking?.id,
      title: `${getEventTitle(group as Booking[])}`,
      start: moment(sampleBooking.event.startDate).toDate(),
      end: moment(sampleBooking.event.endDate).toDate(),
      allDay: false,
      lessonEventHash: sampleBooking.event.lessonEventHash,
      courseEventHash: sampleBooking.event.courseHash,
      equipmentRentHash: sampleBooking.event.equipmentRentHash,
      duration: sampleBooking.event.duration,
      room: sampleBooking.event.room,
      user: sampleBooking.user,
      userName: sampleBooking.user.firstName,
      userLastName: sampleBooking.user.lastName,
      userHash: sampleBooking.user.id,
      allUserHashes: Array.from(uniqueUsers),
      allTeacherHashes: Array.from(uniqueTeachers),
      lessonHash: sampleBooking.event.lessonHash,
      teacherName: sampleBooking.teacherName,
      teacherHash: sampleBooking.teacherHash,
      group: Object.values(group),
      modalTitle: `${getEventTitle(group as Booking[])}`,
      createdBySystem: sampleBooking.createdBySystem,
      isClub: isClub
    };
  });
};
