import React from 'react';
import { useLocation } from 'react-router-dom';
import { RoomEdit } from './roomEdit/RoomEdit';
import { RoomCreate } from './roomsCreate/RoomCreate';
import { RoomList } from './roomsList/RoomList';

export const RoomOverview = () => {
  const sectionList = 'list';
  const sectionCreate = 'create';
  const sectionEdit = 'edit';
  const location = useLocation();
  const parts = location.pathname.split('/');
  const userHash = parts.pop();

  const [section, setSection] = React.useState(sectionList);
  const [roomToEditId, setRoomToEditId] = React.useState(null);

  if (section === sectionCreate) {
    return (
      <RoomCreate
        setSection={setSection}
        sectionList={sectionList}
        userHash={userHash}
      />
    );
  }

  if (section === sectionEdit) {
    return (
      <RoomEdit
        setSection={setSection}
        sectionList={sectionList}
        userHash={userHash}
        editToEditId={roomToEditId}
      />
    );
  }

  if (section === sectionList) {
    return (
      <RoomList
        setSection={setSection}
        sectionCreate={sectionCreate}
        sectionEdit={sectionEdit}
        setRoomToEditId={setRoomToEditId}
        userHash={userHash}
      />
    );
  }
};
