import React from 'react';
import { useRecordContext } from 'react-admin';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { TermAndCondition } from '../../../../core/models/termAndCondition/termAndCondition';

export const PrivacyPoliciesViewButton = (props) => {
  const record: TermAndCondition = useRecordContext(props);
  const navigate = useNavigate();

  const handleView = () => {
    navigate(`${record.id}`);
  };

  return (
    <IconButton onClick={() => handleView()}>
      <RemoveRedEyeIcon />
    </IconButton>
  );
};
