import React, { useContext, useEffect } from 'react';
import { VoucherListShared } from '../../../components/shared/vouchers/vouchersList/VoucherListShared';
import { HeaderTitleContext } from '../../../core/context/headerTitleContext';
import { UserHashContext } from '../../../core/context/userHashContext';

export const VoucherList = () => {
  const { setHeaderTitle } = useContext(HeaderTitleContext);
  const { userHash } = useContext(UserHashContext);

  useEffect(() => {
    setHeaderTitle('resources.vouchers.header.title');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <VoucherListShared userHash={userHash} route={'create'} />;
};
