import sortBy from 'lodash/sortBy';

class Event {
  start: any;
  end: any;
  startMs: number;
  endMs: number;
  top: any;
  height: any;
  data: any;
  overlaps: number = 0;
  allHashes: string[];
  indexOfValue: string;

  constructor(data, { accessors, slotMetrics }) {
    const { start, startDate, end, endDate, top, height } =
      slotMetrics.getRange(accessors.start(data), accessors.end(data));
    this.start = start;
    this.end = end;
    this.startMs = +startDate;
    this.endMs = +endDate;
    this.top = top;
    this.height = height;
    this.data = data;
    this.allHashes = data?.isClub ? data.allTeacherHashes : data.allUserHashes;
    this.indexOfValue = data?.isClub ? data.teacherHash : data.userHash;
  }

  get _width() {
    const totalNames = this.allHashes.length;

    return 100 / totalNames;
  }

  get width() {
    const adjustedWidth = this._width;
    return adjustedWidth;
  }

  get xOffset() {
    const nameIndex = this.allHashes.indexOf(this.indexOfValue);
    if (nameIndex !== -1) {
      const totalNames = this.allHashes.length;
      return (nameIndex / totalNames) * 100;
    }

    return 0;
  }
}

function sortByRender(events) {
  const sortedByTime = sortBy(events, ['startMs', (e) => -e.endMs]);

  for (let i = 0; i < sortedByTime.length; i++) {
    const event = sortedByTime[i];
    event.overlaps = 0;
    for (let j = 0; j < i; j++) {
      const prevEvent = sortedByTime[j];
      if (
        prevEvent.endMs > event.startMs && prevEvent.isClub
          ? prevEvent?.data?.teacherHash === event?.data?.teacherHash
          : prevEvent?.data?.userHash === event?.data?.userHash
      ) {
        event.overlaps++;
      }
    }
  }

  return sortedByTime;
}

export default function getStyledEvents({
  events,
  minimumStartDifference,
  slotMetrics,
  accessors
}) {
  const proxies = events.map(
    (event) => new Event(event, { slotMetrics, accessors })
  );
  const eventsInRenderOrder = sortByRender(proxies);

  return eventsInRenderOrder.map((event) => ({
    event: event.data,
    style: {
      top: event.top,
      height: event.height,
      width: event.width,
      xOffset: Math.max(0, event.xOffset)
    }
  }));
}
