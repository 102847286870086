import { Box } from '@mui/material';
import moment from 'moment';
import React, { useState } from 'react';
import { useLocaleState, useTranslate } from 'react-admin';
import { Views, momentLocalizer } from 'react-big-calendar';
import { Calendar } from 'react-big-calendar/dist/react-big-calendar.esm';
import {
  CALENDAR_END_HOUR,
  CALENDAR_END_MINUTES,
  CALENDAR_SCROLL_HOUR,
  CALENDAR_SCROLL_MINUTES,
  CALENDAR_START_HOUR,
  CALENDAR_START_MINUTES,
  translateMessages
} from '../../../../core/constants/calendar.constants';
import { EventSlot } from '../../../../core/models/event/eventSlot';
import { getCurrentDate } from '../../../../core/utils/date.utils';
import { CalendarEvent } from '../equipmentRentsCalendar/equipment-rents-calendar.utils';
import { EquipmentRentSchedulerEquipmentRentDialog } from './EquipmentRentSchedulerEquipmentRentDialog';
import EquipmentRentSchedulerEquipmentRentFilters from './EquipmentRentSchedulerEquipmentRentFilters';
import { generateColor } from '../../../../core/utils/color.utils';

const localizer = momentLocalizer(moment); // or globalizeLocalizer

export const EquipmentRentsSchedulerView = (props: {
  equipmentRent: string;
  date: any;
  setDate: any;
  view: any;
  setView: any;
  bookings: any[];
  setRefresh: any;
  setEquipmentRent: any;
  setOpenEquipmentRentEventDialog: any;
  openEquipmentRentEventDialog: boolean;
  userHash: string;
}) => {
  const CustomCalendar = Calendar as Calendar;
  const [locale] = useLocaleState();
  const [slotInfo, setSlotInfo] = useState(null);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedBooking, setSelectedBooking] = useState(null);
  const translate = useTranslate();
  const calendarMinDate = getCurrentDate();
  const calendarMaxDate = getCurrentDate();
  const calendarScrollDate = getCurrentDate();

  calendarMinDate.setHours(CALENDAR_START_HOUR, CALENDAR_START_MINUTES);
  calendarMaxDate.setHours(CALENDAR_END_HOUR, CALENDAR_END_MINUTES);
  calendarScrollDate.setHours(CALENDAR_SCROLL_HOUR, CALENDAR_SCROLL_MINUTES);

  const views = [Views.DAY, Views.WEEK, Views.MONTH];
  const translatedMessages = translateMessages(translate);

  const handleNavigate = (newDate) => {
    props.setDate(newDate);
  };

  const handleView = (newView) => {
    props.setView(newView);
  };

  const eventStyleGetter = (event: CalendarEvent) => {
    const style = {
      backgroundColor: generateColor(event.equipmentRentHash),
      color: 'white'
    };

    return { style };
  };

  const updateSlotInfo = (event: any, setSlotInfo: React.Dispatch<any>) => {
    const slot: EventSlot = {
      start: event.start,
      end: event.end,
      action: '',
      box: {
        clientX: 0,
        clientY: 0,
        x: 0,
        y: 0
      },
      slots: []
    };
    setSlotInfo(slot);
  };

  const onSelectBooking = (booking) => {
    setSelectedBooking(booking);
    if (booking.userHash) {
      setSelectedUsers([booking.userHash]);
    }

    if (booking.equipmentRentHash) {
      updateSlotInfo(booking, setSlotInfo);
      props.setOpenEquipmentRentEventDialog(true);
    }
  };

  const bookingsGroupedByUser = props.bookings.reduce((grouped, event) => {
    const userHash = event.userHash || '';
    grouped[userHash] = [...(grouped[userHash] || []), event];
    return grouped;
  }, {});

  const sortedBookings = Object.values(bookingsGroupedByUser).flatMap(
    (group: []) =>
      group.sort((a: CalendarEvent, b: CalendarEvent) => a.start - b.start)
  );

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          paddingBottom: '25px',
          height: '100%'
        }}
      >
        <EquipmentRentSchedulerEquipmentRentFilters
          setEquipmentRent={props.setEquipmentRent}
          equipmentRent={props.equipmentRent}
          userHash={props.userHash}
        />
        <CustomCalendar
          className="custom-calendar"
          culture={locale}
          onNavigate={handleNavigate}
          onView={handleView}
          defaultDate={props.date}
          localizer={localizer}
          events={sortedBookings}
          style={{
            height: 500,
            flex: '1 auto'
          }}
          startAccessor="start"
          view={props.view}
          views={views}
          messages={translatedMessages}
          selectable
          eventPropGetter={eventStyleGetter}
          onSelectEvent={onSelectBooking}
          scrollToTime={calendarScrollDate}
          max={calendarMaxDate}
          min={calendarMinDate}
        />
      </Box>
      {selectedBooking && (
        <EquipmentRentSchedulerEquipmentRentDialog
          slotInfo={slotInfo}
          openDialog={props.openEquipmentRentEventDialog}
          setOpenDialog={props.setOpenEquipmentRentEventDialog}
          equipmentRent={props.equipmentRent}
          booking={selectedBooking}
          view={props.view}
          setRefresh={props.setRefresh}
          selectedUsers={selectedUsers}
        ></EquipmentRentSchedulerEquipmentRentDialog>
      )}
    </>
  );
};
