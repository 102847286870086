import React from 'react';
import { RoomCreateShared } from '../../../../../../components/shared/rooms/roomsCreate/RoomCreateShared';

export const RoomCreate = ({ setSection, sectionList, userHash }) => {
  return (
    <RoomCreateShared
      setSection={setSection}
      sectionList={sectionList}
      userHash={userHash}
    />
  );
};
