import React from 'react';
import { useRecordContext } from 'react-admin';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton } from '@mui/material';
import { DialogDeletePrivacyPolicy } from './DialogDeletePrivacyPolicy';
import { TermAndCondition } from '../../../../core/models/termAndCondition/termAndCondition';

export const PrivacyPolicyDeleteButton = (props) => {
  const record: TermAndCondition = useRecordContext(props);
  const [showDeleteDialog, setShowDeleteDialog] = React.useState(false);

  const handleDeleteLesson = () => setShowDeleteDialog(true);

  return (
    <>
      <IconButton onClick={() => handleDeleteLesson()}>
        <DeleteIcon />
      </IconButton>
      <DialogDeletePrivacyPolicy
        showDeleteDialog={showDeleteDialog}
        setShowDeleteDialog={setShowDeleteDialog}
        id={record.id}
      />
    </>
  );
};
