import React from 'react';
import { useSidebarState } from 'react-admin';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';

export const CustomAppBar = () => {
  const [open, setOpen] = useSidebarState();

  return (
    <>
      <div className="app-bar" onClick={() => setOpen(!open)}>
        <div className="container">
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2, display: { xs: 'block', sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <img src="/src/images/logo-nak.png" alt="nak sports" />
        </div>
      </div>
    </>
  );
};
