import React from 'react';
import { EquipmentRentSchedulerEquipmentRentDialogGrouped } from './EquipmentRentSchedulerEquipmentRentDialogGrouped';
import { EquipmentRentSchedulerEquipmentRentDialogSimple } from './EquipmentRentSchedulerEquipmentRentDialogSimple';

export const EquipmentRentSchedulerEquipmentRentDialog = (props: {
  slotInfo;
  openDialog;
  setOpenDialog;
  equipmentRent;
  booking;
  view;
  setRefresh;
  selectedUsers;
}) => {
  return (
    <>
      {props.booking.group.length > 1 ? (
        <EquipmentRentSchedulerEquipmentRentDialogGrouped
          booking={props.booking}
          setOpenDialog={props.setOpenDialog}
          openDialog={props.openDialog}
          setRefresh={props.setRefresh}
        ></EquipmentRentSchedulerEquipmentRentDialogGrouped>
      ) : (
        <EquipmentRentSchedulerEquipmentRentDialogSimple
          setOpenDialog={props.setOpenDialog}
          openDialog={props.openDialog}
          booking={props.booking}
          setRefresh={props.setRefresh}
        ></EquipmentRentSchedulerEquipmentRentDialogSimple>
      )}
    </>
  );
};
