import { Create, SimpleForm, useCreate, useNotify } from 'react-admin';
import React from 'react';
import { VoucherFormToolbar } from '../vouchersForm/VoucherFormToolbar';
import { voucherFormValidator } from '../vouchersForm/voucherFormValidator';
import { VoucherForm } from '../vouchersForm/VoucherForm';
import { useNavigate } from 'react-router-dom';

export const VouchersCreateShared = (props) => {
  const [create, { isLoading, isError }] = useCreate('vouchers');
  const notify = useNotify();
  const navigate = useNavigate();

  const voucherDefaultValue = () => ({
    category: 1
  });
  const voucherSave = (data) => {
    data = {
      ...data,
      userHash: props.userHash,
      disabled: !data.isEnabled
    };
    create(
      'vouchers',
      { data },
      {
        onSuccess: () => {
          notify('resources.users.vouchers.notifications.create.success', {
            type: 'success'
          });
          if (props.setSection) {
            props.setSection(props.sectionList);
          }

          if (props.route) {
            navigate(-1);
          }
        },
        onError(error: any) {
          notify(error.message, { type: 'warning' });
        }
      }
    );
  };

  return (
    <Create resource={'vouchers'}>
      <SimpleForm
        onSubmit={voucherSave}
        validate={(e) => voucherFormValidator(e)}
        defaultValues={voucherDefaultValue}
        className={'form'}
        toolbar={
          <VoucherFormToolbar
            setSection={props.setSection}
            sectionList={props.sectionList}
            route={props.route}
            isLoading={isLoading}
            isError={isError}
          />
        }
      >
        <VoucherForm userHash={props.userHash} />
      </SimpleForm>
    </Create>
  );
};
