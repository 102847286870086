import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import {
  Create,
  Loading,
  SaveButton,
  SimpleForm,
  Toolbar,
  useCreate,
  useGetList,
  useNotify,
  usePermissions,
  useRecordContext
} from 'react-admin';
import { PRIVILEGE_CLUB } from '../../../../../core/constants/privilege.constants';
import { UserHashContext } from '../../../../../core/context/userHashContext';
import { EventSlot } from '../../../../../core/models/event/eventSlot';
import { getLinkedTeachers } from '../../../../../core/services/userParent-api.service';
import {
  formatMillis,
  getMillis,
  getTimeMillisOfDate,
  getUTCMillis,
  toDate
} from '../../../../../core/utils/date.utils';
import { CalendarAddEventDialogForm } from './CalendarAddEventDialogForm';
import { EventFormValidator } from './EventFormValidator';
import { NOT_AVAILABILITY_ERROR_MESSAGE } from '../../../../../core/constants/error.constants';

type CalendarAddEventType = {
  slotInfo: EventSlot;
  openDialog: boolean;
  setOpenDialog: (openDialog: boolean) => void;
  setRefresh: (refresh: boolean) => void;
  id?: string;
  view?: string;
  fromTabs?: boolean;
  selectedUsers?: string[];
};

export const CalendarAddEventDialog = (props: CalendarAddEventType) => {
  const slotInfo: EventSlot = props.slotInfo;
  const isAvailability = 'isAvailability';
  const isLesson = 'isLesson';
  const isFullLesson = 'isFullLesson';
  const isCourse = 'isCourse';
  const isSmallCourses = 'isSmallCourses';
  const [create] = useCreate();
  const notify = useNotify();
  const record = useRecordContext();
  const { userHash } = useContext(UserHashContext);
  const permissions = usePermissions();
  const isClubPermission = permissions.permissions === PRIVILEGE_CLUB;
  const isClub = isClubPermission || record?.isClub;
  const isTeacher = record?.isTeacher;

  const clubHash =
    permissions.permissions === PRIVILEGE_CLUB ? userHash : record?.id;
  const { data, isLoading } = useGetList('lessons/lesson-time-types', {
    pagination: { page: 1, perPage: 10 },
    sort: { field: 'time', order: 'DESC' }
  });
  const [linkedTeachers, setLinkedTeachers] = useState<any>([]);

  useEffect(() => {
    if (isClub) {
      getLinkedTeachers(0, 0, 'id', clubHash).then((res) => {
        setLinkedTeachers(res.data);
      });
    }
  }, [clubHash, isClub]);

  const hours = isLoading
    ? []
    : data.map((item) => ({ time: item.name, value: item.time }));

  const lessonDefaultValue = () => ({
    level: [{}],
    price: hours,
    startDate: slotInfo.start ?? null,
    endDate: slotInfo.end ?? null
  });

  const getTitle = () => {
    return formatMillis('dddd, D MMMM', getMillis(slotInfo.start));
  };
  const onCloseDialog = () => {
    props.setOpenDialog(false);
  };

  const eventSave = (data) => {
    const date = new Date();

    if (typeof data.startDate === 'number') {
      data.startDate = new Date(data.startDate);
    }
    data.startDate.setFullYear(slotInfo.start.getFullYear());
    data.startDate.setMonth(slotInfo.start.getMonth());
    data.startDate.setDate(
      slotInfo.start.getDate() ? slotInfo.start.getDate() : date.getDate()
    );

    if (typeof data.endDate === 'number') {
      data.endDate = new Date(data.endDate);
    }
    data.endDate.setFullYear(slotInfo.start.getFullYear());
    data.endDate.setMonth(slotInfo.start.getMonth());
    data.endDate.setDate(slotInfo.start.getDate());

    data.startDate = getMillis(data.startDate);
    data.endDate = getMillis(data.endDate);
    data.iterationDateEnd = getUTCMillis(data.iterationDateEnd);
    data.dayTypes =
      (data.category === isLesson || data.category === isFullLesson) &&
      data.timeType === 1
        ? null
        : data.dayTypes;

    data.timeIteration = data.timeIteration != null ? data.timeIteration : 1;

    if (isNaN(data.iterationDateEnd) && data.iterationEnd == null) {
      data.iterationEnd = 1;
    }
    if (isClub) {
      data.parentHash = clubHash;
    }
    const dataToSend = { ...data };

    if (data.category === isAvailability) {
      dataToSend.userHash = dataToSend.userHash ? data.userHash : props.id;
      createAvailability(dataToSend);
    }
    if (data.category === isLesson) {
      dataToSend.userHash = dataToSend.userHash ? data.userHash : props.id;
      createLessonEvents(dataToSend);
    }

    if (data.category === isFullLesson) {
      createLesson(dataToSend);
    }

    if (data.category === isSmallCourses || data.category === isCourse) {
      createCourse(dataToSend);
    }
  };

  const getDates = (data) => {
    const dateStart = toDate(data.dateStart);
    const dateEnd = toDate(data.dateEnd);
    const timeStart = toDate(data.timeStart);
    const timeEnd = toDate(data.timeEnd);

    data['dateStart'] = getMillis(dateStart);
    data['dateEnd'] = getMillis(dateEnd);
    data['timeStart'] = getTimeMillisOfDate(timeStart);
    data['timeEnd'] = getTimeMillisOfDate(timeEnd);
    data['registrationDeadline'] = getMillis(
      new Date(data.registrationDeadline)
    );

    return data;
  };

  const createCourse = (data) => {
    data = getDates(data);
    data.smallCourse = data.category === isSmallCourses;

    data = {
      ...data,
      userHash: data.userHash ? data.userHash : props.id,
      disabled: !data.isEnabled
    };

    create(
      'courses',
      { data },
      {
        onSuccess: () => {
          notify('resources.users.course.notifications.courseCreate.success', {
            type: 'success'
          });
          props.setRefresh(true);
          onCloseDialog();
        },
        onError(error: any) {
          if (error.message === NOT_AVAILABILITY_ERROR_MESSAGE) {
            onCloseDialog();
          }
          notify(error.message, { type: 'warning' });
        }
      }
    );
  };

  const createLessonEvents = (data) => {
    create(
      'lesson-events',
      { data },
      {
        onSuccess: () => {
          notify('resources.users.notifications.lessonCreate.success', {
            type: 'success'
          });
          props.setRefresh(true);
          onCloseDialog();
        },
        onError(error: any) {
          if (error.message === NOT_AVAILABILITY_ERROR_MESSAGE) {
            onCloseDialog();
          }
          notify(error.message, { type: 'warning' });
        }
      }
    );
  };

  const createAvailability = (data) => {
    create(
      'availabilities',
      { data },
      {
        onSuccess: () => {
          notify('resources.users.notifications.availabilityCreate.success', {
            type: 'success'
          });
          props.setRefresh(true);
          onCloseDialog();
        },
        onError(error: any) {
          notify(error.message, { type: 'warning' });
        }
      }
    );
  };

  const createLesson = (data) => {
    data = {
      ...data,
      userHash: data.userHash ? data.userHash : props.id,
      prices: [data.price],
      disabled: !data.isEnabled
    };
    create(
      'lessons',
      { data },
      {
        onSuccess: (lesson) => {
          data.lesson = lesson.id;
          createLessonEvents(data);
          props.setRefresh(true);
          onCloseDialog();
        },
        onError(error: any) {
          if (error.message === NOT_AVAILABILITY_ERROR_MESSAGE) {
            onCloseDialog();
          }
          notify(error.message, { type: 'warning' });
        }
      }
    );
  };

  if (isLoading) return <Loading />;

  return (
    <Dialog
      fullWidth
      open={props.openDialog}
      onClose={onCloseDialog}
      className="calendar-add-event-dialog"
    >
      <DialogTitle>{getTitle()}</DialogTitle>
      <DialogContent>
        <Create resource="availabilities">
          <SimpleForm
            toolbar={<Toolbar> </Toolbar>}
            onSubmit={eventSave}
            className={'calendar-add-event-dialog-form'}
            validate={(e) =>
              EventFormValidator(
                e,
                isLesson,
                isAvailability,
                isCourse,
                isSmallCourses,
                isFullLesson,
                slotInfo,
                isClubPermission,
                isTeacher
              )
            }
            defaultValues={lessonDefaultValue}
          >
            <CalendarAddEventDialogForm
              slotInfo={slotInfo}
              isAvailability={isAvailability}
              isLesson={isLesson}
              isFullLesson={isFullLesson}
              isCourse={isCourse}
              isSmallCourses={isSmallCourses}
              id={props.id}
              record={record}
              linkedTeachers={linkedTeachers}
              view={props.view}
              isClub={isClub}
              fromTabs={props.fromTabs}
              selectedUsers={props.selectedUsers}
            />
            <SaveButton alwaysEnable label="ra.action.save" disabled={false} />
          </SimpleForm>
        </Create>
      </DialogContent>
    </Dialog>
  );
};
