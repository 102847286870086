import React from 'react';
import { EquipmentRentsListShared } from '../../../../../../components/shared/equipmentRents/equipmentRentsList/EquipmentRentsListShared';

export const EquipmentRentsList = ({
  setSection,
  sectionCreate,
  sectionEdit,
  setEquipmentRentToEditId,
  userHash
}) => {
  return (
    <EquipmentRentsListShared
      setSection={setSection}
      sectionCreate={sectionCreate}
      sectionEdit={sectionEdit}
      setEquipmentRentToEditId={setEquipmentRentToEditId}
      userHash={userHash}
    />
  );
};
