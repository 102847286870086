import React from 'react';
import {
  DateInput,
  minValue,
  required,
  SelectInput,
  TextInput,
  useRecordContext,
  useTranslate
} from 'react-admin';
import { InputRichTextField } from '../../../../components/shared/form/InputRichTextField/InputRichTextField';
import {
  formatMillis,
  getCurrentDate,
  getMillis,
  toDate
} from '../../../../core/utils/date.utils';
import {
  PRIVILEGE_CLUB,
  PRIVILEGE_STUDENT,
  PRIVILEGE_TEACHER
} from '../../../../core/constants/privilege.constants';

export const TermsAndConditionsForm = () => {
  const date = formatMillis('yyyy-MM-DD', getMillis(getCurrentDate()));
  const record = useRecordContext();
  const translate = useTranslate();

  if (record) {
    record.date = toDate(record.date);
  }

  const getPrivilegeChoices = [
    {
      id: PRIVILEGE_STUDENT,
      name: translate(
        'resources.configuration.termsAndConditions.fields.student'
      )
    },
    {
      id: PRIVILEGE_CLUB,
      name: translate('resources.configuration.termsAndConditions.fields.club')
    },
    {
      id: PRIVILEGE_TEACHER,
      name: translate(
        'resources.configuration.termsAndConditions.fields.teacher'
      )
    }
  ];

  return (
    <div>
      <TextInput
        source={'title'}
        label={'resources.configuration.termsAndConditions.fields.title'}
        validate={[required()]}
      />
      <DateInput
        label="resources.configuration.termsAndConditions.fields.date"
        source="date"
        validate={[required(), minValue(date, 'validation.dateTodayOrGreater')]}
      />

      <SelectInput
        source="privilegeType"
        label="resources.configuration.termsAndConditions.fields.privilege"
        choices={getPrivilegeChoices}
        validate={[required()]}
      />
      <InputRichTextField
        size={['20']}
        validate={[required()]}
        source={'text'}
      />
    </div>
  );
};
