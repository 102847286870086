import React from 'react';
import { VoucherEditShared } from '../../../../../../components/shared/vouchers/vouchersEdit/VoucherEditShared';

export const VoucherEdit = ({
  setSection,
  sectionList,
  voucherToEditId,
  userHash
}) => {
  return (
    <VoucherEditShared
      setSection={setSection}
      sectionList={sectionList}
      voucherToEditId={voucherToEditId}
      userHash={userHash}
    />
  );
};
