import React, { useContext, useEffect } from 'react';
import { EquipmentRentsSchedulerShared } from '../../components/shared/equipmentRents/equipmentRentsScheduler/EquipmentRentsSchedulerShared';
import { UserHashContext } from '../../core/context/userHashContext';
import { HeaderTitleContext } from '../../core/context/headerTitleContext';

export const EquipmentRentsScheduler = () => {
  const { userHash } = useContext(UserHashContext);
  const { setHeaderTitle } = useContext(HeaderTitleContext);

  useEffect(() => {
    setHeaderTitle('resources.equipmentRentsScheduler.header.title');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return userHash ? (
    <EquipmentRentsSchedulerShared
      route={'/equipment-rents-scheduler'}
      userHash={userHash}
    />
  ) : null;
};
