import React, { useContext, useEffect, useState } from 'react';
import {
  EmailIcon,
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton
} from 'react-share';
import {
  APP_URL_ANDROID,
  APP_URL_IOS
} from '../../../core/constants/app.constant';
import { useTranslate } from 'react-admin';
import { ShareEmailDialog } from './ShareEmailDialog';
import { HeaderTitleContext } from '../../../core/context/headerTitleContext';

export const Share = () => {
  const buttonSize = 50;
  const translate = useTranslate();
  const [showEmailDialog, setShowEmailDialog] = useState(false);
  const { setHeaderTitle } = useContext(HeaderTitleContext);

  useEffect(() => {
    setHeaderTitle('resources.configuration.share.title');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="share-page">
      <h2>{translate('resources.configuration.share.title')}</h2>
      <div>
        <TwitterShareButton
          url={translate('resources.configuration.share.message', {
            iosUrl: APP_URL_IOS,
            androidUrl: APP_URL_ANDROID
          })}
        >
          <TwitterIcon size={buttonSize} />
        </TwitterShareButton>
        <span>{translate('resources.configuration.share.twitter')}</span>
      </div>
      <div>
        <FacebookShareButton url={APP_URL_IOS}>
          <FacebookIcon size={buttonSize} />
        </FacebookShareButton>
        <span>{translate('resources.configuration.share.facebookIos')}</span>
      </div>
      <div>
        <FacebookShareButton url={APP_URL_ANDROID}>
          <FacebookIcon size={buttonSize} />
        </FacebookShareButton>
        <span>
          {translate('resources.configuration.share.facebookAndroid')}
        </span>
      </div>
      <div>
        <WhatsappShareButton
          url={translate('resources.configuration.share.message', {
            iosUrl: APP_URL_IOS,
            androidUrl: APP_URL_ANDROID
          })}
        >
          <WhatsappIcon size={buttonSize} />
        </WhatsappShareButton>
        <span>{translate('resources.configuration.share.whatsapp')}</span>
      </div>
      <div>
        <EmailIcon size={buttonSize} onClick={() => setShowEmailDialog(true)} />
        <span>{translate('resources.configuration.share.email')}</span>
      </div>

      <ShareEmailDialog
        showEmailDialog={showEmailDialog}
        setShowEmailDialog={setShowEmailDialog}
      />
    </div>
  );
};
