import React from 'react';
import { TopToolbar } from 'react-admin';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../buttons/Button';

export const CourseListActions = (props) => {
  const navigate = useNavigate();
  const goToCreate = () => {
    if (props.route) {
      navigate(props.route);
    }

    if (props.setSection) {
      props.setSection(props.sectionCreate);
    }
  };

  return (
    <TopToolbar>
      <Button
        onClick={goToCreate}
        label={
          props.smallCourses
            ? 'resources.users.course.actions.createSmallCourse'
            : 'resources.users.course.actions.create'
        }
      ></Button>
    </TopToolbar>
  );
};
