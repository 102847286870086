import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import React, { useContext, useState } from 'react';
import { usePermissions, useRecordContext, useTranslate } from 'react-admin';
import { useParams } from 'react-router-dom';
import {
  PRIVILEGE_ADMIN,
  PRIVILEGE_TEACHER
} from '../../../../../../core/constants/privilege.constants';
import { UserHashContext } from '../../../../../../core/context/userHashContext';
import { Button } from '../../../../buttons/Button';
import { DialogDeleteCourseEvent } from '../DialogDeleteCourseEvent';
import DialogEditRoomCourseEvent from '../DialogEditRoomCourseEvent';

export const CalendarCourseEventDialogSimple = (props) => {
  const translate = useTranslate();
  const record = useRecordContext();
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [deleteSeries, setDeleteSeries] = useState(false);
  const permissions = usePermissions();

  const isTeacher = permissions.permissions === PRIVILEGE_TEACHER;
  const isAdmin = permissions.permissions === PRIVILEGE_ADMIN;
  const { id } = useParams();
  const { userHash: hash } = useContext(UserHashContext);
  const userHash = isAdmin ? id : hash;

  const formattedStart = props.event?.start.toLocaleString();
  const formattedEnd = props.event?.end.toLocaleString();
  const teacher = `${props.event?.userName} ${props.event?.userLastName}`;

  const onCloseDialog = () => {
    props.setOpenDialog(false);
  };

  const onDeleteEvent = () => {
    setDeleteSeries(false);
    setShowDeleteDialog(true);
  };

  const onDeleteSeries = () => {
    setDeleteSeries(true);
    setShowDeleteDialog(true);
  };

  return (
    <>
      <Dialog fullWidth open={props.openDialog} onClose={onCloseDialog}>
        <DialogTitle>{props.event?.modalTitle}</DialogTitle>
        <DialogContent>
          {isAdmin && (
            <p hidden={!record.isClub}>
              {translate('resources.users.calendar.courseEvent.club')}
              {record.firstName}
            </p>
          )}
          <p hidden={isTeacher}>
            {translate('resources.users.calendar.courseEvent.teacher')}
            {teacher}
          </p>
          <p>
            {translate('resources.users.calendar.courseEvent.start')}
            {formattedStart.slice(0, -3)}
          </p>
          <p>
            {translate('resources.users.calendar.courseEvent.end')}
            {formattedEnd.slice(0, -3)}
          </p>
          <DialogEditRoomCourseEvent
            event={props.event}
            id={userHash}
            onCloseDialog={onCloseDialog}
          />
          <div className="dialog-actions">
            <Button
              onClick={onDeleteEvent}
              label={'resources.users.calendar.courseEvent.actions.delete'}
            />
            <Button
              onClick={onDeleteSeries}
              label={
                'resources.users.calendar.courseEvent.actions.deleteSeries'
              }
            />
          </div>
        </DialogContent>
      </Dialog>
      <DialogDeleteCourseEvent
        event={props.event}
        showDeleteDialog={showDeleteDialog}
        setShowDeleteDialog={setShowDeleteDialog}
        setOpenCourseEventDialog={props.setOpenDialog}
        deleteSeries={deleteSeries}
        setRefresh={props.setRefresh}
      />
    </>
  );
};
