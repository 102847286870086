import React, { useContext } from 'react';
import { UserHashContext } from '../../../core/context/userHashContext';
import { CourseEditShared } from '../../../components/shared/courses/courseEdit/CourseEditShared';

export const CourseEdit = (props) => {
  const { userHash } = useContext(UserHashContext);
  const smallCourses = props.smallCourses ? props.smallCourses : false;
  return (
    <CourseEditShared
      route={smallCourses ? '/small-course' : '/yearly-course'}
      userHash={userHash}
      smallCourses={smallCourses}
    />
  );
};
