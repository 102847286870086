import {
  Edit,
  SimpleForm,
  useGetOne,
  useNotify,
  useRefresh,
  useUpdate
} from 'react-admin';
import { FormToolbar } from '../../../../components/shared/form/formToolbar/FormToolbar';
import { SportsForm } from '../sportsForm/SportsForm';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { convertFileToBase64 } from '../../../../core/utils/file-utils';
import { Sport } from '../../../../core/models/sport/sport';

export const SportsEdit = () => {
  const [update, { isLoading, isError }] = useUpdate('sports');
  const notify = useNotify();
  const refresh = useRefresh();
  const { id } = useParams();
  const navigate = useNavigate();
  const { data: record } = useGetOne<Sport>('sports', { id });

  const getImage = async (data) => {
    if (data.icon?.rawFile instanceof File) {
      data.icon = await convertFileToBase64(data.icon);
    } else {
      if (record.icon) {
        data.icon = record.icon.title;
      }
    }

    return data.icon;
  };

  const sportsSave = async (data) => {
    data.icon = await getImage(data);
    const id = data.id;

    update(
      'sports',
      { id, data },
      {
        onSuccess: () => {
          notify('resources.configuration.sports.notifications.edit.success', {
            type: 'success'
          });
          refresh();
          navigate(-1);
        },
        onError(error: any) {
          notify(error.message, { type: 'warning' });
        }
      }
    );
  };

  return (
    <Edit resource="sports" id={id}>
      <SimpleForm
        onSubmit={sportsSave}
        toolbar={<FormToolbar isLoading={isLoading} isError={isError} />}
        className={'form'}
      >
        <SportsForm />
      </SimpleForm>
    </Edit>
  );
};
