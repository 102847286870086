import React, { useEffect, useState } from 'react';
import {
  Edit,
  Loading,
  NumberInput,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
  required,
  useNotify,
  useUpdate
} from 'react-admin';
import { useParams } from 'react-router-dom';
import { getUserInvoiceData } from '../../../../../core/services/user-api.service';
import { removeWhiteSpaces } from '../../../../../core/utils/string-utils';
import { validateNoSpaces } from '../../../../../core/utils/validation-utils';
import { UserInvoiceDataFormValidator } from './UserInvoiceDataFormValidator';

export const UserInvoiceData = () => {
  const params = useParams();
  const [invoiceData, setInvoiceData] = useState(null);
  const [update] = useUpdate();
  const notify = useNotify();

  useEffect(() => {
    getUserInvoiceData(params.id).then((data) => {
      setInvoiceData(data.data);
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const invoiceDataSave = (data) => {
    const updatedData = {
      ...data,
      accountNumber: removeWhiteSpaces(data['accountNumber'])
    };

    update(
      'user-invoices-data',
      { id: invoiceData.id, data: updatedData },
      {
        onSuccess: () => {
          notify('resources.users.invoiceData.notifications.update.success', {
            type: 'success'
          });
        },
        onError(error: any) {
          notify(error.message, { type: 'warning' });
        }
      }
    );
  };

  if (!invoiceData) return <Loading />;

  return (
    <>
      <Edit resource="user-invoices-data" id={invoiceData?.id}>
        <SimpleForm
          onSubmit={invoiceDataSave}
          record={invoiceData}
          toolbar={
            <div>
              <Toolbar>
                <SaveButton label="resources.users.actions.saveChanges" />
              </Toolbar>
            </div>
          }
          validate={UserInvoiceDataFormValidator}
          className={'form'}
        >
          <TextInput
            source="accountNumber"
            validate={[required(), validateNoSpaces]}
            label="resources.users.invoiceData.fields.accountNumber"
          />
          <NumberInput
            source="fixedMonthFee"
            label="resources.users.invoiceData.fields.fixedMonthFee"
          />
          <NumberInput
            source="bookingFee"
            label="resources.users.invoiceData.fields.bookingFee"
            parse={(value) => (value ? value / 100 : null)}
            format={(value) => (value ? value * 100 : null)}
          />
          <NumberInput
            source="bookingFixFee"
            label="resources.users.invoiceData.fields.bookingFixFee"
          />
        </SimpleForm>
      </Edit>
    </>
  );
};
