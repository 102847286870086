import React from 'react';
import { Button } from '../../../../components/shared/buttons/Button';
import { useNavigate } from 'react-router-dom';
import { useNotify, useRecordContext } from 'react-admin';
import { GlobalOperation } from '../../../../core/models/globalOperation/globalOperation';
import { cancelBooking } from '../../../../core/services/booking-api.service';
import { BOOKING_STATUS_CANCEL } from '../../../../core/constants/booking.constants';

export const GlobalOperationActions = () => {
  const navigate = useNavigate();
  const record = useRecordContext<GlobalOperation>();
  const notify = useNotify();

  const onCancelBooking = () => {
    cancelBooking(record.bookingHash).then(() => {
      notify('resources.invoicing.globalOperations.cancelBooking.success', {
        type: 'success'
      });
      navigate(-1);
    });
  };

  return (
    <>
      <Button
        size="small"
        sx={{ marginRight: '8px' }}
        label="resources.invoicing.globalOperations.list.actions.back"
        onClick={() => navigate(-1)}
      />
      {record.bookingHash && (
        <Button
          size="large"
          label="resources.invoicing.globalOperations.list.actions.cancelBooking"
          onClick={() => onCancelBooking()}
          disabled={record.bookingStatus?.id === BOOKING_STATUS_CANCEL}
        />
      )}
    </>
  );
};
