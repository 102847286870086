import { useLocation } from 'react-router-dom';
import React from 'react';
import { EquipmentRentCreate } from './equipmentRentCreate/EquipmentRentCreate';
import { EquipmentRentEdit } from './equipmentRentEdit/EquipmentRentEdit';
import { EquipmentRentsList } from './equipmentRentsList/EquipmentRentsList';

export const EquipmentRentOverView = () => {
  const sectionList = 'list';
  const sectionCreate = 'create';
  const sectionEdit = 'edit';
  const location = useLocation();
  const parts = location.pathname.split('/');
  const userHash = parts.pop();

  const [section, setSection] = React.useState(sectionList);
  const [equipmentRentToEditId, setEquipmentRentToEditId] =
    React.useState(null);

  if (section === sectionCreate) {
    return (
      <EquipmentRentCreate
        sectionList={sectionList}
        setSection={setSection}
        userHash={userHash}
      />
    );
  }

  if (section === sectionEdit) {
    return (
      <EquipmentRentEdit
        setSection={setSection}
        sectionList={sectionList}
        equipmentRentToEditId={equipmentRentToEditId}
        userHash={userHash}
      />
    );
  }

  if (section === sectionList) {
    return (
      <EquipmentRentsList
        setSection={setSection}
        sectionCreate={sectionCreate}
        sectionEdit={sectionEdit}
        setEquipmentRentToEditId={setEquipmentRentToEditId}
        userHash={userHash}
      />
    );
  }
};
