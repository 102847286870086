import moment from 'moment/moment';
import React, { useState } from 'react';
import { useLocaleState, useTranslate } from 'react-admin';
import {
  Calendar,
  Views,
  momentLocalizer
} from 'react-big-calendar/dist/react-big-calendar.esm';
import {
  CALENDAR_BOOKING_BG_COLOR,
  CALENDAR_BOOKING_COLOR,
  CALENDAR_END_HOUR,
  CALENDAR_END_MINUTES,
  CALENDAR_SCROLL_HOUR,
  CALENDAR_SCROLL_MINUTES,
  CALENDAR_START_HOUR,
  CALENDAR_START_MINUTES,
  translateMessages
} from '../../../core/constants/calendar.constants';
import { getCurrentDate } from '../../../core/utils/date.utils';
import { ScheduleBookingDialog } from './dialog/ScheduleBookingDialog';
import overlap from '../overlap';
import { CalendarEvent } from '../calendar/calendar.utils';

const localizer = momentLocalizer(moment); // or globalizeLocalizer

export const ScheduleShared = (props) => {
  const [locale] = useLocaleState();
  const translate = useTranslate();
  const CustomCalendar = Calendar as Calendar;
  const [selectedBooking, setSelectedBooking] = useState(null);
  const views = [Views.DAY, Views.WEEK, Views.MONTH];
  const translatedMessages = translateMessages(translate);

  const calendarScrollDate = getCurrentDate();
  calendarScrollDate.setHours(CALENDAR_SCROLL_HOUR, CALENDAR_SCROLL_MINUTES);

  const calendarMinDate = getCurrentDate();
  calendarMinDate.setHours(CALENDAR_START_HOUR, CALENDAR_START_MINUTES);

  const calendarMaxDate = getCurrentDate();
  calendarMaxDate.setHours(CALENDAR_END_HOUR, CALENDAR_END_MINUTES);

  const handleNavigate = (newDate) => {
    props.setDate(newDate);
  };

  const handleView = (newView) => {
    props.setView(newView);
  };

  const eventStyleGetter = () => {
    const style = {
      backgroundColor: CALENDAR_BOOKING_BG_COLOR,
      color: CALENDAR_BOOKING_COLOR
    };
    return { style };
  };

  const onSelectEvent = (booking) => {
    setSelectedBooking(booking);
    props.setOpenBookingDialog(true);
  };

  const bookingsGroupedByUser = props.bookings.reduce((grouped, booking) => {
    const userHash = booking?.event?.userHash || '';
    grouped[userHash] = [...(grouped[userHash] || []), booking];
    return grouped;
  }, {});

  const sortedBookings = Object.values(bookingsGroupedByUser).flatMap(
    (group: []) =>
      group.sort((a: CalendarEvent, b: CalendarEvent) => a.start - b.start)
  );

  return (
    <div className="calendar-container-fullwidth">
      <CustomCalendar
        className="custom-calendar"
        culture={locale}
        onNavigate={handleNavigate}
        onView={handleView}
        defaultDate={props.date}
        localizer={localizer}
        events={sortedBookings}
        style={{ height: 500, flex: '1 auto' }}
        dayLayoutAlgorithm={overlap}
        startAccessor="start"
        view={props.view}
        views={views}
        messages={translatedMessages}
        selectable
        eventPropGetter={eventStyleGetter}
        onSelectEvent={onSelectEvent}
        scrollToTime={calendarScrollDate}
        max={calendarMaxDate}
        min={calendarMinDate}
      />

      {selectedBooking && (
        <ScheduleBookingDialog
          booking={selectedBooking}
          openDialog={props.openBookingDialog}
          setOpenDialog={props.setOpenBookingDialog}
          setRefresh={props.setRefresh}
        />
      )}
    </div>
  );
};

export default ScheduleShared;
