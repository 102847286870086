import React, { useState } from 'react';
import { useRecordContext } from 'react-admin';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton } from '@mui/material';
import { Notification } from '../../../core/models/notification/notification';
import { DialogDeleteNotification } from './DialogDeleteNotification';

export const NotificationDeleteButton = (props) => {
  const record: Notification = useRecordContext(props);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const handleDeleteLesson = () => setShowDeleteDialog(true);

  return (
    <>
      <IconButton onClick={() => handleDeleteLesson()}>
        <DeleteIcon />
      </IconButton>
      <DialogDeleteNotification
        showDeleteDialog={showDeleteDialog}
        setShowDeleteDialog={setShowDeleteDialog}
        id={record.id}
      />
    </>
  );
};
