import React, { useEffect, useState } from 'react';
import { Views } from 'react-big-calendar';
import { getEquipmentRentEvents } from '../../../../core/services/equipment-rent-api.service';
import { getCurrentDate } from '../../../../core/utils/date.utils';
import { EquipmentRentsCalendarView } from './EquipmentRentsCalendarView';
import {
  generateEventsFromEvents,
  getFirstMillisOfView,
  getLastMillisOfView
} from './equipment-rents-calendar.utils';

export const EquipmentRentsCalendarShared = (props: {
  route: string;
  userHash: string;
}) => {
  const [date, setDate] = useState(getCurrentDate());
  const [view, setView] = useState(Views.WEEK);
  const [events, setEvents] = React.useState([]);
  const [refresh, setRefresh] = useState(false);
  const [equipmentRent, setEquipmentRent] = useState(null);
  const [openEquipmentRentEventDialog, setOpenEquipmentRentEventDialog] =
    useState(false);
  const [openAddEquipmentEventDialog, setOpenAddEquipmentEventDialog] =
    useState(false);

  useEffect(() => {
    getEquipmentRentEvents(
      equipmentRent,
      getFirstMillisOfView(date, view),
      getLastMillisOfView(date, view),
      props.userHash
    ).then((data) => {
      const events = generateEventsFromEvents(data.data);
      setEvents(events);
      setRefresh(false);
    });
  }, [date, view, refresh, equipmentRent, props.userHash]);

  return (
    <EquipmentRentsCalendarView
      equipmentRent={equipmentRent}
      date={date}
      setDate={setDate}
      view={view}
      setView={setView}
      events={events}
      setRefresh={setRefresh}
      setOpenEquipmentRentEventDialog={setOpenEquipmentRentEventDialog}
      openEquipmentRentEventDialog={openEquipmentRentEventDialog}
      setOpenAddEventDialog={setOpenAddEquipmentEventDialog}
      openAddEventDialog={openAddEquipmentEventDialog}
      setEquipmentRent={setEquipmentRent}
      userHash={props.userHash}
    />
  );
};
