import React from 'react';
import { VouchersCreateShared } from '../../../../../../components/shared/vouchers/vouchersCreate/vouchersCreateShared';

export const VoucherCreate = ({ setSection, sectionList, userHash }) => {
  return (
    <VouchersCreateShared
      setSection={setSection}
      sectionList={sectionList}
      userHash={userHash}
    />
  );
};
