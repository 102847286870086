import moment from 'moment';
import { Views } from 'react-big-calendar/dist/react-big-calendar.esm';
import { Event } from '../../../../core/models/event/event';

export type CalendarEvent = {
  id;
  title;
  start;
  end;
  allDay;
  equipmentRent;
  equipmentRentHash;
  userName;
  userLastName;
  userHash;
  group;
  modalTitle;
  createdBySystem;
  allUserHashes;
};

export const getFirstMillisOfView = (date, view) => {
  if (view === Views.WEEK) {
    return moment(date).startOf('isoWeek').valueOf();
  }
  return moment(date).startOf(view).valueOf();
};

export const getLastMillisOfView = (date, view) => {
  if (view === Views.WEEK) {
    return moment(date).endOf('isoWeek').add(1, 'week').valueOf();
  }
  return moment(date).endOf(view).valueOf();
};

export const getEventTitle = (group) => {
  if (group.length > 1) {
    return `${'(' + group.length.toString() + ')' + getMultiEventTitle(group)}`;
  } else {
    return getSingleEventTitle(group[0]);
  }
};

export const getSingleEventTitle = (event) => {
  return event?.eventTitle;
};

export const getMultiEventTitle = (group) => {
  const uniqueTitles = {};
  const uniqueEvents = [];

  group.forEach((event) => {
    const title = event?.eventTitle;
    if (!uniqueTitles[title]) {
      uniqueTitles[title] = true;
      uniqueEvents.push(event);
    }
  });

  return uniqueEvents.map((event) => {
    return `${event?.eventTitle} (${event?.userName ?? ''} ${
      event?.userLastName ?? ''
    })`;
  });
};

export const groupEvents = (events: Event[]) => {
  const groupedEvents = {};

  events.forEach((event) => {
    const key =
      `${event?.room?.id ?? 'null'}` +
      `-${event.equipmentRentHash ?? 'null'}` +
      `-${event.userHash ?? 'null'}` +
      `-${event.startDate}-${event.endDate}`;

    if (!groupedEvents[key]) {
      groupedEvents[key] = [];
    }

    groupedEvents[key].push(event);
  });

  return groupedEvents;
};

export const generateEventsFromEvents = (data: Event[]): CalendarEvent[] => {
  const groupedEvents = groupEvents(data);

  return Object.values(groupedEvents).map((group: any[]) => {
    const sampleEvent = group[0];
    const filterUsersDay = data
      .filter(
        (event) =>
          moment(event.startDate) >
            moment(sampleEvent.startDate).startOf('day') &&
          moment(event.endDate) < moment(sampleEvent.startDate).endOf('day')
      )
      .sort((event1: Event, event2: Event) =>
        event1.userName.localeCompare(event2.userName)
      )
      .map((event) => event.userHash);

    const uniqueUsers = new Set(filterUsersDay);
    return {
      id: sampleEvent.id,
      title: `${getEventTitle(group)}`,
      start: moment(sampleEvent.startDate).toDate(),
      end: moment(sampleEvent.endDate).toDate(),
      allDay: false,
      equipmentRent: !!sampleEvent.equipmentRentHash,
      equipmentRentHash: sampleEvent.equipmentRentHash,
      equipmentRentEventHash: sampleEvent.equipmentRentEventHash,
      userName: sampleEvent.userName,
      userLastName: sampleEvent.userLastName,
      userHash: sampleEvent.userHash,
      allUserHashes: Array.from(uniqueUsers),
      group: Object.values(group),
      modalTitle: `${getEventTitle(group)}`,
      createdBySystem: sampleEvent.createdBySystem
    };
  });
};
