import React, { useContext } from 'react';
import { EquipmentRentsEditShared } from '../../../components/shared/equipmentRents/equipmentRentsEdit/EquipmentRentsEditShared';
import { UserHashContext } from '../../../core/context/userHashContext';

export const EquipmentRentsEdit = () => {
  const { userHash } = useContext(UserHashContext);
  return (
    <EquipmentRentsEditShared route={'/equipment-rents'} userHash={userHash} />
  );
};
