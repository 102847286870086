import React from 'react';
import { CalendarEquipmentRentEventDialogGroupedEvent } from './calendarEquipmentRentEventDialogGrouped/CalendarEquipmentRentEventDialogGroupedEvent';
import { CalendarEquipmentRentEventDialogSimpleEvent } from './calendarEquipmentRentEventDialogSimple/CalendarEquipmentRentEventDialogSimpleEvent';

export const CalendarEquipmentRentEventDialog = (props) => {
  return (
    <>
      {props.event.group.length > 1 ? (
        <CalendarEquipmentRentEventDialogGroupedEvent
          event={props.event}
          setOpenDialog={props.setOpenDialog}
          openDialog={props.openDialog}
          setRefresh={props.setRefresh}
        ></CalendarEquipmentRentEventDialogGroupedEvent>
      ) : (
        <CalendarEquipmentRentEventDialogSimpleEvent
          setOpenDialog={props.setOpenDialog}
          openDialog={props.openDialog}
          event={props.event}
          setRefresh={props.setRefresh}
          userHash={props.userHash}
        ></CalendarEquipmentRentEventDialogSimpleEvent>
      )}
    </>
  );
};
