import React from 'react';
import { LessonCreate } from './lessonCreate/LessonCreate';
import { LessonEdit } from './lessonEdit/LessonEdit';
import { LessonsList } from './lessonsList/LessonList';
import { useLocation } from 'react-router-dom';

export const LessonOverview = ({ isClub }) => {
  const sectionList = 'list';
  const sectionCreate = 'create';
  const sectionEdit = 'edit';
  const location = useLocation();
  const parts = location.pathname.split('/');
  const userHash = parts.pop();

  const [section, setSection] = React.useState(sectionList);
  const [lessonToEditId, setLessonToEditId] = React.useState(null);

  if (section === sectionCreate) {
    return (
      <LessonCreate
        setSection={setSection}
        sectionList={sectionList}
        userHash={userHash}
        isClub={isClub}
      />
    );
  }

  if (section === sectionEdit) {
    return (
      <LessonEdit
        setSection={setSection}
        sectionList={sectionList}
        lessonToEditId={lessonToEditId}
        userHash={userHash}
        isClub={isClub}
      />
    );
  }

  if (section === sectionList) {
    return (
      <LessonsList
        setSection={setSection}
        sectionCreate={sectionCreate}
        sectionEdit={sectionEdit}
        setLessonToEditId={setLessonToEditId}
        userHash={userHash}
        isClub={isClub}
      />
    );
  }
};
