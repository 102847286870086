export function getErrorMessageCode(error?: any) {
  return error &&
    error.response &&
    error.response.data &&
    error.response.data.errors &&
    error.response.data.errors.global &&
    error.response.data.errors.global.code
    ? error.response.data.errors.global.code
    : 'unrecognized_error';
}
