import React from 'react';

import { SaveButton, Toolbar } from 'react-admin';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../buttons/Button';
import { useFormContext } from 'react-hook-form';

export const EquipmentRentFormToolbar = ({
  setSection,
  sectionList,
  route,
  isLoading,
  isError
}) => {
  const {
    formState: { isSubmitted, isValid, isDirty }
  } = useFormContext();
  const navigate = useNavigate();
  const clearFields = () => {
    if (route) {
      navigate(route);
    }

    if (setSection) {
      setSection(sectionList);
    }
  };

  return (
    <div>
      <Toolbar>
        <Button
          onClick={() => clearFields()}
          label="resources.users.actions.cancel"
        ></Button>
        <SaveButton
          label="resources.users.actions.saveChanges"
          disabled={
            isLoading || (isSubmitted && !isError && isValid && !isDirty)
          }
        />
      </Toolbar>
    </div>
  );
};
