import { createContext, Dispatch, SetStateAction } from 'react';

interface HeaderTitleContextInterface {
  headerTitle: string;
  setHeaderTitle: Dispatch<SetStateAction<string>>;
}

export const headerTitleContextDefaultValue: HeaderTitleContextInterface = {
  headerTitle: '',
  setHeaderTitle: () => ''
};
export const HeaderTitleContext = createContext<HeaderTitleContextInterface>(
  headerTitleContextDefaultValue
);
