import React from 'react';
import { Datagrid, List, TextField } from 'react-admin';
import { RoomDeleteButton } from './RoomDeleteButton';
import { RoomEditButton } from './RoomEditButton';
import { RoomListActions } from './RoomListActions';
import { CustomPagination } from '../../../general/Pagination';
import { EmptyList } from '../../../general/EmptyList';

export const RoomListShared = (props) => {
  return (
    <List
      resource={`users/${props.userHash}/rooms`}
      sort={{ field: 'name', order: 'ASC' }}
      perPage={Number(process.env.REACT_APP_LIST_SIZE)}
      pagination={<CustomPagination />}
      empty={
        <EmptyList
          setSection={props.setSection}
          sectionCreate={props.sectionCreate}
          route={props.route}
          message="resources.users.rooms.list.empty"
          buttonLabel="resources.users.rooms.actions.create"
        />
      }
      actions={
        <RoomListActions
          setSection={props.setSection}
          sectionCreate={props.sectionCreate}
          route={props.route}
        />
      }
    >
      <Datagrid bulkActionButtons={false}>
        <TextField
          source="name"
          label="resources.users.rooms.list.name"
          sortBy="name"
          emptyText="-"
        />
        <TextField
          source="userLimit"
          label="resources.users.rooms.list.userLimit"
          sortBy="userLimit"
          emptyText="-"
        />
        <RoomDeleteButton />
        <RoomEditButton
          setRoomToEditId={props.setRoomToEditId}
          sectionEdit={props.sectionEdit}
          setSection={props.setSection}
          route={props.route}
        />
      </Datagrid>
    </List>
  );
};
