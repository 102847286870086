import React from 'react';
import { useRecordContext } from 'react-admin';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton } from '@mui/material';
import { DialogDeleteRoom } from './DialogDeleteRoom';
import { Room } from '../../../../core/models/room/rooms';

export const RoomDeleteButton = (props) => {
  const record: Room = useRecordContext(props);
  const [showDeleteDialog, setShowDeleteDialog] = React.useState(false);

  const handleDeleteRoom = () => setShowDeleteDialog(true);

  return (
    <>
      <IconButton onClick={() => handleDeleteRoom()}>
        <DeleteIcon />
      </IconButton>
      <DialogDeleteRoom
        showDeleteDialog={showDeleteDialog}
        setShowDeleteDialog={setShowDeleteDialog}
        id={record.id}
      />
    </>
  );
};
