import { ButtonProps } from 'react-admin';
import React from 'react';
import { styled } from '@mui/material/styles';
import { Button as MuiButton } from '@mui/material';
import { useTranslate } from 'ra-core';
import PropTypes from 'prop-types';
import { Path } from 'react-router';

export const Button = (props: ButtonProps) => {
  const {
    alignIcon = 'left',
    children,
    className,
    disabled,
    label,
    color = 'primary',
    size = 'small',
    to: locationDescriptor,
    ...rest
  } = props;
  const translate = useTranslate();
  const translatedLabel = label ? translate(label, { _: label }) : undefined;
  const linkParams = getLinkParams(locationDescriptor);

  return (
    <StyledButton
      className={className}
      color={color}
      size={size}
      aria-label={translatedLabel}
      disabled={disabled}
      startIcon={alignIcon === 'left' && children ? children : undefined}
      endIcon={alignIcon === 'right' && children ? children : undefined}
      {...rest}
      {...linkParams}
    >
      {translatedLabel}
    </StyledButton>
  );
};

Button.propTypes = {
  alignIcon: PropTypes.oneOf(['left', 'right']),
  children: PropTypes.element,
  className: PropTypes.string,
  color: PropTypes.oneOfType([
    PropTypes.oneOf([
      'inherit',
      'default',
      'primary',
      'secondary',
      'error',
      'info',
      'success',
      'warning'
    ]),
    PropTypes.string
  ]),
  disabled: PropTypes.bool,
  label: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large'])
};

const PREFIX = 'RaButton';

const StyledButton = styled(MuiButton, {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root
})({
  '&.MuiButton-sizeSmall': {
    // fix for icon misalignment on small buttons, see https://github.com/mui/material-ui/pull/30240
    lineHeight: 1.5
  }
});

const getLinkParams = (locationDescriptor?: LocationDescriptor | string) => {
  // eslint-disable-next-line eqeqeq
  if (locationDescriptor == undefined) {
    return undefined;
  }

  if (typeof locationDescriptor === 'string') {
    return { to: locationDescriptor };
  }

  const { redirect, replace, state, ...to } = locationDescriptor;
  return {
    to,
    redirect,
    replace,
    state
  };
};

export type LocationDescriptor = Partial<Path> & {
  redirect?: boolean;
  state?: any;
  replace?: boolean;
};
