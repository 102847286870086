import {
  Datagrid,
  EmailField,
  FunctionField,
  List,
  NumberField,
  TextField,
  usePermissions,
  useTranslate
} from 'react-admin';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { EmptyList } from '../../../components/general/EmptyList';
import { CustomPagination } from '../../../components/general/Pagination';
import {
  ACTIVE_STATUS_COLOR,
  CANCEL_STATUS_COLOR,
  DEFAULT_STATUS_COLOR
} from '../../../core/constants/color.constants';
import {
  PRIVILEGE_ADMIN,
  PRIVILEGE_CLUB
} from '../../../core/constants/privilege.constants';
import { HeaderTitleContext } from '../../../core/context/headerTitleContext';
import { User } from '../../../core/models/user/user';
import { getLocaleFromTimestamp } from '../../../core/utils/time-utils';
import { LinkedStatus } from './LinkedStatus';
import { StudentsFilters } from './StudentsFilter';
import { UserFilters } from './UserFilters';
import { ListActions } from './UserListActions';

export const UserList = () => {
  const { privilege } = useParams();
  const { setHeaderTitle } = useContext(HeaderTitleContext);
  const permissions = usePermissions();
  const isClub = permissions.permissions === PRIVILEGE_CLUB;
  const isAdmin = permissions.permissions === PRIVILEGE_ADMIN;

  const params = useParams();
  const translate = useTranslate();

  const getTerm = () => {
    if (privilege === 'students') {
      return { students: true };
    }

    if (privilege === 'teachers') {
      return { teachers: true };
    }

    if (privilege === 'clubs') {
      return { clubs: true };
    }
  };

  useEffect(() => {
    setHeaderTitle(
      getTerm().students
        ? 'resources.users.header.titleStudent'
        : getTerm().teachers
        ? 'resources.users.header.titleTeacher'
        : 'resources.users.header.titleClub'
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getTerm()]);

  const userRowStyle = (record) => ({
    backgroundColor: record.isCandidate
      ? ACTIVE_STATUS_COLOR
      : record.isDisabled
      ? CANCEL_STATUS_COLOR
      : DEFAULT_STATUS_COLOR
  });

  const getStatus = (record: User) => {
    if (record.isCandidate && !getTerm()?.students) {
      return translate('resources.users.fields.pending');
    }

    if (record.isDisabled) {
      return translate('resources.users.fields.disabled');
    }

    if (!record.isCandidate && !getTerm()?.students) {
      return translate('resources.users.fields.verified');
    }

    return '-';
  };

  const getEmptyMessage = () => {
    if (privilege === 'teachers' && isClub) {
      return (
        <EmptyList
          buttonLabel="resources.users.actions.createLinkedTeachers"
          route="/teachers/create-linked-teachers"
        />
      );
    }
  };

  return (
    <List
      resource="users"
      filters={
        privilege === 'students' && isAdmin ? StudentsFilters : UserFilters
      }
      filter={getTerm()}
      exporter={false}
      perPage={Number(process.env.REACT_APP_LIST_SIZE)}
      pagination={<CustomPagination />}
      actions={<ListActions options={getTerm()} />}
      empty={getEmptyMessage()}
    >
      <Datagrid
        rowClick={(id) => `${id}`}
        bulkActionButtons={false}
        rowStyle={userRowStyle}
        className="custom"
      >
        <FunctionField
          label="resources.users.fields.date"
          render={(record: User) => getLocaleFromTimestamp(record.entryDate)}
          sortBy="entryDate"
        />

        {!getTerm()?.clubs && (
          <FunctionField
            label="resources.users.fields.surnames"
            sortBy="lastName"
            render={(record: User) =>
              `${record.lastName} ${
                record.secondLastName ? record.secondLastName : ''
              }`
            }
          />
        )}

        {!getTerm()?.clubs && <TextField source="firstName" emptyText="-" />}

        {getTerm()?.clubs && (
          <FunctionField
            label="resources.users.fields.clubName"
            sortBy="firstName"
            render={(record: User) => `${record.firstName}`}
          />
        )}
        {getTerm()?.clubs && (
          <TextField source="managerFirstName" emptyText="-" />
        )}

        <TextField
          source="identification"
          label="resources.users.fields.identification"
          emptyText="-"
        />
        <TextField
          source="countryName"
          label="resources.users.fields.country"
          sortBy="country"
          emptyText="-"
        />
        <TextField
          source="regionName"
          label="resources.users.fields.region"
          emptyText="-"
          sortBy="region"
        />
        {/* TODO: Fix sorting */}
        {getTerm()?.students && (
          <TextField
            source="club.firstName"
            label="resources.users.fields.club"
            sortBy="club"
          />
        )}
        <TextField source="address" emptyText="-" />
        <FunctionField
          label="resources.users.fields.sport"
          sortBy="firstSport"
          render={(record: User) =>
            record.firstSport?.icon ? (
              <img alt="" width="20" src={record.firstSport.icon.src} />
            ) : (
              ''
            )
          }
        />
        {getTerm()?.students && (
          <NumberField
            label="resources.users.fields.lessonsCount"
            source="lessonsCount"
            emptyText="-"
            sortable={false}
          />
        )}
        {getTerm()?.students && (
          <NumberField
            label="resources.users.fields.lessonsCountInMonth"
            source="lessonsCountInMonth"
            emptyText="-"
            sortable={false}
          />
        )}
        <TextField source="telephone" emptyText="-" />
        <EmailField source="email" emptyText="-" />
        {isAdmin && (
          <FunctionField
            label="resources.users.fields.status"
            render={(record: User) => getStatus(record)}
            sortBy="candidate"
          />
        )}
        {isClub && params.privilege === 'teachers' && (
          <LinkedStatus label="resources.users.fields.status" />
        )}
        <FunctionField label="" render={() => <ChevronRightIcon />} />
      </Datagrid>
    </List>
  );
};
