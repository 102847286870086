import { useTranslate } from 'react-admin';
import React, { useState } from 'react';
import { DialogRestorePassword } from './toolbar/dialog/DialogRestorePassword';
import { Button } from '../../../../../components/shared/buttons/Button';

export const RestorePasswordButton = () => {
  const translate = useTranslate();
  const [showRestorePasswordDialog, setShowRestorePasswordDialog] =
    useState(false);

  const onClickUserRestorePassword = () => {
    setShowRestorePasswordDialog(true);
  };
  return (
    <>
      <div className="restorePassword">
        <p>{translate('resources.users.generalData.security.title')}</p>
        <Button
          color="secondary"
          onClick={onClickUserRestorePassword}
          label="resources.users.actions.restorePassword"
        ></Button>
      </div>

      <DialogRestorePassword
        {...{ showRestorePasswordDialog: showRestorePasswordDialog }}
        setShowRestorePasswordDialog={setShowRestorePasswordDialog.bind(this)}
      ></DialogRestorePassword>
    </>
  );
};
