import { FC, useContext, useEffect, useState } from 'react';
import { Layout, Loading, usePermissions } from 'react-admin';
import { ReactQueryDevtools } from 'react-query/devtools';
import { CustomAppBar } from './AppBar';
import { AdminMenu } from './menu/AdminMenu';

import decodeJwt from 'jwt-decode';
import React from 'react';
import {
  PRIVILEGE_ADMIN,
  PRIVILEGE_CLUB,
  PRIVILEGE_TEACHER
} from '../../core/constants/privilege.constants';
import { TermsAndPolicy } from '../../dashboard/termsAndPolicy/TermsAndPolicy';
import { Header } from './Header';
import { ClubMenu } from './menu/ClubMenu';
import { CustomMenu } from './menu/CustomMenu';
import { TeacherMenu } from './menu/TeacherMenu';
import { UserHashContext } from '../../core/context/userHashContext';
import { useNavigate } from 'react-router-dom';
import { useResponsiveSideBar } from '../../core/hooks/useResponsiveSideBar';
import { UseTermsAndConditionsAndPrivacyPolicy } from '../../core/hooks/UseTermsAndConditionsAndPrivacyPolicy';

export const CustomLayout: FC = (props) => {
  const permissions = usePermissions();
  useResponsiveSideBar();
  const [
    checkTermsAndConditionsAndPrivacyPolicy,
    loadingTermAndCondition,
    loadingPrivacyPolicy,
    termAndCondition,
    privacyPolicy
  ] = UseTermsAndConditionsAndPrivacyPolicy(permissions.permissions);
  const { setUserHash } = useContext(UserHashContext);
  const navigate = useNavigate();
  const [
    saveTermsAndPrivacyPolicyAccepted,
    setSaveTermsAndPrivacyPolicyAccepted
  ] = useState(false);

  useEffect(() => {
    if (!localStorage.getItem('token')) {
      navigate('/login');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const user: any = localStorage.getItem('token')
    ? decodeJwt(localStorage.getItem('token'))
    : null;

  useEffect(() => {
    setUserHash(user?.sub);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    checkTermsAndPolicy();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveTermsAndPrivacyPolicyAccepted]);

  const checkTermsAndPolicy = () => {
    return localStorage.getItem('termsAndPrivacyPolicyAccepted') === 'true'
      ? false
      : checkTermsAndConditionsAndPrivacyPolicy;
  };

  if (checkTermsAndPolicy()) {
    if (loadingTermAndCondition && loadingPrivacyPolicy) return <Loading />;

    return (
      <TermsAndPolicy
        user={user}
        termAndCondition={
          user.isLastTermAndConditionAccepted ? null : termAndCondition
        }
        privacyPolicy={user.isLastPrivacyPolicyAccepted ? null : privacyPolicy}
        setSaveTermsAndPrivacyPolicyAccepted={
          setSaveTermsAndPrivacyPolicyAccepted
        }
      />
    );
  }
  return (
    <>
      <Layout
        {...props}
        appBar={CustomAppBar}
        menu={
          permissions.permissions === PRIVILEGE_ADMIN
            ? AdminMenu
            : permissions.permissions === PRIVILEGE_CLUB
            ? ClubMenu
            : permissions.permissions === PRIVILEGE_TEACHER
            ? TeacherMenu
            : CustomMenu
        }
      >
        <Header />
        {props.children}
      </Layout>
      {process.env.REACT_APP_QUERY_DEV_TOOLS && (
        <ReactQueryDevtools
          initialIsOpen={!!process.env.REACT_APP_QUERY_DEV_TOOLS}
        />
      )}
    </>
  );
};
