import React from 'react';
import { Edit, useRecordContext } from 'react-admin';
import { UserCard } from './userCard/UserCard';
import { UserTabs } from './userTabs/UserTabs';

const UserTitle = () => {
  const record = useRecordContext();
  return (
    <div>
      {record?.firstName} {record?.lastName}
    </div>
  );
};

export const UserOverview = () => {
  return (
    <>
      <Edit resource="users" className="users-header">
        <UserCard />
      </Edit>
      <Edit resource="users" title={<UserTitle />} className="users-content">
        <UserTabs />
      </Edit>
    </>
  );
};
