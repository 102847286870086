import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import React from 'react';
import { Create, SimpleForm, useNotify, useTranslate } from 'react-admin';
import { shareAppByEmail } from '../../../core/services/share-api.service';
import { ShareEmailDialogForm } from './ShareEmailDialogForm';
import { ShareEmailDialogToolBar } from './ShareEmailDialogToolBar';

export const ShareEmailDialog = (props) => {
  const translate = useTranslate();
  const notify = useNotify();

  const onCloseEmailDialog = () => {
    props.setShowEmailDialog(false);
  };

  const onShareAppByEmail = (data) => {
    shareAppByEmail(data.emails.split(';'), data.message)
      .then(() => {
        notify('resources.configuration.share.emailDialog.sendEmail.success', {
          type: 'success'
        });
        onCloseEmailDialog();
      })
      .catch((error) => {
        notify(error.message, { type: 'warning' });
      });
  };

  return (
    <Dialog fullWidth open={props.showEmailDialog} onClose={onCloseEmailDialog}>
      <DialogTitle>
        {translate('resources.configuration.share.emailDialog.title')}
      </DialogTitle>
      <DialogContent>
        <Create resource={'share'}>
          <SimpleForm
            className="form share-form"
            onSubmit={onShareAppByEmail}
            toolbar={
              <ShareEmailDialogToolBar
                setShowEmailDialog={props.setShowEmailDialog}
              />
            }
          >
            <ShareEmailDialogForm />
          </SimpleForm>
        </Create>
      </DialogContent>
    </Dialog>
  );
};
