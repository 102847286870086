import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import React from 'react';
import { useTranslate } from 'react-admin';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../buttons/Button';

export const LessonEmptyList = (props) => {
  const translate = useTranslate();
  const navigate = useNavigate();
  const goToCreate = () => {
    if (props.setSection) {
      props.setSection(props.sectionCreate);
    }

    if (props.route) {
      navigate(props.route);
    }
  };
  return (
    <Box textAlign="center" m={1}>
      <Typography variant="h4" paragraph>
        {translate('resources.users.lessons.list.empty')}
      </Typography>
      <Button
        onClick={goToCreate}
        label="resources.users.lessons.actions.create"
      ></Button>
    </Box>
  );
};
