import React from 'react';
import { CourseEditShared } from '../../../../../../components/shared/courses/courseEdit/CourseEditShared';

export const CourseEdit = ({
  setSection,
  sectionList,
  courseToEditId,
  userHash,
  smallCourses,
  isClub
}) => {
  return (
    <CourseEditShared
      setSection={setSection}
      sectionList={sectionList}
      courseToEditId={courseToEditId}
      userHash={userHash}
      smallCourses={smallCourses}
      isClub={isClub}
    />
  );
};
