import React, { useEffect } from 'react';
import {
  FilterButton,
  TopToolbar,
  useListContext,
  usePermissions,
  useRedirect,
  useTranslate
} from 'react-admin';
import { useParams } from 'react-router-dom';
import { Button } from '../../../components/shared/buttons/Button';
import {
  PRIVILEGE_ADMIN,
  PRIVILEGE_CLUB
} from '../../../core/constants/privilege.constants';

export const ListActions = (props: { options: any }) => {
  const redirect = useRedirect();
  const translate = useTranslate();
  const { privilege } = useParams();
  const { filterValues, displayedFilters, setFilters } = useListContext();
  const permissions = usePermissions();
  const isAdmin = permissions.permissions === PRIVILEGE_ADMIN;
  const isClub = permissions.permissions === PRIVILEGE_CLUB;

  useEffect(() => {
    delete filterValues.filterRegion;
    delete filterValues.filterCountry;
    delete filterValues.filterCity;
    delete filterValues.filterName;
    delete filterValues.filterLastName;
    delete filterValues.filterSport;
    delete filterValues.filterPhone;
    delete filterValues.candidate;
    setFilters(filterValues, {}, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [privilege]);

  const goToCreate = () => {
    if (privilege != null) {
      redirect(`/${privilege}/create`);
    }
  };

  const goToCreateLinkedTeachers = () => {
    if (privilege != null) {
      redirect('/teachers/create-linked-teachers');
    }
  };

  const filterResults = (status) => {
    status != null
      ? redirect(`/${privilege}?filter={"candidate":${status}}`)
      : redirect(`/${privilege}?filter={}`);
  };

  const getLabel = () => {
    return privilege === 'students'
      ? 'resources.users.actions.createStudent'
      : privilege === 'teachers'
      ? 'resources.users.actions.createTeacher'
      : 'resources.users.actions.createClub';
  };
  const removeRegionAndCityFilterOnRemoveCountryFilter = () => {
    if (filterValues.filterCountry === undefined) {
      if (filterValues.filterRegion !== undefined) {
        delete filterValues.filterRegion;
        setFilters(filterValues, displayedFilters, true);
      }

      if (filterValues.filterCity !== undefined) {
        delete filterValues.filterCity;
        setFilters(filterValues, displayedFilters, true);
      }
    }
  };

  removeRegionAndCityFilterOnRemoveCountryFilter();

  return (
    <TopToolbar>
      <FilterButton />
      {(privilege === 'teachers' || privilege === 'clubs') && (
        <div className="flex">
          <button className="N-secondary" onClick={() => filterResults(null)}>
            {translate('resources.users.actions.viewAll')}
          </button>
          <button className="N-secondary" onClick={() => filterResults(true)}>
            {translate('resources.users.actions.viewCandidates')}
          </button>
          <button className="N-secondary" onClick={() => filterResults(false)}>
            {translate('resources.users.actions.viewValidated')}
          </button>
        </div>
      )}
      {isAdmin && (
        <button className="N-primary" onClick={goToCreate}>
          {translate(getLabel())}
        </button>
      )}

      {isClub && privilege === 'teachers' && (
        <Button
          className="N-primary"
          onClick={goToCreateLinkedTeachers}
          label="resources.users.actions.createLinkedTeachers"
        />
      )}
    </TopToolbar>
  );
};
