import React, { useState } from 'react';
import { useRecordContext } from 'react-admin';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton } from '@mui/material';
import { UserVoucher } from '../../../../../../core/models/userVoucher/userVoucher';
import { DialogDeleteUserVoucher } from './DialogDeleteUserVoucher';

export const UserVoucherDeleteButton = (props) => {
  const record: UserVoucher = useRecordContext(props);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const handleDeleteLesson = () => setShowDeleteDialog(true);

  return (
    <>
      <IconButton onClick={() => handleDeleteLesson()}>
        <DeleteIcon />
      </IconButton>
      <DialogDeleteUserVoucher
        showDeleteDialog={showDeleteDialog}
        setShowDeleteDialog={setShowDeleteDialog}
        id={record.id}
      />
    </>
  );
};
