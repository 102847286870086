import React, { useContext, useEffect, useState } from 'react';
import {
  Datagrid,
  DateField,
  FunctionField,
  List,
  Loading,
  NumberField,
  TextField,
  usePermissions,
  useTranslate
} from 'react-admin';
import { GlobalOperationFilters } from './GlobalOperationFilters';

import { EmptyList } from '../../../../components/general/EmptyList';
import { CustomPagination } from '../../../../components/general/Pagination';
import {
  BOOKING_STATUS_ACTIVE,
  BOOKING_STATUS_CANCEL
} from '../../../../core/constants/booking.constants';
import {
  ACTIVE_STATUS_COLOR,
  CANCEL_STATUS_COLOR,
  DEFAULT_STATUS_COLOR
} from '../../../../core/constants/color.constants';
import {
  PRIVILEGE_CLUB,
  PRIVILEGE_TEACHER
} from '../../../../core/constants/privilege.constants';
import { HeaderTitleContext } from '../../../../core/context/headerTitleContext';
import { UserHashContext } from '../../../../core/context/userHashContext';
import { GlobalOperation } from '../../../../core/models/globalOperation/globalOperation';
import { LessonType } from '../../../../core/models/lesson/lessonType';
import { formatDecimal } from '../../../../core/utils/number-utils';
import { GlobalOperationActions } from './GlobalOperationActions';
import { GlobalOperationClubFilters } from './GlobalOperationClubFilters';
import { globalOperationTeacherFilters } from './GlobalOperationTeacherFilters';

export const GlobalOperationsList = () => {
  const { setHeaderTitle } = useContext(HeaderTitleContext);
  const translate = useTranslate();
  const permissions = usePermissions();
  const isClub = permissions.permissions === PRIVILEGE_CLUB;
  const isTeacher = permissions.permissions === PRIVILEGE_TEACHER;
  const { userHash } = useContext(UserHashContext);
  const [isLoading, setisLoading] = useState(false);

  useEffect(() => {
    setHeaderTitle('resources.invoicing.globalOperations.header.title');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getLessonType = (lessonType: LessonType) => {
    return lessonType ? lessonType.name : '-';
  };

  const getPrice = (price: number) => {
    return price !== null
      ? `${formatDecimal(price)} ${translate(
          'resources.invoicing.globalOperations.list.currency'
        )}`
      : '-';
  };

  const userRowStyle = (record: GlobalOperation) => {
    let background = DEFAULT_STATUS_COLOR;
    if (record.bookingStatus?.id === BOOKING_STATUS_ACTIVE) {
      background = ACTIVE_STATUS_COLOR;
    }

    if (record.bookingStatus?.id === BOOKING_STATUS_CANCEL) {
      background = CANCEL_STATUS_COLOR;
    }

    return { backgroundColor: background };
  };

  const getFilter = () => {
    if (isClub) {
      return { club: userHash };
    }

    if (isTeacher) {
      return { teacher: userHash };
    }

    return {};
  };

  const getTeacherName = (record: GlobalOperation) => {
    if (record.teacher) {
      return `${record.teacher.firstName} ${
        record.teacher.lastName ? record.teacher.lastName : ''
      }`;
    }

    return '-';
  };

  const getFilters = () => {
    if (isClub) {
      return GlobalOperationClubFilters;
    }

    if (isTeacher) {
      return globalOperationTeacherFilters;
    }

    return GlobalOperationFilters;
  };

  if (isLoading) return <Loading />;

  return (
    <List
      resource={'global-operations'}
      sort={{ field: 'serial', order: 'DESC' }}
      perPage={Number(process.env.REACT_APP_LIST_SIZE)}
      pagination={<CustomPagination />}
      empty={<EmptyList />}
      exporter={false}
      actions={
        <GlobalOperationActions
          userHash={userHash}
          setIsLoading={setisLoading}
        />
      }
      filters={getFilters()}
      filter={getFilter()}
    >
      <Datagrid
        rowClick={(id, resource, record) => `${record.id}`}
        bulkActionButtons={false}
        rowStyle={userRowStyle}
        className="custom"
      >
        <TextField
          source="serialCode"
          label={'resources.invoicing.globalOperations.list.serialCode'}
          emptyText="-"
          sortBy={'serial'}
        />
        <DateField
          source="paymentDate"
          label={'resources.invoicing.globalOperations.list.paymentDate'}
          emptyText="-"
          sortBy={'paymentDate'}
        />
        <TextField
          source="type"
          label="resources.invoicing.globalOperations.list.type"
          emptyText="-"
          sortBy={'typeOrder'}
        />
        <DateField
          source="lessonDate"
          label={'resources.invoicing.globalOperations.list.lessonDate'}
          emptyText="-"
          sortBy={'lessonDate'}
        />
        <FunctionField
          label="resources.invoicing.globalOperations.list.sport"
          sortBy={'sport'}
          render={(record: GlobalOperation) =>
            record?.sport?.icon ? (
              <img alt="" width="20" src={record.sport.icon.src} />
            ) : (
              ''
            )
          }
        />
        {!isClub && (
          <TextField
            source="club.firstName"
            label="resources.invoicing.globalOperations.list.club"
            emptyText="-"
            sortBy={'parentName'}
          />
        )}
        <FunctionField
          label="resources.invoicing.globalOperations.list.teacher"
          render={(record: GlobalOperation) => getTeacherName(record)}
          sortBy={'teacherName'}
        />
        <FunctionField
          label="resources.invoicing.globalOperations.list.student"
          render={(record: GlobalOperation) =>
            `${record.user?.firstName} ${record.user?.lastName || ''}`
          }
          sortBy={'user.firstName'}
        />
        <FunctionField
          label="resources.users.lessons.list.lessonType"
          render={(record: GlobalOperation) =>
            getLessonType(record?.lessonType)
          }
          sortBy="lesson_types_codes"
        />
        <NumberField
          source="numberOfStudents"
          label="resources.invoicing.globalOperations.list.numberOfStudents"
          emptyText="-"
        />
        <FunctionField
          label="resources.invoicing.globalOperations.list.price"
          render={(record: GlobalOperation) => getPrice(record.price)}
          sortBy={'price'}
        />
        {!(isClub || isTeacher) && (
          <FunctionField
            label="resources.invoicing.globalOperations.list.feeImport"
            render={(record: GlobalOperation) => getPrice(record.feeImport)}
            sortBy={'feeImport'}
          />
        )}
        <FunctionField
          label={
            isClub || isTeacher
              ? 'resources.invoicing.globalOperations.list.fee'
              : 'resources.invoicing.globalOperations.list.teacherFee'
          }
          render={(record: GlobalOperation) => getPrice(record.teacherFee)}
          sortBy={'teacherFee'}
        />
        <FunctionField
          label="resources.invoicing.globalOperations.list.vat"
          render={(record: GlobalOperation) =>
            getPrice(record.teacherFee * 0.21)
          }
        ></FunctionField>
        {!(isClub || isTeacher) && (
          <FunctionField
            label="resources.invoicing.globalOperations.list.teacherPayment"
            render={(record: GlobalOperation) =>
              getPrice(record.teacherPayment)
            }
            sortBy={'teacherPayment'}
          />
        )}
        <FunctionField
          label="resources.invoicing.globalOperations.list.publicPrice"
          render={(record: GlobalOperation) => getPrice(record.publicPrice)}
          sortBy={'amount'}
        />
        <FunctionField
          label="resources.invoicing.globalOperations.list.status"
          render={(record: GlobalOperation) => {
            return record.bookingStatus?.name;
          }}
          sortBy={'bookingStatus'}
        />
      </Datagrid>
    </List>
  );
};
