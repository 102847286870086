import {
  useNotify,
  useRecordContext,
  useRefresh,
  useUpdate
} from 'react-admin';
import { IconButton } from '@mui/material';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import React, { useState } from 'react';
import { Notification } from '../../../core/models/notification/notification';
import { NOTIFICATION_STATUS_READ } from '../../../core/constants/notificationStatus.constants';
import { DialogViewNotification } from './DialogViewNotification';

export const NotificationViewButton = (props) => {
  const record: Notification = useRecordContext(props);
  const [update] = useUpdate();
  const notify = useNotify();
  const refresh = useRefresh();
  const [showDialog, setShowDialog] = useState(false);

  const handleView = () => {
    setShowDialog(true);
    if (record.status === NOTIFICATION_STATUS_READ) {
      return;
    }

    //navigate(`${record.id}`);
    update(
      'notifications/status',
      { id: record.id, data: { statusCode: NOTIFICATION_STATUS_READ } },
      {
        onSuccess: () => {
          refresh();
        },
        onError(error: any) {
          notify(error.message, { type: 'warning' });
        }
      }
    );
  };

  return (
    <>
      <IconButton
        style={{
          opacity: record.status === NOTIFICATION_STATUS_READ ? 0.5 : 1
        }}
        onClick={() => handleView()}
      >
        <RemoveRedEyeIcon />
      </IconButton>

      <DialogViewNotification
        showDialog={showDialog}
        setShowDialog={setShowDialog}
        notification={record}
      />
    </>
  );
};
