import React, { useContext, useEffect, useState } from 'react';
import { useNotify, useRedirect, useDelete, Confirm } from 'react-admin';
import { useParams } from 'react-router-dom';
import { getUserParent } from '../../../../../../../core/services/user-api.service';
import { UserHashContext } from '../../../../../../../core/context/userHashContext';

export const DialogUnlinkUser = ({
  showUnlinkDialog,
  setShowUnlinkDialog,
  id
}) => {
  const { userHash } = useContext(UserHashContext);
  const [erase] = useDelete('users');
  const params = useParams();
  const notify = useNotify();
  const redirect = useRedirect();
  const { privilege } = useParams();
  const [userParent, setUserParent] = useState(null);

  useEffect(() => {
    if (privilege === 'teachers') {
      getUserParent(params.id, userHash).then((data) => {
        setUserParent(data.data);
      });
    }
  }, [params.id, privilege, userHash]);

  const onCloseUserUnlinkDialog = () => {
    setShowUnlinkDialog(false);
  };

  const onUnlinkUser = () => {
    erase(
      'user-parents/linked-teachers',
      {
        id: userParent.id
      },
      {
        onSuccess: () => {
          notify(
            'resources.users.notifications.userEdit.linkedTeachers.delete.success',
            {
              type: 'success'
            }
          );
          onCloseUserUnlinkDialog();
          redirect(`/${privilege}`);
        },
        onError(error: any) {
          notify(error.message, { type: 'warning' });
        }
      }
    );
  };

  return (
    <Confirm
      title="resources.users.unlink.title"
      content="resources.users.unlink.message"
      onConfirm={onUnlinkUser}
      isOpen={showUnlinkDialog}
      onClose={onCloseUserUnlinkDialog}
    />
  );
};
