import React, { useContext, useEffect } from 'react';
import { HeaderTitleContext } from '../../../../core/context/headerTitleContext';
import { CustomPagination } from '../../../../components/general/Pagination';
import {
  Datagrid,
  DateField,
  FunctionField,
  List,
  TextField,
  useTranslate
} from 'react-admin';
import { PrivacyPoliciesListActions } from './PrivacyPoliciesListActions';
import { EmptyList } from '../../../../components/general/EmptyList';
import { PrivacyPolicyDeleteButton } from './PrivacyPolicyDeleteButton';
import { PrivacyPoliciesViewButton } from './PrivacyPoliciesViewButton';
import {
  PRIVILEGE_CLUB,
  PRIVILEGE_STUDENT,
  PRIVILEGE_TEACHER
} from '../../../../core/constants/privilege.constants';

export const PrivacyPoliciesList = () => {
  const { setHeaderTitle } = useContext(HeaderTitleContext);
  const translate = useTranslate();

  useEffect(() => {
    setHeaderTitle('resources.configuration.privacyPolicies.title');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getPrivilege = (record) => {
    if (record.privilegeType === PRIVILEGE_STUDENT) {
      return translate(
        'resources.configuration.privacyPolicies.fields.student'
      );
    }

    if (record.privilegeType === PRIVILEGE_CLUB) {
      return translate('resources.configuration.privacyPolicies.fields.club');
    }

    if (record.privilegeType === PRIVILEGE_TEACHER) {
      return translate(
        'resources.configuration.privacyPolicies.fields.teacher'
      );
    }

    return '';
  };

  return (
    <List
      resource="privacy-policies"
      exporter={false}
      perPage={Number(process.env.REACT_APP_LIST_SIZE)}
      pagination={<CustomPagination />}
      actions={<PrivacyPoliciesListActions />}
      empty={
        <EmptyList
          buttonLabel={
            'resources.configuration.privacyPolicies.list.actions.create'
          }
          route={'/privacy-policies/create'}
        />
      }
    >
      <Datagrid bulkActionButtons={false}>
        <TextField
          source="title"
          label={'resources.configuration.privacyPolicies.fields.title'}
          emptyText="-"
        />
        <DateField
          source="date"
          label={'resources.configuration.privacyPolicies.fields.date'}
          emptyText="-"
        />
        <FunctionField
          label="resources.configuration.privacyPolicies.fields.privilege"
          render={(record) => getPrivilege(record)}
        />
        <PrivacyPolicyDeleteButton />
        <PrivacyPoliciesViewButton />
      </Datagrid>
    </List>
  );
};
