import React from 'react';
import { TopToolbar } from 'react-admin';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../../../components/shared/buttons/Button';

export const TermsAndConditionsListActions = () => {
  let navigate = useNavigate();
  const goToCreate = () => {
    navigate('/terms-and-conditions/create');
  };

  return (
    <TopToolbar>
      <Button
        onClick={goToCreate}
        label="resources.configuration.termsAndConditions.list.actions.create"
      ></Button>
    </TopToolbar>
  );
};
