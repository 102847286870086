import React from 'react';
import { useRecordContext } from 'react-admin';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { IconButton } from '@mui/material';
import { Room } from '../../../../core/models/room/rooms';
import { useNavigate } from 'react-router-dom';

export const RoomEditButton = (props) => {
  const record: Room = useRecordContext(props);
  const navigate = useNavigate();

  const handleEdit = () => {
    if (props.route) {
      navigate(record.id);
    }

    if (props.setSection) {
      props.setSection(props.sectionEdit);
      props.setRoomToEditId(record.id);
    }
  };

  return (
    <IconButton onClick={() => handleEdit()}>
      <ChevronRightIcon />
    </IconButton>
  );
};
