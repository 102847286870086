import React from 'react';
import { Create, SimpleForm, useCreate, useNotify } from 'react-admin';
import { roomFormValidator } from '../roomsForm/roomFormValidator';
import { RoomFormToolbar } from '../roomsForm/RoomFormToolbar';
import { RoomForm } from '../roomsForm/RoomForm';
import { useNavigate } from 'react-router-dom';

export const RoomCreateShared = (props) => {
  const [create, { isLoading, isError }] = useCreate('users');
  const notify = useNotify();
  const navigate = useNavigate();

  const roomSave = (data) => {
    data = {
      ...data,
      prices: [data.price],
      userHash: props.userHash
    };
    create(
      'rooms',
      { data },
      {
        onSuccess: () => {
          notify('resources.users.rooms.notifications.create.success', {
            type: 'success'
          });
          if (props.route) {
            navigate(props.route);
          }

          if (props.setSection) {
            props.setSection(props.sectionList);
          }
        },
        onError(error: any) {
          notify(error.message, { type: 'warning' });
        }
      }
    );
  };

  return (
    <Create resource="rooms">
      <SimpleForm
        onSubmit={roomSave}
        validate={(e) => roomFormValidator(e)}
        className={'form'}
        toolbar={
          <RoomFormToolbar
            setSection={props.setSection}
            sectionList={props.sectionList}
            route={props.route}
            isLoading={isLoading}
            isError={isError}
          />
        }
      >
        <RoomForm />
      </SimpleForm>
    </Create>
  );
};
