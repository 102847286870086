import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { UserPaymentMethodsList } from './userPaymentMethodsList/UserPaymentMethodsList';

export const UserPaymentMethodsOverview = () => {
  const sectionList = 'list';
  const location = useLocation();
  const parts = location.pathname.split('/');
  const userHash = parts.pop();
  const [section] = useState(sectionList);

  if (section === sectionList) {
    return <UserPaymentMethodsList userHash={userHash} />;
  }
};
