import React from 'react';

import { SaveButton, Toolbar } from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { Button } from '../../buttons/Button';
import { useNavigate } from 'react-router-dom';

export const CourseFormToolbar = (props) => {
  const {
    setValue,
    resetField,
    formState: { isSubmitted, isValid, isDirty }
  } = useFormContext();
  const navigate = useNavigate();

  const clearFields = () => {
    resetField('title');
    setValue('userType', Number(process.env.REACT_APP_USER_TYPE_CHILD));
    resetField('fromYears');
    resetField('toYears');
    resetField('LevelType');
    resetField('minimumStudents');
    resetField('maximumStudents');
    resetField('dateStart');
    resetField('dateEnd');
    resetField('registrationDeadline');
    resetField('timeStart');
    resetField('timeEnd');
    resetField('place');
    resetField('price');
    resetField('dayTypes');
    resetField('freeCancellationPeriod');
    resetField('disabled');

    if (props.route) {
      navigate(props.route);
    }

    if (props.setSection) {
      props.setSection(props.sectionList);
    }
  };

  return (
    <div>
      <Toolbar>
        <Button
          onClick={() => clearFields()}
          label="resources.users.actions.cancel"
        ></Button>
        <SaveButton
          label="resources.users.actions.saveChanges"
          disabled={
            props.isLoading ||
            (isSubmitted && !props.isError && isValid && !isDirty)
          }
        />
      </Toolbar>
    </div>
  );
};
