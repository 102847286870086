import {
  ImageField,
  ImageInput,
  useInput,
  useNotify,
  useRecordContext
} from 'react-admin';
import React from 'react';
import { useFormContext } from 'react-hook-form';

export const InputImageField = (props) => {
  const { onChange, onBlur, ...rest } = props;
  const {
    field,
    fieldState: { isTouched, error },
    formState: { isSubmitted },
    isRequired
  } = useInput({
    onChange,
    onBlur,
    ...props
  });

  const notify = useNotify();
  const record = useRecordContext();
  const { setValue } = useFormContext();
  const validExtensions = process.env.REACT_APP_IMAGE_VALID_EXTENSIONS;

  const handleDropFiles = (files) => {
    if (files.length === 0) {
      setValue(
        props.source,
        record[props.source] ? record[props.source] : null
      );
      notify('resources.users.notifications.extension.error', {
        type: 'warning'
      });
    }
    if (files[0]?.size > Number(process.env.REACT_APP_IMAGE_MAX_SIZE)) {
      setValue(
        props.source,
        record[props.source] ? record[props.source] : null
      );
      notify('resources.users.notifications.imageSize.error', {
        messageArgs: {
          max: Number(process.env.REACT_APP_IMAGE_MAX_SIZE) / 1000000
        },
        type: 'warning'
      });
    }
  };
  const handleImageChange = (e) => {
    if (e == null) {
      setValue(props.source, null);
    }
  };
  return (
    <ImageInput
      {...field}
      label={props.label}
      error={isTouched || isSubmitted}
      helperText={isTouched || isSubmitted ? error : ''}
      required={isRequired}
      ref={props.field?.ref}
      value={props.date}
      onChange={(e) => handleImageChange(e)}
      validate={props.validate}
      accept={validExtensions}
      options={{
        onDrop: (files) => {
          handleDropFiles(files);
        }
      }}
      {...rest}
    >
      <ImageField source="src" title="title" />
    </ImageInput>
  );
};
