import React, { useEffect } from 'react';
import {
  Loading,
  required,
  SelectInput,
  useGetList,
  useTranslate
} from 'react-admin';

export const CitySelect = (props) => {
  const translate = useTranslate();
  const [cities, setCities] = React.useState([]);
  const { data: citiesData, isLoading: citiesIsLoading } = useGetList(
    'public/cities',
    {
      filter: { region: props.region },
      sort: { field: 'name', order: 'ASC' },
      pagination: { page: 1, perPage: 9999999 }
    }
  );

  useEffect(() => {
    setCities(citiesData);
  }, [props.region, citiesData]);

  if (citiesIsLoading) {
    return <Loading />;
  }

  const createEmptyChoice = () => {
    if (cities && cities.length > 0 && cities[0].id != null) {
      cities.unshift({
        id: null,
        name: ' '
      });
    }
  };

  if (!citiesIsLoading && cities && cities.length === 0) {
    return (
      <p className="error field--warning">
        {translate('resources.users.notifications.citiesNotFound')}
      </p>
    );
  }

  if (cities && cities.length > 0) {
    createEmptyChoice();

    return (
      <>
        <SelectInput
          optionText="name"
          source="city"
          label="resources.users.fields.city"
          validate={[required()]}
          choices={cities ?? []}
          defaultValue=""
        />
      </>
    );
  }
};
