import React from 'react';
import { useLocation } from 'react-router-dom';
import { CourseCreate } from './courseCreate/CourseCreate';
import { CourseList } from './courseList/CourseList';
import { CourseEdit } from './courseEdit/CourseEdit';

export const CourseOverview = ({ smallCourses, isClub }) => {
  const sectionList = 'list';
  const sectionCreate = 'create';
  const sectionEdit = 'edit';
  const location = useLocation();
  const parts = location.pathname.split('/');
  const userHash = parts.pop();

  const [section, setSection] = React.useState(sectionList);
  const [courseToEditId, setCourseToEditId] = React.useState(null);

  if (section === sectionCreate) {
    return (
      <CourseCreate
        setSection={setSection}
        sectionList={sectionList}
        userHash={userHash}
        smallCourses={smallCourses}
        isClub={isClub}
      />
    );
  }

  if (section === sectionEdit) {
    return (
      <CourseEdit
        setSection={setSection}
        sectionList={sectionList}
        courseToEditId={courseToEditId}
        userHash={userHash}
        smallCourses={smallCourses}
        isClub={isClub}
      />
    );
  }

  if (section === sectionList) {
    return (
      <CourseList
        setSection={setSection}
        sectionCreate={sectionCreate}
        sectionEdit={sectionEdit}
        userHash={userHash}
        smallCourses={smallCourses}
        setCourseToEditId={setCourseToEditId}
        isClub={isClub}
      />
    );
  }
};
