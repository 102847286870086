import { TextInput } from 'react-admin';
import React from 'react';
import { FilterSport } from './FilterSport';
import { FilterCountry } from './FilterCountry';
import { FilterClub } from './FilterClub';

export const StudentsFilters = [
  <TextInput source="q" label="resources.users.filters.searchTerm" />,
  <TextInput source="filterName" label="resources.users.filters.name" />,
  <FilterClub source="filterClub" label="resources.users.filters.clubName" />,
  <TextInput source="filterPhone" label="resources.users.filters.phone" />,
  <FilterSport source="filterSport" label="resources.users.filters.sport" />,
  <FilterCountry
    source="filterCountry"
    label="resources.users.filters.country"
  />
];
