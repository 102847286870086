import React, { useEffect } from 'react';
import { SaveButton, Toolbar } from 'react-admin';
import { Button } from '../../../../../../components/shared/buttons/Button';
import { useFormContext } from 'react-hook-form';

export const LinkedTeacherToolbar = ({
  setSection,
  sectionList,
  setSaveAndAdd,
  submit
}) => {
  const { reset, setValue } = useFormContext();

  useEffect(() => {
    if (submit) {
      reset();
      setValue('name', '');
    }
  }, [submit]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <Toolbar className={'linked-teachers-toolbar'}>
        <Button
          label="resources.users.linkedTeachers.create.form.buttons.back"
          color="secondary"
          className="button-back"
          onClick={() => setSection(sectionList)}
        ></Button>
        <SaveButton
          label="resources.users.linkedTeachers.create.form.buttons.saveAndAdd"
          onClick={() => setSaveAndAdd(true)}
        />
        <SaveButton label="resources.users.linkedTeachers.create.form.buttons.save" />
      </Toolbar>
    </div>
  );
};
