import React from 'react';
import { FunctionField, useRecordContext } from 'react-admin';
import { UserVoucher } from '../../../../../core/models/userVoucher/userVoucher';
import { getLocaleFromTimestamp } from '../../../../../core/utils/time-utils';
import { UserActiveVouchersListExpandWithPendingLessons } from './UserActiveVouncherListExpandWithPendingLessons';

const renderWithPendingLessons = (userVoucher) => (
  <FunctionField
    key={userVoucher.id}
    label="resources.users.userVouchers.list.progress"
    render={() => (
      <UserActiveVouchersListExpandWithPendingLessons {...userVoucher} />
    )}
  />
);

const renderWithoutPendingLessons = (userVoucher) => (
  <FunctionField
    key={userVoucher?.id}
    label="resources.users.userVouchers.list.progress"
    render={() => (
      <>
        <br></br>
        <span>{userVoucher?.voucher?.title}</span>
        <span>
          {' - '}
          {userVoucher?.voucher?.timeRangeType?.name}
        </span>
        <span>
          {' - '}
          {getLocaleFromTimestamp(userVoucher?.buyDate)}
        </span>
        <span>
          {' - '}
          {getLocaleFromTimestamp(
            userVoucher?.buyDate +
              userVoucher?.voucher?.timeRangeType?.milliseconds
          )}
        </span>
      </>
    )}
  />
);

export const UserActiveVouchersListExpand = () => {
  const record = useRecordContext();

  const userVouchersWithPendingLessons = record.userVouchers
    .filter((userVoucher: UserVoucher) => !userVoucher.voucher.timeRangeType)
    .sort((a, b) => a.id.localeCompare(b.id));

  const userVouchersWithoutPendingLessons = record.userVouchers
    .filter((userVoucher: UserVoucher) => userVoucher.voucher.timeRangeType)
    .sort((a, b) => a.id.localeCompare(b.id));

  return (
    <>
      {userVouchersWithPendingLessons.map(renderWithPendingLessons)}
      <br></br>
      {userVouchersWithoutPendingLessons.length > 0 && (
        <>
          <hr></hr>
          {userVouchersWithoutPendingLessons.map(renderWithoutPendingLessons)}
        </>
      )}
    </>
  );
};
