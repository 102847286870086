import { Loading, required, SelectInput, useGetList } from 'react-admin';
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { FilterCity } from './FilterCity';

export const FilterRegion = (props) => {
  const { getValues, setValue } = useFormContext();
  const [regions, setRegions] = React.useState([]);
  const { data: regionsData, isLoading } = useGetList('public/regions', {
    filter: { country: getValues('filterCountry') },
    pagination: { page: 1, perPage: 99999 },
    sort: { field: 'name', order: 'ASC' }
  });

  useEffect(() => {
    setRegions(regionsData);
  }, [regionsData]);

  const handleRegionChange = () => {
    setValue('filterCity', '');
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <SelectInput
        source="filterRegion"
        optionText="name"
        label="resources.users.fields.region"
        validate={[required()]}
        defaultValue=""
        choices={regions || []}
        onChange={() => handleRegionChange()}
      />
      {getValues('filterRegion') && getValues('filterRegion') !== '' && (
        <FilterCity />
      )}
    </>
  );
};
