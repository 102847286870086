import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LanguageIcon from '@mui/icons-material/Translate';
import { Box, Button, Menu, MenuItem, styled } from '@mui/material';
import * as React from 'react';
import { LANGS } from '../../../core/providers/i18nProvider';
import { useLocaleState } from 'react-admin';
import { requestForToken } from '../../../firebase';
import { updateNotificationDeviceLang } from '../../../core/services/notification-api.service';

export const CustomLocaleButton = (props) => {
  const [locale, setLocale] = useLocaleState();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const languages = LANGS.map(({ code, label }) => ({
    locale: code,
    name: label
  }));

  const getNameForLocale = (locale: string): string => {
    const language = languages.find((lang) => lang.locale === locale);
    return language ? language.name : '';
  };

  const changeLocale = (locale: string) => async (): Promise<void> => {
    setLocale(locale);
    setAnchorEl(null);
    const deviceToken = await requestForToken().then((token) => {
      return token;
    });
    if (deviceToken !== null) {
      await updateNotificationDeviceLang(locale, deviceToken);
    }
  };

  const handleLanguageClick = (event): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  return (
    <Root component="span">
      <Button
        color="inherit"
        aria-controls="simple-menu"
        aria-label=""
        aria-haspopup="true"
        onClick={handleLanguageClick}
        startIcon={<LanguageIcon />}
        endIcon={<ExpandMoreIcon fontSize="small" />}
      >
        {getNameForLocale(locale)}
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {languages.map((language) => (
          <MenuItem
            key={language.locale}
            onClick={changeLocale(language.locale)}
            selected={language.locale === locale}
          >
            {language.name}
          </MenuItem>
        ))}
      </Menu>
    </Root>
  );
};

const PREFIX = 'RaLocalesMenuButton';

const Root = styled(Box, {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root
})({});
