import React from 'react';
import { FilterUsers } from './FilterUsers';
import { DateInput, TextInput } from 'react-admin';
import { FilterCountry } from './FilterCountry';

export const InvoiceFilters = [
  <TextInput
    source="serial"
    label="resources.invoicing.invoices.filters.serialCode"
  />,
  <DateInput
    alwaysOn
    source="fromDate"
    label="resources.invoicing.invoices.filters.from"
  />,
  <DateInput
    alwaysOn
    source="toDate"
    label="resources.invoicing.invoices.filters.to"
  />,
  <TextInput
    source="identification"
    label="resources.invoicing.invoices.filters.identification"
  />,
  <FilterUsers
    source="userFilter"
    label="resources.invoicing.invoices.filters.user"
  />,
  <FilterCountry
    source="country"
    label="resources.invoicing.invoices.filters.country"
  />
];
