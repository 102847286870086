import React, { useContext, useEffect, useState } from 'react';
import { usePermissions, useRecordContext } from 'react-admin';
import { Views } from 'react-big-calendar/dist/react-big-calendar.esm';
import { ScheduleShared } from '../../../../../components/shared/schedule/ScheduleShared';
import {
  generateCalendarEventsFromBookings,
  getFirstMillisOfView,
  getLastMillisOfView
} from '../../../../../components/shared/schedule/schedule.utils';
import {
  PRIVILEGE_ADMIN,
  PRIVILEGE_CLUB,
  PRIVILEGE_TEACHER
} from '../../../../../core/constants/privilege.constants';
import { UserHashContext } from '../../../../../core/context/userHashContext';
import { User } from '../../../../../core/models/user/user';
import { getUserEventBookings } from '../../../../../core/services/user-api.service';
import { getCurrentDate } from '../../../../../core/utils/date.utils';

export const UserSchedule = () => {
  const { userHash } = useContext(UserHashContext);
  const [date, setDate] = useState(getCurrentDate());
  const [view, setView] = useState(Views.WEEK);
  const permissions = usePermissions();
  const isClub = permissions.permissions === PRIVILEGE_CLUB;
  const isTeacher = permissions.permissions === PRIVILEGE_TEACHER;
  const isAdmin = permissions.permissions === PRIVILEGE_ADMIN;
  const record: User = useRecordContext();
  const isRecordClub = record?.isClub;
  const [refresh, setRefresh] = useState(false);
  const [bookings, setBookings] = useState([]);
  const [openBookingDialog, setOpenBookingDialog] = useState(false);

  useEffect(() => {
    getUserEventBookings(
      record?.id,
      getFirstMillisOfView(date, view),
      getLastMillisOfView(date, view),
      getParentId()
    ).then((data) => {
      const bookings = generateCalendarEventsFromBookings(
        data.data,
        isClub || isAdmin
      );
      setBookings(bookings);
      setRefresh(false);
    }); // eslint-disable-next-line
  }, [record?.id, date, view, openBookingDialog, refresh]); // eslint-disable-line react-hooks/exhaustive-deps

  const getParentId = () => {
    if (isClub) {
      return userHash;
    }

    if (isTeacher) {
      return userHash;
    }

    if (isRecordClub) {
      return record.id;
    }

    return null;
  };

  return (
    <>
      <ScheduleShared
        date={date}
        setDate={setDate}
        bookings={bookings}
        view={view}
        setView={setView}
        openBookingDialog={openBookingDialog}
        setOpenBookingDialog={setOpenBookingDialog}
        setRefresh={setRefresh}
      />
    </>
  );
};
