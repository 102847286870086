import React from 'react';
import { useTranslate } from 'react-admin';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { BookingOverview } from '../bookings/BookingOverview';
import { UserFavouriteSports } from '../userFavouriteSports/UserFavouriteSports';
import { UserSchedule } from '../userSchedule/UserSchedule';
import { UserView } from '../userView/Userview';
import { UserVoucherOverview } from '../userVouchers/UserVoucherOverview';

export const ClubStudentTabList = ({ handleClickActiveTab }) => {
  const translate = useTranslate();
  return (
    <Tabs className={'user-tabs-list'}>
      <TabList>
        <Tab
          onClick={() =>
            handleClickActiveTab('resources.users.tabs.generalData')
          }
        >
          {translate('resources.users.tabs.generalData')}
        </Tab>
        <Tab
          onClick={() => handleClickActiveTab('resources.users.tabs.schedule')}
        >
          {translate('resources.users.tabs.schedule')}
        </Tab>
        <Tab
          onClick={() =>
            handleClickActiveTab('resources.users.tabs.historical')
          }
        >
          {translate('resources.users.tabs.historical')}
        </Tab>
        <Tab
          onClick={() => handleClickActiveTab('resources.users.tabs.vouchers')}
        >
          {translate('resources.users.tabs.vouchers')}
        </Tab>
        <Tab
          onClick={() =>
            handleClickActiveTab('resources.users.tabs.favouriteSports')
          }
        >
          {translate('resources.users.tabs.favouriteSports')}
        </Tab>
      </TabList>
      <TabPanel>
        <UserView />
      </TabPanel>
      <TabPanel>
        <UserSchedule />
      </TabPanel>
      <TabPanel>
        <BookingOverview />
      </TabPanel>
      <TabPanel>
        <UserVoucherOverview />
      </TabPanel>
      <TabPanel>
        <UserFavouriteSports />
      </TabPanel>
    </Tabs>
  );
};
