import Box from '@mui/material/Box';
import React from 'react';
import {
  DateField,
  FunctionField,
  Loading,
  NumberField,
  Show,
  SimpleShowLayout,
  TextField,
  useGetOne,
  usePermissions,
  useTranslate
} from 'react-admin';
import { useParams } from 'react-router-dom';
import {
  PRIVILEGE_CLUB,
  PRIVILEGE_TEACHER
} from '../../../../core/constants/privilege.constants';
import { GlobalOperation } from '../../../../core/models/globalOperation/globalOperation';
import { LessonType } from '../../../../core/models/lesson/lessonType';
import { formatDecimal } from '../../../../core/utils/number-utils';
import { GlobalOperationActions } from './GlobalOperationActions';

export const GlobalOperationView = () => {
  const translate = useTranslate();
  const params = useParams();
  const { data, isLoading } = useGetOne('global-operations', { id: params.id });
  const permissions = usePermissions();
  const isTeacher = permissions.permissions === PRIVILEGE_TEACHER;
  const isClub = permissions.permissions === PRIVILEGE_CLUB;

  const getPrice = (price: number) => {
    return price
      ? `${formatDecimal(price)} ${translate(
          'resources.invoicing.globalOperations.list.currency'
        )}`
      : '-';
  };

  const getLessonType = (lessonType: LessonType) => {
    return lessonType ? lessonType.name : '-';
  };

  if (isLoading) return <Loading />;

  const getTeacherName = (record: GlobalOperation) => {
    if (record.teacher) {
      return `${record.teacher.firstName} ${
        record.teacher.lastName ? record.teacher.lastName : ''
      }`;
    }

    return '-';
  };

  return (
    <Show resource="global-operations">
      <Box sx={{ padding: '40px' }} className="showtext">
        <SimpleShowLayout>
          <TextField
            source="serialCode"
            label={'resources.invoicing.globalOperations.list.serialCode'}
            emptyText="-"
          />
          <TextField
            source="paymentIntentId"
            label={'resources.invoicing.globalOperations.list.stripeId'}
            emptyText={'resources.invoicing.globalOperations.pendingPayment'}
          />
          <FunctionField
            variant="h5"
            render={(record: GlobalOperation) =>
              record.type?.toLowerCase() ===
              translate(
                'resources.invoicing.globalOperations.types.voucher'
              ).toLowerCase()
                ? record.voucher.title
                : record.title
            }
            emptyText="-"
            label="resources.invoicing.globalOperations.list.title"
          />
          <TextField
            variant="h6"
            source="sport.name"
            emptyText="-"
            label="resources.invoicing.globalOperations.list.sport"
          />
          {!isClub && (
            <TextField
              variant="h6"
              source="club.firstName"
              label="resources.invoicing.globalOperations.list.club"
              emptyText="-"
            />
          )}
          <FunctionField
            label="resources.invoicing.globalOperations.list.teacher"
            render={(record: GlobalOperation) => getTeacherName(record)}
            sortBy={'teacherName'}
          />
          <FunctionField
            label="resources.invoicing.globalOperations.list.student"
            render={(record: GlobalOperation) =>
              `${record.user?.firstName} ${record.user?.lastName || ''}`
            }
            sortBy={'user.firstName'}
          />
          <FunctionField
            variant="h6"
            label="resources.users.lessons.list.lessonType"
            render={(record: GlobalOperation) =>
              getLessonType(record?.lessonType)
            }
          />
          <NumberField
            source="numberOfStudents"
            label="resources.invoicing.globalOperations.list.numberOfStudents"
            emptyText="-"
          />
          <DateField
            source="bookingDate"
            label={'resources.invoicing.globalOperations.list.bookingDate'}
            emptyText="-"
          />
          <DateField
            source="lessonDate"
            label={'resources.invoicing.globalOperations.list.lessonDate'}
            emptyText="-"
          />
          <TextField
            variant="h6"
            source="type"
            emptyText="-"
            label="resources.invoicing.globalOperations.list.type"
          />
          <FunctionField
            variant="h6"
            label="resources.invoicing.globalOperations.list.price"
            render={(record: GlobalOperation) => getPrice(record.price)}
          />
          <FunctionField
            label={'resources.invoicing.globalOperations.list.fee'}
            render={(record: GlobalOperation) =>
              getPrice(
                isClub || isTeacher ? record.teacherFee : record.feeImport
              )
            }
            sortBy={'teacherFee'}
          />
          {data?.bookingHash && (
            <FunctionField
              variant="h6"
              label="resources.invoicing.globalOperations.list.status"
              render={(record: GlobalOperation) => {
                return record.bookingStatus?.name;
              }}
            />
          )}
          <DateField
            source="paymentDate"
            label={'resources.invoicing.globalOperations.list.paymentDate'}
            emptyText="-"
            sortBy={'paymentDate'}
          />
          {!(isClub || isTeacher) && (
            <FunctionField
              label="resources.invoicing.globalOperations.list.teacherFee"
              render={(record: GlobalOperation) => getPrice(record.teacherFee)}
              sortBy={'feeImport'}
            />
          )}
          {!(isClub || isTeacher) && (
            <FunctionField
              label="resources.invoicing.globalOperations.list.teacherPayment"
              render={(record: GlobalOperation) =>
                getPrice(record.teacherPayment)
              }
              sortBy={'teacherPayment'}
            />
          )}
          <FunctionField
            label="resources.invoicing.globalOperations.list.publicPrice"
            render={(record: GlobalOperation) => getPrice(record.publicPrice)}
            sortBy={'amount'}
          />
          <GlobalOperationActions />
        </SimpleShowLayout>
      </Box>
    </Show>
  );
};
