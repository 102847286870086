import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import React from 'react';
import { UserView } from '../userView/Userview';
import { UserCalendar } from '../userCalendar/UserCalendar';
import { CourseOverview } from '../courses/CourseOverview';
import { LessonOverview } from '../lessons/LessonOverview';
import { UserSchedule } from '../userSchedule/UserSchedule';
import { useTranslate } from 'react-admin';

export const ClubTeachersTabList = ({ handleClickActiveTab }) => {
  const translate = useTranslate();

  return (
    <Tabs className={'user-tabs-list'}>
      <TabList>
        <Tab
          onClick={() => handleClickActiveTab('resources.users.tabs.schedule')}
        >
          {translate('resources.users.tabs.schedule')}
        </Tab>
        <Tab
          onClick={() => handleClickActiveTab('resources.users.tabs.calendar')}
        >
          {translate('resources.users.tabs.calendar')}
        </Tab>

        <Tab onClick={() => handleClickActiveTab('resources.users.tabs.class')}>
          {translate('resources.users.tabs.class')}
        </Tab>
        <Tab
          onClick={() =>
            handleClickActiveTab('resources.users.tabs.annualCourses')
          }
        >
          {translate('resources.users.tabs.annualCourses')}
        </Tab>
        <Tab
          onClick={() => handleClickActiveTab('resources.users.tabs.courses')}
        >
          {translate('resources.users.tabs.courses')}
        </Tab>
        <Tab
          onClick={() =>
            handleClickActiveTab('resources.users.tabs.generalData')
          }
        >
          {translate('resources.users.tabs.generalData')}
        </Tab>
      </TabList>
      <TabPanel>
        <UserSchedule />
      </TabPanel>
      <TabPanel>
        <UserCalendar />
      </TabPanel>
      <TabPanel>
        <LessonOverview isClub={false} />
      </TabPanel>
      <TabPanel>
        <CourseOverview smallCourses={false} isClub={false} />
      </TabPanel>
      <TabPanel>
        <CourseOverview smallCourses={true} isClub={false} />
      </TabPanel>
      <TabPanel>
        <UserView />
      </TabPanel>
    </Tabs>
  );
};
