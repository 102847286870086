import { Loading, SelectInput } from 'react-admin';
import React, { useEffect, useState } from 'react';
import { getUsers } from '../../../../../core/services/user-api.service';

export const FilterUsers = (props) => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    getUsers(0, 0, 'id', true, false).then((teachers) => {
      getUsers(0, 0, 'id', false, true).then((clubs) => {
        setUsers(teachers.data.concat(clubs.data));
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (users?.length === 0) return <Loading />;

  users.sort((a, b) => a.firstName.localeCompare(b.firstName));

  return (
    <SelectInput
      source={props.source}
      optionText="firstName"
      choices={users}
      label={props.label}
    ></SelectInput>
  );
};
