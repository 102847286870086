import React from 'react';
import { CalendarAvailableDialogSimple } from './calendarAvailableDialogSimple/CalendarAvailableDialogSimple';
import { CalendarAvailableDialogGrouped } from './calendarAvailableDialogGrouped/CalendarAvailableDialogGrouped';

export const CalendarAvailableDialog = (props) => {
  return (
    <>
      {props.event.group.length > 1 ? (
        <CalendarAvailableDialogGrouped
          event={props.event}
          setOpenDialog={props.setOpenDialog}
          openDialog={props.openDialog}
          setRefresh={props.setRefresh}
        ></CalendarAvailableDialogGrouped>
      ) : (
        <CalendarAvailableDialogSimple
          setOpenDialog={props.setOpenDialog}
          openDialog={props.openDialog}
          event={props.event}
          setRefresh={props.setRefresh}
        ></CalendarAvailableDialogSimple>
      )}
    </>
  );
};
