import React from 'react';
import { useRecordContext } from 'react-admin';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton } from '@mui/material';
import { DialogDeleteCourse } from './DialogDeleteCourse';
import { Course } from '../../../../core/models/course/course';

export const CourseDeleteButton = (props) => {
  const record: Course = useRecordContext(props);
  const [showDeleteDialog, setShowDeleteDialog] = React.useState(false);

  const handleDeleteCourse = () => setShowDeleteDialog(true);

  return (
    <>
      <IconButton onClick={() => handleDeleteCourse()}>
        <DeleteIcon />
      </IconButton>
      <DialogDeleteCourse
        showDeleteDialog={showDeleteDialog}
        setShowDeleteDialog={setShowDeleteDialog}
        id={record.id}
        smallCourses={props.smallCourses}
      />
    </>
  );
};
