import React, { useEffect, useState } from 'react';
import { Notification } from 'react-admin';
import toast, { Toaster } from 'react-hot-toast';
import { onMessageListener } from '../../../firebase';

export const CustomNotification = (props) => {
  const [notification, setNotification] = useState({ title: '', body: '' });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const notify = () => toast(<ToastDisplay />, { duration: 5000 });
  useEffect(() => {
    if (notification?.title) {
      notify();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notification]);

  onMessageListener().then((payload: any) => {
    setNotification({
      title: payload?.notification?.title,
      body: payload?.notification?.body
    });
  });

  function ToastDisplay() {
    return (
      <div>
        <p>
          <b>{notification?.title}</b>
        </p>
        <p>{notification?.body}</p>
      </div>
    );
  }

  if (notification?.title) {
    return <Toaster />;
  }

  return <Notification autoHideDuration={10000} {...props} />;
};

export default CustomNotification;
