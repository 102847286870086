import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography
} from '@mui/material';
import React, { Fragment, useState } from 'react';
import {
  useGetList,
  usePermissions,
  useRecordContext,
  useTranslate
} from 'react-admin';
import {
  PRIVILEGE_ADMIN,
  PRIVILEGE_TEACHER
} from '../../../../../core/constants/privilege.constants';
import { getTimeDetail } from '../../../../../core/utils/time-utils';
import { Button } from '../../../buttons/Button';
import { DialogCancelBooking } from '../DialogCancelBooking';

export const ScheduleBookingDialogSimple = (props) => {
  const translate = useTranslate();
  const record = useRecordContext();
  const lessonTypes = useGetList('lessons/lesson-types');
  const [showCancelDialog, setShowCancelDialog] = useState(false);
  const permissions = usePermissions();

  const isAdmin = permissions.permissions === PRIVILEGE_ADMIN;
  const isTeacher = permissions.permissions === PRIVILEGE_TEACHER;

  const formattedStart = props.booking.start.toLocaleString();
  const formattedEnd = props.booking.end.toLocaleString();

  const lesson = props.booking?.group[0]?.event?.lessonEvent?.lesson ?? null;

  const onCloseDialog = () => {
    props.setOpenDialog(false);
  };

  const onCancelBooking = () => {
    setShowCancelDialog(true);
  };

  const getLessonType = () => {
    const lessonType = lessonTypes.data
      ? lessonTypes.data.find((lt) => lt.id === lesson?.lessonType)
      : null;

    return lessonType ? lessonType.name : '-';
  };

  const getDuration = () => {
    const { hours, minutes } = getTimeDetail(props.booking.duration);
    return `${hours}h ${minutes}m`;
  };

  const totalAssistants = props?.booking?.group[0]?.numberOfStudents;

  return (
    <>
      <Dialog fullWidth open={props.openDialog} onClose={onCloseDialog}>
        <DialogTitle>{props.booking.modalTitle}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={5} md={5}>
              {/* Detalles del evento */}
              <Typography fontWeight="bold">
                {translate('resources.schedule.dialog.event.user')}
              </Typography>
              <Typography>
                {`${props.booking.user?.firstName} ${
                  props.booking.user?.lastName ?? ''
                }`}
              </Typography>
              <Typography fontWeight="bold">
                {translate('resources.schedule.dialog.event.start')}
              </Typography>
              <Typography>{formattedStart.slice(0, -3)}</Typography>
              <Typography fontWeight="bold">
                {translate('resources.schedule.dialog.event.end')}
              </Typography>
              <Typography>{formattedEnd.slice(0, -3)}</Typography>
              {!!props.booking.duration && (
                <>
                  <Typography fontWeight="bold">
                    {translate('resources.schedule.dialog.event.duration')}
                  </Typography>
                  <Typography>{getDuration()}</Typography>
                </>
              )}
              {props.booking.room && (
                <>
                  <Typography fontWeight="bold">
                    {translate('resources.schedule.dialog.event.place')}
                  </Typography>
                  <Typography>{props.booking.room.name}</Typography>
                </>
              )}
              {isAdmin && record.isClub && (
                <>
                  <Typography fontWeight="bold">
                    {translate('resources.schedule.dialog.event.club')}
                  </Typography>
                  <Typography>
                    {record.firstName} {record.lastName}
                  </Typography>
                </>
              )}
              {!isTeacher && (
                <>
                  <Typography fontWeight="bold">
                    {translate('resources.schedule.dialog.event.teacher')}
                  </Typography>
                  <Typography>{props.booking.teacherName}</Typography>
                </>
              )}
              {lesson && props.booking.lessonHash && (
                <>
                  <Typography fontWeight="bold">
                    {translate('resources.schedule.dialog.event.lessonType')}
                  </Typography>
                  <Typography>{getLessonType()}</Typography>
                </>
              )}
            </Grid>
            {props?.booking?.group[0]?.bookingTargets &&
              props?.booking?.group[0]?.bookingTargets?.length > 0 && (
                <Grid item xs={5} md={5}>
                  <Typography variant="h6" fontWeight="bold">
                    ({totalAssistants ?? 0}){' '}
                    {translate('resources.schedule.dialog.event.assistants')}
                  </Typography>
                  {props?.booking?.group[0]?.bookingTargets?.map(
                    (target) =>
                      target.name && (
                        <Fragment key={target.hash}>
                          <Typography>
                            - {target.name ?? '-'}{' '}
                            {target.age ? `(${target.age})` : ''}
                          </Typography>
                        </Fragment>
                      )
                  )}
                </Grid>
              )}
          </Grid>
          {/* Botón de cancelar */}
          <Button
            onClick={onCancelBooking}
            label={'resources.schedule.dialog.actions.cancel'}
          />
        </DialogContent>
      </Dialog>
      {/* Diálogo de cancelación */}
      <DialogCancelBooking
        booking={props.booking}
        showCancelDialog={showCancelDialog}
        setShowCancelDialog={setShowCancelDialog}
        setOpenScheduleBookingDialog={props.setOpenDialog}
        setRefresh={props.setRefresh}
      />
    </>
  );
};
