import React from 'react';
import {
  useListContext,
  useNotify,
  useRecordContext,
  useRefresh
} from 'react-admin';
import { IconButton } from '@mui/material';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { UserPaymentMethod } from '../../../../../../core/models/paymentMethod/userPaymentMethod';
import { updateFavourite } from '../../../../../../core/services/paymentMethod-api.service';
import { DialogCantUpdateStatusUserPaymentMethod } from './DialogCantUpdateStatusUserPaymentMethod';

export const UserPaymentFavoriteButton = (props) => {
  const record: UserPaymentMethod = useRecordContext(props);
  const notify = useNotify();
  const refresh = useRefresh();
  const list = useListContext();
  const [showCantUpdateStatusDialog, setShowCantUpdateStatusDialog] =
    React.useState(false);

  const handleClick = () => {
    if (list.data.length === 1 && record.favourite) {
      setShowCantUpdateStatusDialog(true);
    }

    if (!record.favourite) {
      updateFavourite(record.id).then(() => {
        notify(
          'resources.users.userPaymentMethods.notifications.favourite.success',
          {
            type: 'success'
          }
        );
        refresh();
      });
    }
  };

  return (
    <>
      <IconButton onClick={() => handleClick()}>
        {record.favourite ? (
          <FavoriteIcon
            style={list.data.length === 1 ? { opacity: 0.5 } : {}}
          />
        ) : (
          <FavoriteBorderIcon />
        )}
      </IconButton>
      <DialogCantUpdateStatusUserPaymentMethod
        showCantUpdateStatusDialog={showCantUpdateStatusDialog}
        setShowCantUpdateStatusDialog={setShowCantUpdateStatusDialog}
      />
    </>
  );
};
