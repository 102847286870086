import Box from '@mui/material/Box';
import React from 'react';
import { useTranslate } from 'react-admin';
import { Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Button } from '../shared/buttons/Button';
export const EmptyList = (props) => {
  const translate = useTranslate();
  const navigate = useNavigate();
  const goToCreate = () => {
    if (props.setSection && props.sectionCreate) {
      props.setSection(props.sectionCreate);
    }

    if (props.route) {
      navigate(props.route);
    }
  };
  return (
    <Box textAlign="center" m={1} className="empty">
      <Typography variant="h4" paragraph>
        {translate(
          props.message ? props.message : 'resources.list.empty.title'
        )}
      </Typography>
      {props.buttonLabel && (
        <Button onClick={goToCreate} label={props.buttonLabel}></Button>
      )}
    </Box>
  );
};
