import React from 'react';
import { required, TextInput } from 'react-admin';
import { InputImageField } from '../../../../components/shared/form/inputImageField/InputImageField';
import { ColorInput } from 'react-admin-color-picker';
import { useFormContext } from 'react-hook-form';

export const SportsForm = () => {
  const { watch, getValues } = useFormContext();

  const iconRequired = () => {
    if (getValues('icon') === null) {
      return [required()];
    }
  };

  return (
    <>
      <TextInput
        source="name"
        label="resources.configuration.sports.fields.name"
        validate={[required()]}
      />
      <div className={'color-picker-wrapper'}>
        <ColorInput
          source="color"
          label="resources.configuration.sports.fields.color"
          className={'color-picker'}
          validate={[required()]}
        />
        <div
          style={{ backgroundColor: watch('color'), width: 20, height: 20 }}
          className={'color-picker-preview'}
        ></div>
      </div>
      <InputImageField
        source="icon"
        label="resources.configuration.sports.fields.icon"
        validate={iconRequired()}
      />
    </>
  );
};
