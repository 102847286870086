import React from 'react';
import { Button, Toolbar } from 'react-admin';

export const EquipmentRentCalendarEquipmentRentDialogGroupedToolbar = ({
  onDeleteEvent,
  onDeleteSeries,
  group
}) => {
  return (
    <div>
      <Toolbar>
        <Button
          label="ra.action.delete"
          onClick={onDeleteEvent}
          disabled={!group}
        />
        <Button
          label="resources.equipmentRentsCalendar.dialog.delete.actions.deleteSeries"
          onClick={onDeleteSeries}
          disabled={!group}
        />
      </Toolbar>
    </div>
  );
};
