import parse from 'html-react-parser';
import React from 'react';
import {
  DateField,
  FunctionField,
  Show,
  SimpleShowLayout,
  TextField,
  Toolbar
} from 'react-admin';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from '../../../../components/shared/buttons/Button';
import { TermAndCondition } from '../../../../core/models/termAndCondition/termAndCondition';

export const TermsAndConditionsView = () => {
  const params = useParams();
  const navigate = useNavigate();
  const getText = (record: TermAndCondition) => {
    return parse(record.text);
  };

  const goBack = () => {
    navigate(-1);
  };

  return (
    <Show
      resource="terms-and-conditions"
      id={params.id}
      className="show-page--full"
    >
      <SimpleShowLayout>
        <TextField
          source="title"
          label="resources.configuration.termsAndConditions.fields.title"
          emptyText="-"
        />
        <DateField
          source="date"
          label="resources.configuration.termsAndConditions.fields.date"
          emptyText="-"
        />
        <FunctionField
          label="resources.configuration.termsAndConditions.fields.text"
          render={(record) => getText(record)}
        />
      </SimpleShowLayout>
      <Toolbar>
        <Button onClick={() => goBack()} label="ra.action.back" />
      </Toolbar>
    </Show>
  );
};
