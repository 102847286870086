import LinearProgress from '@mui/material/LinearProgress';
import React from 'react';
import { useTranslate } from 'react-admin';
import { UserVoucher } from '../../../../../core/models/userVoucher/userVoucher';

export const UserActiveVouchersListExpandWithPendingLessons = (
  userVoucher: UserVoucher
) => {
  const translate = useTranslate();
  const getPercentage = (userVoucher: UserVoucher) => {
    if (!userVoucher.pendingLessons) {
      return 100;
    }

    return (
      ((userVoucher.voucher.lessonNumber - userVoucher.pendingLessons) /
        userVoucher.voucher.lessonNumber) *
      100
    );
  };

  return (
    <>
      <p>
        <span>{userVoucher.voucher.title}</span>
        <span>
          {' - '}
          {translate('resources.users.userVouchers.list.usedLessons')}
          {userVoucher.voucher.lessonNumber - userVoucher.pendingLessons}
        </span>
        <span>
          {' - '}
          {translate('resources.users.userVouchers.list.pending')}
          {userVoucher.pendingLessons}
        </span>
        <span>
          {' - '}
          {translate('resources.users.userVouchers.list.totalLessons')}
          {userVoucher.voucher.lessonNumber}
        </span>
      </p>

      <LinearProgress
        variant="determinate"
        value={getPercentage(userVoucher)}
      />
    </>
  );
};
