import {
  VALIDATION_IS_GREATHER,
  VALIDATION_MORE_THAN_ZERO,
  VALIDATION_POSITIVE_NUMBER,
  VALIDATION_REQUIRED
} from '../../../../../core/constants/validation.constants';
import {
  formatMillis,
  getCurrentDate,
  getMillis,
  getTimeMillisOfDate
} from '../../../../../core/utils/date.utils';
import { isNumber, isObject } from '../../../../../core/utils/object-utils';

export function EventFormValidator(
  values: any,
  isLesson,
  isAvailability,
  isCourse,
  isSmallCourses,
  isFullLesson,
  slotInfo,
  isClubPermission,
  isTeacher
) {
  const errors: any = {};
  const currentYear = getCurrentDate();
  currentYear.setFullYear(currentYear.getFullYear() + 1);
  const date = formatMillis('yyyy-MM-DD', getMillis(currentYear));

  const endDate = convertToSlotDate(values.endDate, slotInfo.end);
  const startDate = convertToSlotDate(values.startDate, slotInfo.start);

  if (values.category && values.category === isLesson && values.lesson === '') {
    errors.lesson = VALIDATION_REQUIRED;
  }

  if (
    values.category === isLesson ||
    values.category === isAvailability ||
    values.category === isFullLesson
  ) {
    if (!values.timeType) {
      errors.timeType = VALIDATION_REQUIRED;
    }

    if (!values.startDate) {
      errors.startDate = VALIDATION_REQUIRED;
    }

    if (!values.endDate) {
      errors.endDate = VALIDATION_REQUIRED;
    }

    if (values.endDate && values.startDate && endDate < startDate) {
      errors.startDate = VALIDATION_IS_GREATHER;
    }

    if (values.iterationDateEnd && values.iterationDateEnd > date) {
      errors.iterationDateEnd = 'validation.dateOneYearOrLess';
    }
  }

  if (values.category === isCourse || values.category === isSmallCourses) {
    if (!values.title) {
      errors.title = VALIDATION_REQUIRED;
    }

    if (
      values.minimumStudents < 1 &&
      typeof values.minimumStudents == 'number'
    ) {
      errors.minimumStudents = VALIDATION_MORE_THAN_ZERO;
    }

    if (!values.minimumStudents && typeof values.minimumStudents == 'object') {
      errors.minimumStudents = VALIDATION_REQUIRED;
    }

    if (
      values.maximumStudents < 1 &&
      typeof values.maximumStudents == 'number'
    ) {
      errors.maximumStudents = VALIDATION_MORE_THAN_ZERO;
    }

    if (!values.maximumStudents && typeof values.maximumStudents == 'object') {
      errors.maximumStudents = VALIDATION_REQUIRED;
    }

    if (values.minimumStudents > values.maximumStudents) {
      errors.minimumStudents = VALIDATION_IS_GREATHER;
    }

    if (!values.dateStart) {
      errors.dateStart = VALIDATION_REQUIRED;
    }

    if (!values.dateEnd) {
      errors.dateStart = VALIDATION_REQUIRED;
    }

    if (getMillis(values.dateStart) > getMillis(values.dateEnd)) {
      errors.dateStart = VALIDATION_IS_GREATHER;
    }

    if (!values.registrationDeadline) {
      errors.registrationDeadline = VALIDATION_REQUIRED;
    }

    if (!values.dayTypes) {
      errors.dayTypes = VALIDATION_REQUIRED;
    }

    if (getMillis(values.registrationDeadline) > getMillis(values.dateStart)) {
      errors.registrationDeadline = VALIDATION_IS_GREATHER;
    }

    if (
      ((typeof values.timeStart === 'number' && values.timeStart < 0) ||
        !values.timeStart) &&
      typeof values.timeStart !== 'undefined' &&
      values.timeStart < 0
    ) {
      errors.timeStart = VALIDATION_REQUIRED;
    }

    if (
      ((typeof values.timeEnd === 'number' && values.timeStart < 0) ||
        !values.timeEnd) &&
      typeof values.timeEnd !== 'undefined' &&
      values.timeEnd < 0
    ) {
      errors.timeEnd = VALIDATION_REQUIRED;
    }

    if (
      (typeof values.timeStart === 'object' &&
        typeof values.timeEnd === 'object' &&
        getTimeMillisOfDate(values.timeStart) >=
          getTimeMillisOfDate(values.timeEnd)) ||
      (typeof values.timeStart === 'object'
        ? getTimeMillisOfDate(values.timeStart) >= values.timeEnd
        : typeof values.timeEnd === 'object'
        ? values.timeStart >= getTimeMillisOfDate(values.timeEnd)
        : values.timeStart >= values.timeEnd)
    ) {
      errors.timeStart = VALIDATION_IS_GREATHER;
    }

    if (
      values.freeCancellationPeriod < 0 &&
      typeof values.freeCancellationPeriod == 'number'
    ) {
      errors.freeCancellationPeriod = VALIDATION_POSITIVE_NUMBER;
    }

    if (
      !values.freeCancellationPeriod &&
      typeof values.freeCancellationPeriod == 'object'
    ) {
      errors.freeCancellationPeriod = VALIDATION_REQUIRED;
    }

    if (values.uniquePrice < 0 && isNumber(values.uniquePrice)) {
      errors.uniquePrice = VALIDATION_POSITIVE_NUMBER;
    }

    if (values.quarterlyPrice < 0 && isNumber(values.quarterlyPrice)) {
      errors.quarterlyPrice = VALIDATION_POSITIVE_NUMBER;
    }

    if (values.monthlyPrice < 0 && isNumber(values.monthlyPrice)) {
      errors.monthlyPrice = VALIDATION_POSITIVE_NUMBER;
    }

    if (
      values.category === isSmallCourses &&
      !values.annualPrice &&
      isObject(values.annualPrice)
    ) {
      errors.annualPrice = VALIDATION_REQUIRED;
    }

    if (
      values.category === isCourse &&
      !values.uniquePrice &&
      isObject(values.uniquePrice) &&
      !values.quarterlyPrice &&
      isObject(values.quarterlyPrice) &&
      !values.monthlyPrice &&
      isObject(values.monthlyPrice)
    ) {
      errors.uniquePrice = 'validation.onePriceRequired';
      errors.quarterlyPrice = 'validation.onePriceRequired';
      errors.monthlyPrice = 'validation.onePriceRequired';
    }

    if (values.userTypes.length === 0) {
      errors.userTypes = VALIDATION_REQUIRED;
    }
  }

  if (values.category === isFullLesson) {
    if (Array.isArray(values.level)) {
      errors.level = values.level?.map((level) => {
        const levelErrors: any = {};
        if (
          (level != null && level.fromYears > level.toYears) ||
          level.fromYears < 0 ||
          level.toYears < 0
        ) {
          levelErrors.fromYears = VALIDATION_IS_GREATHER;
        }

        if (level.fromYears < 0) {
          levelErrors.fromYears = VALIDATION_MORE_THAN_ZERO;
        }

        if (level.toYears < 0) {
          levelErrors.toYears = VALIDATION_MORE_THAN_ZERO;
        }
        return levelErrors;
      });
    }

    if (Array.isArray(values.price)) {
      errors.price = values.price?.map((price) => {
        const pricesErrors: any = {};

        if (price.price < 0 && typeof price.price == 'number') {
          pricesErrors.price = VALIDATION_POSITIVE_NUMBER;
        }

        return pricesErrors;
      });
    }

    if (values.minimumNotice < 0 && typeof values.minimumNotice == 'number') {
      errors.minimumNotice = VALIDATION_POSITIVE_NUMBER;
    }

    if (!values.minimumNotice && typeof values.minimumNotice == 'object') {
      errors.minimumNotice = VALIDATION_REQUIRED;
    }

    if (!values.title) {
      errors.title = VALIDATION_REQUIRED;
    }

    if (!values.userTypes) {
      errors.userTypes = VALIDATION_REQUIRED;
    }

    if (!values.paymentType) {
      errors.paymentType = VALIDATION_REQUIRED;
    }

    if (!values.lessonType) {
      errors.lessonType = VALIDATION_REQUIRED;
    }

    if (
      !values.freeCancellationPeriod &&
      typeof values.freeCancellationPeriod == 'object'
    ) {
      errors.freeCancellationPeriod = VALIDATION_REQUIRED;
    }

    if (
      values.freeCancellationPeriod < 0 &&
      typeof values.freeCancellationPeriod == 'number'
    ) {
      errors.freeCancellationPeriod = VALIDATION_POSITIVE_NUMBER;
    }

    if (
      values.minimumStudents < 0 &&
      typeof values.minimumStudents == 'number'
    ) {
      errors.minimumStudents = VALIDATION_POSITIVE_NUMBER;
    }

    if (
      values.maximumStudents < 0 &&
      typeof values.maximumStudents == 'number'
    ) {
      errors.maximumStudents = VALIDATION_POSITIVE_NUMBER;
    }

    if (
      (values.minimumNoticeHours <= 0 && values.minimumNoticeDays === null) ||
      (values.minimumNoticeDays <= 0 && values.minimumNoticeHours <= 0)
    ) {
      errors.minimumNoticeHours = VALIDATION_MORE_THAN_ZERO;
    }

    if (values.minimumNoticeHours < 0 && values.minimumNoticeDays !== null) {
      errors.minimumNoticeHours = VALIDATION_POSITIVE_NUMBER;
    }

    if (values.minimumNoticeDays < 0 && values.minimumNoticeHours !== null) {
      errors.minimumNoticeDays = VALIDATION_POSITIVE_NUMBER;
    }

    if (
      values.minimumNoticeDays <= 0 &&
      values.minimumNoticeDays !== null &&
      values.minimumNoticeHours === null
    ) {
      errors.minimumNoticeDays = VALIDATION_MORE_THAN_ZERO;
    }

    if (values.userTypes.length === 0) {
      errors.userTypes = VALIDATION_REQUIRED;
    }
  }

  if (
    values.category === isLesson ||
    values.category === isFullLesson ||
    values.category === isAvailability
  ) {
    if (values.timeIteration < 0) {
      errors.timeIteration = VALIDATION_POSITIVE_NUMBER;
    }

    if (values.iterationEnd != null && values.iterationEnd <= 0) {
      errors.iterationEnd = VALIDATION_MORE_THAN_ZERO;
    }
  }

  if (
    (values.category === isCourse ||
      values.category === isSmallCourses ||
      values.category === isFullLesson) &&
    isClubPermission &&
    !isTeacher
  ) {
    if (!values.userHash) {
      errors.userHash = VALIDATION_REQUIRED;
    }
  }

  if (values.category === isCourse || values.category === isSmallCourses) {
    if (
      values.fromYears > values.toYears ||
      values.fromYears < 0 ||
      values.toYears < 0
    ) {
      errors.fromYears = VALIDATION_IS_GREATHER;
    }

    if (values.fromYears < 0) {
      errors.fromYears = VALIDATION_MORE_THAN_ZERO;
    }

    if (values.toYears < 0) {
      errors.toYears = VALIDATION_MORE_THAN_ZERO;
    }
  }

  return errors;
}

const convertToSlotDate = (date: Date | number, slotDate: Date) => {
  const slotDateCopy = new Date(slotDate);
  if (!date) {
    return slotDateCopy;
  }

  if (typeof date === 'number') {
    date = new Date(date);
  }
  slotDateCopy.setHours(date?.getHours());
  slotDateCopy.setMinutes(date?.getMinutes());

  return slotDateCopy;
};
