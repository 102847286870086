import React, { useState } from 'react';
import { Confirm, useDelete, useNotify } from 'react-admin';

export const DialogDeleteLessonEvent = ({
  showDeleteDialog,
  setShowDeleteDialog,
  event,
  setOpenLessonEventDialog,
  deleteSeries,
  setRefresh
}) => {
  const [erase] = useDelete();
  const notify = useNotify();
  const [loading, setLoading] = useState(false);
  const onCloseDeleteDialog = () => {
    setShowDeleteDialog(false);
    setOpenLessonEventDialog(false);
  };

  const onDeleteLessonEvent = () => {
    if (deleteSeries) {
      onDeleteSeries();
    } else {
      onDeleteEvent();
    }
  };

  const onDeleteSeries = () => {
    setLoading(true);
    erase(
      'lesson-events',
      {
        id: event.lessonEventHash
      },
      {
        onSuccess: () => {
          notify('resources.users.calendar.lessonEvent.delete.success', {
            type: 'success'
          });
          setRefresh(true);
          onCloseDeleteDialog();
          setLoading(false);
        },
        onError(error: any) {
          notify(error.message, { type: 'warning' });
          setLoading(false);
        }
      }
    );
  };

  const onDeleteEvent = () => {
    setLoading(true);
    erase(
      'events',
      {
        id: event.id
      },
      {
        onSuccess: () => {
          notify('resources.users.calendar.lessonEvent.delete.success', {
            type: 'success'
          });
          setRefresh(true);
          onCloseDeleteDialog();
          setLoading(false);
        },
        onError(error: any) {
          notify(error.message, { type: 'warning' });
          setLoading(false);
        }
      }
    );
  };

  return (
    <Confirm
      loading={loading}
      title="resources.users.calendar.lessonEvent.delete.title"
      content="resources.users.calendar.lessonEvent.delete.message"
      onConfirm={onDeleteLessonEvent}
      isOpen={showDeleteDialog}
      onClose={onCloseDeleteDialog}
    />
  );
};
