import React, { useContext, useEffect, useState } from 'react';
import {
  useGetList,
  usePermissions,
  useRecordContext,
  useTranslate
} from 'react-admin';
import { Views } from 'react-big-calendar';
import { CalendarShared } from '../../components/shared/calendar/CalendarShared';
import {
  CalendarEvent,
  generateEventsFromEvents,
  getFirstMillisOfView,
  getLastMillisOfView
} from '../../components/shared/calendar/calendar.utils';
import { PRIVILEGE_CLUB } from '../../core/constants/privilege.constants';
import { HeaderTitleContext } from '../../core/context/headerTitleContext';
import { UserHashContext } from '../../core/context/userHashContext';
import { User } from '../../core/models/user/user';
import { getEvents } from '../../core/services/user-api.service';
import { getCurrentDate } from '../../core/utils/date.utils';

export const CustomCalendar = () => {
  const { setHeaderTitle } = useContext(HeaderTitleContext);
  const { userHash } = useContext(UserHashContext);
  const permissions = usePermissions();
  const isClub = permissions.permissions === PRIVILEGE_CLUB;
  const record: User = useRecordContext();
  const translate = useTranslate();
  const [events, setEvents] = React.useState<CalendarEvent[]>([]);
  const [date, setDate] = useState(getCurrentDate());
  const [view, setView] = useState(Views.WEEK);
  const [openAddEventDialog, setOpenAddEventDialog] = useState(false);
  const [openAvailableDialog, setOpenAvailableDialog] = useState(false);
  const [openLessonEventDialog, setOpenLessonEventDialog] = useState(false);
  const [openCourseEventDialog, setOpenCourseEventDialog] = useState(false);
  const [openEquipmentRentEventDialog, setOpenEquipmentRentEventDialog] =
    useState(false);
  const [refresh, setRefresh] = useState(false);
  const [lessonTypeCode, setLessonTypeCode] = useState(null);
  const [teacher, setTeacher] = useState(null);
  const [room, setRoom] = useState(null);
  const { data: teachers } = useGetList('users', {
    filter: { teachers: true }
  });
  const { data: rooms } = useGetList(`users/${userHash}/rooms`);

  useEffect(() => {
    setHeaderTitle('resources.calendar.header.title');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getEvents(
      userHash,
      getFirstMillisOfView(date, view),
      getLastMillisOfView(date, view),
      isClub ? userHash : null,
      lessonTypeCode,
      teacher,
      room
    ).then((data) => {
      const events = generateEventsFromEvents(
        data.data,
        translate('resources.users.calendar.messages.available'),
        isClub || record?.isClub
      );
      setEvents(events);
      setRefresh(false);
    }); // eslint-disable-next-line
  }, [record?.id, date, view, lessonTypeCode, teacher, room, refresh]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <CalendarShared
      events={events}
      openAddEventDialog={openAddEventDialog}
      setOpenAddEventDialog={setOpenAddEventDialog}
      openAvailableDialog={openAvailableDialog}
      setOpenAvailableDialog={setOpenAvailableDialog}
      openLessonEventDialog={openLessonEventDialog}
      setOpenLessonEventDialog={setOpenLessonEventDialog}
      openCourseEventDialog={openCourseEventDialog}
      setOpenCourseEventDialog={setOpenCourseEventDialog}
      openEquipmentRentEventDialog={openEquipmentRentEventDialog}
      setOpenEquipmentRentEventDialog={setOpenEquipmentRentEventDialog}
      lessonTypeCode={lessonTypeCode}
      setLessonTypeCode={setLessonTypeCode}
      setDate={setDate}
      setView={setView}
      view={view}
      userHash={userHash}
      teachers={teachers}
      setTeacher={setTeacher}
      rooms={rooms}
      setRoom={setRoom}
      fromTabs={false}
      setRefresh={setRefresh}
    />
  );
};
