import { defaultTheme } from 'react-admin';

export const theme = {
  ...defaultTheme,
  sidebar: {
    width: 240,
    closedWidth: 140
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600, //default 600
      md: 900,
      lg: 1200,
      xl: 1536
    }
  },
  components: {
    ...defaultTheme.components,
    RaDatagrid: {
      styleOverrides: {
        root: {
          display: 'flex',
          '& .RaDatagrid-tableWrapper': {
            flex: ' 1 auto',
            width: '100px',
            overflow: 'auto'
          }
        }
      }
    }
  }
};
