import React from 'react';
import { LessonCreateShared } from '../../../../../../components/shared/lessons/lessonCreate/LessonCreateShared';

export const LessonCreate = ({ setSection, sectionList, userHash, isClub }) => {
  return (
    <LessonCreateShared
      setSection={setSection}
      sectionList={sectionList}
      userHash={userHash}
      isClub={isClub}
    />
  );
};
