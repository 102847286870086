import React from 'react';
import {
  SelectInput,
  SimpleForm,
  useListContext,
  useNotify,
  useRefresh,
  useUnselectAll,
  useUpdateMany
} from 'react-admin';
import {
  PAYMENT_REQUEST_STATUS_EMITTED,
  PAYMENT_REQUEST_STATUS_PAID,
  PAYMENT_REQUEST_STATUS_PENDING
} from '../../../../core/constants/paymentRequest.constants';

export const PaymentsRequestUpdateStatusButton = (props) => {
  const { selectedIds } = useListContext();
  const [update] = useUpdateMany();
  const refresh = useRefresh();
  const notify = useNotify();
  const unselectAll = useUnselectAll('payments-request');

  const choices = [
    {
      id: PAYMENT_REQUEST_STATUS_EMITTED,
      name: 'resources.invoicing.paymentRequest.list.emitted'
    },
    {
      id: PAYMENT_REQUEST_STATUS_PENDING,
      name: 'resources.invoicing.paymentRequest.list.pending'
    },
    {
      id: PAYMENT_REQUEST_STATUS_PAID,
      name: 'resources.invoicing.paymentRequest.list.paid'
    }
  ];

  const updateMany = (statusCode: number) => {
    update(
      'payments-request/status',
      { ids: selectedIds, data: { statusCode } },
      {
        onSuccess: () => {
          refresh();
          unselectAll();
          notify(
            'resources.invoicing.paymentRequest.notifications.editStatuses.success',
            {
              type: 'success'
            }
          );
        }
      }
    );
  };

  return (
    <SimpleForm toolbar={false} className={'bulk-action-form'}>
      <SelectInput
        source="category"
        label="resources.invoicing.paymentRequest.list.status"
        choices={choices}
        onChange={(e) => updateMany(e.target.value)}
        helperText={false}
      />
    </SimpleForm>
  );
};
