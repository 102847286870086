import { Typography } from '@mui/material';
import React from 'react';
import {
  email,
  Form,
  required,
  TextInput,
  useNotify,
  useTranslate
} from 'react-admin';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from '../components/shared/buttons/Button';
import { recoverPasword } from '../core/services/auth-api.service';
import { getErrorMessageCode } from '../core/utils/error.utils';

export const PasswordRecovery = () => {
  const translate = useTranslate();
  const notify = useNotify();
  const navigate = useNavigate();

  const onRecoveryPassword = (event: FormValues): void => {
    recoverPasword(event.email)
      .then((response) => {
        const sucessKey =
          'resources.users.notifications.passwordRecovery.success';
        notify(sucessKey, { type: 'success' });
        navigate('/login');
      })
      .catch((error) => {
        const code = getErrorMessageCode(error);
        notify(`errors.${code.toLowerCase()}`, { type: 'error' });
      });
  };

  return (
    <main id="pass-recovery">
      <Typography
        component="h1"
        variant="h2"
        align="center"
        color="textPrimary"
        gutterBottom
      >
        {translate('resources.auth.recovery_password.title')}
      </Typography>
      <Typography
        component="span"
        align="center"
        color="textPrimary"
        gutterBottom
      >
        {translate('resources.auth.recovery_password.message')}
        <Link to={'/login'}>
          {translate('resources.auth.recovery_password.link')}
        </Link>
      </Typography>
      <Form onSubmit={onRecoveryPassword} className="form-dark">
        <TextInput source="email" validate={[required(), email()]} />
        <Button
          type="submit"
          color="primary"
          label="resources.auth.recovery_password.button"
        ></Button>
      </Form>
    </main>
  );
};

interface FormValues {
  email?: string;
}
