import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import React, { useState } from 'react';
import { RadioButtonGroupInput, SimpleForm, useTranslate } from 'react-admin';
import { DialogDeleteAvailability } from '../DialogDeleteAvailability';
import { CalendarAvailableDialogGroupedToolbar } from './CalendarAvailableDialogGroupedToolbar';

export const CalendarAvailableDialogGrouped = ({
  setOpenDialog,
  openDialog,
  event,
  setRefresh
}) => {
  const translate = useTranslate();
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [deleteSeries, setDeleteSeries] = useState(false);
  const [group, setGroup] = useState(null);

  const onCloseDialog = () => {
    setOpenDialog(false);
  };

  const onDeleteEvent = () => {
    setDeleteSeries(false);
    setShowDeleteDialog(true);
  };

  const onDeleteSeries = () => {
    setDeleteSeries(true);
    setShowDeleteDialog(true);
  };

  const createChoicesFromGroup = () => {
    const choices = [];
    if (event && Array.isArray(event.group)) {
      event.group.forEach((event) => {
        choices.push({
          id: event?.id,
          name: `${translate(
            'resources.users.calendar.availability.title'
          )} - ${event?.userName} ${event?.userLastName}`
        });
      });
    }
    return choices;
  };

  return (
    <>
      <Dialog fullWidth open={openDialog} onClose={onCloseDialog}>
        <DialogTitle>
          {translate('resources.users.calendar.availability.title')}
        </DialogTitle>
        <DialogContent className="event-dialog">
          <p>
            {translate('resources.users.calendar.availability.start')}
            {event.start.toLocaleString()}
          </p>
          <p>
            {translate('resources.users.calendar.availability.end')}
            {event.end.toLocaleString()}
          </p>
          <SimpleForm
            className={'form'}
            toolbar={
              <CalendarAvailableDialogGroupedToolbar
                onDeleteEvent={onDeleteEvent}
                onDeleteSeries={onDeleteSeries}
                group={group}
              />
            }
          >
            <RadioButtonGroupInput
              label="resources.users.calendar.lessonEvent.group.title"
              source="group"
              choices={createChoicesFromGroup()}
              onChange={(e) => {
                setGroup(e?.target?.value);
              }}
              className="radiogroup radiogroup-modal"
            ></RadioButtonGroupInput>
          </SimpleForm>
        </DialogContent>
      </Dialog>
      <DialogDeleteAvailability
        event={event}
        showDeleteDialog={showDeleteDialog}
        setShowDeleteDialog={setShowDeleteDialog}
        setOpenAvailableDialog={setOpenDialog}
        deleteSeries={deleteSeries}
        setRefresh={setRefresh}
      />
    </>
  );
};
