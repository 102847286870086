import React from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { useTranslate } from 'react-admin';
import {
  PAYMENT_REQUEST_STATUS_EMITTED,
  PAYMENT_REQUEST_STATUS_PAID,
  PAYMENT_REQUEST_STATUS_PENDING
} from '../../../core/constants/paymentRequest.constants';
import { PaymentsList } from './paymentsList/PaymentsList';

export const PaymentsOverView = () => {
  const translate = useTranslate();

  return (
    <>
      <Tabs>
        <TabList>
          <Tab>{translate('resources.invoicing.payments.tabs.pending')}</Tab>
          <Tab>{translate('resources.invoicing.payments.tabs.emitted')}</Tab>
          <Tab>{translate('resources.invoicing.payments.tabs.paid')}</Tab>
        </TabList>
        <TabPanel>
          <PaymentsList status={PAYMENT_REQUEST_STATUS_PENDING} />
        </TabPanel>
        <TabPanel>
          <PaymentsList status={PAYMENT_REQUEST_STATUS_EMITTED} />
        </TabPanel>
        <TabPanel>
          <PaymentsList status={PAYMENT_REQUEST_STATUS_PAID} />
        </TabPanel>
      </Tabs>
    </>
  );
};
