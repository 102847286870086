import React from 'react';
import { ScheduleBookingDialogGrouped } from './scheduleBookingDialogGrouped/ScheduleBookingDialogGrouped';
import { ScheduleBookingDialogSimple } from './scheduleBookingDialogSimple/ScheduleBookingDialogSimple';

export const ScheduleBookingDialog = (props) => {
  return (
    <>
      {props.booking.group.length > 1 ? (
        <ScheduleBookingDialogGrouped
          booking={props.booking}
          setOpenDialog={props.setOpenDialog}
          openDialog={props.openDialog}
          setRefresh={props.setRefresh}
        ></ScheduleBookingDialogGrouped>
      ) : (
        <ScheduleBookingDialogSimple
          setOpenDialog={props.setOpenDialog}
          openDialog={props.openDialog}
          booking={props.booking}
          setRefresh={props.setRefresh}
        ></ScheduleBookingDialogSimple>
      )}
    </>
  );
};
