import React from 'react';

import { SaveButton, Toolbar } from 'react-admin';

export const UserToolbar = () => {
  return (
    <div>
      <Toolbar>
        <SaveButton />
      </Toolbar>
    </div>
  );
};
