import React from 'react';
import { useRecordContext } from 'react-admin';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton } from '@mui/material';
import { DialogDeleteVoucher } from './DialogDeleteVoucher';
import { Voucher } from '../../../../core/models/voucher/voucher';

export const VoucherDeleteButton = (props) => {
  const record: Voucher = useRecordContext(props);
  const [showDeleteDialog, setShowDeleteDialog] = React.useState(false);

  const handleDeleteVoucher = () => setShowDeleteDialog(true);

  return (
    <>
      <IconButton onClick={() => handleDeleteVoucher()}>
        <DeleteIcon />
      </IconButton>
      <DialogDeleteVoucher
        showDeleteDialog={showDeleteDialog}
        setShowDeleteDialog={setShowDeleteDialog}
        id={record.id}
      />
    </>
  );
};
