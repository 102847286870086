import { HeaderTitleContext } from '../../../core/context/headerTitleContext';
import { UserHashContext } from '../../../core/context/userHashContext';
import { CourseListShared } from '../../../components/shared/courses/coursesList/CourseListShared';
import React, { useContext, useEffect } from 'react';
export const CoursesList = (props) => {
  const { setHeaderTitle } = useContext(HeaderTitleContext);
  const { userHash } = useContext(UserHashContext);
  const smallCourses = props.smallCourses ? props.smallCourses : false;

  useEffect(() => {
    setHeaderTitle(
      smallCourses
        ? 'resources.courses.small.header.title'
        : 'resources.courses.yearly.header.title'
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [smallCourses]);
  return (
    <CourseListShared
      userHash={userHash}
      route={'create'}
      smallCourses={smallCourses}
    />
  );
};
