import React from 'react';
import { VoucherListShared } from '../../../../../../components/shared/vouchers/vouchersList/VoucherListShared';

export const VoucherList = ({
  setSection,
  sectionCreate,
  sectionEdit,
  setVoucherToEditId,
  userHash
}) => {
  return (
    <VoucherListShared
      setSection={setSection}
      sectionCreate={sectionCreate}
      sectionEdit={sectionEdit}
      setVoucherToEditId={setVoucherToEditId}
      userHash={userHash}
    />
  );
};
