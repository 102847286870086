import { Button, Card, CardActions, CircularProgress } from '@mui/material';
import Box from '@mui/material/Box';
import React, { useEffect, useState } from 'react';
import {
  BooleanInput,
  email,
  Form,
  Link,
  PasswordInput,
  required,
  TextInput,
  useLogin,
  useNotify,
  useTranslate
} from 'react-admin';
import { useLocalStorage } from '../core/hooks/useLocalStorage';
import { useNavigate } from 'react-router-dom';

export const CustomLoginPage = () => {
  const [loading, setLoading] = useState(false);
  const [remindUser, setRemindUser] = useLocalStorage('remindUser', false);
  const translate = useTranslate();
  const notify = useNotify();
  const login = useLogin();
  const navigate = useNavigate();

  localStorage.setItem('termsAndPrivacyPolicyAccepted', 'false');

  useEffect(() => {
    if (localStorage.getItem('token')) {
      navigate('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = (values: FormValues) => {
    setLoading(true);
    login(values).catch(() => {
      setLoading(false);
      return notify('errors.login');
    });
  };

  return (
    <Form onSubmit={handleSubmit} noValidate className="form-dark">
      <Box
        id="login-box"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
          alignItems: 'center',
          justifyContent: 'flex-start'
        }}
      >
        <Card sx={{ minWidth: 300, marginTop: '6em' }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <div className="logo">
              <img
                src="src/images/logo-nak-sports.png"
                alt="NAK SPORTS"
                width="200"
                height="96"
              />
            </div>
          </Box>
          <Box
            sx={{
              marginTop: '1em',
              display: 'flex',
              justifyContent: 'center',
              color: (theme) => theme.palette.grey[500]
            }}
          ></Box>
          <Box sx={{ padding: '0 1em 1em 1em' }}>
            <Box sx={{ marginTop: '1em' }}>
              <TextInput
                autoFocus
                source="email"
                label={translate('ra.auth.username')}
                disabled={loading}
                validate={[required(), email()]}
                fullWidth
              />
            </Box>
            <Box sx={{ marginTop: '1em' }}>
              <PasswordInput
                label="ra.auth.password"
                source="password"
                disabled={loading}
                validate={required()}
              />
            </Box>
            <Box sx={{ marginTop: '1em' }}>
              <BooleanInput
                value={remindUser}
                label="resources.auth.remind_user"
                source="remindUser"
                onChange={(event) => {
                  setRemindUser(event.target.checked);
                }}
              />
            </Box>
          </Box>
          <CardActions sx={{ padding: '0 1em 1em 1em' }}>
            <Button
              variant="contained"
              type="submit"
              color="primary"
              disabled={loading}
              fullWidth
            >
              {loading && <CircularProgress size={25} thickness={2} />}
              {translate('ra.auth.sign_in')}
            </Button>
          </CardActions>
          <CardActions sx={{ padding: '0 1em 1em 1em' }}>
            <Link type="button" color="secondary" to={'/password-recovery'}>
              {translate('resources.auth.recovery_password_button')}
            </Link>
          </CardActions>
          <CardActions sx={{ padding: '0 1em 1em 1em' }}>
            <Link type="button" color="primary" to={'/register'}>
              {translate('resources.auth.recovery_password.register')}
            </Link>
          </CardActions>
        </Card>
      </Box>
    </Form>
  );
};

interface FormValues {
  email?: string;
  password?: string;
  remindUser?: boolean;
}
