import {
  DateInput,
  ReferenceInput,
  required,
  SelectInput,
  TextInput
} from 'react-admin';
import React from 'react';
import { FilterBookingStatus } from './FilterBookingStatus';

export const globalOperationTeacherFilters = [
  <TextInput
    source="q"
    label="resources.invoicing.globalOperations.filters.searchTerm"
  />,
  <DateInput
    alwaysOn
    source="fromDate"
    label="resources.invoicing.invoices.filters.from"
  />,
  <DateInput
    alwaysOn
    source="toDate"
    label="resources.invoicing.invoices.filters.to"
  />,
  <ReferenceInput
    source="sport"
    reference="sports"
    label="resources.invoicing.globalOperations.filters.sport"
    sort={{ field: 'name', order: 'ASC' }}
  >
    <SelectInput
      optionText="name"
      label="resources.invoicing.globalOperations.filters.sport"
      resource="sport"
    ></SelectInput>
  </ReferenceInput>,
  <ReferenceInput
    source="club"
    reference="users"
    filter={{ clubs: true }}
    label="resources.invoicing.globalOperations.filters.club"
    sort={{ field: 'firstName', order: 'ASC' }}
  >
    <SelectInput
      optionText="firstName"
      label="resources.invoicing.globalOperations.filters.club"
    ></SelectInput>
  </ReferenceInput>,
  <ReferenceInput
    source="user"
    reference="users"
    filter={{ students: true }}
    perPage={9999999}
    label="resources.invoicing.globalOperations.filters.user"
    sort={{ field: 'firstName', order: 'ASC' }}
  >
    <SelectInput
      optionText={(record: any) =>
        `${record.firstName} ${record.lastName || ''}`
      }
      label="resources.invoicing.globalOperations.filters.user"
    ></SelectInput>
  </ReferenceInput>,
  <ReferenceInput
    source="lessonType"
    reference="lessons/lesson-types"
    sort={{ field: 'name', order: 'ASC' }}
    label="resources.invoicing.globalOperations.filters.lessonType"
  >
    <SelectInput
      optionText="name"
      source="lessonType"
      defaultValue=""
      validate={[required()]}
      label="resources.invoicing.globalOperations.filters.lessonType"
    />
  </ReferenceInput>,
  <FilterBookingStatus
    source="bookingStatus"
    label="resources.invoicing.globalOperations.filters.bookingStatus"
  />
];
