import { createContext, Dispatch, SetStateAction } from 'react';

interface UserHashContextInterface {
  userHash: string;
  setUserHash: Dispatch<SetStateAction<string>>;
}

export const userHashContextDefaultValue: UserHashContextInterface = {
  userHash: '',
  setUserHash: () => ''
};
export const UserHashContext = createContext<UserHashContextInterface>(
  userHashContextDefaultValue
);
