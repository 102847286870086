import React from 'react';
import { LessonListShared } from '../../../../../../components/shared/lessons/lessonsList/LessonListShared';

export const LessonsList = ({
  setSection,
  sectionCreate,
  sectionEdit,
  setLessonToEditId,
  userHash,
  isClub
}) => {
  return (
    <LessonListShared
      setSection={setSection}
      sectionCreate={sectionCreate}
      sectionEdit={sectionEdit}
      setLessonToEditId={setLessonToEditId}
      userHash={userHash}
      isClub={isClub}
    />
  );
};
