import React, { useContext, useEffect } from 'react';
import { Create, SimpleForm, useCreate, useNotify } from 'react-admin';
import { useNavigate } from 'react-router-dom';
import { HeaderTitleContext } from '../../../../core/context/headerTitleContext';
import { getMillis } from '../../../../core/utils/date.utils';
import { PrivacyPoliciesForm } from '../privacyPoliciesForm/PrivacyPoliciesForm';
import { PrivacyPoliciesFormToolbar } from '../privacyPoliciesForm/PrivacyPoliciesFormToolbar';

export const PrivacyPoliciesCreate = () => {
  const { setHeaderTitle } = useContext(HeaderTitleContext);
  const [create, { isLoading, isError }] = useCreate();
  const notify = useNotify();
  const navigate = useNavigate();
  useEffect(() => {
    setHeaderTitle('resources.configuration.header.title');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const termsAndConditionsSave = (data) => {
    data['date'] = getMillis(data['date']);

    create(
      'privacy-policies',
      { data },
      {
        onSuccess: () => {
          notify('resources.configuration.privacyPolicies.create.success', {
            type: 'success'
          });
          navigate(-1);
        },
        onError(error: any) {
          notify(error.message, { type: 'warning' });
        }
      }
    );
  };
  return (
    <Create resource="privacyPolicies">
      <SimpleForm
        onSubmit={termsAndConditionsSave}
        toolbar={
          <PrivacyPoliciesFormToolbar isLoading={isLoading} isError={isError} />
        }
        className={'form'}
      >
        <PrivacyPoliciesForm />
      </SimpleForm>
    </Create>
  );
};
