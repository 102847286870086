export function isValidDate(date: any) {
  return date instanceof Date && !!date.getTime();
}

export function isValidEmail(email: string) {
  if (email) {
    const matchLenght = email.match(
      new RegExp(/^(\d|\D){1,100}@(\d|[a-zA-Z]){1,100}[.]([a-zA-Z0-9]){1,63}$/i)
    )?.length;
    if (matchLenght) {
      return matchLenght > 0;
    }
    return false;
  }
  return false;
}

export function passWordValidation(values: any) {
  const errors: any = {};
  if (!values.password) {
    errors.password = 'ra.validation.required';
  }
  if (!values.confirmPassword) {
    errors.confirmPassword = 'ra.validation.required';
  } else if (values.confirmPassword !== values.password) {
    errors.confirmPassword = 'ra.validation.passwordMismatched';
  }

  return errors;
}

export const validateNoSpaces = (value) => {
  if (value && /\s/g.test(value)) {
    // Check if there are any whitespace characters
    return 'validation.noSpaces';
  }
};
