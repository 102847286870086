import React from 'react';
import { Confirm, useNotify, useRedirect, useUpdate } from 'react-admin';
import { useParams } from 'react-router-dom';

export const DialogValidateUser = (props) => {
  const [update] = useUpdate('users');
  const notify = useNotify();
  const redirect = useRedirect();
  const { privilege } = useParams();

  const onCloseUserValidateDialog = () => {
    props.setShowValidateDialog(false);
  };

  const onValidateUser = () => {
    const id = props.record['id'];
    update(
      'users/validate',
      {
        id
      },
      {
        onSuccess: () => {
          notify('resources.users.notifications.userValidate.success', {
            type: 'success'
          });
          onCloseUserValidateDialog();
          redirect(`/${privilege}`);
        },
        onError(error: any) {
          notify(error.message, { type: 'warning' });
        }
      }
    );
  };

  return (
    <Confirm
      title="resources.users.validateModal.title"
      content="resources.users.validateModal.message"
      onConfirm={onValidateUser}
      isOpen={props.showValidateDialog}
      onClose={onCloseUserValidateDialog}
    />
  );
};
