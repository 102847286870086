import React from 'react';
import { Toolbar } from 'react-admin';
import { Button } from '../../../../buttons/Button';

export const CalendarLessonEventDialogGroupedEventToolbar = ({
  onDeleteEvent,
  onDeleteSeries,
  group
}) => {
  return (
    <div>
      <Toolbar>
        <Button
          label="ra.action.delete"
          onClick={onDeleteEvent}
          disabled={!group}
        />
        <Button
          label="resources.users.calendar.lessonEvent.actions.deleteSeries"
          onClick={onDeleteSeries}
          disabled={!group}
        />
      </Toolbar>
    </div>
  );
};
