import React, { useContext, useEffect, useState } from 'react';
import {
  Datagrid,
  DateField,
  FunctionField,
  List,
  Loading,
  TextField,
  TopToolbar,
  useNotify,
  usePermissions,
  useRefresh,
  useTranslate
} from 'react-admin';
import { CustomPagination } from '../../../../components/general/Pagination';
import { Button } from '../../../../components/shared/buttons/Button';
import { PRIVILEGE_ADMIN } from '../../../../core/constants/privilege.constants';
import { HeaderTitleContext } from '../../../../core/context/headerTitleContext';
import { UserHashContext } from '../../../../core/context/userHashContext';
import {
  getUserBalance,
  requestPayment
} from '../../../../core/services/user-api.service';
import { formatDecimal } from '../../../../core/utils/number-utils';
import PaymentRequestView from '../paymentRequestView/PaymentRequestView';
import { PaymentRequestDownloadXMLBulkActionButton } from './PaymentRequestDownloadXMLBulkActionButton';
import { PaymentRequestDownloadXMLButton } from './PaymentRequestDownloadXMLButton';
import { PaymentRequestExport } from './PaymentRequestExport';
import { PaymentRequestFilters } from './PaymentRequestFilters';
import { PaymentsRequestUpdateStatusButton } from './PaymentsRequestUpdateStatusButton';
import { StatusField } from './StatusField';
import UserBalanceOperations from './UserBalanceOperations';

const PaymentBulkActions = () => (
  <>
    <PaymentsRequestUpdateStatusButton />
    <PaymentRequestDownloadXMLBulkActionButton />
  </>
);

export const PaymentRequestList = ({ status }) => {
  const { userHash } = useContext(UserHashContext);
  const [balance, setBalance] = useState(null);
  const notify = useNotify();
  const refresh = useRefresh();
  const { setHeaderTitle } = useContext(HeaderTitleContext);
  const translate = useTranslate();
  const permissions = usePermissions();
  const isAdmin = permissions.permissions === PRIVILEGE_ADMIN;
  const [isLoading, setisLoading] = useState(false);
  const [userBalances, setUserBalances] = useState([]);

  useEffect(() => {
    setHeaderTitle('resources.invoicing.paymentRequest.header.title');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (userHash) {
      getUserBalance(userHash).then((data) => {
        setBalance(data.data.amount);
        setUserBalances(data.data.balances);
      });
    }
  }, [userHash]); // eslint-disable-line react-hooks/exhaustive-deps

  const requestBalance = () => {
    requestPayment(userHash).then(() => {
      notify(
        'resources.invoicing.paymentRequest.notifications.request.success',
        {
          type: 'success'
        }
      );
      getUserBalance(userHash).then((data) => {
        setBalance(data.data.amount);
        setUserBalances(data.data.balances);
      });
      refresh();
    });
  };

  const getBalanceAmount = () => {
    if (balance === null) return 0;
    const amount = balance.toLocaleString();

    return translate(
      'resources.invoicing.paymentRequest.list.balanceAvailable',
      {
        balance: amount
      }
    );
  };

  const getAmount = (record) => {
    return translate('resources.invoicing.paymentRequest.list.currency', {
      value: formatDecimal(record.amount)
    });
  };

  if (!userHash || isLoading) return <Loading />;

  return (
    <>
      {!isAdmin && (
        <div>
          <div>{getBalanceAmount()}</div>
          <UserBalanceOperations userBalances={userBalances} />
          <Button
            label="resources.invoicing.paymentRequest.list.paymentRequestButton"
            onClick={requestBalance}
            disabled={balance === 0}
          />
        </div>
      )}

      <List
        resource={
          isAdmin ? 'payments-request' : `users/${userHash}/payments-request`
        }
        sort={{ field: 'requestDate', order: 'DESC' }}
        exporter={false}
        filter={{ manualRequest: true, status }}
        filters={PaymentRequestFilters}
        actions={
          <TopToolbar>
            <PaymentRequestExport
              isAdmin={isAdmin}
              userHash={userHash}
              status={status}
              setIsLoading={setisLoading}
            />
          </TopToolbar>
        }
        perPage={Number(process.env.REACT_APP_LIST_SIZE)}
        pagination={<CustomPagination />}
        empty={false}
      >
        <Datagrid
          bulkActionButtons={isAdmin ? <PaymentBulkActions /> : false}
          rowClick="expand"
          expand={<PaymentRequestView isAdmin={isAdmin} />}
        >
          {isAdmin && (
            <TextField
              source="user.firstName"
              sortBy="user.firstName"
              label={'resources.invoicing.paymentRequest.list.user'}
              emptyText="-"
            />
          )}
          <FunctionField
            sortBy="amount"
            label="resources.invoicing.paymentRequest.list.amount"
            render={(record) => (
              <>
                Total: <b>{getAmount(record)}</b>
              </>
            )}
          />
          <DateField
            source="dateRequest"
            sortBy="requestDate"
            label={'resources.invoicing.paymentRequest.list.date'}
            emptyText="-"
          />

          {isAdmin && <StatusField />}
          {!isAdmin && (
            <FunctionField
              sortBy="paymentRequestStatus"
              label="resources.invoicing.paymentRequest.list.paymentRequestStatus"
              render={(record) => record.paymentRequestStatus.name}
            />
          )}
          {isAdmin && <PaymentRequestDownloadXMLButton />}
        </Datagrid>
      </List>
    </>
  );
};
