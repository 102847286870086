import React from 'react';
import { useLocation } from 'react-router-dom';
import { BookingList } from './bookingsList/BookingList';
import { BookingView } from './bookingView/BookingView';

export const BookingOverview = () => {
  const sectionList = 'list';
  const sectionView = 'view';
  const location = useLocation();
  const parts = location.pathname.split('/');
  const userHash = parts.pop();

  const [section, setSection] = React.useState(sectionList);
  const [bookingToEditId, setBookingToEditId] = React.useState(null);

  if (section === sectionList) {
    return (
      <BookingList
        setSection={setSection}
        userHash={userHash}
        sectionView={sectionView}
        setBookingToEditId={setBookingToEditId}
      />
    );
  }

  if (section === sectionView) {
    return (
      <BookingView
        setSection={setSection}
        bookingToEditId={bookingToEditId}
        sectionList={sectionList}
      />
    );
  }
};
