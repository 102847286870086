import React from 'react';
import {
  Datagrid,
  FunctionField,
  List,
  useGetList,
  useTranslate
} from 'react-admin';
import { EmptyList } from '../../../../../../components/general/EmptyList';
import { CustomPagination } from '../../../../../../components/general/Pagination';
import {
  BOOKING_STATUS_ACTIVE,
  BOOKING_STATUS_CANCEL
} from '../../../../../../core/constants/booking.constants';
import {
  ACTIVE_STATUS_COLOR,
  CANCEL_STATUS_COLOR,
  DEFAULT_STATUS_COLOR
} from '../../../../../../core/constants/color.constants';
import {
  getDayTypeNumberFromTimestamp,
  getLocaleFromTimestamp,
  getTimeFromTimestamp,
  millisecondsToTime
} from '../../../../../../core/utils/time-utils';
import { BookingEditButton } from './BookingEditButton';

export const BookingList = ({
  setSection,
  userHash,
  sectionView,
  setBookingToEditId
}) => {
  const translate = useTranslate();
  const getDate = (date) => {
    return getLocaleFromTimestamp(date, {
      year: 'numeric',
      month: 'long',
      day: '2-digit'
    });
  };
  const isSmallCourse = (record) => record.event?.course?.isSmallCourse;

  const getBookingType = (record) => {
    if (record.isCourse) {
      if (isSmallCourse(record)) {
        return translate('resources.users.bookings.list.type.smallCourse');
      }
      return translate('resources.users.bookings.list.type.course');
    }

    if (record.isLesson) {
      return translate('resources.users.bookings.list.type.lesson');
    }

    if (record.isEquipmentRent) {
      return translate('resources.users.bookings.list.type.equipmentRent');
    }
  };

  const { data: dayTypes } = useGetList('day-types');

  const getCourseShortDays = (days: number[]) => {
    return days
      ? days
          .map((day) => {
            return dayTypes?.find((dayType) => dayType.id === day).shortName;
          })
          .join('- ')
      : '';
  };

  const getTime = (record) => {
    const start = record.isCourse
      ? millisecondsToTime(record.event.course.timeStart)
      : getTimeFromTimestamp(record.event.startDate);
    const end = record.isCourse
      ? millisecondsToTime(record.event.course.timeEnd)
      : getTimeFromTimestamp(record.event.endDate);
    return `${start} - ${end}`;
  };

  const userRowStyle = (record) => {
    let background = DEFAULT_STATUS_COLOR;
    if (record.bookingStatus?.id === BOOKING_STATUS_ACTIVE) {
      background = ACTIVE_STATUS_COLOR;
    }

    if (record.bookingStatus?.id === BOOKING_STATUS_CANCEL) {
      background = CANCEL_STATUS_COLOR;
    }
    return { backgroundColor: background };
  };

  return (
    <List
      resource={`users/${userHash}/bookings`}
      sort={{ field: 'date', order: 'DESC' }}
      perPage={Number(process.env.REACT_APP_LIST_SIZE)}
      pagination={<CustomPagination />}
      empty={<EmptyList />}
      actions={null}
    >
      <Datagrid
        bulkActionButtons={false}
        rowStyle={userRowStyle}
        className="custom"
      >
        <FunctionField
          label="resources.users.bookings.list.name"
          render={(record) => {
            return record.event.eventTitle;
          }}
        />
        <FunctionField
          label="resources.users.bookings.list.bookingType"
          render={(record) => getBookingType(record)}
        />
        <FunctionField
          label="resources.users.bookings.list.date"
          render={(record) => getDate(record.date)}
          sortBy={'date'}
        />
        <FunctionField
          label="resources.users.bookings.list.startDate"
          render={(record) => getDate(record.startDate)}
          sortBy={'startDate'}
        />
        <FunctionField
          label="resources.users.bookings.list.endDate"
          render={(record) => getDate(record.endDate)}
          sortBy={'event.endDate'}
        />
        <FunctionField
          label="resources.users.bookings.list.weekDay"
          render={(record) =>
            getCourseShortDays(
              record.isCourse
                ? record.event.course.dayTypes
                : [getDayTypeNumberFromTimestamp(record.event.startDate)]
            )
          }
          sortable={false}
        />
        <FunctionField
          label="resources.users.bookings.list.time"
          render={(record) => getTime(record)}
          sortable={false}
        />
        <BookingEditButton
          setSection={setSection}
          sectionView={sectionView}
          setBookingToEditId={setBookingToEditId}
        />
      </Datagrid>
    </List>
  );
};
