import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import React, { useState } from 'react';
import { RadioButtonGroupInput, SimpleForm, useTranslate } from 'react-admin';
import { DeleteSchedulerEquipmentRentDialog } from './DeleteSchedulerEquipmentRentDialog';
import { EquipmentRentSchedulerEquipmentRentDialogGroupedToolbar } from './EquipmentRentSchedulerEquipmentRentDialogGroupedToolbar';

export const EquipmentRentSchedulerEquipmentRentDialogGrouped = (props: {
  booking;
  setOpenDialog;
  openDialog;
  setRefresh;
}) => {
  const translate = useTranslate();
  const [showCancelDialog, setShowCancelDialog] = useState(false);

  const formattedStart = props.booking?.start.toLocaleString();
  const formattedEnd = props.booking?.end.toLocaleString();

  const [group, setGroup] = useState(null);

  const onCloseDialog = () => {
    props.setOpenDialog(false);
  };

  const onDeleteEvent = () => {
    setShowCancelDialog(true);
  };

  const createChoicesFromGroup = () => {
    const choices = [];
    if (props.booking && Array.isArray(props.booking.group)) {
      props.booking.group.forEach((booking) => {
        choices.push({
          id: booking?.id,
          name: `${booking?.event?.eventTitle} - ${booking?.user?.firstName} ${booking?.user?.lastName}`
        });
      });
    }
    return choices;
  };

  const findBookingFromChoice = (id) => {
    if (props.booking.group.length === 0) return null;
    return props.booking.group.find((booking) => booking.id === id);
  };

  return (
    <>
      <Dialog fullWidth open={props.openDialog} onClose={onCloseDialog}>
        <DialogTitle>{props.booking?.modalTitle}</DialogTitle>
        <DialogContent className="event-dialog">
          <p>
            {translate('resources.equipmentRentsScheduler.start')}
            {formattedStart.slice(0, -3)}
          </p>
          <p>
            {translate('resources.equipmentRentsScheduler.end')}
            {formattedEnd.slice(0, -3)}
          </p>
          <SimpleForm
            className={'form'}
            toolbar={
              <EquipmentRentSchedulerEquipmentRentDialogGroupedToolbar
                onDeleteEvent={onDeleteEvent}
                group={group}
              />
            }
          >
            <RadioButtonGroupInput
              label="resources.equipmentRentsScheduler.group.title"
              source="group"
              choices={createChoicesFromGroup()}
              onChange={(e) => {
                setGroup(e?.target?.value);
              }}
              className="radiogroup radiogroup-modal"
            ></RadioButtonGroupInput>
            <DeleteSchedulerEquipmentRentDialog
              booking={findBookingFromChoice(group)}
              showCancelDialog={showCancelDialog}
              setShowCancelDialog={setShowCancelDialog}
              setOpenScheduleBookingDialog={props.setOpenDialog}
              setRefresh={props.setRefresh}
            />
          </SimpleForm>
        </DialogContent>
      </Dialog>
    </>
  );
};
