import React, { useState } from 'react';
import {
  Create,
  SimpleForm,
  useCreate,
  useNotify,
  useRecordContext
} from 'react-admin';
import { LinkedTeacherForm } from '../linkedTeacherForm/LinkedTeacherForm';
import { LinkedTeacherToolbar } from './LinkedTeacherToolbar';

export const LinkedTeacherCreate = ({ setSection, sectionList }) => {
  const record = useRecordContext();
  const [create] = useCreate('user-parents');
  const notify = useNotify();
  const [saveAndAdd, setSaveAndAdd] = useState(false);
  const [submit, setSubmit] = useState(false);

  const linkedTeacherSave = (data) => {
    data = {
      teacherHash: data.name,
      clubHash: record.id
    };
    create(
      'user-parents/request-link-teacher',
      { data },
      {
        onSuccess: () => {
          notify(
            'resources.users.notifications.userEdit.linkedTeachers.create.success',
            {
              type: 'success'
            }
          );
          setSubmit(true);

          if (!saveAndAdd) {
            setSection(sectionList);
          }
          setSaveAndAdd(false);
        },
        onError(error: any) {
          notify(error.message, { type: 'warning' });
          if (!saveAndAdd) {
            setSection(sectionList);
            setSaveAndAdd(false);
          }
        }
      }
    );
  };
  return (
    <>
      <Create>
        <SimpleForm
          onSubmit={linkedTeacherSave}
          className={'form'}
          toolbar={
            <LinkedTeacherToolbar
              setSection={setSection}
              sectionList={sectionList}
              setSaveAndAdd={setSaveAndAdd}
              submit={submit}
            />
          }
        >
          <LinkedTeacherForm />
        </SimpleForm>
      </Create>
    </>
  );
};
