import React from 'react';
import { LessonEditShared } from '../../../../../../components/shared/lessons/lessonEdit/LessonEditShared';

export const LessonEdit = ({
  setSection,
  sectionList,
  lessonToEditId,
  userHash,
  isClub
}) => {
  return (
    <LessonEditShared
      setSection={setSection}
      sectionList={sectionList}
      lessonToEditId={lessonToEditId}
      userHash={userHash}
      isClub={isClub}
    />
  );
};
