import React, { useContext, useEffect } from 'react';

import { EquipmentRentsCalendarShared } from '../../components/shared/equipmentRents/equipmentRentsCalendar/EquipmentRentsCalendarShared';
import { UserHashContext } from '../../core/context/userHashContext';
import { HeaderTitleContext } from '../../core/context/headerTitleContext';

export const EquipmentRentsCalendar = () => {
  const { userHash } = useContext(UserHashContext);
  const { setHeaderTitle } = useContext(HeaderTitleContext);

  useEffect(() => {
    setHeaderTitle('resources.equipmentRentsCalendar.header.title');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return userHash ? (
    <EquipmentRentsCalendarShared
      route={'/equipment-rents-calendar'}
      userHash={userHash}
    />
  ) : (
    <></>
  );
};
