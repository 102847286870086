import React, { useEffect, useState } from 'react';
import {
  CheckboxGroupInput,
  email,
  Form,
  FormDataConsumer,
  Loading,
  PasswordInput,
  RadioButtonGroupInput,
  ReferenceInput,
  required,
  SaveButton,
  SelectInput,
  TextInput,
  useCreate,
  useNotify,
  useRedirect,
  useTranslate
} from 'react-admin';
import {
  IDENTIFICATION_TYPE_CIF,
  IDENTIFICATION_TYPE_ID,
  IDENTIFICATION_TYPE_OTHER
} from '../core/constants/IdentificationType.constants';
import {
  PRIVILEGE_CLUB,
  PRIVILEGE_TEACHER
} from '../core/constants/privilege.constants';
import {
  getCountries,
  getLatesPrivacyPolicy,
  getLatesTermAndCondition
} from '../core/services/public-api.service';
import { RegionSelect } from '../dashboard/users/userShared/RegionSelect';
import { DialogPrivacyPolicies } from './dialog/DialogPrivacyPolicies';
import { DialogTermsAndConditions } from './dialog/DialogTermsAndConditions';
import { registerFormValidator } from './registerFormValidator';
import { Button } from '../components/shared/buttons/Button';
import { removeWhiteSpaces } from '../core/utils/string-utils';
import { validateNoSpaces } from '../core/utils/validation-utils';

export const Register = () => {
  const translate = useTranslate();
  const [create] = useCreate('users');
  const notify = useNotify();
  const redirect = useRedirect();
  const [isClub, setIsClub] = useState(false);
  const teacher = 'teacher';
  const club = 'club';
  const [country, setCountry] = useState();
  const [countries, setCountries] = useState([]);
  const [showTermsModal, setShowTermsModal] = useState(false);
  const [showPrivacyModal, setShowPrivacyModal] = useState(false);
  const [termAndCondition, setTermAndCondition] = useState(null);
  const [privacyPolicy, setPrivacyPolicy] = useState(null);

  useEffect(() => {
    getCountries().then((data) => {
      setCountries(data.data);
    });

    getLatesTermAndCondition(PRIVILEGE_TEACHER).then((data) => {
      setTermAndCondition(data.data);
    });

    getLatesPrivacyPolicy(PRIVILEGE_TEACHER).then((data) => {
      setPrivacyPolicy(data.data);
    });
  }, []);

  const getIdentificationType = (data) => {
    return isClub ? IDENTIFICATION_TYPE_CIF : data['identificationType'];
  };

  const handleCountryChange = (e) => {
    setCountry(e);
  };

  const handleSubmit = (data) => {
    const updatedData = {
      ...data,
      identificationType: getIdentificationType(data),
      privilege: isClub ? PRIVILEGE_CLUB : PRIVILEGE_TEACHER,
      accountNumber: removeWhiteSpaces(data['accountNumber'])
    };
    updatedData.term = termAndCondition ? termAndCondition.id : null;
    updatedData.privacyPolicy = privacyPolicy ? privacyPolicy.id : null;

    create(
      'public/user',
      { data: updatedData },
      {
        onSuccess: () => {
          notify('resources.users.notifications.userRegister.success', {
            type: 'success'
          });
          redirect('/login');
        },
        onError: (error: any) => {
          notify(error.message, { type: 'warning' });
        }
      }
    );
  };

  const privilegeChoices = [
    { id: teacher, name: 'resources.users.fields.teacher' },
    { id: club, name: 'resources.users.fields.club' }
  ];

  const documentOptionChoices = [
    { id: IDENTIFICATION_TYPE_ID, name: 'resources.users.fields.nif' },
    { id: IDENTIFICATION_TYPE_OTHER, name: 'resources.users.fields.other' }
  ];

  const onClickRadioBtn = (id: any) => {
    id.target.value === club ? setIsClub(true) : setIsClub(false);

    const privilege =
      id.target.value === club ? PRIVILEGE_CLUB : PRIVILEGE_TEACHER;

    getLatesTermAndCondition(privilege).then((data) => {
      setTermAndCondition(data.data);
    });

    getLatesPrivacyPolicy(privilege).then((data) => {
      setPrivacyPolicy(data.data);
    });
  };

  const showTermsAndConditions = () => {
    setShowTermsModal(true);
  };

  const showPrivacyPolicies = () => {
    setShowPrivacyModal(true);
  };

  const goBack = () => {
    redirect('/login');
  };

  if (!countries && countries.length === 0) {
    return <Loading />;
  }

  return (
    <>
      <div className="header-logo">
        <img
          src="src/images/logo-nak-sports.png"
          alt="NAK SPORTS"
          width="200"
          height="96"
        />
      </div>
      <main className="auth-page" id="register">
        <Form
          //className="form-dark"
          validate={registerFormValidator}
          onSubmit={handleSubmit}
          defaultValues={{
            category: teacher,
            documentOption: IDENTIFICATION_TYPE_ID
          }}
          className={'form'}
        >
          <div>
            <RadioButtonGroupInput
              source="category"
              label="resources.users.fields.category"
              choices={privilegeChoices}
              onChange={onClickRadioBtn}
              defaultValue={teacher}
              helperText={false}
            />
          </div>
          <div>
            <TextInput
              source="firstName"
              label="resources.users.fields.firstName"
              validate={[required()]}
            />
          </div>
          <FormDataConsumer>
            {({ formData }) =>
              formData.category !== club && (
                <>
                  <div>
                    <TextInput
                      source="lastName"
                      label="resources.users.fields.lastName"
                      validate={[required()]}
                    />
                  </div>
                  <div>
                    <TextInput
                      source="secondLastName"
                      label="resources.users.fields.secondLastName"
                    />
                  </div>
                </>
              )
            }
          </FormDataConsumer>

          <FormDataConsumer>
            {({ formData }) =>
              formData.category === teacher && (
                <>
                  <div>
                    <SelectInput
                      source="identificationType"
                      label="resources.users.fields.documentOption"
                      choices={documentOptionChoices}
                      defaultValue={IDENTIFICATION_TYPE_ID}
                      validate={[required()]}
                      emptyValue={false}
                    />
                  </div>
                  {formData.identificationType === IDENTIFICATION_TYPE_ID && (
                    <div>
                      <TextInput
                        source="identification"
                        label="resources.users.fields.nif"
                        validate={[required()]}
                        helperText={'resources.users.fields.nifHelperText'}
                      />
                    </div>
                  )}

                  {formData.identificationType ===
                    IDENTIFICATION_TYPE_OTHER && (
                    <div>
                      <TextInput
                        source="identification"
                        label="resources.users.fields.other"
                        validate={[required()]}
                      />
                    </div>
                  )}
                </>
              )
            }
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData }) =>
              formData.category === club && (
                <>
                  <div>
                    <TextInput
                      source="identification"
                      label="resources.users.fields.cif"
                      validate={[required()]}
                    />
                  </div>
                </>
              )
            }
          </FormDataConsumer>
          <div>
            <TextInput
              source="telephone"
              label="resources.users.fields.telephone"
            />
          </div>
          <div>
            <TextInput
              source="email"
              type="email"
              label="resources.users.fields.email"
              validate={[required(), email()]}
              inputProps={{ autoComplete: 'off' }}
            />
          </div>
          {isClub ? (
            <div>
              <TextInput
                source="managerFirstName"
                type="managerFirstName"
                label="resources.users.fields.managerFirstName"
                validate={[required()]}
              />
            </div>
          ) : null}
          <div>
            <ReferenceInput
              source="firstSport"
              reference="public/sports"
              sort={{ field: 'name', order: 'ASC' }}
            >
              <SelectInput
                optionText="name"
                defaultValue=""
                label="resources.users.fields.firstSport"
                validate={[required()]}
              />
            </ReferenceInput>
          </div>
          <div>
            <TextInput
              source="accountNumber"
              label="resources.users.fields.accountNumber"
              validate={[required(), validateNoSpaces]}
              helperText="resources.users.fields.accountNumberHelperText"
            />
          </div>
          <div>
            <SelectInput
              optionText="name"
              source="country"
              label="resources.users.fields.country"
              validate={[required()]}
              defaultValue={country}
              choices={countries}
              onChange={(e) => handleCountryChange(e.target.value)}
              value={country}
            />

            {country && countries.length > 0 && (
              <RegionSelect country={country} />
            )}
          </div>
          <div>
            <TextInput
              source="address"
              label="resources.users.fields.address"
              validate={[required()]}
            />
          </div>
          <div>
            <PasswordInput
              source="password"
              label="resources.users.fields.password"
              validate={required()}
              inputProps={{ autoComplete: 'new-password' }}
            />
          </div>
          <div>
            <PasswordInput
              source="confirmPassword"
              label="resources.users.fields.confirmPassword"
              validate={[required()]}
              inputProps={{ autoComplete: 'new-password' }}
            />
          </div>
          {termAndCondition && privacyPolicy && (
            <div className="terms-checkbox">
              <CheckboxGroupInput
                source="term"
                label=""
                choices={[{ id: termAndCondition.id, name: '' }]}
                helperText={false}
              />
              <p>
                {translate('resources.users.fields.accept')}
                <span onClick={() => showTermsAndConditions()}>
                  {translate('resources.users.fields.termsAndConditions')}
                </span>
                <span onClick={() => showPrivacyPolicies()}>
                  {translate('resources.users.fields.privacyPolicies')}
                </span>
              </p>
            </div>
          )}
          <div className="actions">
            <Button
              label="resources.navigation.prev"
              onClick={(event) => goBack()}
            />
            <SaveButton label={translate('resources.register.actions.send')} />
          </div>
          <div className="help-text">
            <p>{translate('resources.register.helpText')}</p>
          </div>
        </Form>
      </main>

      {termAndCondition && (
        <DialogTermsAndConditions
          termAndCondition={termAndCondition}
          showTermsModal={showTermsModal}
          setShowTermsModal={setShowTermsModal}
        />
      )}

      {privacyPolicy && (
        <DialogPrivacyPolicies
          privacyPolicy={privacyPolicy}
          showPrivacyModal={showPrivacyModal}
          setShowPrivacyModal={setShowPrivacyModal}
        />
      )}
    </>
  );
};
