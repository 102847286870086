import React from 'react';
import { TopToolbar } from 'react-admin';
import { Button } from '../../../../components/shared/buttons/Button';

export const UserLinkedSportsListActions = (props) => {
  const addUserSport = () => {
    props.setShowDialog(true);
  };

  return (
    <TopToolbar>
      <Button
        onClick={addUserSport}
        label="resources.configuration.sports.list.actions.create"
      ></Button>
    </TopToolbar>
  );
};
