import { get } from './httpClient.service';
const DEFAULT_ENTRY_POINT = 'equipment-rents';

export async function getEquipmentRentEvents(
  hash: string,
  startDate: number,
  endDate: number,
  userHash: string
): Promise<any> {
  return get(`${DEFAULT_ENTRY_POINT}/events`, {
    startDate,
    endDate,
    hash,
    userHash
  });
}

export async function getEquipmentRentBookings(
  hash: string,
  startDate: number,
  endDate: number,
  userHash: string
): Promise<any> {
  return get(`${DEFAULT_ENTRY_POINT}/bookings`, {
    startDate,
    endDate,
    hash,
    userHash
  });
}
