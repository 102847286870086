import React from 'react';
import { useNotify, useDelete, Confirm, useRefresh } from 'react-admin';

export const DialogDeleteNotification = ({
  showDeleteDialog,
  setShowDeleteDialog,
  id
}) => {
  const [erase] = useDelete();
  const notify = useNotify();
  const refresh = useRefresh();
  const onCloseDeleteDialog = () => {
    setShowDeleteDialog(false);
  };

  const onDelete = () => {
    erase(
      'notifications',
      {
        id
      },
      {
        onSuccess: () => {
          notify('resources.notifications.notifications.delete.success', {
            type: 'success'
          });
          onCloseDeleteDialog();
          refresh();
        },
        onError(error: any) {
          notify(error.message, { type: 'warning' });
        }
      }
    );
  };

  return (
    <Confirm
      title="resources.notifications.delete.title"
      content="resources.notifications.delete.message"
      onConfirm={onDelete}
      isOpen={showDeleteDialog}
      onClose={onCloseDeleteDialog}
    />
  );
};
