import React from 'react';
import { useRecordContext } from 'react-admin';
import { EquipmentRentsSchedulerShared } from '../../../../../components/shared/equipmentRents/equipmentRentsScheduler/EquipmentRentsSchedulerShared';

export const UserEquipmentRentsScheduler = () => {
  const record = useRecordContext();
  return (
    <>
      <EquipmentRentsSchedulerShared
        route={'/equipment-rents-scheduler'}
        userHash={record?.id as string}
      ></EquipmentRentsSchedulerShared>
    </>
  );
};
