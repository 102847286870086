import React, { useContext, useEffect } from 'react';
import {
  Loading,
  SimpleForm,
  useGetOne,
  useNotify,
  usePermissions,
  useUpdate
} from 'react-admin';
import { IDENTIFICATION_TYPE_CIF } from '../../core/constants/IdentificationType.constants';
import {
  PRIVILEGE_ADMIN,
  PRIVILEGE_CLUB,
  PRIVILEGE_STUDENT,
  PRIVILEGE_TEACHER
} from '../../core/constants/privilege.constants';
import { HeaderTitleContext } from '../../core/context/headerTitleContext';
import { UserHashContext } from '../../core/context/userHashContext';
import { convertFileToBase64 } from '../../core/utils/file-utils';
import { createFormValidators } from '../users/userCreate/createFormValidator';
import { RestorePasswordButton } from '../users/userOverview/userTabs/userEdit/RestorePasswordButton';
import { UserToolbar } from '../users/userOverview/userTabs/userEdit/toolbar/UserToolbar';
import UserForm from '../users/userShared/UserForm';
import { removeWhiteSpaces } from '../../core/utils/string-utils';

export const UserData = () => {
  const { setHeaderTitle } = useContext(HeaderTitleContext);
  const { userHash } = useContext(UserHashContext);
  const [update] = useUpdate();
  const notify = useNotify();
  const { data: user, isLoading } = useGetOne('users', {
    id: userHash
  });
  const permissions = usePermissions();
  const isClub = permissions.permissions === PRIVILEGE_CLUB;
  const isTeacher = permissions.permissions === PRIVILEGE_TEACHER;
  const isStudent = permissions.permissions === PRIVILEGE_STUDENT;
  const isAdmin = permissions.permissions === PRIVILEGE_ADMIN;

  useEffect(() => {
    setHeaderTitle('resources.userData.header.title');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getImage = async (data) => {
    if (data.image?.rawFile instanceof File) {
      data.image = convertFileToBase64(data.image);
    } else {
      data.image =
        data.image == null ? null : user.image ? user.image.title : null;
    }

    return data.image;
  };

  const getIdentificationType = (data) => {
    return isClub ? IDENTIFICATION_TYPE_CIF : data['identificationType'];
  };

  const generalDataSave = async (data) => {
    const updatedData = {
      ...data,
      accountNumber: removeWhiteSpaces(data['accountNumber']),
      identificationType: getIdentificationType(data)
    };
    const image = await getImage(updatedData);
    const id = updatedData.id;

    update(
      'users',
      { id, data: { ...updatedData, image } },
      {
        onSuccess: () => {
          notify('resources.users.notifications.userEdit.success', {
            type: 'success'
          });
        },
        onError(error: any) {
          notify(error.message, { type: 'warning' });
        }
      }
    );
  };

  if (isLoading) return <Loading />;

  return (
    <SimpleForm
      toolbar={<UserToolbar />}
      onSubmit={generalDataSave}
      validate={(e) => createFormValidators(e, isClub, isStudent, isTeacher)}
      record={user}
      resource={'users'}
      className="form"
    >
      <UserForm
        isClub={isClub}
        isTeacher={isTeacher}
        isStudent={isStudent}
        isAdmin={isAdmin}
      />
      <RestorePasswordButton />
    </SimpleForm>
  );
};
