import React from 'react';
import {
  Edit,
  SimpleForm,
  useNotify,
  useRefresh,
  useUpdate
} from 'react-admin';
import { roomFormValidator } from '../roomsForm/roomFormValidator';
import { RoomFormToolbar } from '../roomsForm/RoomFormToolbar';
import { RoomForm } from '../roomsForm/RoomForm';
import { useNavigate } from 'react-router-dom';

export const RoomEditShared = (props) => {
  const [update, { isLoading, isError }] = useUpdate();
  const notify = useNotify();
  const refresh = useRefresh();
  const navigate = useNavigate();
  const roomSave = (data) => {
    const id = data.id;
    data = {
      ...data,
      userHash: props.userHash
    };
    update(
      'rooms',
      { id, data },
      {
        onSuccess: () => {
          notify('resources.users.rooms.notifications.edit.success', {
            type: 'success'
          });

          if (props.setSection) {
            props.setSection(props.sectionList);
          }

          if (props.route) {
            navigate(-1);
          }
          refresh();
        },
        onError(error: any) {
          notify(error.message, { type: 'warning' });
        }
      }
    );
  };
  return (
    <Edit resource="rooms" id={props.editToEditId}>
      <SimpleForm
        onSubmit={roomSave}
        validate={(e) => roomFormValidator(e)}
        className={'form'}
        toolbar={
          <RoomFormToolbar
            setSection={props.setSection}
            sectionList={props.sectionList}
            route={props.route}
            isLoading={isLoading}
            isError={isError}
          />
        }
      >
        <RoomForm />
      </SimpleForm>
    </Edit>
  );
};
