import React from 'react';
import {
  required,
  SelectInput,
  SimpleForm,
  useNotify,
  useRecordContext,
  useRefresh
} from 'react-admin';
import {
  PAYMENT_REQUEST_STATUS_EMITTED,
  PAYMENT_REQUEST_STATUS_PAID,
  PAYMENT_REQUEST_STATUS_PENDING
} from '../../../../core/constants/paymentRequest.constants';
import { updatePaymentRequestStatus } from '../../../../core/services/paymentRequest-api.service';
import { PaymentRequest } from '../../../../core/models/paymentRequest/paymentRequest';

export const StatusField = (props: any) => {
  const record: PaymentRequest = useRecordContext(props);
  const notify = useNotify();
  const refresh = useRefresh();

  const statusSave = (e) => {
    updatePaymentRequestStatus(record.id, e.target.value)
      .then(() => {
        notify(
          'resources.invoicing.paymentRequest.notifications.editStatuses.success',
          {
            type: 'success'
          }
        );
        refresh();
      })
      .catch((error) => {
        notify(error.message, { type: 'warning' });
      });
  };

  const statusChoices = () => {
    return [
      {
        id: PAYMENT_REQUEST_STATUS_EMITTED,
        name: 'resources.invoicing.paymentRequest.list.emitted'
      },
      {
        id: PAYMENT_REQUEST_STATUS_PENDING,
        name: 'resources.invoicing.paymentRequest.list.pending'
      },
      {
        id: PAYMENT_REQUEST_STATUS_PAID,
        name: 'resources.invoicing.paymentRequest.list.paid'
      }
    ];
  };

  return (
    <SimpleForm toolbar={false} className="status-form">
      <SelectInput
        source="status"
        optionText="name"
        choices={statusChoices()}
        label={false}
        value={record.paymentRequestStatus.id}
        defaultValue={record.paymentRequestStatus.id}
        onChange={statusSave}
        helperText={false}
        validate={[required()]}
      ></SelectInput>
    </SimpleForm>
  );
};
