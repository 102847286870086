const sessionKey = 'session';

export const setItem = (key: string, value: any): void =>
  localStorage.setItem(key, JSON.stringify(value));

export const getItem = (key: string): any => {
  if (typeof window == 'undefined') {
    return null;
  }
  const value = localStorage.getItem(key);

  return value ? JSON.parse(value) : null;
};

export const setSession = (
  session: { token: string; refreshToken: string } | null
): void => {
  setItem(sessionKey, session);
};

export const getSession = (): {
  token: string;
  refreshToken: string;
} | null => {
  return getItem(sessionKey);
};

export const deleteSession = (): void => {
  setSession(null);
};
