import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import React from 'react';
import { useTranslate } from 'react-admin';
import { Button } from '../../../../components/shared/buttons/Button';
export const UserLinkedSportsEmptyList = (props) => {
  const translate = useTranslate();
  const goToCreate = () => {
    props.setShowDialog(true);
  };
  return (
    <Box textAlign="center" m={1}>
      <Typography variant="h4" paragraph>
        {translate(props.message)}
      </Typography>
      <Button onClick={goToCreate} label={props.buttonLabel}></Button>
    </Box>
  );
};
