import React, { useContext, useEffect } from 'react';
import { LinkedClubListShared } from '../../components/shared/linkedClubs/linkedClubList/LinkedClubListShared';
import { UserHashContext } from '../../core/context/userHashContext';
import { HeaderTitleContext } from '../../core/context/headerTitleContext';

export const LinkedClubsList = () => {
  const { userHash } = useContext(UserHashContext);
  const { setHeaderTitle } = useContext(HeaderTitleContext);

  useEffect(() => {
    setHeaderTitle('resources.linkedClubs.header.title');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <LinkedClubListShared id={userHash} />;
};
