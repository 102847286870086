import React, { useEffect, useState } from 'react';
import {
  AutocompleteArrayInput,
  email,
  ImageField,
  ImageInput,
  Loading,
  ReferenceArrayInput,
  ReferenceInput,
  required,
  SelectInput,
  TextInput,
  useNotify,
  useRecordContext
} from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { RegionSelect } from './RegionSelect';
import {
  IDENTIFICATION_TYPE_CIF,
  IDENTIFICATION_TYPE_ID,
  IDENTIFICATION_TYPE_OTHER
} from '../../../core/constants/IdentificationType.constants';
import { getCountries } from '../../../core/services/public-api.service';
import { validateNoSpaces } from '../../../core/utils/validation-utils';

const UserForm = (props: {
  isClub: any;
  isTeacher: any;
  isStudent: any;
  isAdmin: any;
}) => {
  const notify = useNotify();
  const record = useRecordContext();
  const { getValues, setValue } = useFormContext();
  const [isClub, isStudent, isAdmin] = [
    props.isClub,
    props.isStudent,
    props.isAdmin
  ];
  const [countries, setCountries] = useState([]);
  const [country, setCountry] = React.useState();
  const validExtensions = process.env.REACT_APP_IMAGE_VALID_EXTENSIONS;

  useEffect(() => {
    getCountries().then((data) => {
      setCountries(data.data);
    });
  }, []);

  useEffect(() => {
    if (record && record.country) {
      setCountry(record.country);
      setValue('country', record.country);
      setValue('region', record.region);
      setValue('city', record.city);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleImageChange = (e) => {
    if (e == null) {
      setValue('image', null);
    }
  };

  const handleCountryChange = (e) => {
    setCountry(e);
    setValue('country', e);
    setValue('region', '');
    setValue('city', '');
  };

  const documentOptionChoices = [
    { id: IDENTIFICATION_TYPE_ID, name: 'resources.users.fields.nif' },
    { id: IDENTIFICATION_TYPE_OTHER, name: 'resources.users.fields.other' }
  ];

  const clubDocumentOptionChoices = [
    { id: IDENTIFICATION_TYPE_CIF, name: 'resources.users.fields.cif' }
  ];

  const handleDropFiles = (files) => {
    if (files.length === 0) {
      setValue('image', record.image ? record.image : null);
      notify('resources.users.notifications.extension.error', {
        type: 'warning'
      });
    }
    if (files[0]?.size > Number(process.env.REACT_APP_IMAGE_MAX_SIZE)) {
      setValue('image', record.image ? record.image : null);
      notify('resources.users.notifications.imageSize.error', {
        messageArgs: {
          max: Number(process.env.REACT_APP_IMAGE_MAX_SIZE) / 1000000
        },
        type: 'warning'
      });
    }
  };

  if (!countries || countries.length === 0) return <Loading />;

  return (
    <>
      <TextInput source="firstName" validate={[required()]} />
      {!isClub ? <TextInput source="lastName" validate={[required()]} /> : null}
      {!isClub ? <TextInput source="secondLastName" /> : null}
      <SelectInput
        source="identificationType"
        label="resources.users.fields.documentOption"
        choices={
          isClub || isAdmin ? clubDocumentOptionChoices : documentOptionChoices
        }
        defaultValue={
          isClub || isAdmin ? IDENTIFICATION_TYPE_CIF : IDENTIFICATION_TYPE_ID
        }
        validate={[required()]}
        emptyValue={false}
      />
      {getValues('identificationType') === IDENTIFICATION_TYPE_ID &&
        !isClub &&
        !isAdmin && (
          <TextInput
            source="identification"
            label="resources.users.fields.nif"
            validate={[required()]}
            helperText={'resources.users.fields.nifHelperText'}
          />
        )}
      {getValues('identificationType') === IDENTIFICATION_TYPE_OTHER &&
        !isClub && (
          <TextInput
            source="identification"
            label="resources.users.fields.other"
            validate={[required()]}
          />
        )}
      {getValues('identificationType') === IDENTIFICATION_TYPE_CIF &&
        (isClub || isAdmin) && (
          <TextInput
            source="identification"
            label="resources.users.fields.cif"
            validate={[required()]}
          />
        )}
      <TextInput source="address" validate={[required()]} />
      <TextInput source="telephone" />
      <TextInput source="email" type="email" validate={[required(), email()]} />
      {isClub ? <TextInput source="managerFirstName" /> : null}
      {isStudent && (
        <ReferenceArrayInput
          source="clubs"
          reference="users"
          sort={{ field: 'firstName', order: 'ASC' }}
          filter={{ clubs: true }}
        >
          <AutocompleteArrayInput
            optionText="firstName"
            label="resources.users.fields.club"
          />
        </ReferenceArrayInput>
      )}
      {!isAdmin && (
        <ReferenceInput
          source="firstSport"
          reference="sports"
          sort={{ field: 'name', order: 'ASC' }}
        >
          <SelectInput
            optionText="name"
            label="resources.users.fields.firstSport"
            validate={[required()]}
            defaultValue=""
          />
        </ReferenceInput>
      )}
      {!isStudent && (
        <TextInput
          source="accountNumber"
          label="resources.users.fields.accountNumber"
          validate={[required(), validateNoSpaces]}
          helperText="resources.users.fields.accountNumberHelperText"
        />
      )}
      <SelectInput
        optionText="name"
        source="country"
        label="resources.users.fields.country"
        validate={[required()]}
        defaultValue={country}
        choices={countries}
        onChange={(e) => handleCountryChange(e.target.value)}
        value={country}
      />
      {country && countries.length > 0 && <RegionSelect country={country} />}
      {!isAdmin && (
        <ImageInput
          onChange={(e) => handleImageChange(e)}
          source="image"
          label="resources.users.fields.image"
          accept={validExtensions}
          options={{
            onDrop: (files) => {
              handleDropFiles(files);
            }
          }}
        >
          <ImageField source="src" title="title" />
        </ImageInput>
      )}
    </>
  );
};

export default UserForm;
