import React from 'react';
import { useNotify, useDelete, Confirm, useRefresh } from 'react-admin';

export const DialogDeleteRoom = ({
  showDeleteDialog,
  setShowDeleteDialog,
  id
}) => {
  const [erase] = useDelete();
  const notify = useNotify();
  const refresh = useRefresh();
  const onCloseRoomDeleteDialog = () => {
    setShowDeleteDialog(false);
  };

  const onDeleteRoom = () => {
    erase(
      'rooms',
      {
        id
      },
      {
        onSuccess: () => {
          notify('resources.users.rooms.notifications.delete.success', {
            type: 'success'
          });
          onCloseRoomDeleteDialog();
          refresh();
        },
        onError(error: any) {
          notify(error.message, { type: 'warning' });
        }
      }
    );
  };

  return (
    <Confirm
      title="resources.users.rooms.delete.title"
      content="resources.users.rooms.delete.message"
      onConfirm={onDeleteRoom}
      isOpen={showDeleteDialog}
      onClose={onCloseRoomDeleteDialog}
    />
  );
};
