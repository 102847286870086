import React from 'react';
import { TopToolbar } from 'react-admin';
import { Button } from '../../buttons/Button';
import { useNavigate } from 'react-router-dom';

export const LessonListActions = (props) => {
  const navigate = useNavigate();
  const goToCreate = () => {
    if (props.route) {
      navigate(props.route);
    }

    if (props.setSection) {
      props.setSection(props.sectionCreate);
    }
  };

  return (
    <TopToolbar>
      <Button
        onClick={goToCreate}
        label="resources.users.lessons.actions.create"
      ></Button>
    </TopToolbar>
  );
};
