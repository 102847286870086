import {
  VALIDATION_MORE_THAN_ZERO,
  VALIDATION_REQUIRED
} from '../../../../../core/constants/validation.constants';
import {
  formatMillis,
  getCurrentDate,
  getMillis
} from '../../../../../core/utils/date.utils';
import { EquipmentRentEventFormType } from './EquipmentRentsCalendarAddEventDialog';

export function EquipmentRentsCalendarEventFormValidator(
  values: EquipmentRentEventFormType
) {
  const currentYear = getCurrentDate();
  currentYear.setFullYear(currentYear.getFullYear() + 1);
  const date = formatMillis('yyyy-MM-DD', getMillis(currentYear));

  const errors: EquipmentRentEventFormType = {} as EquipmentRentEventFormType;

  if (!values.equipmentRentHash) {
    errors.equipmentRentHash = VALIDATION_REQUIRED;
  }

  if (!values.startDate) {
    errors.startDate = VALIDATION_REQUIRED;
  }

  if (!values.endDate) {
    errors.endDate = VALIDATION_REQUIRED;
  }

  if (!values.timeType) {
    errors.timeType = VALIDATION_REQUIRED;
  }

  if (values.iterationType === '2' && !values.iterationEnd) {
    errors.iterationEnd = VALIDATION_REQUIRED;
  }

  if (values.iterationType === '1' && !values.iterationDateEnd) {
    errors.iterationDateEnd = VALIDATION_REQUIRED;
  }

  if (values.iterationEnd != null && values.iterationEnd <= 0) {
    errors.iterationEnd = VALIDATION_MORE_THAN_ZERO;
  }

  if (values.iterationDateEnd && values.iterationDateEnd > date) {
    errors.iterationDateEnd = 'validation.dateOneYearOrLess';
  }

  return errors;
}
