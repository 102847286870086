/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useState } from 'react';
import { Loading, Logout, Menu, useGetOne, useTranslate } from 'react-admin';
import { useLocation } from 'react-router-dom';
import { UserHashContext } from '../../../core/context/userHashContext';
import { CustomLocaleButton } from '../../shared/buttons/CustomLocaleButton';

export const TeacherMenu = () => {
  const translate = useTranslate();
  const location = useLocation();
  const sectionUsers = 'users';
  const sectionInvoicing = 'invoicing';
  const [openedSectionMenu, setOpenedSectionMenu] = useState([sectionUsers]);
  const { userHash } = useContext(UserHashContext);
  const { data: user, isLoading } = useGetOne('users', {
    id: userHash
  });

  const handleUserMenuClick = (section) => {
    openedSectionMenu.indexOf(section) === -1
      ? setOpenedSectionMenu([...openedSectionMenu, section])
      : setOpenedSectionMenu(
          openedSectionMenu.filter((item) => item !== section)
        );
  };

  const showIfIsClicked = (section) => {
    return openedSectionMenu.indexOf(section) !== -1;
  };

  const getClassName = (
    path: string,
    activeClass: string,
    defaultClass: string
  ) => (location.pathname.includes(path) ? activeClass : defaultClass);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Menu>
      <div className="logo-sidebar">
        <img
          src="src/images/nak-sports-icon.svg"
          alt="NAK SPORTS"
          width="56"
          height="55"
        />
      </div>
      <p>{user.firstName}</p>
      <Menu.Item
        className={getClassName('schedule', 'active', 'custom')}
        to={{
          pathname: '/schedule'
        }}
        primaryText="resources.schedule.menu.title"
      />
      <Menu.Item
        className={getClassName(
          '/equipment-rents-scheduler',
          'active',
          'custom'
        )}
        to={{
          pathname: '/equipment-rents-scheduler'
        }}
        primaryText="resources.equipmentRentsScheduler.menu.title"
      />
      <a
        className={getClassName(
          'users',
          'RaMenuItemLink-active title-active',
          'RaMenuItemLink-root title-default'
        )}
        onClick={(e) => handleUserMenuClick(sectionUsers)}
      >
        {translate('resources.sidebar.users')}
      </a>
      {showIfIsClicked(sectionUsers) && (
        <div className="sidebar__submenu">
          <Menu.Item
            className={
              location.pathname.includes('students') ? 'active' : 'custom'
            }
            to={{
              pathname: '/students'
            }}
            primaryText="resources.users.menu.students"
          />
        </div>
      )}
      <Menu.Item
        className={location.pathname.includes('calendar') ? 'active' : 'custom'}
        to={{
          pathname: '/calendar'
        }}
        primaryText="resources.calendar.menu.title"
      />
      <Menu.Item
        className={
          location.pathname.includes('/equipment-rents-calendar')
            ? 'active'
            : 'custom'
        }
        to={{ pathname: '/equipment-rents-calendar' }}
        primaryText="resources.equipmentRentsCalendar.menu.title"
      />
      <Menu.Item
        className={
          location.pathname.includes('lessons')
            ? 'active'
            : 'custom RaMenuItemLink-root'
        }
        to={{
          pathname: '/lessons'
        }}
        primaryText="resources.lessons.menu.title"
      />
      <Menu.Item
        className={
          location.pathname.includes('small-course')
            ? 'active'
            : 'custom RaMenuItemLink-root'
        }
        to={{
          pathname: '/small-course'
        }}
        primaryText="resources.courses.small.menu.title"
      />
      <Menu.Item
        className={
          location.pathname.includes('yearly-course')
            ? 'active'
            : 'custom RaMenuItemLink-root'
        }
        to={{
          pathname: '/yearly-course'
        }}
        primaryText="resources.courses.yearly.menu.title"
      />
      <Menu.Item
        className={
          location.pathname.includes('vouchers')
            ? 'active'
            : 'custom RaMenuItemLink-root'
        }
        to={{
          pathname: '/vouchers'
        }}
        primaryText="resources.vouchers.menu.title"
      />
      <Menu.Item
        className={
          location.pathname.includes('active-vouchers') ? 'active' : 'custom'
        }
        to={{
          pathname: '/active-vouchers'
        }}
        primaryText="resources.activeVouchers.menu.title"
      />
      <Menu.Item
        className={getClassName('rooms', 'active', 'custom')}
        to={{
          pathname: '/rooms'
        }}
        primaryText="resources.rooms.menu.title"
      />
      <Menu.Item
        className={
          location.pathname.includes('equipment-rents') &&
          !location.pathname.includes('equipment-rents-scheduler') &&
          !location.pathname.includes('equipment-rents-calendar')
            ? 'active'
            : 'custom RaMenuItemLink-root'
        }
        to={{
          pathname: '/equipment-rents'
        }}
        primaryText="resources.equipmentRents.menu.title"
      />
      <Menu.Item
        className={
          location.pathname.includes('linked-clubs') ? 'active' : 'custom'
        }
        to={{
          pathname: '/linked-clubs'
        }}
        primaryText="resources.linkedClubs.menu.title"
      />
      <Menu.Item
        className={getClassName('notifications', 'active', 'custom')}
        to={{
          pathname: '/notifications'
        }}
        primaryText="resources.notifications.menu.title"
      />
      <a
        className={getClassName(
          'sectionInvoicing',
          'RaMenuItemLink-active title-active',
          'RaMenuItemLink-root title-default'
        )}
        onClick={(e) => handleUserMenuClick(sectionInvoicing)}
      >
        {translate('resources.sidebar.invoicing')}
      </a>
      {showIfIsClicked(sectionInvoicing) && (
        <div className="sidebar__submenu">
          <Menu.Item
            className={getClassName('payment-request', 'active', 'custom')}
            to={{
              pathname: '/payment-request'
            }}
            primaryText="resources.invoicing.menu.paymentRequest"
          />
          <Menu.Item
            className={getClassName('invoices', 'active', 'custom')}
            to={{
              pathname: '/invoices'
            }}
            primaryText="resources.invoicing.menu.invoices"
          />
          <Menu.Item
            className={getClassName('global-operations', 'active', 'custom')}
            to={{
              pathname: '/global-operations'
            }}
            primaryText="resources.invoicing.menu.globalOperations"
          />
        </div>
      )}
      <Menu.Item
        className={
          location.pathname.includes('my-data')
            ? 'active'
            : 'custom RaMenuItemLink-root'
        }
        to={{
          pathname: '/my-data'
        }}
        primaryText="resources.userData.menu.title"
      />
      <Menu.Item
        className={getClassName('share', 'active', 'custom')}
        to={{
          pathname: '/share'
        }}
        primaryText="resources.configuration.menu.share"
      />
      <Menu.Item
        className={getClassName('invoicing-data', 'active', 'custom')}
        to={{
          pathname: '/invoicing-data'
        }}
        primaryText="resources.configuration.menu.invoicingData"
      />
      <div className="sidebar-bottom">
        <div className="language">
          <label>{translate('resources.sidebar.language')}</label>
          <CustomLocaleButton />
        </div>
      </div>
      <Logout />;
    </Menu>
  );
};
