import jwtManager from '../providers/jwtManager';
import axiosInstance from './base/axios.client';

export const getAuthorization = () => {
  const userToken = jwtManager.getToken();
  return { Authorization: 'Bearer ' + userToken };
};

export const getAuthorizationWithLang = (locale: string) => {
  const userToken = jwtManager.getToken();
  return { Authorization: 'Bearer ' + userToken, 'Accept-Language': locale };
};

export const put = async (url, data) => {
  return await axiosInstance.put(url, data, {
    headers: getAuthorization()
  });
};

export const patch = async (url, data, headers) => {
  return await axiosInstance.patch(url, data, {
    headers: { ...getAuthorization(), ...headers }
  });
};

export const get = async (url, params) => {
  return await axiosInstance.get(url, {
    headers: getAuthorization(),
    params
  });
};

export const post = async (url, data, responseType = null) => {
  return await axiosInstance.post(url, data, {
    headers: getAuthorization(),
    responseType
  });
};
