import React from 'react';
import { useRecordContext } from 'react-admin';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton } from '@mui/material';
import { DialogDeleteLesson } from './DialogDeleteLesson';
import { Lesson } from '../../../../core/models/lesson/lesson';

export const LessonDeleteButton = (props) => {
  const record: Lesson = useRecordContext(props);
  const [showDeleteDialog, setShowDeleteDialog] = React.useState(false);

  const handleDeleteLesson = () => setShowDeleteDialog(true);

  return (
    <>
      <IconButton onClick={() => handleDeleteLesson()}>
        <DeleteIcon />
      </IconButton>
      <DialogDeleteLesson
        showDeleteDialog={showDeleteDialog}
        setShowDeleteDialog={setShowDeleteDialog}
        id={record.id}
      />
    </>
  );
};
