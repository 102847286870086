import React, { useContext, useEffect, useState } from 'react';
import {
  Loading,
  NumberInput,
  required,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
  useNotify,
  useUpdate
} from 'react-admin';
import { InvoiceDataSettingFormValidator } from './InvoiceDataSettingFormValidator';
import { getInvoiceDataSetting } from '../../../core/services/invoice-data-setting-api.service';
import { HeaderTitleContext } from '../../../core/context/headerTitleContext';
import { removeWhiteSpaces } from '../../../core/utils/string-utils';

export const InvoiceDataSetting = () => {
  const { setHeaderTitle } = useContext(HeaderTitleContext);

  useEffect(() => {
    setHeaderTitle('resources.configuration.invoicingDataSettings.title');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [invoiceData, setInvoiceData] = useState(null);
  const [update] = useUpdate();
  const notify = useNotify();

  useEffect(() => {
    getInvoiceData();
  }, []);

  const getInvoiceData = () => {
    getInvoiceDataSetting().then((data) => setInvoiceData(data.data));
  };

  const invoiceDataSave = (data) => {
    const updatedData = {
      ...data,
      iban: removeWhiteSpaces(data['iban'])
    };

    update(
      'invoice-data-settings',
      { id: invoiceData.id, data: updatedData },
      {
        onSuccess: () => {
          notify(
            'resources.configuration.invoicingDataSettings.notifications.update.success',
            {
              type: 'success'
            }
          );
          getInvoiceData();
        },
        onError(error: any) {
          notify(error.message, { type: 'warning' });
        }
      }
    );
  };

  if (!invoiceData) return <Loading />;

  return (
    <SimpleForm
      onSubmit={invoiceDataSave}
      record={invoiceData}
      toolbar={
        <div>
          <Toolbar>
            <SaveButton label="resources.users.actions.saveChanges" />
          </Toolbar>
        </div>
      }
      validate={InvoiceDataSettingFormValidator}
      className={'form'}
    >
      <NumberInput
        source="fixedMonthFee"
        label="resources.configuration.invoicingDataSettings.fields.fixedMonthFee"
      />
      <NumberInput
        source="bookingFee"
        label="resources.configuration.invoicingDataSettings.fields.bookingFee"
        parse={(value) => (value ? value / 100 : null)}
        format={(value) => (value ? value * 100 : null)}
      />
      <NumberInput
        source="bookingFixFee"
        label="resources.configuration.invoicingDataSettings.fields.bookingFixFee"
      />
      <TextInput
        source="iban"
        validate={[required()]}
        label="resources.configuration.invoicingDataSettings.fields.iban"
      />
      <TextInput
        source="bic"
        label="resources.configuration.invoicingDataSettings.fields.bic"
        validate={[required()]}
      />
      <TextInput
        source="debtorId"
        validate={[required()]}
        label="resources.configuration.invoicingDataSettings.fields.debtorId"
      />
    </SimpleForm>
  );
};
