import React from 'react';
import {
  Edit,
  Loading,
  SimpleForm,
  useGetOne,
  useNotify,
  useRefresh,
  useUpdate
} from 'react-admin';
import { useNavigate, useParams } from 'react-router-dom';
import {
  getMillis,
  getTimeMillisOfDate,
  toDate
} from '../../../../core/utils/date.utils';
import { CourseForm } from '../courseForm/CourseForm';
import { CourseFormToolbar } from '../courseForm/CourseFormToolbar';
import { courseFormValidator } from '../courseForm/courseFormValidator';

export const CourseEditShared = (props) => {
  const [update, { isLoading: updateIsLoading, isError }] =
    useUpdate('courses');
  const notify = useNotify();
  const refresh = useRefresh();
  const params = useParams();
  const navigate = useNavigate();
  const { data, isLoading } = useGetOne('courses', {
    id: props.courseToEditId ? props.courseToEditId : params.id
  });

  const getDates = (data) => {
    const dateStart = toDate(data.dateStart);
    const dateEnd = toDate(data.dateEnd);
    const registrationDeadline = toDate(data.registrationDeadline);

    const timeStart =
      typeof data.timeStart === 'object'
        ? getTimeMillisOfDate(data.timeStart)
        : data.timeStart;
    const timeEnd =
      typeof data.timeEnd === 'object'
        ? getTimeMillisOfDate(data.timeEnd)
        : data.timeEnd;

    data['dateStart'] = getMillis(dateStart);
    data['dateEnd'] = getMillis(dateEnd);

    data['timeStart'] = timeStart;
    data['timeEnd'] = timeEnd;
    data['registrationDeadline'] = getMillis(registrationDeadline);

    return data;
  };

  const courseSave = (data) => {
    const id = data.id;
    data = getDates(data);
    data.disabled = !data.disabled;
    data.smallCourse = props.smallCourses;
    data = {
      ...data,
      userHash: props.userHash,
      disabled: !data.isEnabled
    };
    update(
      'courses',
      { id, data },
      {
        onSuccess: () => {
          notify('resources.users.course.notifications.editCourse.success', {
            type: 'success'
          });
          if (props.setSection) {
            props.setSection(props.sectionList);
          }

          if (props.route) {
            navigate(-1);
          }
          refresh();
        },
        onError(error: any) {
          notify(error.message, { type: 'warning' });
        }
      }
    );
  };

  if (isLoading) return <Loading />;

  return (
    <Edit
      resource="courses"
      id={props.courseToEditId ? props.courseToEditId : params.id}
    >
      <SimpleForm
        onSubmit={courseSave}
        validate={(e) => courseFormValidator(e, props.smallCourses)}
        record={data}
        className={'form'}
        toolbar={
          <CourseFormToolbar
            setSection={props.setSection}
            sectionList={props.sectionList}
            route={props.route}
            isLoading={updateIsLoading}
            isError={isError}
          />
        }
      >
        <CourseForm
          smallCourses={props.smallCourses}
          startDate={null}
          endDate={null}
          isClub={props.isClub}
        />
      </SimpleForm>
    </Edit>
  );
};
