import {
  Datagrid,
  FunctionField,
  List,
  Loading,
  TextField,
  useGetList,
  useTranslate
} from 'react-admin';
import { EquipmentRent } from '../../../../core/models/equipmentRent/equipmentRent';
import { CustomPagination } from '../../../general/Pagination';
import { EmptyList } from '../../../general/EmptyList';
import React from 'react';
import { EquipmentRentsListActions } from './EquipmentRentsListActions';
import { EquipmentRentsDeleteButton } from './EquipmentRentsDeleteButton';
import { EquipmentRentsEditButton } from './EquipmentRentsEditButton';

export const EquipmentRentsListShared = (props) => {
  const { data: timeTypes, isLoading } = useGetList('time-types');
  const translate = useTranslate();

  const getTime = (equipmentRent: EquipmentRent) => {
    const timeType = timeTypes.find(
      (timeType) => timeType.id === equipmentRent.timeType
    );
    return `${equipmentRent.time} ${timeType.name}`;
  };

  const getPrice = (equipmentRent: EquipmentRent) => {
    return `${equipmentRent.price.toLocaleString()} ${translate(
      'resources.users.equipmentRents.list.currency'
    )}`;
  };

  if (isLoading) return <Loading />;
  return (
    <List
      resource={`users/${props.userHash}/equipment-rents`}
      sort={{ field: 'name', order: 'ASC' }}
      perPage={Number(process.env.REACT_APP_LIST_SIZE)}
      pagination={<CustomPagination />}
      empty={
        <EmptyList
          setSection={props.setSection}
          sectionCreate={props.sectionCreate}
          route={props.route}
          message="resources.users.equipmentRents.list.empty.title"
          buttonLabel="resources.users.equipmentRents.list.empty.buttonLabel"
        />
      }
      actions={
        <EquipmentRentsListActions
          setSection={props.setSection}
          sectionCreate={props.sectionCreate}
          route={props.route}
        />
      }
    >
      <Datagrid bulkActionButtons={false}>
        <TextField
          source="name"
          label="resources.users.equipmentRents.list.name"
          sortBy="name"
          emptyText="-"
        />
        <FunctionField
          label="resources.users.equipmentRents.list.time"
          render={(record: EquipmentRent) => getTime(record)}
          sortBy={'time'}
        />
        <FunctionField
          label="resources.users.equipmentRents.list.price"
          render={(record: EquipmentRent) => getPrice(record)}
          sortBy={'price'}
        />
        <EquipmentRentsDeleteButton />
        <EquipmentRentsEditButton
          sectionEdit={props.sectionEdit}
          setEquipmentRentToEditId={props.setEquipmentRentToEditId}
          setSection={props.setSection}
          route={props.route}
        />
      </Datagrid>
    </List>
  );
};
