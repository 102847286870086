import React, { useContext, useEffect } from 'react';
import { EquipmentRentsListShared } from '../../../components/shared/equipmentRents/equipmentRentsList/EquipmentRentsListShared';
import { HeaderTitleContext } from '../../../core/context/headerTitleContext';
import { UserHashContext } from '../../../core/context/userHashContext';

export const EquipmentRentsList = () => {
  const { setHeaderTitle } = useContext(HeaderTitleContext);
  const { userHash } = useContext(UserHashContext);

  useEffect(() => {
    setHeaderTitle('resources.equipmentRents.header.title');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <EquipmentRentsListShared userHash={userHash} route={'create'} />;
};
