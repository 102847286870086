import {
  VALIDATION_POSITIVE_NUMBER,
  VALIDATION_REQUIRED
} from '../../../../core/constants/validation.constants';

export function equipmentRentFormValidator(values: any) {
  const errors: any = {};

  if (!values.name) {
    errors.name = VALIDATION_REQUIRED;
  }

  if (!values.time) {
    errors.time = VALIDATION_REQUIRED;
  }

  if (values.time < 1 && typeof values.time == 'number') {
    errors.time = 'validation.moreThanZero';
  }

  if (!values.timeType) {
    errors.timeType = VALIDATION_REQUIRED;
  }

  if (!values.price && typeof values.price == 'object') {
    errors.price = VALIDATION_REQUIRED;
  }

  if (values.price < 0 && typeof values.price == 'number') {
    errors.price = VALIDATION_POSITIVE_NUMBER;
  }

  return errors;
}
