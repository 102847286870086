import React, { useContext, useEffect } from 'react';
import { HeaderTitleContext } from '../../../core/context/headerTitleContext';
import { UserHashContext } from '../../../core/context/userHashContext';
import { LessonListShared } from '../../../components/shared/lessons/lessonsList/LessonListShared';

export const LessonsList = () => {
  const { setHeaderTitle } = useContext(HeaderTitleContext);
  const { userHash } = useContext(UserHashContext);

  useEffect(() => {
    setHeaderTitle('resources.lessons.header.title');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <LessonListShared userHash={userHash} route={'create'} />;
};
