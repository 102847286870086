import { Datagrid, FunctionField, List, TextField } from 'react-admin';
import { CustomPagination } from '../../../../../../components/general/Pagination';
import { EmptyList } from '../../../../../../components/general/EmptyList';
import React from 'react';
import { UserPaymentFavoriteButton } from './UserPaymentFavoriteButton';
import { UserPaymentMethodDeleteButton } from './UserPaymentMethodDeleteButton';

export const UserPaymentMethodsList = (props) => {
  return (
    <List
      resource={`users/${props.userHash}/payment-methods`}
      perPage={Number(process.env.REACT_APP_LIST_SIZE)}
      pagination={<CustomPagination />}
      empty={<EmptyList />}
      actions={false}
    >
      <Datagrid bulkActionButtons={false}>
        <TextField
          source="brand.name"
          label="resources.users.userPaymentMethods.list.brand"
          sortBy="brand"
          emptyText="-"
        />
        <FunctionField
          source="userLimit"
          label="resources.users.userPaymentMethods.list.expirationDate"
          render={(record) => {
            return `${record.expMonth} / ${record.expYear}`;
          }}
          sortable={false}
        />
        <FunctionField
          source="userLimit"
          label="resources.users.userPaymentMethods.list.cardNumber"
          render={(record) => {
            return `**** **** **** ${record.lastFour}`;
          }}
          sortBy={'lastFour'}
        />
        <UserPaymentFavoriteButton />
        <UserPaymentMethodDeleteButton />
      </Datagrid>
    </List>
  );
};
