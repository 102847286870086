import { Translate } from 'react-admin';

export const CALENDAR_START_HOUR = 0;
export const CALENDAR_END_HOUR = 23;
export const CALENDAR_START_MINUTES = 0;
export const CALENDAR_END_MINUTES = 59;
export const CALENDAR_SCROLL_HOUR = 7;
export const CALENDAR_SCROLL_MINUTES = 0;
export const CALENDAR_LESSON_BG_COLOR = '#F06400';
export const CALENDAR_LESSON_COLOR = '#F5F5F5';
export const CALENDAR_AVAILABILITY_COLOR = '#EF9622';
export const CALENDAR_EQUIPMENT_RENT_COLOR = '#F06400';
export const CALENDAR_BOOKING_BG_COLOR = CALENDAR_LESSON_BG_COLOR;
export const CALENDAR_BOOKING_COLOR = CALENDAR_LESSON_COLOR;
export const translateMessages = (translate: Translate) => {
  return {
    agenda: translate('resources.users.calendar.messages.agenda'),
    allDay: translate('resources.users.calendar.messages.allDay'),
    month: translate('resources.users.calendar.messages.month'),
    day: translate('resources.users.calendar.messages.day'),
    today: translate('resources.users.calendar.messages.today'),
    previous: translate('resources.users.calendar.messages.previous'),
    next: translate('resources.users.calendar.messages.next'),
    date: translate('resources.users.calendar.messages.date'),
    noEventsInRange: translate(
      'resources.users.calendar.messages.noEventsInRange'
    ),
    time: translate('resources.users.calendar.messages.time'),
    tomorrow: translate('resources.users.calendar.messages.tomorrow'),
    week: translate('resources.users.calendar.messages.week'),
    work_week: translate('resources.users.calendar.messages.work_week'),
    yesterday: translate('resources.users.calendar.messages.yesterday'),
    january: translate('resources.users.calendar.messages.january')
  };
};
