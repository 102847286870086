import React from 'react';
import { Toolbar } from 'react-admin';
import { Button } from '../../../buttons/Button';

export const ScheduleBookingDialogGroupedToolbar = ({
  onDeleteEvent,
  group
}) => {
  return (
    <div>
      <Toolbar>
        <Button
          label={'resources.schedule.dialog.actions.cancel'}
          onClick={onDeleteEvent}
          disabled={!group}
        />
      </Toolbar>
    </div>
  );
};
