import { useListContext, useTranslate } from 'react-admin';
import React, { useEffect } from 'react';
import { Button } from '../../../../components/shared/buttons/Button';
import { getInvoices } from '../../../../core/services/invoice-api.service';
import {
  getCountry,
  getRegion
} from '../../../../core/services/public-api.service';
import XLSX from 'xlsx';
import { round } from '../../../../core/utils/number-utils';

export const InvoiceExport = ({ filters, status, user }) => {
  const translate = useTranslate();
  const { data: records, filterValues, sort } = useListContext();
  const [recordsToExport, setRecordsToExport] = React.useState([]);

  useEffect(() => {
    getInvoices(
      0,
      0,
      sort.field,
      user ? user : filterValues.user,
      status,
      filterValues
    ).then((data) => {
      setRecordsToExport(data.data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [records]);

  const getDate = (date: number) => {
    return new Date(date).toLocaleDateString();
  };

  const getCountryName = (country: string) => {
    if (!country) return Promise.resolve('-');

    return getCountry(country).then((data) => {
      return data.data.name;
    });
  };

  const getRegionName = (region: string) => {
    if (!region) return Promise.resolve('-');

    return getRegion(region).then((data) => {
      return data.data.name;
    });
  };

  const onExport = () => {
    const exports = recordsToExport.map(async (invoice) => {
      const {
        id,
        user,
        invoiceStatus,
        invoiceBookings,
        vatAmount,
        totalWithTaxes,
        ...invoiceForExport
      } = invoice; // omit backlinks and author
      invoiceForExport.serialCode = invoice.serialCode; // add a field
      invoiceForExport.date = getDate(invoice.date);
      invoiceForExport.total = round(invoice.totalWithTaxes);
      invoiceForExport.subtotal = round(invoice.total);
      invoiceForExport.taxRate = round(invoice.vat);
      invoiceForExport.vat = round(invoice.vatAmount);
      invoiceForExport.status = invoice.invoiceStatus.name;
      invoiceForExport.identification = invoice.user.identification;
      invoiceForExport.name = invoice.user.firstName;
      invoiceForExport.address = invoice.user.address;
      invoiceForExport.country = await getCountryName(invoice.user.country);
      invoiceForExport.region = await getRegionName(invoice.user.region);

      return invoiceForExport;
    });

    Promise.all(exports).then((data) => {
      // eslint-disable-next-line array-callback-return
      data.map((value) => {
        renameKeys(
          value,
          'serialCode',
          translate('resources.invoicing.invoices.list.serialCode')
        );
        renameKeys(
          value,
          'date',
          translate('resources.invoicing.invoices.list.date')
        );
        renameKeys(
          value,
          'total',
          translate('resources.invoicing.invoices.list.total')
        );
        renameKeys(
          value,
          'subtotal',
          translate('resources.invoicing.invoices.list.subtotal')
        );
        renameKeys(
          value,
          'taxRate',
          translate('resources.invoicing.invoices.list.taxRate')
        );
        renameKeys(
          value,
          'vat',
          translate('resources.invoicing.invoices.list.vat')
        );
        renameKeys(
          value,
          'status',
          translate('resources.invoicing.invoices.list.status')
        );
        renameKeys(
          value,
          'identification',
          translate('resources.invoicing.invoices.list.identification')
        );
        renameKeys(
          value,
          'name',
          translate('resources.invoicing.invoices.list.user')
        );
        renameKeys(
          value,
          'address',
          translate('resources.invoicing.invoices.list.address')
        );
        renameKeys(
          value,
          'country',
          translate('resources.invoicing.invoices.list.country')
        );
        renameKeys(
          value,
          'region',
          translate('resources.invoicing.invoices.list.region')
        );
      });

      let ws = XLSX.utils.json_to_sheet(data);
      let wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'sheet');
      XLSX.writeFile(
        wb,
        `${translate('resources.invoicing.invoices.csv.title')}.xlsx`
      );
    });
  };

  const renameKeys = (obj, oldKey, newKey) => {
    obj[newKey] = obj[oldKey];
    delete obj[oldKey];
  };

  return (
    <Button
      label="resources.invoicing.invoices.csv.export"
      onClick={() => onExport()}
    />
  );
};
