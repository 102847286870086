import React from 'react';
import { EquipmentRentCalendarEquipmentRentDialogGrouped } from './EquipmentRentCalendarEquipmentRentDialogGrouped';
import { EquipmentRentCalendarEquipmentRentDialogSimple } from './EquipmentRentCalendarEquipmentRentDialogSimple';

export const EquipmentRentCalendarEquipmentRentDialog = (props: {
  slotInfo;
  openDialog;
  setOpenDialog;
  event;
  view;
  setRefresh;
  selectedUsers;
}) => {
  return (
    <>
      {props.event.group.length > 1 ? (
        <EquipmentRentCalendarEquipmentRentDialogGrouped
          event={props.event}
          setOpenDialog={props.setOpenDialog}
          openDialog={props.openDialog}
          setRefresh={props.setRefresh}
        ></EquipmentRentCalendarEquipmentRentDialogGrouped>
      ) : (
        <EquipmentRentCalendarEquipmentRentDialogSimple
          setOpenDialog={props.setOpenDialog}
          openDialog={props.openDialog}
          event={props.event}
          setRefresh={props.setRefresh}
        ></EquipmentRentCalendarEquipmentRentDialogSimple>
      )}
    </>
  );
};
