import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';
import React from 'react';
import {
  useNotify,
  useRecordContext,
  useTranslate,
  useUpdate
} from 'react-admin';
import { Button } from '../../../../../../../components/shared/buttons/Button';

export const DialogRestorePassword = (props) => {
  const translate = useTranslate();
  const notify = useNotify();
  const [update] = useUpdate('users');
  const record = useRecordContext();

  const onCloseUserRestoreDialog = () => {
    props.setShowRestorePasswordDialog(false);
  };

  const onRestoreUserPassword = () => {
    update(
      'users/restore-password',
      {
        id: record.id
      },
      {
        onSuccess: () => {
          notify('resources.users.notifications.useRestore.success', {
            type: 'success'
          });
          onCloseUserRestoreDialog();
        },
        onError(error: any) {
          notify(error.message, { type: 'warning' });
        }
      }
    );
  };

  return (
    <Dialog
      fullWidth
      open={props.showRestorePasswordDialog}
      onClose={onCloseUserRestoreDialog}
    >
      <DialogTitle>
        {translate('resources.users.restorePasswordModal.title')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {translate('resources.users.restorePasswordModal.message')}
        </DialogContentText>
        <DialogActions>
          <Button
            onClick={onCloseUserRestoreDialog}
            color="secondary"
            label="resources.users.restorePasswordModal.cancelLabel"
            autoFocus
          ></Button>
          <Button
            onClick={onRestoreUserPassword}
            color="primary"
            label="resources.users.restorePasswordModal.acceptLabel"
          ></Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};
