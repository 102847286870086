import {
  NumberInput,
  ReferenceInput,
  required,
  SelectInput,
  TextInput
} from 'react-admin';
import React from 'react';

export const EquipmentRentForm = () => {
  return (
    <>
      <TextInput
        source="name"
        validate={[required()]}
        label="resources.users.equipmentRents.fields.name"
      />
      <NumberInput
        source="time"
        validate={[required()]}
        label="resources.users.equipmentRents.fields.time"
      />
      <ReferenceInput
        source="timeType"
        reference="time-types"
        sort={{ field: 'name', order: 'ASC' }}
      >
        <SelectInput
          optionText="name"
          source="timeType"
          defaultValue=""
          validate={[required()]}
          label=""
        />
      </ReferenceInput>
      <NumberInput
        source="price"
        validate={[required()]}
        label="resources.users.equipmentRents.fields.price"
      />
    </>
  );
};
