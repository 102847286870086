import React from 'react';
import { Create, SimpleForm, useCreate, useNotify } from 'react-admin';
import { equipmentRentFormValidator } from '../equipmentRentsForm/equipmentRentFormValidator';
import { EquipmentRentFormToolbar } from '../equipmentRentsForm/EquipmentRentFormToolbar';
import { EquipmentRentForm } from '../equipmentRentsForm/EquipmentRentForm';
import { useNavigate } from 'react-router-dom';

export const EquipmentRentsCreateShared = (props) => {
  const [create, { isLoading, isError }] = useCreate('equipment-rents');
  const notify = useNotify();
  const navigate = useNavigate();

  const equipmentRentSave = (data) => {
    data = {
      ...data,
      userHash: props.userHash
    };
    create(
      'equipment-rents',
      { data },
      {
        onSuccess: () => {
          notify(
            'resources.users.equipmentRents.notifications.create.success',
            {
              type: 'success'
            }
          );
          if (props.setSection) {
            props.setSection(props.sectionList);
          }

          if (props.route) {
            navigate(-1);
          }
        },
        onError(error: any) {
          notify(error.message, { type: 'warning' });
        }
      }
    );
  };
  return (
    <Create resource={'equipment-rents'}>
      <SimpleForm
        onSubmit={equipmentRentSave}
        validate={(e) => equipmentRentFormValidator(e)}
        className={'form'}
        toolbar={
          <EquipmentRentFormToolbar
            setSection={props.setSection}
            sectionList={props.sectionList}
            route={props.route}
            isLoading={isLoading}
            isError={isError}
          />
        }
      >
        <EquipmentRentForm />
      </SimpleForm>
    </Create>
  );
};
