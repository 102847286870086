import React from 'react';
import { useRecordContext } from 'react-admin';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { IconButton } from '@mui/material';
import { Booking } from '../../../../../../core/models/booking/booking';

export const BookingEditButton = (props) => {
  const record: Booking = useRecordContext(props);

  const handleEdit = () => {
    props.setBookingToEditId(record.id);
    props.setSection(props.sectionView);
  };

  return (
    <IconButton onClick={() => handleEdit()}>
      <ChevronRightIcon />
    </IconButton>
  );
};
