import { get, put } from './httpClient.service';

const DEFAULT_ENTRY_POINT = 'invoices';

export async function updateInvoiceStatus(invoiceHash, status): Promise<any> {
  return put(`${DEFAULT_ENTRY_POINT}/${invoiceHash}/status`, { status });
}

export async function getInvoices(
  _start: number,
  _end: number,
  _sort: string,
  user: string,
  status: number,
  filters: {
    serial: string;
    fromDate: number;
    identification: string;
    country: string;
    region: string;
    city: string;
  }
): Promise<any> {
  return get(`${DEFAULT_ENTRY_POINT}`, {
    _start,
    _end,
    _sort,
    status,
    user,
    ...filters
  });
}

export async function getUserInvoices(
  _start: number,
  _end: number,
  _sort: string,
  user: string,
  status: number,
  filters: {
    serial: string;
    fromDate: number;
    identification: string;
    country: string;
    region: string;
    city: string;
  }
): Promise<any> {
  return get(`${DEFAULT_ENTRY_POINT}`, {
    _start,
    _end,
    _sort,
    status,
    user,
    ...filters
  });
}

export async function getInvoicePdf(invoiceHash: string): Promise<any> {
  return get(`${DEFAULT_ENTRY_POINT}/${invoiceHash}/pdf`, {});
}
