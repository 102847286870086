import moment from 'moment';
import React, { useContext } from 'react';
import {
  Create,
  SimpleForm,
  useCreate,
  useNotify,
  usePermissions,
  useRecordContext
} from 'react-admin';
import { useNavigate } from 'react-router-dom';
import { PRIVILEGE_CLUB } from '../../../../core/constants/privilege.constants';
import { UserHashContext } from '../../../../core/context/userHashContext';
import {
  getMillis,
  getTimeMillisOfDate,
  toDate
} from '../../../../core/utils/date.utils';
import { CourseForm } from '../courseForm/CourseForm';
import { CourseFormToolbar } from '../courseForm/CourseFormToolbar';
import { courseFormValidator } from '../courseForm/courseFormValidator';

export const CourseCreateShared = (props) => {
  const navigate = useNavigate();
  const { userHash: parentHash } = useContext(UserHashContext);
  const [create, { isLoading, isError }] = useCreate('courses');
  const notify = useNotify();
  const permissions = usePermissions();
  const record = useRecordContext();

  const isClub = permissions.permissions === PRIVILEGE_CLUB;

  const getDates = (data) => {
    const dateStart = toDate(data.dateStart);
    const dateEnd = toDate(data.dateEnd);
    const registrationDeadline = toDate(data.registrationDeadline);

    const timeStart =
      typeof data.timeStart === 'object'
        ? getTimeMillisOfDate(data.timeStart)
        : typeof data.timeStart === 'string'
        ? getTimeMillisOfDate(moment().startOf('day').toDate())
        : typeof data.timeStart === 'undefined'
        ? 0
        : data.timeStart;
    const timeEnd =
      typeof data.timeEnd === 'object'
        ? getTimeMillisOfDate(data.timeEnd)
        : typeof data.timeEnd === 'string'
        ? getTimeMillisOfDate(moment().startOf('day').toDate())
        : typeof data.timeEnd === 'undefined'
        ? 0
        : data.timeEnd;

    data.dateStart = getMillis(dateStart);
    data.dateEnd = getMillis(dateEnd);
    data.timeStart = timeStart;
    data.timeEnd = timeEnd;
    data.registrationDeadline = getMillis(registrationDeadline);

    if (isClub) {
      data.parentHash = parentHash;
    }

    if (props.isClub) {
      data.parentHash = record.id;
    }

    return data;
  };

  const courseSave = (data) => {
    data = getDates(data);
    data.smallCourse = props.smallCourses;

    data = {
      ...data,
      userHash: props.isClub
        ? data.userHash
          ? data.userHash
          : record.userHash
        : props.userHash,
      disabled: !data.isEnabled
    };

    create(
      'courses',
      { data },
      {
        onSuccess: () => {
          notify('resources.users.course.notifications.courseCreate.success', {
            type: 'success'
          });
          if (props.setSection) {
            props.setSection(props.sectionList);
          }

          if (props.route) {
            navigate(-1);
          }
        },
        onError(error: any) {
          notify(error.message, { type: 'warning' });
        }
      }
    );
  };

  return (
    <Create resource={'courses'}>
      <SimpleForm
        onSubmit={courseSave}
        validate={(e) => courseFormValidator(e, props.smallCourses)}
        className={'form'}
        toolbar={
          <CourseFormToolbar
            setSection={props.setSection}
            sectionList={props.sectionList}
            route={props.route}
            isLoading={isLoading}
            isError={isError}
          />
        }
      >
        <CourseForm
          smallCourses={props.smallCourses}
          endDate={null}
          startDate={null}
          isClub={props.isClub}
        />
      </SimpleForm>
    </Create>
  );
};
