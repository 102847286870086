import React from 'react';
import { useRecordContext } from 'react-admin';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton } from '@mui/material';
import { DialogDeleteUserLinkedSport } from './DialogDeleteUserLinkedSport';
import { UserSport } from '../../../../core/models/userSport/userSport';

export const UserLinkedSportsDeleteButton = (props) => {
  const record: UserSport = useRecordContext(props);
  const [showDeleteDialog, setShowDeleteDialog] = React.useState(false);
  const id = props.isFavouriteSport ? record.id : record.sport.id;

  const handleDeleteUserLinkedSport = () => setShowDeleteDialog(true);

  return (
    <>
      <IconButton onClick={() => handleDeleteUserLinkedSport()}>
        <DeleteIcon />
      </IconButton>
      <DialogDeleteUserLinkedSport
        showDeleteDialog={showDeleteDialog}
        setShowDeleteDialog={setShowDeleteDialog}
        id={id}
        userHash={props.userHash}
      />
    </>
  );
};
