import React from 'react';
import { Loading, useNotify, useTranslate } from 'react-admin';
import parse from 'html-react-parser';
import Box from '@mui/material/Box';
import { acceptTermsAndConditions } from '../../core/services/termAndCondition-api.service';
import { acceptPrivacyPolicies } from '../../core/services/privacyPolicy-api.service';
import { Button } from '../../components/shared/buttons/Button';

export const TermsAndPolicy = (props) => {
  const translate = useTranslate();
  const notify = useNotify();

  const exit = () => {
    localStorage.setItem('termsAndPrivacyPolicyAccepted', 'true');
    props.setSaveTermsAndPrivacyPolicyAccepted(true);
    notify(
      'resources.termsAndConditionsAndPrivacyPolicy.notifications.accept.success',
      {
        type: 'success'
      }
    );
  };

  if (props.termAndConditionIsLoading) {
    return <Loading />;
  }

  const update = async () => {
    if (props.termAndCondition) {
      await acceptTermsAndConditions(props.termAndCondition?.id);
    }

    if (props.privacyPolicy) {
      await acceptPrivacyPolicies(props.privacyPolicy?.id);
    }

    exit();
  };

  return (
    <>
      <div className="header-logo">
        <img
          src="src/images/logo-nak-sports.png"
          alt="NAK SPORTS"
          width="200"
          height="96"
        />
      </div>
      <main className="auth-page">
        <Box className="auth-page__container">
          {props.termAndCondition && (
            <>
              <h3 className="custom-title">
                {translate(
                  'resources.termsAndConditionsAndPrivacyPolicy.titleTerms'
                )}
              </h3>
              <div>{parse(props.termAndCondition.text)}</div>
            </>
          )}
          {props.privacyPolicy && (
            <>
              <h3 className="custom-title">
                {translate(
                  'resources.termsAndConditionsAndPrivacyPolicy.titlePrivacy'
                )}
              </h3>
              <div>{parse(props.privacyPolicy.text)}</div>
            </>
          )}
          <Button
            className="button-responsive"
            label={'resources.termsAndConditionsAndPrivacyPolicy.buttonLabel'}
            onClick={() => update()}
          />
        </Box>
      </main>
    </>
  );
};
