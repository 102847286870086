import React from 'react';
import { Button } from '../../../../buttons/Button';

export const CalendarAvailableDialogGroupedToolbar = ({
  onDeleteEvent,
  onDeleteSeries,
  group
}) => {
  return (
    <div>
      <Button
        label="ra.action.delete"
        onClick={onDeleteEvent}
        disabled={!group}
        style={{ marginLeft: '8px' }}
      />
      <Button
        label="resources.users.calendar.courseEvent.actions.deleteSeries"
        onClick={onDeleteSeries}
        disabled={!group}
        style={{ marginLeft: '8px' }}
      />
    </div>
  );
};
