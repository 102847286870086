import React, { useEffect, useState } from 'react';
import { Views } from 'react-big-calendar';
import { getEquipmentRentBookings } from '../../../../core/services/equipment-rent-api.service';
import { getCurrentDate } from '../../../../core/utils/date.utils';
import { EquipmentRentsSchedulerView } from './EquipmentRentsSchedulerView';
import {
  generateCalendarEventsFromBookings,
  getFirstMillisOfView,
  getLastMillisOfView
} from './equipment-rents-schedule.utils';

export const EquipmentRentsSchedulerShared = (props: {
  route: string;
  userHash: string;
}) => {
  const [date, setDate] = useState(getCurrentDate());
  const [view, setView] = useState(Views.WEEK);
  const [bookings, setBookings] = React.useState([]);
  const [refresh, setRefresh] = useState(false);
  const [equipmentRent, setEquipmentRent] = useState(null);
  const [openEquipmentRentEventDialog, setOpenEquipmentRentEventDialog] =
    useState(false);

  useEffect(() => {
    getEquipmentRentBookings(
      equipmentRent,
      getFirstMillisOfView(date, view),
      getLastMillisOfView(date, view),
      props.userHash
    ).then((data) => {
      const bokings = generateCalendarEventsFromBookings(data.data);
      setBookings(bokings);
      setRefresh(false);
    });
    // eslint-disable-next-line
  }, [equipmentRent, date, view, refresh]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <EquipmentRentsSchedulerView
      equipmentRent={equipmentRent}
      date={date}
      setDate={setDate}
      view={view}
      setView={setView}
      bookings={bookings}
      setRefresh={setRefresh}
      setOpenEquipmentRentEventDialog={setOpenEquipmentRentEventDialog}
      openEquipmentRentEventDialog={openEquipmentRentEventDialog}
      setEquipmentRent={setEquipmentRent}
      userHash={props.userHash}
    />
  );
};
