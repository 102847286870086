import moment from 'moment';
import * as R from 'ramda';

export interface TimeDetail {
  hours: number;
  minutes: number;
  seconds: number;
}

export const getTimeDetail = (time: number): TimeDetail => {
  const totalSeconds = Math.floor(time / 1000);
  const totalMinutes = Math.floor(totalSeconds / 60);
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  const seconds = totalSeconds % 60;

  return { hours, minutes, seconds };
};

export const getTimeFormat = R.curry(
  (format = '{HOURS}:{MINS}', time: number) => {
    const timeDetail = getTimeDetail(time);

    return format
      .replace('{HOURS}', timeDetail.hours.toString())
      .replace('{MINS}', timeDetail.minutes.toString().padStart(2, '0'))
      .replace('{SECS}', timeDetail.seconds.toString().padStart(2, '0'));
  }
);

export const getSecondsFromMillis = (millis: number) => millis / 1000;

export const getMinutesFromMillis = (millis: number) =>
  getSecondsFromMillis(millis) / 60;

export const getHoursFromMillis = (millis: number) =>
  getMinutesFromMillis(millis) / 60;

export const formatAmPm = (date: Date) => {
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? 'pm' : 'am';
  const hours12 = hours % 12;
  const hours12String = hours12 ? hours12.toString() : '12';
  const minutesString = minutes < 10 ? '0' + minutes : minutes.toString();
  return hours12String + ':' + minutesString + ' ' + ampm;
};

export const getTimeFromTo = (from: number, to: number, gmtOffset?: string) => {
  const fromDate = new Date(from);
  const toDate = new Date(to);

  return `${formatAmPm(fromDate)} - ${formatAmPm(toDate)}`;
};

export const getDayFromCurrentWeek = (day: number) => {
  const today = new Date();
  const dayOfWeek = today.getDay();
  const diff = day - dayOfWeek;
  return new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() + diff
  );
};

export const getHHMM = (date: Date | number) => {
  return moment(new Date(date)).format('HH:mm');
};

export const getMilliesFromHHMM = (time: string) => {
  const [hours, minutes] = time.split(':');
  return (parseInt(hours) * 60 + parseInt(minutes)) * 60 * 1000;
};

export const isBetweenTimeHHMM = (time: string, from: string, to: string) => {
  const timeMillis = getMilliesFromHHMM(time);
  const fromMillis = getMilliesFromHHMM(from);
  const toMillis = getMilliesFromHHMM(to);

  return timeMillis > fromMillis && timeMillis < toMillis;
};

export const getInputDateFormFromMillis = (timestamp: number) => {
  const date = new Date(timestamp);
  return `${date.getFullYear()}-${(date.getMonth() + 1)
    .toString()
    .padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
};

export const getTimestampFromDate = (date: Date) => {
  return date.getTime();
};

export const getDateFromTimestamp = (timestamp: number) => {
  return new Date(timestamp);
};

export const getTimeFromTimestamp = (timestamp: number) => {
  return moment(timestamp).format('HH:mm');
};

export const getDayTypeNumberFromTimestamp = (timestamp: number) => {
  const date = getDateFromTimestamp(timestamp);
  const number = date.getDay();
  return number === 0 ? 7 : number;
};

export const getLocaleFromTimestamp = (timestamp: number, options = {}) => {
  return getDateFromTimestamp(timestamp).toLocaleDateString(
    navigator.language,
    options
  );
};

export const changeHoursAndMinutesToTimestamp = (
  timestamp: number,
  hhmm: string
) => {
  const [hours, minutes] = hhmm.split(':');
  const date = new Date(timestamp);
  date.setHours(parseInt(hours));
  date.setMinutes(parseInt(minutes));
  return date.getTime();
};

export const millisecondsToTime = (milliseconds: number) => {
  const totalMinutes = Math.floor(milliseconds / (1000 * 60));
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  const formattedHours = hours.toString().padStart(2, '0');
  const formattedMinutes = minutes.toString().padStart(2, '0');
  return `${formattedHours}:${formattedMinutes}`;
};
