import { Loading, required, SelectInput, useGetList } from 'react-admin';
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

export const FilterCity = (props) => {
  const { getValues } = useFormContext();
  const [cities, setCities] = React.useState([]);
  const { data: citiesData, isLoading } = useGetList('public/cities', {
    filter: { region: getValues('region') },
    pagination: { page: 1, perPage: 99999 },
    sort: { field: 'name', order: 'ASC' }
  });

  useEffect(() => {
    setCities(citiesData);
  }, [citiesData]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <SelectInput
      source="city"
      optionText="name"
      label="resources.users.fields.city"
      validate={[required()]}
      defaultValue=""
      choices={cities || []}
    />
  );
};
