import React from 'react';
import {
  FunctionField,
  Show,
  SimpleShowLayout,
  TextField,
  useGetList,
  useTranslate
} from 'react-admin';
import { Button } from '../../../../../../components/shared/buttons/Button';
import {
  getDayTypeNumberFromTimestamp,
  getLocaleFromTimestamp,
  getTimeFromTimestamp,
  millisecondsToTime
} from '../../../../../../core/utils/time-utils';

export const BookingView = (props) => {
  const translate = useTranslate();

  const getDate = (date) => {
    return getLocaleFromTimestamp(date, {
      year: 'numeric',
      month: 'long',
      day: '2-digit'
    });
  };

  const isSmallCourse = (record) => record.event?.course?.isSmallCourse;

  const getBookingType = (record) => {
    if (record.isCourse) {
      if (isSmallCourse(record)) {
        return translate('resources.users.bookings.list.type.smallCourse');
      }
      return translate('resources.users.bookings.list.type.course');
    }

    if (record.isLesson) {
      return translate('resources.users.bookings.list.type.lesson');
    }

    if (record.isEquipmentRent) {
      return translate('resources.users.bookings.list.type.equipmentRent');
    }
  };

  const { data: dayTypes } = useGetList('day-types');

  const getCourseShortDays = (days: number[]) => {
    return days
      ? days
          .map((day) => {
            return dayTypes?.find((dayType) => dayType.id === day).shortName;
          })
          .join('- ')
      : '';
  };

  const getTime = (record) => {
    const start = record.isCourse
      ? millisecondsToTime(record.event.course.timeStart)
      : getTimeFromTimestamp(record.event.startDate);
    const end = record.isCourse
      ? millisecondsToTime(record.event.course.timeEnd)
      : getTimeFromTimestamp(record.event.endDate);
    return `${start} - ${end}`;
  };

  return (
    <Show resource="bookings" id={props.bookingToEditId}>
      <SimpleShowLayout>
        <TextField
          label="resources.users.bookings.list.name"
          source="event.eventTitle"
          emptyText="-"
        />
        <TextField
          label="resources.users.bookings.list.teacherName"
          source="teacherName"
          emptyText="-"
        />
        <FunctionField
          label="resources.users.bookings.list.bookingType"
          render={(record) => getBookingType(record)}
        />
        <FunctionField
          label="resources.users.bookings.list.date"
          render={(record) => getDate(record.date)}
        />
        <FunctionField
          label="resources.users.bookings.list.startDate"
          render={(record) => getDate(record.startDate)}
        />
        <FunctionField
          label="resources.users.bookings.list.endDate"
          render={(record) => getDate(record.endDate)}
        />
        <FunctionField
          label="resources.users.bookings.list.weekDay"
          render={(record) =>
            getCourseShortDays(
              record.isCourse
                ? record.event.course.dayTypes
                : [getDayTypeNumberFromTimestamp(record.event.startDate)]
            )
          }
          sortable={false}
        />
        <FunctionField
          label="resources.users.bookings.list.time"
          render={(record) => getTime(record)}
          sortable={false}
        />
        <Button
          label="resources.users.bookings.list.back"
          onClick={() => props.setSection(props.sectionList)}
        />
      </SimpleShowLayout>
    </Show>
  );
};
