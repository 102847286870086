import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import React, { useState } from 'react';
import {
  Button,
  usePermissions,
  useRecordContext,
  useTranslate
} from 'react-admin';
import {
  PRIVILEGE_ADMIN,
  PRIVILEGE_TEACHER
} from '../../../../../../core/constants/privilege.constants';
import { DialogDeleteEquipmentRentEvent } from '../DialogDeleteEquipmentRentEvent';

export const CalendarEquipmentRentEventDialogSimpleEvent = (props) => {
  const translate = useTranslate();
  const record = useRecordContext();
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const permissions = usePermissions();

  const isTeacher = permissions.permissions === PRIVILEGE_TEACHER;
  const isAdmin = permissions.permissions === PRIVILEGE_ADMIN;

  const formattedEnd = props.event?.end.toLocaleString();
  const formattedStart = props.event?.start.toLocaleString();
  const teacher = `${props.event?.userName} ${
    props.event?.userLastName ? props.event?.userLastName : ''
  }`;

  const onCloseDialog = () => {
    props.setOpenDialog(false);
  };

  const onDeleteEvent = () => {
    setShowDeleteDialog(true);
  };

  return (
    <>
      <Dialog fullWidth open={props.openDialog} onClose={onCloseDialog}>
        <DialogTitle>{props.event?.modalTitle}</DialogTitle>
        <DialogContent>
          {isAdmin && (
            <p hidden={!record.isClub}>
              {translate('resources.users.calendar.equipmentRent.club')}
              {record.firstName}
            </p>
          )}
          <p hidden={isTeacher}>
            {translate('resources.users.calendar.equipmentRent.teacher')}
            {teacher}
          </p>
          <p>
            {translate('resources.users.calendar.equipmentRent.start')}
            {formattedStart.slice(0, -3)}
          </p>
          <p>
            {translate('resources.users.calendar.equipmentRent.end')}
            {formattedEnd.slice(0, -3)}
          </p>
          <div className="dialog-actions">
            <Button
              onClick={onDeleteEvent}
              label={'resources.users.calendar.courseEvent.actions.delete'}
            />
          </div>
        </DialogContent>
      </Dialog>
      <DialogDeleteEquipmentRentEvent
        event={props.event}
        showDeleteDialog={showDeleteDialog}
        setShowDeleteDialog={setShowDeleteDialog}
        setOpenEquipmentRentEventDialog={props.setOpenDialog}
        setRefresh={props.setRefresh}
      />
    </>
  );
};
