import { Dialog, DialogTitle, DialogContent } from '@mui/material';
import React from 'react';
import {
  useTranslate,
  useUpdate,
  useNotify,
  useRedirect,
  Toolbar,
  SaveButton,
  Edit,
  SimpleForm,
  TextInput,
  required
} from 'react-admin';
import { useParams } from 'react-router-dom';

export const DialogDisableUser = (props) => {
  const translate = useTranslate();
  const [update] = useUpdate('users');
  const notify = useNotify();
  const redirect = useRedirect();
  const { privilege } = useParams();

  const ModalToolbar = (props) => (
    <Toolbar {...props}>
      <SaveButton />
    </Toolbar>
  );

  const onCloseUserDisableDialog = () => {
    props.setShowDisableDialog(false);
  };

  const onDisableUser = (data) => {
    const id = data['id'];
    update(
      'users/disable',
      {
        id,
        data: {
          text: data['text']
        }
      },
      {
        onSuccess: () => {
          notify('resources.users.notifications.userDisable.success', {
            type: 'success'
          });
          onCloseUserDisableDialog();
          redirect(`/${privilege}`);
        },
        onError(error: any) {
          notify(error.message, { type: 'warning' });
        }
      }
    );
  };

  return (
    <Dialog
      fullWidth
      open={props.showDisableDialog}
      onClose={onCloseUserDisableDialog}
    >
      <DialogTitle>
        {translate('resources.users.disableModal.title')}
      </DialogTitle>
      <DialogContent>
        <Edit>
          <SimpleForm onSubmit={onDisableUser} toolbar={<ModalToolbar />}>
            <TextInput
              source="text"
              label="resources.users.disableModal.field.reason"
              validate={[required()]}
              defaultValue=""
              multiline
              fullWidth
            />
          </SimpleForm>
        </Edit>
      </DialogContent>
    </Dialog>
  );
};
