import React from 'react';

import { SaveButton, Toolbar } from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../buttons/Button';

export const RoomFormToolbar = (props) => {
  const {
    resetField,
    formState: { isSubmitted, isValid, isDirty }
  } = useFormContext();
  const navigate = useNavigate();

  const clearFields = () => {
    resetField('name');
    resetField('userLimit');

    if (props.route) {
      navigate(props.route);
    }

    if (props.setSection) {
      props.setSection(props.sectionList);
    }
  };

  return (
    <div>
      <Toolbar>
        <Button
          onClick={() => clearFields()}
          label="resources.users.actions.cancel"
        ></Button>
        <SaveButton
          label="resources.users.actions.saveChanges"
          disabled={
            props.isLoading ||
            (isSubmitted && !props.isError && isValid && !isDirty)
          }
        />
      </Toolbar>
    </div>
  );
};
