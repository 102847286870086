import React from 'react';
import { SaveButton, Toolbar } from 'react-admin';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../../../components/shared/buttons/Button';
import { useFormContext } from 'react-hook-form';

export const TermsAndConditionsFormToolbar = ({ isLoading, isError }) => {
  const {
    formState: { isSubmitted, isValid, isDirty }
  } = useFormContext();
  const navigate = useNavigate();
  const goToList = () => {
    navigate('/terms-and-conditions');
  };

  return (
    <div>
      <Toolbar>
        <Button
          onClick={() => goToList()}
          label="resources.users.actions.cancel"
        ></Button>
        <SaveButton
          label="resources.users.actions.saveChanges"
          disabled={
            isLoading || (isSubmitted && !isError && isValid && !isDirty)
          }
        />
      </Toolbar>
    </div>
  );
};
