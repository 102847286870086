import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography
} from '@mui/material';
import React, { Fragment, useState } from 'react';
import {
  RadioButtonGroupInput,
  SimpleForm,
  useGetList,
  usePermissions,
  useRecordContext,
  useTranslate
} from 'react-admin';
import {
  PRIVILEGE_ADMIN,
  PRIVILEGE_TEACHER
} from '../../../../../core/constants/privilege.constants';
import { Booking } from '../../../../../core/models/booking/booking';
import { getTimeDetail } from '../../../../../core/utils/time-utils';
import { DialogCancelBooking } from '../DialogCancelBooking';
import { ScheduleBookingDialogGroupedToolbar } from './ScheduleBookingDialogGroupedToolbar';

export const ScheduleBookingDialogGrouped = (props) => {
  const translate = useTranslate();
  const record = useRecordContext();
  const lessonTypes = useGetList('lessons/lesson-types');
  const [showCancelDialog, setShowCancelDialog] = useState(false);
  const [group, setGroup] = useState(null);
  const permissions = usePermissions();
  const lesson = props.booking?.group[0]?.event?.lessonEvent?.lesson ?? null;

  const isAdmin = permissions.permissions === PRIVILEGE_ADMIN;
  const isTeacher = permissions.permissions === PRIVILEGE_TEACHER;

  const formattedStart = props.booking.start.toLocaleString();
  const formattedEnd = props.booking.end.toLocaleString();

  const onCloseDialog = () => {
    props.setOpenDialog(false);
  };

  const onCancelBooking = () => {
    setShowCancelDialog(true);
  };

  const getLessonType = () => {
    const lessonType = lessonTypes.data
      ? lessonTypes.data.find((lt) => lt.id === lesson.lessonType)
      : null;

    return lessonType ? lessonType.name : '-';
  };

  const getDuration = () => {
    const { hours, minutes } = getTimeDetail(props.booking.duration);
    return `${hours}h ${minutes}m`;
  };

  const findBookingFromChoice = (id) => {
    if (props.booking.group.length === 0) return null;
    return props.booking.group.find((booking) => booking.id === id);
  };

  const createChoicesFromGroup = () => {
    const choices = [];
    if (props.booking && Array.isArray(props.booking.group)) {
      props.booking.group.forEach((booking: Booking) => {
        choices.push({
          id: booking?.id,
          name: `${booking?.event?.eventTitle} - ${booking?.user?.firstName} ${booking?.user?.lastName}`
        });
      });
    }
    return choices;
  };

  const totalAssistants = props?.booking?.group
    .map((g) => g.numberOfStudents)
    .reduce((a, b) => a + b, 0);

  return (
    <>
      <Dialog fullWidth open={props.openDialog} onClose={onCloseDialog}>
        <DialogTitle>{props.booking.modalTitle}</DialogTitle>
        <DialogContent className="event-dialog">
          <Grid container spacing={2}>
            <Grid item xs={5} md={5}>
              {isAdmin && record.isClub && (
                <>
                  <Typography fontWeight="bold">
                    {translate('resources.schedule.dialog.event.club')}
                  </Typography>
                  <Typography>{record.firstName}</Typography>
                </>
              )}
              {isTeacher && (
                <>
                  <Typography fontWeight="bold">
                    {translate('resources.schedule.dialog.event.teacher')}
                  </Typography>
                  <Typography>{props.booking.teacherName}</Typography>
                </>
              )}
              {lesson && props.booking.lessonHash && (
                <>
                  <Typography fontWeight="bold">
                    {translate('resources.schedule.dialog.event.lessonType')}
                  </Typography>
                  <Typography>{getLessonType()}</Typography>
                </>
              )}
              <Typography fontWeight="bold">
                {translate('resources.schedule.dialog.event.start')}
              </Typography>
              <Typography>{formattedStart.slice(0, -3)}</Typography>
              <Typography fontWeight="bold">
                {translate('resources.schedule.dialog.event.end')}
              </Typography>
              <Typography>{formattedEnd.slice(0, -3)}</Typography>
              {!!props.booking.duration && (
                <>
                  <Typography fontWeight="bold">
                    {translate('resources.schedule.dialog.event.duration')}
                  </Typography>
                  <Typography>{getDuration()}</Typography>
                </>
              )}
              {props.booking.teacherName && (
                <>
                  <Typography fontWeight="bold">
                    {translate('resources.schedule.dialog.event.teacher')}
                  </Typography>
                  <Typography>{props.booking.teacherName}</Typography>
                </>
              )}
              {props.booking.room && (
                <>
                  <Typography fontWeight="bold">
                    {translate('resources.schedule.dialog.event.place')}
                  </Typography>
                  <Typography>{props.booking.room.name}</Typography>
                </>
              )}
            </Grid>
            {props?.booking?.group && (
              <Grid item xs={5} md={5}>
                <Typography variant="h6" fontWeight="bold">
                  ({totalAssistants ?? 0}){' '}
                  {translate('resources.schedule.dialog.event.assistants')}
                </Typography>
                {props?.booking?.group?.map((g) =>
                  g.bookingTargets?.map(
                    (target) =>
                      target.name && (
                        <Fragment key={target.hash}>
                          <Typography>
                            - {target.name ?? '-'}{' '}
                            {target.age ? `(${target.age})` : ''}
                          </Typography>
                        </Fragment>
                      )
                  )
                )}
              </Grid>
            )}
          </Grid>
          <SimpleForm
            className={'form'}
            toolbar={
              <ScheduleBookingDialogGroupedToolbar
                onDeleteEvent={onCancelBooking}
                group={group}
              />
            }
          >
            <RadioButtonGroupInput
              label="resources.users.calendar.lessonEvent.group.title"
              source="group"
              choices={createChoicesFromGroup()}
              onChange={(e) => {
                setGroup(e?.target?.value);
              }}
              translateChoice={false}
              key={props.booking.id}
              className="radiogroup radiogroup-modal"
            ></RadioButtonGroupInput>
          </SimpleForm>
        </DialogContent>
      </Dialog>
      <DialogCancelBooking
        booking={findBookingFromChoice(group)}
        showCancelDialog={showCancelDialog}
        setShowCancelDialog={setShowCancelDialog}
        setOpenScheduleBookingDialog={props.setOpenDialog}
        setRefresh={props.setRefresh}
      />
    </>
  );
};
