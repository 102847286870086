import React from 'react';
import { useListContext } from 'react-admin';
import { Button } from '../../../../components/shared/buttons/Button';
import { getXMLs } from '../../../../core/services/paymentRequest-api.service';
import { downloadFile } from '../../../../core/utils/file-utils';
import { PAYMENT_REQUEST_DOWNLOAD_ZIP_DEFAULT_NAME } from '../../../../core/constants/paymentRequest.constants';

export const PaymentRequestDownloadXMLBulkActionButton = () => {
  const { selectedIds } = useListContext();

  const handleClick = async () => {
    getXMLs(selectedIds).then((response) => {
      downloadFile(
        response.data,
        `${PAYMENT_REQUEST_DOWNLOAD_ZIP_DEFAULT_NAME}.xml`
      );
    });
  };

  return (
    <Button
      onClick={() => handleClick()}
      label={'resources.invoicing.paymentRequest.list.download'}
      className={'bulk-action-button'}
    />
  );
};
