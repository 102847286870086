import React from 'react';
import {
  useNotify,
  useRecordContext,
  useRefresh,
  useTranslate,
  useUpdate
} from 'react-admin';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import { IconButton, Popover, Typography } from '@mui/material';
import { Lesson } from '../../../../core/models/lesson/lesson';

export const LessonStatusButton = (props) => {
  const record: Lesson = useRecordContext(props);
  const [update] = useUpdate();
  const notify = useNotify();
  const refresh = useRefresh();
  const translate = useTranslate();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const getPopOverLabel = () => {
    if (record.isDisabled) {
      return translate('resources.users.lessons.popover.disabled');
    }
    return translate('resources.users.lessons.popover.enabled');
  };

  const handleStatus = (id: string, disabled: boolean) => {
    const data = { disabled };
    update(
      'lessons/status',
      { id, data },
      {
        onSuccess: () => {
          notify(
            'resources.users.notifications.userEdit.lesson.status.success',
            {
              type: 'success'
            }
          );
          refresh();
        },
        onError(error: any) {
          notify(error.message, { type: 'warning' });
        }
      }
    );
  };

  return (
    <>
      <div
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        <IconButton onClick={() => handleStatus(record.id, !record.isDisabled)}>
          {record.isDisabled ? (
            <ToggleOffIcon />
          ) : (
            <ToggleOnIcon className="toggle-active" />
          )}
        </IconButton>
      </div>

      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none'
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography sx={{ p: 1 }}>{getPopOverLabel()}</Typography>
      </Popover>
    </>
  );
};
