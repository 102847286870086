import React from 'react';
import { Datagrid, FunctionField, List } from 'react-admin';
import { useLocation } from 'react-router-dom';
import { EmptyList } from '../../../../../components/general/EmptyList';
import { CustomPagination } from '../../../../../components/general/Pagination';
import { UserActiveVouchersListExpand } from './UserActiveVoucherListExpand';

export const UserActiveVouchersList = () => {
  const location = useLocation();
  const parts = location.pathname.split('/');
  const userHash = parts.pop();

  if (!userHash) {
    return <></>;
  }

  return (
    <>
      <List
        resource={`users/${userHash}/active-user-vouchers`}
        filter={{ students: true }}
        exporter={false}
        perPage={Number(process.env.REACT_APP_LIST_SIZE)}
        pagination={<CustomPagination />}
        actions={false}
        empty={<EmptyList />}
      >
        <Datagrid
          bulkActionButtons={false}
          expand={<UserActiveVouchersListExpand />}
        >
          <FunctionField
            source="firstName"
            label="resources.activeVouchers.list.user"
            render={(record) => `${record.firstName} ${record.lastName}`}
          />
        </Datagrid>
      </List>
    </>
  );
};
