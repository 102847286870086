import React from 'react';
import { Logout, Menu } from 'react-admin';
import { CustomLocaleButton } from '../../shared/buttons/CustomLocaleButton';

export const CustomMenu = () => {
  return (
    <Menu>
      <div className="logo-sidebar">
        <img
          src="src/images/nak-sports-icon.svg"
          alt="NAK SPORTS"
          width="56"
          height="55"
        />
      </div>
      <CustomLocaleButton />
      <Logout />;
    </Menu>
  );
};
