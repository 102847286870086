import { Loading, required, SelectInput, useGetList } from 'react-admin';
import React from 'react';

export const FilterSport = (props) => {
  const { data: sports, isLoading } = useGetList('sports', {
    sort: { field: 'name', order: 'ASC' }
  });

  if (isLoading) {
    return <Loading />;
  }

  return (
    <SelectInput
      source="filterSport"
      optionText="name"
      label="resources.users.fields.firstSport"
      validate={[required()]}
      defaultValue=""
      choices={sports}
    />
  );
};
