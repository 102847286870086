import React from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { usePermissions, useTranslate } from 'react-admin';
import { PRIVILEGE_ADMIN } from '../../../core/constants/privilege.constants';
import { PaymentRequestList } from './paymentRequestsList/PaymentRequestList';
import {
  PAYMENT_REQUEST_STATUS_EMITTED,
  PAYMENT_REQUEST_STATUS_PAID,
  PAYMENT_REQUEST_STATUS_PENDING
} from '../../../core/constants/paymentRequest.constants';

export const PaymentRequestsOverview = () => {
  const translate = useTranslate();
  const permissions = usePermissions();
  const isAdmin = permissions.permissions === PRIVILEGE_ADMIN;

  if (!isAdmin) {
    return <PaymentRequestList status={null} />;
  }

  return (
    <>
      <Tabs>
        <TabList>
          <Tab>
            {translate('resources.invoicing.paymentRequest.tabs.pending')}
          </Tab>
          <Tab>
            {translate('resources.invoicing.paymentRequest.tabs.emitted')}
          </Tab>
          <Tab>{translate('resources.invoicing.paymentRequest.tabs.paid')}</Tab>
        </TabList>
        <TabPanel>
          <PaymentRequestList status={PAYMENT_REQUEST_STATUS_PENDING} />
        </TabPanel>
        <TabPanel>
          <PaymentRequestList status={PAYMENT_REQUEST_STATUS_EMITTED} />
        </TabPanel>
        <TabPanel>
          <PaymentRequestList status={PAYMENT_REQUEST_STATUS_PAID} />
        </TabPanel>
      </Tabs>
    </>
  );
};
