import {
  Datagrid,
  DateField,
  FunctionField,
  List,
  TextField,
  useTranslate
} from 'react-admin';
import { CustomPagination } from '../../../../components/general/Pagination';
import { EmptyList } from '../../../../components/general/EmptyList';
import React, { useContext, useEffect, useState } from 'react';
import { HeaderTitleContext } from '../../../../core/context/headerTitleContext';
import { Invoice } from '../../../../core/models/invoice/invoice';
import { getCountries } from '../../../../core/services/public-api.service';
import { RegionField } from './RegionField';
import { StatusField } from './StatusField';
import { InvoiceFilters } from './invoiceFilters/InvoiceFilters';
import { PdfButton } from './PdfButton';
import { InvoiceListActions } from './InvoiceListActions';
import { InvoiceSmallFilters } from './invoiceFilters/InvoiceSmallFilters';
import { InvoicesUpdateStatusButton } from './InvoicesUpdateStatusButton';
import { formatDecimal } from '../../../../core/utils/number-utils';

const InvoiceBulkActions = (props) => (
  <>
    <InvoicesUpdateStatusButton />
  </>
);

export const InvoicesList = ({ status, user }) => {
  const { setHeaderTitle } = useContext(HeaderTitleContext);
  const [countries, setCountries] = useState([]);
  const translate = useTranslate();

  useEffect(() => {
    setHeaderTitle('resources.invoicing.invoices.header.title');
    getCountries().then((data) => {
      setCountries(data.data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getTotal = (invoice: Invoice) => {
    return `${formatDecimal(invoice.totalWithTaxes)} ${translate(
      'resources.invoicing.invoices.list.currency'
    )}`;
  };

  const getCountry = (country: string) => {
    const target = countries.find((item) => item.id === country);
    return target ? target.name : '-';
  };

  return (
    <List
      resource={'invoices'}
      sort={{ field: 'serialCode', order: 'DESC' }}
      filters={user ? InvoiceSmallFilters : InvoiceFilters}
      perPage={Number(process.env.REACT_APP_LIST_SIZE)}
      pagination={<CustomPagination />}
      empty={<EmptyList />}
      className="invoice-list"
      exporter={false}
      actions={<InvoiceListActions status={status} user={user} />}
      filter={{ status, user }}
    >
      <Datagrid bulkActionButtons={<InvoiceBulkActions />}>
        <TextField
          source="serialCode"
          label="resources.invoicing.invoices.list.serialCode"
          emptyText="-"
        />
        <DateField
          source="date"
          label={'resources.invoicing.invoices.list.date'}
          emptyText="-"
        />
        <FunctionField
          sortBy="total"
          label="resources.invoicing.invoices.list.total"
          render={(record: Invoice) => getTotal(record)}
        />

        {user && (
          <TextField
            source="invoiceStatus.name"
            label="resources.invoicing.invoices.list.status"
            emptyText="-"
            sortable={false}
          />
        )}
        {!user && (
          <StatusField label="resources.invoicing.invoices.list.status" />
        )}
        <TextField
          source="user.identification"
          label="resources.invoicing.invoices.list.identification"
          emptyText="-"
        />
        <TextField
          source="user.firstName"
          label="resources.invoicing.invoices.list.user"
          emptyText="-"
        />
        <TextField
          source="user.address"
          label="resources.invoicing.invoices.list.address"
          emptyText="-"
        />
        <FunctionField
          source="user.country"
          label="resources.invoicing.invoices.list.country"
          sortBy="user.country"
          render={(record: Invoice) => getCountry(record.user.country)}
        />
        <RegionField
          source="user.region"
          label="resources.invoicing.invoices.list.region"
          sortBy="user.region"
        />
        <PdfButton />
      </Datagrid>
    </List>
  );
};
