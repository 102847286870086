import React, { useContext, useEffect, useState } from 'react';
import {
  Edit,
  Loading,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
  required,
  useNotify,
  useUpdate
} from 'react-admin';
import { HeaderTitleContext } from '../../../core/context/headerTitleContext';
import { UserHashContext } from '../../../core/context/userHashContext';
import { getUserInvoiceData } from '../../../core/services/user-api.service';
import { removeWhiteSpaces } from '../../../core/utils/string-utils';
import { validateNoSpaces } from '../../../core/utils/validation-utils';

export const InvoicingData = () => {
  const { setHeaderTitle } = useContext(HeaderTitleContext);
  const { userHash } = useContext(UserHashContext);
  const [invoiceData, setInvoiceData] = useState(null);
  const [update] = useUpdate();
  const notify = useNotify();

  useEffect(() => {
    setHeaderTitle('resources.configuration.invoicingDataSettings.title');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getUserInvoiceData(userHash).then((data) => {
      setInvoiceData(data.data);
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const invoiceDataSave = (data) => {
    const updatedData = {
      ...data,
      accountNumber: removeWhiteSpaces(data['accountNumber'])
    };

    update(
      'user-invoices-data',
      { id: invoiceData.id, data: updatedData },
      {
        onSuccess: () => {
          notify('resources.users.invoiceData.notifications.update.success', {
            type: 'success'
          });
        },
        onError(error: any) {
          notify(error.message, { type: 'warning' });
        }
      }
    );
  };

  if (!invoiceData) return <Loading />;

  return (
    <>
      <Edit resource="user-invoices-data" id={invoiceData?.id}>
        <SimpleForm
          onSubmit={invoiceDataSave}
          record={invoiceData}
          toolbar={
            <div>
              <Toolbar>
                <SaveButton label="resources.users.actions.saveChanges" />
              </Toolbar>
            </div>
          }
          className={'form'}
        >
          <TextInput
            source="accountNumber"
            validate={[required(), validateNoSpaces]}
            label="resources.users.invoiceData.fields.accountNumber"
          />
        </SimpleForm>
      </Edit>
    </>
  );
};
