import React, { useEffect, useState } from 'react';
import { FunctionField, useRecordContext } from 'react-admin';
import { getRegions } from '../../../../core/services/public-api.service';
import { Invoice } from '../../../../core/models/invoice/invoice';

export const RegionField = (props: any) => {
  const record = useRecordContext(props);
  const [regions, setRegions] = useState([]);

  useEffect(() => {
    getRegions(record.user.country, 0, 99999, 'id').then((data) => {
      setRegions(data.data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getRegion = (region: string) => {
    const target = regions.find((item) => item.id === region);
    return target ? target.name : '-';
  };

  return (
    <FunctionField
      label="resources.invoicing.invoices.list.country"
      render={(record: Invoice) => getRegion(record.user.region)}
    />
  );
};
