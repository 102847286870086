import { Box } from '@mui/material';
import moment from 'moment';
import React, { useCallback, useRef, useState } from 'react';
import { useLocaleState, useTranslate } from 'react-admin';
import { Views, momentLocalizer } from 'react-big-calendar';
import { Calendar } from 'react-big-calendar/dist/react-big-calendar.esm';
import {
  CALENDAR_END_HOUR,
  CALENDAR_END_MINUTES,
  CALENDAR_SCROLL_HOUR,
  CALENDAR_SCROLL_MINUTES,
  CALENDAR_START_HOUR,
  CALENDAR_START_MINUTES,
  translateMessages
} from '../../../../core/constants/calendar.constants';
import { EventSlot } from '../../../../core/models/event/eventSlot';
import { generateColor } from '../../../../core/utils/color.utils';
import { getCurrentDate } from '../../../../core/utils/date.utils';
import { EquipmentRentsCalendarAddEventDialog } from './EquipmentRentsCalendarAddEventDialog/EquipmentRentsCalendarAddEventDialog';
import EquipmentRentsCalendarFilters from './EquipmentRentsCalendarFilters';
import { CalendarEvent } from './equipment-rents-calendar.utils';
import { EquipmentRentCalendarEquipmentRentDialog } from './equipmentRentCalendarEquipmentRentDialog/EquipmentRentCalendarEquipmentRentDialog';

const localizer = momentLocalizer(moment); // or globalizeLocalizer

export const EquipmentRentsCalendarView = (props: {
  equipmentRent: any;
  date: any;
  setDate: any;
  view: any;
  setView: any;
  events: any[];
  setRefresh: any;
  setOpenEquipmentRentEventDialog: any;
  openEquipmentRentEventDialog: boolean;
  setOpenAddEventDialog: any;
  openAddEventDialog: boolean;
  setEquipmentRent: any;
  userHash: string;
}) => {
  const CustomCalendar = Calendar as Calendar;
  const [locale] = useLocaleState();
  const clickRef = useRef(null);
  const [slotInfo, setSlotInfo] = useState(null);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const translate = useTranslate();
  const calendarMinDate = getCurrentDate();
  const calendarMaxDate = getCurrentDate();
  const calendarScrollDate = getCurrentDate();

  calendarMinDate.setHours(CALENDAR_START_HOUR, CALENDAR_START_MINUTES);
  calendarMaxDate.setHours(CALENDAR_END_HOUR, CALENDAR_END_MINUTES);
  calendarScrollDate.setHours(CALENDAR_SCROLL_HOUR, CALENDAR_SCROLL_MINUTES);

  const views = [Views.DAY, Views.WEEK, Views.MONTH];
  const translatedMessages = translateMessages(translate);

  const handleNavigate = (newDate) => {
    props.setDate(newDate);
  };

  const handleView = (newView) => {
    props.setView(newView);
  };

  const eventStyleGetter = (event: CalendarEvent) => {
    const style = {
      backgroundColor: generateColor(event.equipmentRentHash),
      color: 'white'
    };
    return { style };
  };

  const updateSlotInfo = (event: any, setSlotInfo: React.Dispatch<any>) => {
    const slot: EventSlot = {
      start: event.start,
      end: event.end,
      action: '',
      box: {
        clientX: 0,
        clientY: 0,
        x: 0,
        y: 0
      },
      slots: []
    };
    setSlotInfo(slot);
  };

  const updateSelectUsers = (
    props: any,
    slotInfo: EventSlot,
    setSelectedUsers: React.Dispatch<React.SetStateAction<any[]>>
  ) => {
    const eventsInSlot = props.events.filter((event) => {
      return (
        ((slotInfo.start >= event.start && slotInfo.start <= event.end) ||
          (slotInfo.end >= event.start && slotInfo.end <= event.end) ||
          (slotInfo.start <= event.start && slotInfo.end >= event.end)) &&
        event.available
      );
    });
    const usersInSlotSet = new Set<string>();
    eventsInSlot.forEach((event) => {
      usersInSlotSet.add(event.userHash);
    });
    const usersInSlot = Array.from(usersInSlotSet);
    setSelectedUsers(usersInSlot);
  };

  const onSelectSlot = useCallback(
    (slotInfo: EventSlot) => {
      window.clearTimeout(clickRef?.current);
      clickRef.current = window.setTimeout(() => {
        setSlotInfo(slotInfo);
        updateSelectUsers(props, slotInfo, setSelectedUsers);
        props.setOpenAddEventDialog(true);
      }, 250);
    },
    [props]
  );

  const onSelectEvent = (event) => {
    setSelectedEvent(event);
    if (event.userHash) {
      setSelectedUsers([event.userHash]);
    }

    if (event.equipmentRent) {
      updateSlotInfo(event, setSlotInfo);
      props.setOpenEquipmentRentEventDialog(true);
    }
  };

  const eventsGroupedByUser = props.events.reduce((grouped, event) => {
    const userHash = event.userHash || '';
    grouped[userHash] = [...(grouped[userHash] || []), event];
    return grouped;
  }, {});

  const sortedEvents = Object.values(eventsGroupedByUser).flatMap((group: []) =>
    group.sort((a: CalendarEvent, b: CalendarEvent) => a.start - b.start)
  );

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          paddingBottom: '25px',
          height: '100%'
        }}
      >
        <EquipmentRentsCalendarFilters
          equipmentRent={props.equipmentRent}
          userHash={props.userHash}
          setEquipmentRent={props.setEquipmentRent}
        ></EquipmentRentsCalendarFilters>
        <CustomCalendar
          className="custom-calendar"
          culture={locale}
          onNavigate={handleNavigate}
          onView={handleView}
          defaultDate={props.date}
          localizer={localizer}
          events={sortedEvents}
          style={{
            height: 500,
            flex: '1 auto'
          }}
          startAccessor="start"
          view={props.view}
          views={views}
          messages={translatedMessages}
          onSelectSlot={onSelectSlot}
          selectable
          eventPropGetter={eventStyleGetter}
          onSelectEvent={onSelectEvent}
          scrollToTime={calendarScrollDate}
          max={calendarMaxDate}
          min={calendarMinDate}
        />
      </Box>
      {slotInfo && (
        <EquipmentRentsCalendarAddEventDialog
          slotInfo={slotInfo}
          openDialog={props.openAddEventDialog}
          setOpenDialog={props.setOpenAddEventDialog}
          view={props.view}
          setRefresh={props.setRefresh}
          selectedUsers={selectedUsers}
          equipmentRent={props.equipmentRent}
          setEquipmentRent={props.setEquipmentRent}
          userHash={props.userHash}
        />
      )}
      {selectedEvent && (
        <EquipmentRentCalendarEquipmentRentDialog
          slotInfo={slotInfo}
          openDialog={props.openEquipmentRentEventDialog}
          setOpenDialog={props.setOpenEquipmentRentEventDialog}
          event={selectedEvent}
          view={props.view}
          setRefresh={props.setRefresh}
          selectedUsers={selectedUsers}
        ></EquipmentRentCalendarEquipmentRentDialog>
      )}
    </>
  );
};
