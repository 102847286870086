import { Typography } from '@mui/material';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import {
  BooleanInput,
  DateInput,
  NumberInput,
  ReferenceInput,
  SelectArrayInput,
  SelectInput,
  TextInput,
  TimeInput,
  required,
  useRecordContext
} from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import {
  PAYMENT_TYPE_MONTHLY,
  PAYMENT_TYPE_QUARTERLY,
  PAYMENT_TYPE_UNIQUE
} from '../../../../core/constants/paymentType.constants';
import {
  USER_TYPE_ADULT,
  USER_TYPE_CHILD
} from '../../../../core/constants/userTypes.constants';
import { UserHashContext } from '../../../../core/context/userHashContext';
import { Course } from '../../../../core/models/course/course';
import { getLinkedTeachers } from '../../../../core/services/userParent-api.service';
import { millisecondsToTime } from '../../../../core/utils/time-utils';

export const CourseForm = ({ smallCourses, startDate, endDate, isClub }) => {
  const record: Course = useRecordContext();
  const userTypeChild = Number(USER_TYPE_CHILD);
  const userTypeAdult = Number(USER_TYPE_ADULT);
  const { setValue } = useFormContext();
  const [userTypes, setUserTypes] = useState([userTypeChild]);

  const [linkedTeachers, setLinkedTeachers] = useState<any>([]);
  const params = useParams();
  const userContext = useContext(UserHashContext);

  useEffect(() => {
    if (isClub) {
      let userHash = null;
      if (params && params.id) {
        userHash = params.id;
      } else if (userContext) {
        userHash = userContext.userHash;
      }
      getLinkedTeachers(0, 0, 'id', userHash).then((res) => {
        setLinkedTeachers(res.data);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (record && setValue) {
      const userTypes = record.userTypes.map((userType) => Number(userType));
      setUserTypes(userTypes);
    } else {
      setValue('userType', userTypeChild);
      if (smallCourses) {
        setValue('paymentType', PAYMENT_TYPE_UNIQUE);
      }
    }
  }, [record, setValue, smallCourses, userTypeChild]);

  useEffect(() => {
    if (startDate && endDate) {
      setValue('dateStart', startDate);
      setValue('dateEnd', endDate);
      setValue('timeStart', startDate);
      setValue('timeEnd', endDate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);

  const handleUserTypeChange = (event) => {
    setUserTypes(event.target.value);
    if (event.target.value === userTypeAdult) {
      setValue('fromYears', null);
      setValue('toYears', null);
    }

    if (event.target.value === userTypeChild) {
      setValue('levelType', null);
    }
  };

  const formatTime = (value) => {
    let formattedTime = moment().format('HH:mm');
    if (!value) return moment().startOf('day').format('HH:mm');
    if (typeof value === 'number') {
      formattedTime = moment(millisecondsToTime(value), 'HH:mm').format(
        'HH:mm'
      );
    } else if (typeof value === 'string') {
      formattedTime = moment(getTimeFromString(value), 'HH:mm').format('HH:mm');
    } else if (typeof value === 'object') {
      formattedTime = moment(getTimeFromObject(value), 'HH:mm').format('HH:mm');
    }
    return `${formattedTime}`;
  };

  function getTimeFromObject(value: any) {
    const date = new Date(value.toString());
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const formattedTime = `${hours}:${minutes}`;
    return formattedTime;
  }

  function getTimeFromString(value: string) {
    const timeTokens = value.split(':').map((v) => parseInt(v));
    return `${timeTokens[0]}:${timeTokens[1]}`;
  }

  const hideFromToYears = () => {
    if (
      !userTypes?.includes(userTypeChild) &&
      userTypes?.includes(userTypeAdult)
    ) {
      return 'hide';
    }

    return hideFromToYearsAndLevel();
  };

  const hideLevelType = () => {
    if (
      userTypes?.includes(userTypeChild) &&
      !userTypes?.includes(userTypeAdult)
    ) {
      return 'hide';
    }

    return hideFromToYearsAndLevel();
  };

  const hideFromToYearsAndLevel = () => {
    if (
      userTypes?.includes(userTypeAdult) &&
      userTypes?.includes(userTypeChild)
    ) {
      return 'hide';
    }

    if (
      !userTypes?.includes(userTypeAdult) &&
      !userTypes?.includes(userTypeChild)
    ) {
      return 'hide';
    }

    return '';
  };

  const getPrice = (paymentType: number) => {
    if (record?.coursePrices) {
      const coursePrice = record.coursePrices.find(
        (coursePrice) => coursePrice.paymentType.id === paymentType
      );
      return coursePrice?.price;
    }
  };

  const getFullName = (firstName: string, lastName: string) => {
    return `${firstName ?? ''} ${lastName ?? ''}`;
  };

  return (
    <div className="course-form">
      {isClub && record && !!record?.userHash ? (
        <Typography component="h1" textAlign={'center'} margin={'1rem'}>
          {getFullName(record?.userFirstName, record?.userLastName)}
        </Typography>
      ) : null}

      {isClub && !record ? (
        <SelectInput
          optionText={(record) =>
            getFullName(record.userName, record.userLastFirstName)
          }
          source="userHash"
          choices={linkedTeachers}
          defaultValue=""
          optionValue="userHash"
          validate={[required()]}
          label="resources.users.fields.lesson.teacher"
        ></SelectInput>
      ) : null}

      <TextInput
        source="title"
        validate={[required()]}
        label="resources.users.course.fields.title"
      />
      <ReferenceInput
        source="userTypes"
        reference="user-types"
        sort={{ field: 'name', order: 'ASC' }}
      >
        <SelectArrayInput
          optionText="name"
          source="userTypes"
          validate={[required()]}
          defaultValue={userTypes}
          onChange={(e) => {
            handleUserTypeChange(e);
          }}
          label="resources.users.course.fields.userType"
        />
      </ReferenceInput>
      <div className="level">
        <NumberInput
          source="fromYears"
          label="resources.users.course.fields.fromYears"
          className={hideFromToYears()}
        />
        <NumberInput
          source="toYears"
          label="resources.users.course.fields.toYears"
          className={hideFromToYears()}
        />
      </div>
      <ReferenceInput
        source="levelType"
        reference="level-types"
        sort={{ field: 'name', order: 'ASC' }}
      >
        <SelectInput
          optionText="name"
          source="levelType"
          defaultValue=""
          validate={[required()]}
          className={hideLevelType()}
          label="resources.users.course.fields.level"
        />
      </ReferenceInput>
      <NumberInput
        source="minimumStudents"
        validate={[required()]}
        label="resources.users.course.fields.minimumStudents"
      />
      <NumberInput
        source="maximumStudents"
        validate={[required()]}
        label="resources.users.course.fields.maximumStudents"
      />
      <DateInput
        source="dateStart"
        label="resources.users.course.fields.dateStart"
        validate={[required()]}
      />
      <DateInput
        source="dateEnd"
        label="resources.users.course.fields.dateEnd"
        validate={[required()]}
      />
      <DateInput
        source="registrationDeadline"
        label="resources.users.course.fields.registrationDeadline"
        validate={[required()]}
      />
      <ReferenceInput
        source="dayTypes"
        reference="day-types"
        sort={{ field: 'name', order: 'ASC' }}
      >
        <SelectArrayInput
          optionText="name"
          source="dayTypes"
          defaultValue=""
          validate={[required()]}
          label="resources.users.course.fields.dayType"
        />
      </ReferenceInput>
      <TimeInput
        source="timeStart"
        label="resources.users.course.fields.timeStart"
        format={formatTime}
        validate={[required()]}
      />

      <TimeInput
        source="timeEnd"
        label="resources.users.course.fields.timeEnd"
        format={formatTime}
        validate={[required()]}
      />
      {!smallCourses && (
        <NumberInput
          source="monthlyPrice"
          label="resources.users.course.fields.monthlyPrice"
          defaultValue={getPrice(PAYMENT_TYPE_MONTHLY)}
        />
      )}
      {!smallCourses && (
        <NumberInput
          source="quarterlyPrice"
          label="resources.users.course.fields.quarterlyPrice"
          defaultValue={getPrice(PAYMENT_TYPE_QUARTERLY)}
        />
      )}

      <NumberInput
        source="uniquePrice"
        label={
          smallCourses
            ? 'resources.users.course.fields.price'
            : 'resources.users.course.fields.annualPrice'
        }
        defaultValue={getPrice(PAYMENT_TYPE_UNIQUE)}
      />

      <NumberInput
        source="freeCancellationPeriod"
        validate={[required()]}
        label="resources.users.course.fields.freeCancellationPeriod"
      />
      <BooleanInput
        className="boolean-input"
        source="isEnabled"
        label="resources.users.course.fields.disable"
      />
    </div>
  );
};
