import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';
import React from 'react';
import { useTranslate } from 'react-admin';
import { Button } from '../../../../../../components/shared/buttons/Button';

export const DialogCantDeleteUserPaymentMethod = (props) => {
  const translate = useTranslate();
  const onCloseDialog = () => {
    props.setShowCantDeleteDialog(false);
  };

  return (
    <Dialog fullWidth open={props.showCantDeleteDialog} onClose={onCloseDialog}>
      <DialogTitle>
        {translate('resources.users.userPaymentMethods.cantDelete.title')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {translate('resources.users.userPaymentMethods.cantDelete.message')}
        </DialogContentText>
        <DialogActions>
          <Button
            onClick={onCloseDialog}
            color="primary"
            label="resources.users.userPaymentMethods.cantDelete.buttonLabel"
            autoFocus
          ></Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};
