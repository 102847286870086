import { useListContext, useTranslate } from 'react-admin';
import React from 'react';
import { Button } from '../../../../components/shared/buttons/Button';
import {
  getPaymentsRequest,
  getuserPaymentsRequest
} from '../../../../core/services/paymentRequest-api.service';
import XLSX from 'xlsx';
import { formatDecimalAsNumber } from '../../../../core/utils/number-utils';
import { User } from '../../../../core/models/user/user';

export const PaymentRequestExport = (props) => {
  const translate = useTranslate();
  const { filterValues, sort } = useListContext();

  const getDate = (date: number) => {
    return !isNaN(new Date(date).getTime())
      ? new Date(date).toLocaleDateString()
      : null;
  };

  const getStudentName = (student: User) => {
    return (
      student.firstName + ' ' + student.lastName + ' ' + student.secondLastName
    );
  };

  const onExport = async () => {
    props.setIsLoading(true);
    const recordsToExport = await (props.isAdmin
      ? getPaymentsRequest(
          0,
          0,
          sort.field,
          filterValues.user,
          props.status,
          filterValues
        )
      : getuserPaymentsRequest(
          0,
          0,
          sort.field,
          props.userHash,
          props.status,
          filterValues
        )
    ).then((data) => data.data);
    const exports = recordsToExport.map((paymentRequest) => {
      const {
        id,
        user,
        amount,
        dateRequest,
        downloaded,
        balances,
        paymentRequestStatus,
        serialCode,
        ...paymentsForExport
      } = paymentRequest; // omit
      paymentsForExport.user = user.firstName;
      paymentsForExport.amount = formatDecimalAsNumber(amount);
      paymentRequest.title = '';
      paymentsForExport.status = paymentRequestStatus.name;
      paymentsForExport.date = getDate(dateRequest);
      paymentsForExport.subject = balances.map((subject) => {
        return {
          amount: formatDecimalAsNumber(subject.amount),
          partialAmount: formatDecimalAsNumber(subject.amount),
          title: subject.booking
            ? subject.booking.event.eventTitle
            : subject.userVoucher
            ? subject.userVoucher.voucher.title
            : '',
          student: subject.booking ? getStudentName(subject.booking.user) : '',
          lessonDate: subject.booking
            ? getDate(subject.booking.event.lessonDate)
            : '',
          serialCode: subject.booking?.paymentSerialCode,
          chargeDate: subject.booking
            ? subject.booking.paymentResultDate != null
              ? getDate(subject.booking.paymentResultDate)
              : ''
            : subject.userVoucher
            ? getDate(subject.userVoucher.date)
            : ''
        };
      });
      return paymentsForExport;
    });

    const exportsCopy = [...exports];
    let numberOfNewColumns = 0;

    // eslint-disable-next-line array-callback-return
    exportsCopy.map((value, indexExport) => {
      const exportArrayIndex = indexExport + numberOfNewColumns;
      // eslint-disable-next-line array-callback-return
      value.subject?.map((value, indexSubject) => {
        exports.splice(exportArrayIndex + 1 + indexSubject, 0, {
          user: '',
          partialAmount: value.partialAmount,
          title: value.title,
          student: value.student,
          lessonDate: value.lessonDate,
          serialCode: value.serialCode,
          chargeDate: value.chargeDate,
          status: '',
          date: ''
        });
        numberOfNewColumns += 1;
      });
    });

    // eslint-disable-next-line array-callback-return
    exports.map((value) => {
      delete value.subject;
    });

    Promise.all(exports).then((data) => {
      // eslint-disable-next-line array-callback-return
      data.map((value, index) => {
        renameKeys(
          value,
          'user',
          translate('resources.invoicing.paymentRequest.list.user')
        );
        renameKeys(
          value,
          'date',
          translate('resources.invoicing.paymentRequest.list.date')
        );
        renameKeys(
          value,
          'amount',
          translate('resources.invoicing.paymentRequest.list.totalAmount')
        );
        renameKeys(
          value,
          'partialAmount',
          translate('resources.invoicing.paymentRequest.list.partialAmount')
        );
        renameKeys(
          value,
          'serialCode',
          translate('resources.invoicing.paymentRequest.list.serialCode')
        );
        renameKeys(
          value,
          'status',
          translate(
            'resources.invoicing.paymentRequest.list.paymentRequestStatus'
          )
        );
        renameKeys(
          value,
          'title',
          translate('resources.invoicing.paymentRequest.list.title')
        );
        renameKeys(
          value,
          'student',
          translate('resources.invoicing.paymentRequest.list.student')
        );
        renameKeys(
          value,
          'lessonDate',
          translate('resources.invoicing.paymentRequest.list.lessonDate')
        );
        renameKeys(
          value,
          'chargeDate',
          translate('resources.invoicing.paymentRequest.list.chargeDate')
        );
      });
      let ws = XLSX.utils.json_to_sheet(data);
      let wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'sheet');
      XLSX.writeFile(
        wb,
        `${translate('resources.invoicing.payments.csv.title')}.xlsx`
      );
    });
    props.setIsLoading(false);
  };

  const renameKeys = (obj, oldKey, newKey) => {
    obj[newKey] = obj[oldKey];
    delete obj[oldKey];
  };

  return (
    <Button
      label="resources.invoicing.payments.csv.export"
      onClick={() => onExport()}
    />
  );
};
