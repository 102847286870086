import {
  VALIDATION_POSITIVE_NUMBER,
  VALIDATION_REQUIRED
} from '../../../../core/constants/validation.constants';

export function voucherFormValidator(values: any) {
  const errors: any = {};
  const lessonTypeNumber = 1;
  const lessonTypeTime = 2;

  if (!values.title) {
    errors.title = VALIDATION_REQUIRED;
  }

  if (!values.conditions) {
    errors.conditions = VALIDATION_REQUIRED;
  }

  if (!values.price && typeof values.price == 'object') {
    errors.price = VALIDATION_REQUIRED;
  }

  if (values.price && values.price < 0) {
    errors.price = VALIDATION_POSITIVE_NUMBER;
  }

  if (!values.category) {
    errors.category = VALIDATION_REQUIRED;
  }

  if (
    values.category &&
    values.category === lessonTypeNumber &&
    !values.lessonNumber
  ) {
    errors.lessonNumber = VALIDATION_REQUIRED;
  }

  if (
    values.category &&
    values.category === lessonTypeTime &&
    !values.timeRangeType
  ) {
    errors.timeRangeType = VALIDATION_REQUIRED;
  }

  return errors;
}
