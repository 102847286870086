import React, { useContext } from 'react';
import {
  Datagrid,
  FunctionField,
  List,
  Loading,
  TextField,
  useGetList,
  usePermissions,
  useTranslate
} from 'react-admin';
import { CourseDeleteButton } from './CourseDeleteButton';
import { CourseEditButton } from './CourseEditButton';
import { CourseEmptyList } from './CourseEmptyList';
import { CourseListActions } from './CourseListActions';
import { CourseStatusButton } from './CourseStatusButton';
import { UserHashContext } from '../../../../core/context/userHashContext';
import { PRIVILEGE_CLUB } from '../../../../core/constants/privilege.constants';
import {
  getLocaleFromTimestamp,
  millisecondsToTime
} from '../../../../core/utils/time-utils';
import { Course } from '../../../../core/models/course/course';
import { CustomPagination } from '../../../general/Pagination';

export const CourseListShared = (props) => {
  const { userHash: parentHash } = useContext(UserHashContext);
  const translate = useTranslate();
  const permissions = usePermissions();
  const isClub = permissions.permissions === PRIVILEGE_CLUB;

  const { data: userTypes, isLoading: isUserTypesLoading } =
    useGetList('user-types');
  const { data: dayTypes, isLoading: isDayTypesLoading } =
    useGetList('day-types');

  const { data: levelTypes, isLoading: isLevelTypesLoading } =
    useGetList('level-types');

  const getLevel = (course) => {
    if (course.levelType) {
      return levelTypes?.find((levelType) => levelType.id === course.levelType)
        .name;
    }

    if (course.fromYears && course.toYears) {
      return `${course.fromYears} - ${course.toYears} ${translate(
        'resources.users.course.list.years'
      )}`;
    }

    return '';
  };

  const getCourseShortDays = (days: number[]) => {
    return days
      ? days
          .map((day) => {
            return dayTypes?.find((dayType) => dayType.id === day).shortName;
          })
          .join('- ')
      : '';
  };

  const getTiming = (course: Course) => {
    return `${millisecondsToTime(course.timeStart)} - ${millisecondsToTime(
      course.timeEnd
    )}`;
  };

  const getUserType = (course: Course) => {
    const target = course.userTypes.map((courseUserNumber) => {
      return userTypes?.find((userType) => userType.id === courseUserNumber)
        .name;
    });
    return target.join(', ');
  };

  const getFilters = () => {
    const smallCourses = props.smallCourses;
    if (isClub) {
      return { smallCourses, parentHash };
    }
    return { smallCourses };
  };

  const getFullName = (firstName: string, lastName: string) => {
    return `${firstName ?? ''} ${lastName ?? ''}`;
  };

  if (isLevelTypesLoading || isDayTypesLoading || isUserTypesLoading)
    return <Loading />;

  return (
    <List
      resource={`users/${props.userHash}/courses`}
      sort={{ field: 'dateStart', order: 'DESC' }}
      filter={getFilters()}
      perPage={Number(process.env.REACT_APP_LIST_SIZE)}
      pagination={<CustomPagination />}
      empty={
        <CourseEmptyList
          setSection={props.setSection}
          sectionCreate={props.sectionCreate}
          route={props.route}
        />
      }
      actions={
        <CourseListActions
          setSection={props.setSection}
          sectionCreate={props.sectionCreate}
          smallCourses={props.smallCourses}
          route={props.route}
        />
      }
    >
      <Datagrid bulkActionButtons={false}>
        {props.isClub && (
          <FunctionField
            label="resources.users.lessons.list.teacher"
            render={(record: Course) =>
              getFullName(record.userFirstName, record.userLastName)
            }
          />
        )}

        <TextField
          source="title"
          label="resources.users.course.list.courseTitle"
          sortBy="title"
          emptyText="-"
        />
        <FunctionField
          label="resources.users.course.list.target"
          render={(record: Course) => getUserType(record)}
        />
        <FunctionField
          label="resources.users.course.list.level"
          render={(record: Course) => getLevel(record)}
        />
        <FunctionField
          label="resources.users.course.list.dateStart"
          render={(record: Course) => getLocaleFromTimestamp(record.dateStart)}
          sortBy="dateStart"
        />
        <FunctionField
          label="resources.users.course.list.dateEnd"
          render={(record: Course) => getLocaleFromTimestamp(record.dateEnd)}
          sortBy="dateEnd"
        />
        <FunctionField
          label="resources.users.course.list.courseDays"
          render={(record: Course) => getCourseShortDays(record.dayTypes)}
          sortable={false}
        />
        <FunctionField
          label="resources.users.course.list.timing"
          render={(record: Course) => getTiming(record)}
          sortable={false}
        />
        <CourseStatusButton
          label="resources.users.course.list.activeInactive"
          sortBy="disabled"
        />

        <CourseDeleteButton smallCourses={props.smallCourses} />
        <CourseEditButton
          setCourseToEditId={props.setCourseToEditId}
          sectionEdit={props.sectionEdit}
          setSection={props.setSection}
          route={props.route}
        />
      </Datagrid>
    </List>
  );
};
