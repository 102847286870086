import React from 'react';
import { Navigate } from 'react-router';
import { Loading, usePermissions } from 'react-admin';

export const ProtectedRoute = ({
  privileges = [],
  redirectPath = '/',
  children
}) => {
  const permissions = usePermissions();

  if (permissions.isLoading) {
    return <Loading />;
  }

  if (!privileges.includes(permissions.permissions)) {
    return <Navigate to={redirectPath} replace />;
  }

  return children;
};
