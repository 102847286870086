import React from 'react';
import { useNotify, Confirm, useRefresh, useUpdate } from 'react-admin';

export const DialogLinkClub = ({ showLinkDialog, setShowLinkDialog, id }) => {
  const [update] = useUpdate();
  const notify = useNotify();
  const refresh = useRefresh();
  const onCloseLinkedDialog = () => {
    setShowLinkDialog(false);
  };

  const onLinkClub = () => {
    update(
      'user-parents/link-club',
      {
        id
      },
      {
        onSuccess: () => {
          notify(
            'resources.users.notifications.userEdit.linkedClubs.link.success',
            {
              type: 'success'
            }
          );
          onCloseLinkedDialog();
          refresh();
        },
        onError(error: any) {
          notify(error.message, { type: 'warning' });
        }
      }
    );
  };

  return (
    <Confirm
      title="resources.users.linkedClubs.link.title"
      content="resources.users.linkedClubs.link.message"
      onConfirm={onLinkClub}
      isOpen={showLinkDialog}
      onClose={onCloseLinkedDialog}
    />
  );
};
