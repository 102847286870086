import React, { useState } from 'react';
import { usePermissions, useRecordContext, useTranslate } from 'react-admin';
import { getLocaleFromTimestamp } from '../../../../core/utils/time-utils';
import { User } from '../../../../core/models/user/user';
import { DialogDisableUser } from '../userTabs/userEdit/toolbar/dialog/DialogDisableUser';
import { DialogDeleteUser } from '../userTabs/userEdit/toolbar/dialog/DialogDeleteUser';
import { DialogValidateUser } from '../userTabs/userEdit/toolbar/dialog/DialogValidateUser';
import { Grid } from '@mui/material';
import {
  PRIVILEGE_ADMIN,
  PRIVILEGE_CLUB
} from '../../../../core/constants/privilege.constants';
import { DialogEnableUser } from '../userTabs/userEdit/toolbar/dialog/DialogEnableUser';
import { DialogUnlinkUser } from '../userTabs/userEdit/toolbar/dialog/DialogUnlinkUser';
import { Button } from '../../../../components/shared/buttons/Button';

export const UserCard = () => {
  const record: User = useRecordContext();
  const translate = useTranslate();
  const [showDisableDialog, setShowDisableDialog] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showValidateDialog, setShowValidateDialog] = useState(false);
  const [showEnableDialog, setShowEnableDialog] = useState(false);
  const [showUnlinkDialog, setShowUnlinkDialog] = useState(false);
  const permissions = usePermissions();
  const isAdmin = permissions.permissions === PRIVILEGE_ADMIN;
  const isClub = permissions.permissions === PRIVILEGE_CLUB;

  const getName = () => {
    return `${record?.firstName} ${record?.lastName ? record.lastName : ''} ${
      record.secondLastName ? record.secondLastName : ''
    }`;
  };

  const getDate = () => {
    return getLocaleFromTimestamp(record.entryDate, {
      year: 'numeric',
      month: 'long',
      day: '2-digit'
    });
  };

  const onClickUserDisable = () => {
    setShowDisableDialog(true);
  };

  const onClickUserDelete = () => {
    setShowDeleteDialog(true);
  };

  const onClickUserValidate = () => {
    setShowValidateDialog(true);
  };

  const onClickUserEnable = () => {
    setShowEnableDialog(true);
  };

  const onClickUserUnlink = () => {
    setShowUnlinkDialog(true);
  };

  const getPrivilege = () => {
    if (record.isClub) {
      return 'club';
    }

    if (record.isTeacher) {
      return 'teacher';
    }

    if (record.isStudent) {
      return 'student';
    }

    return 'user';
  };

  const getValidateButtonText = () => {
    return translate(
      `resources.users.actions.validateCustom.${getPrivilege()}`
    );
  };

  return (
    <div>
      <Grid container>
        <Grid item className="user-image">
          <div className="image">
            {record.image && <img alt="" width="20" src={record.image.src} />}
          </div>
        </Grid>
        <Grid item className="user-data">
          <div className="data">
            <p>{getName()}</p>
            <p>{record.email}</p>
            <p>
              {translate('resources.users.card.entryDate')}
              {getDate()}
            </p>
          </div>
        </Grid>
        <Grid item className="user-actions">
          {record.isTeacher && isClub && (
            <div>
              <Button
                className="primary"
                color="primary"
                onClick={onClickUserUnlink}
                label="resources.users.actions.unlink"
              ></Button>
            </div>
          )}
          {!record.isCandidate && isAdmin && !record.isDisabled && (
            <div>
              <Button
                className="secondary"
                color="primary"
                onClick={onClickUserDisable}
                label="resources.users.actions.disableAccount"
              ></Button>
              <Button
                className="primary"
                color="primary"
                onClick={onClickUserDelete}
                label="resources.users.actions.delete"
              ></Button>
            </div>
          )}
          {!record.isCandidate && isAdmin && record.isDisabled && (
            <div>
              <Button
                className="secondary"
                color="primary"
                onClick={onClickUserEnable}
                label="resources.users.actions.enableAccount"
              ></Button>
              <Button
                className="primary"
                color="primary"
                onClick={onClickUserDelete}
                label="resources.users.actions.delete"
              ></Button>
            </div>
          )}
          {record.isCandidate && isAdmin && (
            <div>
              <Button
                color="primary"
                onClick={onClickUserDelete}
                label="resources.users.actions.notValidateAndDelete"
              ></Button>
              <Button
                color="primary"
                onClick={onClickUserValidate}
                label={getValidateButtonText()}
              ></Button>
            </div>
          )}
        </Grid>
      </Grid>
      <DialogDisableUser
        {...{ showDisableDialog: showDisableDialog }}
        setShowDisableDialog={setShowDisableDialog.bind(this)}
      ></DialogDisableUser>
      <DialogEnableUser
        {...{ showEnableDialog: showEnableDialog }}
        setShowEnableDialog={setShowEnableDialog.bind(this)}
      ></DialogEnableUser>
      <DialogDeleteUser
        showDeleteDialog={showDeleteDialog}
        setShowDeleteDialog={setShowDeleteDialog}
        id={record.id}
      ></DialogDeleteUser>
      <DialogValidateUser
        showValidateDialog={showValidateDialog}
        setShowValidateDialog={setShowValidateDialog}
        record={record}
      ></DialogValidateUser>
      {isClub && (
        <DialogUnlinkUser
          showUnlinkDialog={showUnlinkDialog}
          setShowUnlinkDialog={setShowUnlinkDialog}
          id={record.id}
        ></DialogUnlinkUser>
      )}
    </div>
  );
};
