import React from 'react';
import { useNotify, useDelete, Confirm, useRefresh } from 'react-admin';

export const DialogDeleteCourse = ({
  showDeleteDialog,
  setShowDeleteDialog,
  id,
  smallCourses
}) => {
  const [erase] = useDelete();
  const notify = useNotify();
  const refresh = useRefresh();
  const onCloseCourseDeleteDialog = () => {
    setShowDeleteDialog(false);
  };

  const onDeleteCourse = () => {
    erase(
      'courses',
      {
        id
      },
      {
        onSuccess: () => {
          notify('resources.users.course.notifications.deleteCourse.success', {
            type: 'success'
          });
          onCloseCourseDeleteDialog();
          refresh();
        },
        onError(error: any) {
          notify(error.message, { type: 'warning' });
        }
      }
    );
  };

  return (
    <Confirm
      title={
        smallCourses
          ? 'resources.users.course.delete.smallCourseTitle'
          : 'resources.users.course.delete.title'
      }
      content={
        smallCourses
          ? 'resources.users.course.delete.smallCourseMessage'
          : 'resources.users.course.delete.message'
      }
      onConfirm={onDeleteCourse}
      isOpen={showDeleteDialog}
      onClose={onCloseCourseDeleteDialog}
    />
  );
};
