import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import React from 'react';
import { useTranslate } from 'react-admin';
import { Button } from '../../../../../../components/shared/buttons/Button';

export const LinkedTeacherEmptyList = ({ setSection, sectionCreate }) => {
  const translate = useTranslate();
  const goToCreate = () => {
    setSection(sectionCreate);
  };
  return (
    <Box textAlign="center" m={1}>
      <Typography variant="h4" paragraph>
        {translate('resources.users.linkedTeachers.list.empty')}
      </Typography>
      <Button
        onClick={goToCreate}
        label="resources.users.linkedTeachers.actions.create"
      ></Button>
    </Box>
  );
};
