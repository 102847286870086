import {
  Datagrid,
  DateField,
  FunctionField,
  List,
  TextField
} from 'react-admin';
import { CustomPagination } from '../../../components/general/Pagination';
import { EmptyList } from '../../../components/general/EmptyList';
import React, { useContext, useEffect } from 'react';
import { HeaderTitleContext } from '../../../core/context/headerTitleContext';
import { getMoment, toDate } from '../../../core/utils/date.utils';
import { Notification } from '../../../core/models/notification/notification';
import { NotificationDeleteButton } from './NotificationDeleteButton';
import { NotificationViewButton } from './NotificationViewButton';
import { UserHashContext } from '../../../core/context/userHashContext';

export const NotificationsList = () => {
  const { setHeaderTitle } = useContext(HeaderTitleContext);
  const { userHash } = useContext(UserHashContext);

  useEffect(() => {
    setHeaderTitle('resources.notifications.header.title');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getNotificationType = (record: Notification) => {
    return (
      <>
        <img src={record.notificationType.image} alt="" />
        <span>{record.notificationType.name}</span>
      </>
    );
  };

  if (!userHash) return <></>;

  return (
    <List
      resource={`users/${userHash}/notifications`}
      sort={{ field: 'sendDate', order: 'DESC' }}
      exporter={false}
      actions={false}
      perPage={Number(process.env.REACT_APP_LIST_SIZE)}
      pagination={<CustomPagination />}
      empty={<EmptyList />}
    >
      <Datagrid bulkActionButtons={false}>
        <FunctionField
          sortBy="notificationType"
          label={'resources.notifications.list.notification'}
          render={(record: Notification) => getNotificationType(record)}
        />
        <TextField
          source="title"
          sortBy="title"
          label={'resources.notifications.list.notifications'}
          emptyText="-"
        />
        <DateField
          source="sendDate"
          sortBy="sendDate"
          label={'resources.notifications.list.date'}
          emptyText="-"
        />
        <FunctionField
          source="sendDate"
          label={'resources.notifications.list.time'}
          render={(record: Notification) => {
            return (
              <span>{getMoment(toDate(record.sendDate)).format('HH:mm')}</span>
            );
          }}
        />
        <NotificationViewButton />
        <NotificationDeleteButton />
      </Datagrid>
    </List>
  );
};
