import React, { useContext, useEffect, useState } from 'react';
import { usePermissions, useRecordContext, useTranslate } from 'react-admin';
import { Views } from 'react-big-calendar';
import { CalendarShared } from '../../../../../components/shared/calendar/CalendarShared';
import {
  generateEventsFromEvents,
  getFirstMillisOfView,
  getLastMillisOfView
} from '../../../../../components/shared/calendar/calendar.utils';
import { PRIVILEGE_CLUB } from '../../../../../core/constants/privilege.constants';
import { UserHashContext } from '../../../../../core/context/userHashContext';
import { User } from '../../../../../core/models/user/user';
import { getEvents } from '../../../../../core/services/user-api.service';
import { getCurrentDate } from '../../../../../core/utils/date.utils';

export const UserCalendar = () => {
  const { userHash } = useContext(UserHashContext);
  const translate = useTranslate();
  const record: User = useRecordContext();
  const [events, setEvents] = React.useState([]);
  const [date, setDate] = useState(getCurrentDate());
  const [view, setView] = useState(Views.WEEK);
  const [openAddEventDialog, setOpenAddEventDialog] = useState(false);
  const [openAvailableDialog, setOpenAvailableDialog] = useState(false);
  const [openLessonEventDialog, setOpenLessonEventDialog] = useState(false);
  const [openCourseEventDialog, setOpenCourseEventDialog] = useState(false);
  const [openEquipmentRentEventDialog, setOpenEquipmentRentEventDialog] =
    useState(false);
  const [refresh, setRefresh] = useState(false);
  const [lessonTypeCode, setLessonTypeCode] = useState(null);
  const [room, setRoom] = useState(null);
  const isRecordClub = record?.isClub;
  const permissions = usePermissions();
  const isLoggedUserClub = permissions.permissions === PRIVILEGE_CLUB;
  useEffect(() => {
    getEvents(
      record.id,
      getFirstMillisOfView(date, view),
      getLastMillisOfView(date, view),
      isLoggedUserClub ? userHash : isRecordClub ? record.id : null,
      lessonTypeCode,
      null,
      room
    ).then((data) => {
      const events = generateEventsFromEvents(
        data.data,
        translate('resources.users.calendar.messages.available'),
        isLoggedUserClub || isRecordClub
      );
      setEvents(events);
      setRefresh(false);
    });
    // eslint-disable-next-line
  }, [
    record.id,
    date,
    view,
    openAddEventDialog,
    openAvailableDialog,
    openLessonEventDialog,
    lessonTypeCode,
    room,
    refresh
  ]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <CalendarShared
      events={events}
      openAddEventDialog={openAddEventDialog}
      setOpenAddEventDialog={setOpenAddEventDialog}
      openAvailableDialog={openAvailableDialog}
      setOpenAvailableDialog={setOpenAvailableDialog}
      openLessonEventDialog={openLessonEventDialog}
      setOpenLessonEventDialog={setOpenLessonEventDialog}
      openCourseEventDialog={openCourseEventDialog}
      setOpenCourseEventDialog={setOpenCourseEventDialog}
      openEquipmentRentEventDialog={openEquipmentRentEventDialog}
      setOpenEquipmentRentEventDialog={setOpenEquipmentRentEventDialog}
      lessonTypeCode={lessonTypeCode}
      setLessonTypeCode={setLessonTypeCode}
      setDate={setDate}
      setView={setView}
      view={view}
      room={room}
      setRoom={setRoom}
      userHash={record.id}
      fromTabs={true}
      setRefresh={setRefresh}
    />
  );
};
