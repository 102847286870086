import { FilterButton, TopToolbar, useListContext } from 'react-admin';
import React, { useEffect } from 'react';
import { InvoiceExport } from './InvoiceExport';
import { InvoiceFilters } from './invoiceFilters/InvoiceFilters';

export const InvoiceListActions = ({ status, user }) => {
  const { filterValues, setFilters } = useListContext();

  useEffect(() => {
    delete filterValues.serial;
    delete filterValues.date;
    delete filterValues.identification;
    delete filterValues.user;
    delete filterValues.country;
    delete filterValues.region;
    delete filterValues.city;
    delete filterValues.userFilter;
    setFilters(filterValues, {}, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <TopToolbar>
      <FilterButton />
      <InvoiceExport filters={InvoiceFilters} status={status} user={user} />
    </TopToolbar>
  );
};
