import React, { useContext } from 'react';
import { HeaderTitleContext } from '../../core/context/headerTitleContext';
import { useTranslate } from 'react-admin';
import { useLocation } from 'react-router-dom';
import { Breadcrumbs } from './Breadcrumbs';

export const Header = () => {
  const { headerTitle } = useContext(HeaderTitleContext);
  const translate = useTranslate();
  const location = useLocation();
  return (
    <header className={location.pathname === '/' ? 'hide' : 'main-header'}>
      <h1>{translate(headerTitle)}</h1>
      <Breadcrumbs />
    </header>
  );
};
