import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import React, { useState } from 'react';
import { RadioButtonGroupInput, SimpleForm, useTranslate } from 'react-admin';
import { DeleteCalendarEquipmentRentDialog } from './DeleteCalendarEquipmentRentDialog';
import { EquipmentRentCalendarEquipmentRentDialogGroupedToolbar } from './EquipmentRentCalendarEquipmentRentDialogGroupedToolbar';

export const EquipmentRentCalendarEquipmentRentDialogGrouped = (props) => {
  const translate = useTranslate();
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [deleteSeries, setDeleteSeries] = useState(false);

  const formattedStart = props.event?.start.toLocaleString();
  const formattedEnd = props.event?.end.toLocaleString();

  const [group, setGroup] = useState(null);

  const onCloseDialog = () => {
    props.setOpenDialog(false);
  };

  const onDeleteEvent = () => {
    setDeleteSeries(false);
    setShowDeleteDialog(true);
  };

  const onDeleteSeries = () => {
    setDeleteSeries(true);
    setShowDeleteDialog(true);
  };

  const createChoicesFromGroup = () => {
    const choices = [];
    if (props.event && Array.isArray(props.event.group)) {
      props.event.group.forEach((event) => {
        choices.push({
          id: event?.id,
          name: `${event?.eventTitle} - ${event?.userName} ${event?.userLastName}`
        });
      });
    }
    return choices;
  };

  return (
    <>
      <Dialog fullWidth open={props.openDialog} onClose={onCloseDialog}>
        <DialogTitle>{props.event?.modalTitle}</DialogTitle>
        <DialogContent className="event-dialog">
          <p>
            {translate('resources.equipmentRentsCalendar.start')}
            {formattedStart.slice(0, -3)}
          </p>
          <p>
            {translate('resources.equipmentRentsCalendar.end')}
            {formattedEnd.slice(0, -3)}
          </p>
          <SimpleForm
            className={'form'}
            toolbar={
              <EquipmentRentCalendarEquipmentRentDialogGroupedToolbar
                onDeleteEvent={onDeleteEvent}
                onDeleteSeries={onDeleteSeries}
                group={group}
              />
            }
          >
            <RadioButtonGroupInput
              label="resources.equipmentRentsCalendar.group.title"
              source="group"
              choices={createChoicesFromGroup()}
              onChange={(e) => {
                setGroup(e?.target?.value);
              }}
              className="radiogroup radiogroup-modal"
            ></RadioButtonGroupInput>
          </SimpleForm>
        </DialogContent>
      </Dialog>
      <DeleteCalendarEquipmentRentDialog
        event={props.event}
        showDeleteDialog={showDeleteDialog}
        setShowDeleteDialog={setShowDeleteDialog}
        setOpenCourseEventDialog={props.setOpenDialog}
        deleteSeries={deleteSeries}
        setRefresh={props.setRefresh}
      />
    </>
  );
};
