import { FilterButton, TopToolbar } from 'react-admin';
import React from 'react';
import { GlobalOperationsExport } from './GlobalOperationExport';

export const GlobalOperationActions = ({ userHash, setIsLoading }) => {
  return (
    <TopToolbar>
      <FilterButton />
      <GlobalOperationsExport userHash={userHash} setIsLoading={setIsLoading} />
    </TopToolbar>
  );
};
