import ReceiptIcon from '@mui/icons-material/Receipt';
import React from 'react';
import { IconButton } from '@mui/material';
import { getInvoicePdf } from '../../../../core/services/invoice-api.service';
import { useRecordContext } from 'react-admin';
import { Invoice } from '../../../../core/models/invoice/invoice';
import { downloadFile } from '../../../../core/utils/file-utils';
export const PdfButton = (props) => {
  const record: Invoice = useRecordContext();

  const handle = async () => {
    const response = await getInvoicePdf(record.id);

    const base64Response = await fetch(
      `data:application/pdf;base64,${response.data.file}`
    );
    const blob = await base64Response.blob();
    downloadFile(blob, `${record.serialCode}.pdf`);
  };

  return (
    <IconButton onClick={() => handle()}>
      <ReceiptIcon />
    </IconButton>
  );
};
