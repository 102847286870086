import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import React, { useContext, useState } from 'react';
import {
  RadioButtonGroupInput,
  SimpleForm,
  usePermissions,
  useRecordContext,
  useTranslate
} from 'react-admin';
import { useParams } from 'react-router-dom';
import {
  PRIVILEGE_ADMIN,
  PRIVILEGE_TEACHER
} from '../../../../../../core/constants/privilege.constants';
import { UserHashContext } from '../../../../../../core/context/userHashContext';
import { DialogDeleteLessonEvent } from '../DialogDeleteLessonEvent';
import DialogEditRoomLessonEvent from '../DialogEditRoomLessonEvent';
import { CalendarLessonEventDialogGroupedEventToolbar } from './CalendarLessonEventDialogGroupedEventToolbar';

export const CalendarLessonEventDialogGroupedEvent = (props) => {
  const onCloseDialog = () => {
    props.setOpenDialog(false);
  };

  const translate = useTranslate();
  const record = useRecordContext();
  const permissions = usePermissions();

  const isTeacher = permissions.permissions === PRIVILEGE_TEACHER;
  const isAdmin = permissions.permissions === PRIVILEGE_ADMIN;

  const formattedEnd = props.event?.end?.toLocaleString();
  const formattedStart = props.event?.start?.toLocaleString();
  const teacher = `${props.event?.userName} ${props.event?.userLastName}`;
  const [group, setGroup] = useState(null);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [deleteSeries, setDeleteSeries] = useState(false);
  const { id } = useParams();
  const { userHash: hash } = useContext(UserHashContext);
  const userHash = isAdmin ? id : hash;

  const onDeleteEvent = () => {
    setDeleteSeries(false);
    setShowDeleteDialog(true);
  };

  const onDeleteSeries = () => {
    setDeleteSeries(true);
    setShowDeleteDialog(true);
  };

  const findEventFromChoice = (id) => {
    if (props.event.group.length === 0) return null;
    return props.event.group.find((event) => event.id === id);
  };

  const createChoicesFromGroup = () => {
    const choices = [];
    if (props.event && Array.isArray(props.event.group)) {
      props.event.group.forEach((event) => {
        choices.push({
          id: event?.id,
          name: `${event?.eventTitle} - ${event?.userName} ${event?.userLastName}`
        });
      });
    }
    return choices;
  };

  return (
    <>
      <Dialog fullWidth open={props.openDialog} onClose={onCloseDialog}>
        <DialogTitle>{props.event?.modalTitle}</DialogTitle>
        <DialogContent className="event-dialog">
          {isAdmin && (
            <p hidden={!record.isClub}>
              {translate('resources.users.calendar.lessonEvent.club')}
              {record.firstName}
            </p>
          )}
          <p hidden={isTeacher}>
            {translate('resources.users.calendar.lessonEvent.teacher')}
            {teacher}
          </p>
          <p>
            {translate('resources.users.calendar.lessonEvent.start')}
            {formattedStart.slice(0, -3)}
          </p>
          <p>
            {translate('resources.users.calendar.lessonEvent.end')}
            {formattedEnd.slice(0, -3)}
          </p>
          <DialogEditRoomLessonEvent
            event={props.event}
            id={userHash}
            onCloseDialog={onCloseDialog}
          />
          <SimpleForm
            className={'form'}
            toolbar={
              <CalendarLessonEventDialogGroupedEventToolbar
                onDeleteEvent={onDeleteEvent}
                onDeleteSeries={onDeleteSeries}
                group={group}
              />
            }
          >
            <RadioButtonGroupInput
              label="resources.users.calendar.lessonEvent.group.title"
              source="group"
              choices={createChoicesFromGroup()}
              onChange={(e) => {
                setGroup(e?.target?.value);
              }}
              translateChoice={false}
              key={props.event.id}
              className="radiogroup radiogroup-modal"
            ></RadioButtonGroupInput>
          </SimpleForm>
        </DialogContent>
      </Dialog>
      <DialogDeleteLessonEvent
        event={findEventFromChoice(group)}
        showDeleteDialog={showDeleteDialog}
        setShowDeleteDialog={setShowDeleteDialog}
        setOpenLessonEventDialog={props.setOpenDialog}
        deleteSeries={props.deleteSeries}
        setRefresh={props.setRefresh}
      />
    </>
  );
};
