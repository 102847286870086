import React from 'react';
import {
  useNotify,
  useRedirect,
  useDelete,
  Edit,
  SimpleForm,
  TextInput,
  required,
  Toolbar,
  SaveButton,
  useTranslate
} from 'react-admin';
import { useParams } from 'react-router-dom';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { sendDeleteMessage } from '../../../../../../../core/services/user-api.service';

export const DialogDeleteUser = ({
  showDeleteDialog,
  setShowDeleteDialog,
  id
}) => {
  const [erase] = useDelete('users');
  const notify = useNotify();
  const redirect = useRedirect();
  const { privilege } = useParams();
  const translate = useTranslate();
  const onCloseUserDeleteDialog = () => {
    setShowDeleteDialog(false);
  };

  const onDeleteUser = (data) => {
    erase(
      'users',
      {
        id
      },
      {
        onSuccess: () => {
          sendMessage(id, data['text']);
        },
        onError(error: any) {
          notify(error.message, { type: 'warning' });
        }
      }
    );
  };

  const sendMessage = (id, text) => {
    sendDeleteMessage(id, text).then(() => {
      notify('resources.users.notifications.userDelete.success', {
        type: 'success'
      });
      onCloseUserDeleteDialog();
      redirect(`/${privilege}`);
    });
  };

  const ModalToolbar = (props) => (
    <Toolbar {...props}>
      <SaveButton />
    </Toolbar>
  );

  return (
    <Dialog fullWidth open={showDeleteDialog} onClose={onCloseUserDeleteDialog}>
      <DialogTitle>{translate('resources.users.delete.title')}</DialogTitle>
      <DialogContent>
        <Edit>
          <SimpleForm onSubmit={onDeleteUser} toolbar={<ModalToolbar />}>
            <TextInput
              source="text"
              label="resources.users.deleteModal.field.reason"
              validate={[required()]}
              defaultValue=""
              multiline
              fullWidth
            />
          </SimpleForm>
        </Edit>
      </DialogContent>
    </Dialog>
  );
};
