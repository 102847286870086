import { Box } from '@mui/material';
import React from 'react';
import { usePermissions } from 'react-admin';
import { PRIVILEGE_CLUB } from '../../../core/constants/privilege.constants';
import CalendarFilters from './CalendarFilters';
import CalendarView from './CalendarView';

export const CalendarShared = (props) => {
  const permissions = usePermissions();
  const isClub = permissions.permissions === PRIVILEGE_CLUB;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: '25px',
        height: '100%'
      }}
    >
      <CalendarFilters
        isClub={isClub}
        teachers={props.teachers}
        rooms={props.rooms}
        lessonTypeCode={props.lessonTypeCode}
        setTeacher={props.setTeacher}
        setRoom={props.setRoom}
        setLessonTypeCode={props.setLessonTypeCode}
      ></CalendarFilters>
      <CalendarView
        locale={props.locale}
        date={props.date}
        view={props.view}
        events={props.events}
        setOpenAddEventDialog={props.setOpenAddEventDialog}
        openAddEventDialog={props.openAddEventDialog}
        setOpenAvailableDialog={props.setOpenAvailableDialog}
        openAvailableDialog={props.openAvailableDialog}
        setOpenLessonEventDialog={props.setOpenLessonEventDialog}
        openLessonEventDialog={props.openLessonEventDialog}
        setOpenCourseEventDialog={props.setOpenCourseEventDialog}
        openCourseEventDialog={props.openCourseEventDialog}
        setOpenEquipmentRentEventDialog={props.setOpenEquipmentRentEventDialog}
        openEquipmentRentEventDialog={props.openEquipmentRentEventDialog}
        setRefresh={props.setRefresh}
        setDate={props.setDate}
        setView={props.setView}
        isClub={isClub}
        userHash={props.userHash}
        fromTabs={props.fromTabs}
      ></CalendarView>
    </Box>
  );
};
