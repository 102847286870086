import { get } from './httpClient.service';

const DEFAULT_ENTRY_POINT = 'global-operations';

export async function getGlobalOperations(
  _start: number,
  _end: number,
  _sort: string,
  user: string,
  teacher: string,
  club: string,
  filters: {
    fromDate: string;
    toDate: string;
    sport: string;
    lessonType: string;
  }
): Promise<any> {
  return get(`${DEFAULT_ENTRY_POINT}`, {
    _start,
    _end,
    _sort,
    user,
    teacher,
    club,
    ...filters
  });
}
