import React from 'react';
import { useLocation } from 'react-router-dom';
import { VoucherList } from './vouchersList/VoucherList';
import { VoucherCreate } from './voucherCreate/VoucherCreate';
import { VoucherEdit } from './voucherEdit/VoucherEdit';

export const VoucherOverview = () => {
  const sectionList = 'list';
  const sectionCreate = 'create';
  const sectionEdit = 'edit';
  const location = useLocation();
  const parts = location.pathname.split('/');
  const userHash = parts.pop();

  const [section, setSection] = React.useState(sectionList);
  const [voucherToEditId, setVoucherToEditId] = React.useState(null);

  if (section === sectionCreate) {
    return (
      <VoucherCreate
        setSection={setSection}
        sectionList={sectionList}
        userHash={userHash}
      />
    );
  }

  if (section === sectionEdit) {
    return (
      <VoucherEdit
        setSection={setSection}
        sectionList={sectionList}
        userHash={userHash}
        voucherToEditId={voucherToEditId}
      />
    );
  }

  if (section === sectionList) {
    return (
      <VoucherList
        setSection={setSection}
        sectionCreate={sectionCreate}
        sectionEdit={sectionEdit}
        setVoucherToEditId={setVoucherToEditId}
        userHash={userHash}
      />
    );
  }
};
