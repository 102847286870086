import { Dialog, DialogTitle, DialogContent } from '@mui/material';
import React, { useEffect } from 'react';
import {
  useTranslate,
  Toolbar,
  SaveButton,
  SimpleForm,
  Create,
  useGetList,
  CheckboxGroupInput,
  Loading,
  useCreate,
  useNotify,
  useRefresh,
  useDataProvider
} from 'react-admin';
import { UserSport } from '../../../../core/models/userSport/userSport';

export const DialogAddUserLinkedSports = (props) => {
  const dataProvider = useDataProvider();
  const translate = useTranslate();
  const {
    data: sportsData,
    isLoading,
    total: totalSports
  } = useGetList('sports');
  const {
    data: userSportsData,
    isLoading: isLoadingUserSports,
    total: totalUserSports
  } = useGetList('users/sports');
  const [create] = useCreate();
  const notify = useNotify();
  const refresh = useRefresh();
  const [sports, setSports] = React.useState([]);
  const [userSports, setUserSports] = React.useState([]);

  useEffect(() => {
    if (totalSports && sportsData && totalSports > sportsData?.length) {
      dataProvider
        .getList('sports', {
          filter: null,
          sort: { field: 'id', order: 'ASC' },
          pagination: { page: 1, perPage: totalSports }
        })
        .then((response) => {
          setSports(response.data);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalSports, sportsData]);

  useEffect(() => {
    if (
      totalUserSports &&
      userSportsData &&
      totalUserSports > userSportsData?.length
    ) {
      dataProvider
        .getList('users/sports', {
          filter: null,
          sort: { field: 'id', order: 'ASC' },
          pagination: { page: 1, perPage: totalUserSports }
        })
        .then((response) => {
          setUserSports(response.data);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalUserSports, userSportsData]);

  useEffect(() => {
    if (sportsData) {
      setSports(sportsData);
    }
  }, [sportsData]);

  useEffect(() => {
    if (userSportsData) {
      setUserSports(userSportsData);
    }
  }, [userSportsData]);

  const ModalToolbar = (props) => (
    <Toolbar {...props}>
      <SaveButton />
    </Toolbar>
  );

  const onCloseDialog = () => {
    props.setShowDialog(false);
  };

  const onSave = (data) => {
    create(
      'users/sports',
      { data },
      {
        onSuccess: () => {
          notify(
            'resources.configuration.sports.notifications.create.success',
            {
              type: 'success'
            }
          );

          props.setShowDialog(false);
          refresh();
        },
        onError(error: any) {
          notify(error.message, { type: 'warning' });
        }
      }
    );
  };

  const getSportChoices = () => {
    if (sports) {
      return sports.map((sport) => ({
        id: sport.id,
        name: sport.name
      }));
    }
  };

  const getDefaultValues = () => {
    return userSports.map((userSport: UserSport) => userSport.sport.id);
  };

  if (isLoading || isLoadingUserSports) {
    return <Loading />;
  }

  return (
    <Dialog fullWidth open={props.showDialog} onClose={onCloseDialog}>
      <DialogTitle>
        {translate('resources.configuration.sports.create.title')}
      </DialogTitle>
      <DialogContent>
        <Create resource={'users'}>
          <SimpleForm onSubmit={onSave} toolbar={<ModalToolbar />}>
            <CheckboxGroupInput
              label=""
              source="sports"
              choices={getSportChoices()}
              defaultValue={getDefaultValues()}
            />
          </SimpleForm>
        </Create>
      </DialogContent>
    </Dialog>
  );
};
