import { Typography } from '@mui/material';
import React from 'react';
import { Form, PasswordInput, useNotify, useTranslate } from 'react-admin';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from '../components/shared/buttons/Button';
import { resetPassword } from '../core/services/auth-api.service';
import { getErrorMessageCode } from '../core/utils/error.utils';
import { passWordValidation } from '../core/utils/validation-utils';

export const ResetPassword = () => {
  const translate = useTranslate();
  const params = useParams();
  const notify = useNotify();
  const navigate = useNavigate();

  const onResetPassword = (event: FormValues): void => {
    resetPassword(params.id, event.password, event.confirmPassword)
      .then((response) => {
        const sucessKey = 'resources.users.notifications.resetPassword.success';
        notify(sucessKey, { type: 'success' });
        if (localStorage.getItem('token')) {
          localStorage.removeItem('token');
        }
        navigate('/login');
      })
      .catch((error) => {
        const code = getErrorMessageCode(error);
        notify(`errors.${code.toLowerCase()}`, { type: 'error' });
      });
  };

  return (
    <>
      <div className="header-logo">
        <img
          src="src/images/logo-nak-sports.png"
          alt="NAK SPORTS"
          width="200"
          height="96"
        />
      </div>
      <main className="auth-page" id="reset-password">
        <div>
          <Form
            validate={passWordValidation}
            onSubmit={onResetPassword}
            //className="form-dark"
          >
            <Typography
              component="h3"
              className="custom-title"
              color="textPrimary"
              sx={{ margin: '0 0 24px' }}
            >
              {translate('resources.auth.reset_password.subtitle')}
            </Typography>
            <div>
              <PasswordInput
                source="password"
                label="resources.auth.reset_password.form.password"
                inputProps={{ autoComplete: 'current-password' }}
              />
            </div>
            <div>
              <PasswordInput
                label="resources.auth.reset_password.form.confirmPassword"
                source="confirmPassword"
                inputProps={{ autoComplete: 'current-password' }}
              />
            </div>
            <Button
              type="submit"
              color="primary"
              label="resources.auth.recovery_password.button"
            ></Button>
          </Form>
        </div>
      </main>
    </>
  );
};

interface FormValues {
  password?: string;
  confirmPassword?: string;
}
