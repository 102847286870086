import React from 'react';
import { required, TextInput, useTranslate } from 'react-admin';
import {
  APP_URL_ANDROID,
  APP_URL_IOS
} from '../../../core/constants/app.constant';

export const ShareEmailDialogForm = () => {
  const translate = useTranslate();

  const textValue = translate('resources.configuration.share.message', {
    iosUrl: APP_URL_IOS,
    androidUrl: APP_URL_ANDROID
  });

  const validateEmails = (value) => {
    const emails = value.split(';').map((email) => email.trim());
    const isValid = emails.every((email) =>
      /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(email)
    );

    if (!isValid) {
      return translate('ra.validation.emails');
    }

    return undefined; // Validation passed
  };

  return (
    <>
      <TextInput
        source="emails"
        validate={[required(), validateEmails]}
        label="resources.users.fields.lesson.email"
      />
      <TextInput
        source="message"
        validate={[required()]}
        defaultValue={textValue}
      />
    </>
  );
};
