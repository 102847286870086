import React from 'react';
import {
  EmailField,
  FunctionField,
  Loading,
  Show,
  SimpleShowLayout,
  TextField,
  useGetList,
  useGetMany,
  useRecordContext
} from 'react-admin';

export const UserView = () => {
  const record = useRecordContext();
  const { data: countries, isLoading: countriesIsLoading } =
    useGetList('public/countries');
  const { data: regions, isLoading: regionsIsLoading } = useGetMany(
    'public/regions',
    {
      ids: [record.region]
    }
  );

  const { data: cities, isLoading: citiesIsLoading } = useGetMany(
    'public/cities',
    {
      ids: [record.city]
    }
  );

  const { data: sports } = useGetMany('sports', {
    ids: [record.firstSport]
  });

  const getCityName = () => {
    const city = cities?.find((city) => city.id === record.city)?.name;
    return city ? city : '-';
  };

  const getRegionName = () => {
    const region = regions?.find((region) => region.id === record.region)?.name;
    return region ? region : '-';
  };

  const getSportName = () => {
    return sports?.find((sport) => sport.id === record.firstSport)?.name;
  };

  const getCountryName = () => {
    return countries?.find((country) => country.id === record.country)?.name;
  };

  if (citiesIsLoading || countriesIsLoading || regionsIsLoading) {
    return <Loading />;
  }

  return (
    <Show>
      <SimpleShowLayout>
        <TextField source="firstName" emptyText="-" />
        <TextField source="lastName" emptyText="-" />
        <TextField source="identification" emptyText="-" />
        <TextField source="address" emptyText="-" />
        <TextField source="telephone" emptyText="-" />
        <EmailField source="email" emptyText="-" />
        <FunctionField
          label="resources.users.fields.firstSport"
          render={() => getSportName()}
        />
        <FunctionField
          label="resources.users.fields.country"
          render={() => getCountryName()}
        />
        <FunctionField
          label="resources.users.fields.region"
          render={() => getRegionName()}
        />
        <FunctionField
          label="resources.users.fields.city"
          render={() => getCityName()}
        />
      </SimpleShowLayout>
    </Show>
  );
};
