import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';
import React from 'react';
import { Button } from '../../../components/shared/buttons/Button';

export const DialogViewNotification = (props) => {
  const onCloseDialog = () => {
    props.setShowDialog(false);
  };
  return (
    <Dialog fullWidth open={props.showDialog} onClose={onCloseDialog}>
      <DialogTitle>{props.notification.title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{props.notification.body}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onCloseDialog}
          color="primary"
          label="resources.notifications.view.close"
        />
      </DialogActions>
    </Dialog>
  );
};
