import React from 'react';
import { RoomListShared } from '../../../../../../components/shared/rooms/roomsList/RoomListShared';

export const RoomList = ({
  setSection,
  sectionCreate,
  sectionEdit,
  setRoomToEditId,
  userHash
}) => {
  return (
    <RoomListShared
      setSection={setSection}
      sectionCreate={sectionCreate}
      sectionEdit={sectionEdit}
      setRoomToEditId={setRoomToEditId}
      userHash={userHash}
    />
  );
};
