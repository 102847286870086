import React, { useState } from 'react';
import { Confirm, useNotify } from 'react-admin';
import { cancelBooking } from '../../../../core/services/booking-api.service';
import { getErrorMessageCode } from '../../../../core/utils/error.utils';

export const DeleteSchedulerEquipmentRentDialog = ({
  showCancelDialog,
  setShowCancelDialog,
  booking,
  setOpenScheduleBookingDialog,
  setRefresh
}) => {
  const [loading, setLoading] = useState(false);
  const notify = useNotify();

  const onCloseCancelDialog = () => {
    setShowCancelDialog(false);
    setOpenScheduleBookingDialog(false);
  };

  const onCancelBooking = () => {
    setLoading(true); // Indicar que la carga está en progreso
    cancelBooking(booking.id)
      .then((response) => {
        notify('resources.schedule.cancel.success', {
          type: 'success'
        });
        setRefresh(true);
        onCloseCancelDialog();
      })
      .catch((error) => {
        const code = getErrorMessageCode(error);
        notify(`errors.${code.toLowerCase()}`, { type: 'warning' });
      })
      .finally(() => {
        setLoading(false); // Indicar que la carga ha terminado (tanto en éxito como en error)
      });
  };

  return (
    <Confirm
      loading={loading}
      title="resources.equipmentRentsScheduler.dialog.delete.actions.title"
      content="resources.equipmentRentsScheduler.dialog.delete.actions.message"
      onConfirm={onCancelBooking}
      isOpen={showCancelDialog}
      onClose={onCloseCancelDialog}
    />
  );
};
