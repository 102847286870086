import { CustomPagination } from '../../../../../../components/general/Pagination';
import { EmptyList } from '../../../../../../components/general/EmptyList';
import {
  Datagrid,
  FunctionField,
  List,
  TextField,
  usePermissions,
  useTranslate
} from 'react-admin';
import React from 'react';
import { UserVoucher } from '../../../../../../core/models/userVoucher/userVoucher';
import LinearProgress from '@mui/material/LinearProgress';
import { UserVoucherDeleteButton } from './UserVoucherDeleteButton';
import { PRIVILEGE_ADMIN } from '../../../../../../core/constants/privilege.constants';
import { UserVoucherRecurrentButton } from './UserVoucherRecurrentButton';

export const UserVouchersList = (props) => {
  const translate = useTranslate();
  const permissions = usePermissions();
  const isAdmin = permissions.permissions === PRIVILEGE_ADMIN;
  const getPercentage = (userVoucher: UserVoucher) => {
    if (!userVoucher.pendingLessons) {
      return 0;
    }

    return (
      ((userVoucher.voucher.lessonNumber - userVoucher.pendingLessons) /
        userVoucher.voucher.lessonNumber) *
      100
    );
  };

  return (
    <List
      resource={`users/${props.userHash}/user-vouchers`}
      perPage={Number(process.env.REACT_APP_LIST_SIZE)}
      pagination={<CustomPagination />}
      empty={
        <EmptyList message="resources.users.userVouchers.list.empty.title" />
      }
      actions={false}
    >
      <Datagrid bulkActionButtons={false}>
        <TextField
          source="voucher.title"
          label="resources.users.userVouchers.list.title"
          sortBy="voucher.title"
          emptyText="-"
        />
        <TextField
          source="voucher.lessonNumber"
          label="resources.users.userVouchers.list.lessonNumber"
          sortBy="voucher.lessonNumber"
          emptyText="-"
        />
        {isAdmin && (
          <FunctionField
            label="resources.users.userVouchers.list.club"
            render={(record: UserVoucher) =>
              record.isVoucherUserClub ? record.voucherUserName : '-'
            }
            sortBy="voucherUserName"
          />
        )}
        {isAdmin && (
          <FunctionField
            label="resources.users.userVouchers.list.teacher"
            render={(record: UserVoucher) =>
              record.isVoucherUserTeacher ? record.voucherUserName : '-'
            }
            sortBy="voucherUserName"
          />
        )}
        <UserVoucherRecurrentButton label="resources.users.userVouchers.list.recurrent" />
        <FunctionField
          label="resources.users.userVouchers.list.progress"
          render={(record: UserVoucher) => {
            if (record.pendingLessons) {
              return (
                <>
                  <p>
                    |{translate('resources.users.userVouchers.list.pending')}
                    {record.pendingLessons}
                  </p>
                  <LinearProgress
                    variant="determinate"
                    value={getPercentage(record)}
                  />
                </>
              );
            }
          }}
        />
        <UserVoucherDeleteButton />
      </Datagrid>
    </List>
  );
};
