import React from 'react';
import { useRecordContext, useRefresh } from 'react-admin';
import { getXML } from '../../../../core/utils/utils';
import { PaymentRequest } from '../../../../core/models/paymentRequest/paymentRequest';
import { Button } from '../../../../components/shared/buttons/Button';
import { PAYMENT_REQUEST_DOWNLOAD_DEFAULT_NAME } from '../../../../core/constants/paymentRequest.constants';

export const PaymentRequestDownloadXMLButton = (props) => {
  const record: PaymentRequest = useRecordContext(props);
  const refresh = useRefresh();

  const handleClick = async () => {
    getXML(`payments-request/${record?.id}/xml`)
      .then((response) => response.body)
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          `${
            record?.serialCode
              ? record.serialCode
              : PAYMENT_REQUEST_DOWNLOAD_DEFAULT_NAME
          }.xml`
        );
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        refresh();
      });
  };

  const getLabel = () => {
    return record?.downloaded
      ? 'resources.invoicing.paymentRequest.list.downloaded'
      : 'resources.invoicing.paymentRequest.list.download';
  };

  return <Button onClick={() => handleClick()} label={getLabel()} />;
};
