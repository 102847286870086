import React, { useContext } from 'react';
import { EquipmentRentsCreateShared } from '../../../components/shared/equipmentRents/equipmentRentsCreate/EquipmentRentsCreateShared';
import { UserHashContext } from '../../../core/context/userHashContext';

export const EquipmentRentsCreate = () => {
  const { userHash } = useContext(UserHashContext);

  return (
    <EquipmentRentsCreateShared
      route={'/equipment-rents'}
      userHash={userHash}
    />
  );
};
