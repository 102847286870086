import React from 'react';
import {
  SelectInput,
  SimpleForm,
  useListContext,
  useNotify,
  useRefresh,
  useUnselectAll
} from 'react-admin';
import {
  INVOICE_STATUS_EMITTED,
  INVOICE_STATUS_INVOICED,
  INVOICE_STATUS_PENDING
} from '../../../../core/constants/invoice.constants';
import { updateInvoiceStatus } from '../../../../core/services/invoice-api.service';

export const InvoicesUpdateStatusButton = (props) => {
  const { selectedIds } = useListContext();
  const refresh = useRefresh();
  const notify = useNotify();
  const unselectAll = useUnselectAll('invoices');

  const choices = [
    {
      id: INVOICE_STATUS_PENDING,
      name: 'resources.invoicing.invoices.list.pending'
    },
    {
      id: INVOICE_STATUS_EMITTED,
      name: 'resources.invoicing.invoices.list.emitted'
    },
    {
      id: INVOICE_STATUS_INVOICED,
      name: 'resources.invoicing.invoices.list.invoiced'
    }
  ];

  const updateMany = (statusCode: number) => {
    const promises = [];
    selectedIds.forEach((id) => {
      const promise = updateInvoiceStatus(id, statusCode);
      promises.push(promise);
    });

    Promise.all(promises)
      .then(() => {
        refresh();
        unselectAll();
        notify('resources.invoicing.invoices.notifications.status.success', {
          type: 'success'
        });
      })
      .catch((error) => {
        notify(error.message, { type: 'warning' });
      });
  };

  return (
    <SimpleForm toolbar={false}>
      <SelectInput
        source="category"
        label="resources.invoicing.payments.list.status"
        choices={choices}
        onChange={(e) => updateMany(e.target.value)}
        helperText={false}
      />
    </SimpleForm>
  );
};
