import React from 'react';
import { Datagrid, List, TextField, useRecordContext } from 'react-admin';
import { LinkedTeacherDeleteButton } from './LinkedTeacherDeleteButton';
import { LinkedTeacherEmptyList } from './LinkedTeacherEmptyList';
import { LinkedTeacherListActions } from './LinkedTeacherListActions';
import { CustomPagination } from '../../../../../../components/general/Pagination';

export const LinkedTeacherList = ({ setSection, sectionCreate }) => {
  const record = useRecordContext();
  return (
    <List
      resource="user-parents/linked-teachers"
      sort={{ field: 'user.firstName', order: 'ASC' }}
      filter={{ user_id: record.id }}
      actions={
        <LinkedTeacherListActions
          setSection={setSection}
          sectionCreate={sectionCreate}
        />
      }
      empty={
        <LinkedTeacherEmptyList
          setSection={setSection}
          sectionCreate={sectionCreate}
        />
      }
      pagination={<CustomPagination />}
      perPage={Number(process.env.REACT_APP_LIST_SIZE)}
    >
      <Datagrid bulkActionButtons={false}>
        <TextField
          source="userName"
          label="resources.users.linkedTeachers.list.clubName"
          emptyText="-"
          sortBy={'parent.firstName'}
        />
        <TextField
          source="userLastFirstName"
          label="resources.users.linkedTeachers.list.firstName"
          emptyText="-"
          sortBy={'parent.lastName'}
        />
        <TextField
          source="userLastSecondName"
          label="resources.users.linkedTeachers.list.secondName"
          emptyText="-"
          sortBy={'parent.secondLastName'}
        />
        <LinkedTeacherDeleteButton />
      </Datagrid>
    </List>
  );
};
