import { SelectInput, useTranslate } from 'react-admin';
import React from 'react';
import {
  BOOKING_STATUS_ACTIVE,
  BOOKING_STATUS_CANCEL,
  BOOKING_STATUS_END,
  BOOKING_STATUS_INVOICED
} from '../../../../core/constants/booking.constants';

export const FilterBookingStatus = (props) => {
  const translate = useTranslate();

  const bookingStatusChoices = () => {
    return [
      {
        id: BOOKING_STATUS_ACTIVE,
        name: 'resources.invoicing.globalOperations.filters.active'
      },
      {
        id: BOOKING_STATUS_CANCEL,
        name: 'resources.invoicing.globalOperations.filters.cancel'
      },
      {
        id: BOOKING_STATUS_END,
        name: 'resources.invoicing.globalOperations.filters.end'
      },
      {
        id: BOOKING_STATUS_INVOICED,
        name: 'resources.invoicing.globalOperations.filters.invoiced'
      }
    ].sort((a, b) => translate(a.name).localeCompare(translate(b.name)));
  };

  return (
    <SelectInput
      source="bookingStatus"
      optionText="name"
      choices={bookingStatusChoices()}
      label="resources.invoicing.globalOperations.filters.bookingStatus"
    ></SelectInput>
  );
};
