import React from 'react';
import { Button, Toolbar } from 'react-admin';

export const EquipmentRentSchedulerEquipmentRentDialogGroupedToolbar = ({
  onDeleteEvent,
  group
}) => {
  return (
    <div>
      <Toolbar>
        <Button
          label="ra.action.delete"
          onClick={onDeleteEvent}
          disabled={!group}
        />
      </Toolbar>
    </div>
  );
};
