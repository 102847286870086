import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@mui/material';
import React from 'react';
import { useTranslate } from 'react-admin';

import parse from 'html-react-parser';
import { Button } from '../../components/shared/buttons/Button';

export const DialogPrivacyPolicies = (props) => {
  const translate = useTranslate();

  const onCloseDialog = () => {
    props.setShowPrivacyModal(false);
  };

  return (
    <Dialog fullWidth open={props.showPrivacyModal} onClose={onCloseDialog}>
      <DialogTitle>
        {translate('resources.users.privacyPoliciesModal.title')}
      </DialogTitle>
      <DialogContent>{parse(props.privacyPolicy.text)}</DialogContent>
      <DialogActions>
        <Button
          onClick={onCloseDialog}
          color="primary"
          label="resources.users.privacyPoliciesModal.acceptLabel"
        ></Button>
      </DialogActions>
    </Dialog>
  );
};
