import React, { useContext, useEffect } from 'react';
import { usePermissions, useRecordContext, useTranslate } from 'react-admin';
import {
  PRIVILEGE_ADMIN,
  PRIVILEGE_CLUB
} from '../../../../core/constants/privilege.constants';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useNavigate, useParams } from 'react-router-dom';
import { DefaultTabs } from './tabLists/DefaultTabList';
import { ClubTeachersTabList } from './tabLists/ClubTeachersTabList';
import { ClubStudentTabList } from './tabLists/ClubStudentTabList';
import { UserHashContext } from '../../../../core/context/userHashContext';
import { getUserParent } from '../../../../core/services/user-api.service';
import { ClubTeachersTabListNotLinked } from './tabLists/ClubTeachersTabListNotLinked';

export const UserTabs = (props) => {
  const record = useRecordContext();
  const isClub: boolean = record?.isClub;
  const isTeacher = record?.isTeacher;
  const isStudent = record?.isStudent;
  const translate = useTranslate();
  const permissions = usePermissions();
  const isAdmin = permissions.permissions === PRIVILEGE_ADMIN;
  const isClubPrivilege = permissions.permissions === PRIVILEGE_CLUB;
  const [activeTab, setActiveTab] = React.useState(
    'resources.users.tabs.generalData'
  );
  const [active, setActive] = React.useState(false);
  const navigation = useNavigate();
  const params = useParams();
  const { userHash } = useContext(UserHashContext);
  const [status, setStatus] = React.useState(null);

  const handleClickActiveTab = (currentTab) => {
    navigation(`/${params.privilege}/${params.id}`);
    setActive(false);
    setActiveTab(currentTab);
  };

  useEffect(() => {
    if (isClubPrivilege && record.isTeacher) {
      getUserParent(record.id, userHash).then((res) => {
        setStatus(res.data.status);
      });
    }

    if (isClubPrivilege && isTeacher) {
      setActiveTab('resources.users.tabs.schedule');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div id="user-tabs">
      <div
        className={`user-tabs ${active ? 'active' : ''}`}
        onClick={() => setActive(!active)}
      >
        {translate(activeTab)} <ArrowDropDownIcon />
      </div>
      {isClubPrivilege && isTeacher && status && status.isLinked && (
        <ClubTeachersTabList handleClickActiveTab={handleClickActiveTab} />
      )}
      {isClubPrivilege && isTeacher && status && !status.isLinked && (
        <ClubTeachersTabListNotLinked
          handleClickActiveTab={handleClickActiveTab}
        />
      )}
      {isClubPrivilege && isStudent && (
        <ClubStudentTabList handleClickActiveTab={handleClickActiveTab} />
      )}
      {!isClubPrivilege && (
        <DefaultTabs
          handleClickActiveTab={handleClickActiveTab}
          isClub={isClub}
          isTeacher={isTeacher}
          isClubPrivilege={isClubPrivilege}
          isStudent={isStudent}
          isAdmin={isAdmin}
        />
      )}
    </div>
  );
};
