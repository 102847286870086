import React from 'react';
import { useNotify, useDelete, Confirm, useRefresh } from 'react-admin';

export const DialogDeleteVoucher = ({
  showDeleteDialog,
  setShowDeleteDialog,
  id
}) => {
  const [erase] = useDelete();
  const notify = useNotify();
  const refresh = useRefresh();
  const onCloseVoucherDeleteDialog = () => {
    setShowDeleteDialog(false);
  };

  const onDeleteVoucher = () => {
    erase(
      'vouchers',
      {
        id
      },
      {
        onSuccess: () => {
          notify('resources.users.vouchers.notifications.delete.success', {
            type: 'success'
          });
          onCloseVoucherDeleteDialog();
          refresh();
        },
        onError(error: any) {
          notify(error.message, { type: 'warning' });
        }
      }
    );
  };

  return (
    <Confirm
      title={'resources.users.vouchers.delete.title'}
      content={'resources.users.vouchers.delete.message'}
      onConfirm={onDeleteVoucher}
      isOpen={showDeleteDialog}
      onClose={onCloseVoucherDeleteDialog}
    />
  );
};
