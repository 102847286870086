import React from 'react';
import { FilterUsers } from './FilterUsers';
import { DateInput } from 'react-admin';

export const PaymentFilters = [
  <FilterUsers
    source="user"
    label="resources.invoicing.payments.filters.user"
  />,
  <DateInput
    alwaysOn
    source="fromDate"
    label="resources.invoicing.invoices.filters.from"
  />,
  <DateInput
    alwaysOn
    source="toDate"
    label="resources.invoicing.invoices.filters.to"
  />
];
