import React from 'react';
import { EquipmentRentsEditShared } from '../../../../../../components/shared/equipmentRents/equipmentRentsEdit/EquipmentRentsEditShared';

export const EquipmentRentEdit = ({
  setSection,
  sectionList,
  equipmentRentToEditId,
  userHash
}) => {
  return (
    <EquipmentRentsEditShared
      setSection={setSection}
      sectionList={sectionList}
      equipmentRentToEditId={equipmentRentToEditId}
      userHash={userHash}
    />
  );
};
