import React from 'react';
import { CourseListShared } from '../../../../../../components/shared/courses/coursesList/CourseListShared';

export const CourseList = ({
  setSection,
  sectionCreate,
  sectionEdit,
  userHash,
  smallCourses,
  setCourseToEditId,
  isClub
}) => {
  return (
    <CourseListShared
      setSection={setSection}
      sectionCreate={sectionCreate}
      sectionEdit={sectionEdit}
      setCourseToEditId={setCourseToEditId}
      userHash={userHash}
      smallCourses={smallCourses}
      isClub={isClub}
    />
  );
};
