import React, { useContext, useEffect } from 'react';
import { HeaderTitleContext } from '../../../core/context/headerTitleContext';
import { UserHashContext } from '../../../core/context/userHashContext';
import { RoomListShared } from '../../../components/shared/rooms/roomsList/RoomListShared';

export const RoomList = () => {
  const { setHeaderTitle } = useContext(HeaderTitleContext);
  const { userHash } = useContext(UserHashContext);

  useEffect(() => {
    setHeaderTitle('resources.rooms.header.title');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <RoomListShared userHash={userHash} route={'create'} />;
};
