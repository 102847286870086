import React from 'react';
import { Button, SelectInput, SimpleForm, useTranslate } from 'react-admin';
import {
  LESSON_TYPE_ALONE,
  LESSON_TYPE_COLLECTIVE
} from '../../../core/constants/lessonType.constants';

type CalendarFiltersType = {
  isClub;
  teachers;
  rooms;
  lessonTypeCode;
  setTeacher;
  setRoom;
  setLessonTypeCode;
};

const CalendarFilters = (props: CalendarFiltersType) => {
  const translate = useTranslate();
  return (
    <div className="calendar-filters">
      {props.teachers && props.rooms && (
        <div className="custom">
          <SimpleForm toolbar={false}>
            {props.isClub && (
              <SelectInput
                source="teacher"
                choices={props.teachers.sort((a, b) =>
                  a.firstName.localeCompare(b.firstName)
                )}
                optionText="firstName"
                onChange={(e) => props.setTeacher(e.target.value)}
                label={'resources.users.calendar.filter.teacher'}
              />
            )}
            <SelectInput
              source="room"
              choices={props.rooms.sort((a, b) => a.name.localeCompare(b.name))}
              optionText="name"
              onChange={(e) => {
                props.setRoom(e.target.value);
              }}
              label={'resources.users.calendar.filter.room'}
            />
          </SimpleForm>
        </div>
      )}

      <Button
        className={props.lessonTypeCode === null ? 'active' : null}
        onClick={() => props.setLessonTypeCode(null)}
        label={translate('resources.users.calendar.filter.allLessonTypes')}
        color={'secondary'}
        size={'small'}
      />
      <Button
        className={props.lessonTypeCode === LESSON_TYPE_ALONE ? 'active' : null}
        onClick={() => props.setLessonTypeCode(LESSON_TYPE_ALONE)}
        label={translate('resources.users.calendar.filter.aloneLessonType')}
        color={'secondary'}
        size={'small'}
      />
      <Button
        className={
          props.lessonTypeCode === LESSON_TYPE_COLLECTIVE ? 'active' : null
        }
        onClick={() => props.setLessonTypeCode(LESSON_TYPE_COLLECTIVE)}
        label={translate(
          'resources.users.calendar.filter.collectiveLessonType'
        )}
        color={'secondary'}
        size={'small'}
      />
    </div>
  );
};

export default CalendarFilters;
