import React from 'react';
import { useTranslate } from 'react-admin';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { EmptyList } from '../../../../../components/general/EmptyList';
import { BookingOverview } from '../bookings/BookingOverview';
import { CourseOverview } from '../courses/CourseOverview';
import { EquipmentRentOverView } from '../equipmentRent/EquipmentRentOverView';
import { LessonOverview } from '../lessons/LessonOverview';
import { LinkedClubsOverview } from '../linkedClubsOverview/LinkedClubsOverview';
import { LinkedTeacherOverview } from '../linkedTeacherOverview/LinkedTeacherOverview';
import { RoomOverview } from '../rooms/RoomOverview';
import { UserActiveVouchersList } from '../userActiveVouchers/UserActiveVouchersList';
import { UserCalendar } from '../userCalendar/UserCalendar';
import { UserEdit } from '../userEdit/UserEdit';
import { UserEquipmentRentsCalendar } from '../userEquipmentRentsCalendar/UserEquipmentRentsCalendar';
import { UserEquipmentRentsScheduler } from '../userEquipmentRentsScheduler/UserEquipmentRentsScheduler';
import { UserFavouriteSports } from '../userFavouriteSports/UserFavouriteSports';
import { UserInvoiceData } from '../userInvoiceData/UserInvoiceData';
import { UserPaymentMethodsOverview } from '../userPaymentMethods/UserPaymentMethodsOverview';
import { UserSchedule } from '../userSchedule/UserSchedule';
import { UserView } from '../userView/Userview';
import { UserVoucherOverview } from '../userVouchers/UserVoucherOverview';
import { VoucherOverview } from '../vouchers/VoucherOverview';

export const DefaultTabs = ({
  handleClickActiveTab,
  isClub,
  isTeacher,
  isClubPrivilege,
  isStudent,
  isAdmin
}) => {
  const translate = useTranslate();
  return (
    <Tabs className={'user-tabs-list'}>
      <TabList>
        <Tab
          onClick={() =>
            handleClickActiveTab('resources.users.tabs.generalData')
          }
        >
          {translate('resources.users.tabs.generalData')}
        </Tab>
        <Tab
          onClick={() => handleClickActiveTab('resources.users.tabs.access')}
          hidden={!isClub}
        >
          {translate('resources.users.tabs.access')}
        </Tab>
        <Tab
          onClick={() =>
            handleClickActiveTab('resources.users.tabs.linkedTeachers')
          }
          hidden={!isClub}
        >
          {translate('resources.users.tabs.linkedTeachers')}
        </Tab>
        <Tab
          onClick={() => handleClickActiveTab('resources.users.tabs.calendar')}
          hidden={!isTeacher && !isClub}
        >
          {translate('resources.users.tabs.calendar')}
        </Tab>
        <Tab
          onClick={() =>
            handleClickActiveTab('resources.users.tabs.equipmentRentsCalendar')
          }
          hidden={!isTeacher && !isClub}
        >
          {translate('resources.users.tabs.equipmentRentsCalendar')}
        </Tab>
        <Tab
          onClick={() =>
            handleClickActiveTab('resources.users.tabs.linkedClubs')
          }
          hidden={!isTeacher || isClubPrivilege}
        >
          {translate('resources.users.tabs.linkedClubs')}
        </Tab>
        <Tab
          onClick={() => handleClickActiveTab('resources.users.tabs.vouchers')}
        >
          {translate('resources.users.tabs.vouchers')}
        </Tab>
        <Tab
          onClick={() =>
            handleClickActiveTab('resources.users.tabs.activeVouchers')
          }
          hidden={!isTeacher && !isClub}
        >
          {translate('resources.users.tabs.activeVouchers')}
        </Tab>
        <Tab
          onClick={() => handleClickActiveTab('resources.users.tabs.rooms')}
          hidden={!isClub && !isTeacher}
        >
          {translate('resources.users.tabs.rooms')}
        </Tab>
        <Tab
          onClick={() =>
            handleClickActiveTab('resources.users.tabs.annualCourses')
          }
          hidden={isStudent}
        >
          {translate('resources.users.tabs.annualCourses')}
        </Tab>
        <Tab
          onClick={() => handleClickActiveTab('resources.users.tabs.courses')}
          hidden={isStudent}
        >
          {translate('resources.users.tabs.courses')}
        </Tab>
        <Tab
          onClick={() => handleClickActiveTab('resources.users.tabs.class')}
          hidden={isStudent}
        >
          {translate('resources.users.tabs.class')}
        </Tab>
        <Tab
          onClick={() =>
            handleClickActiveTab('resources.users.tabs.equipmentRent')
          }
          hidden={(!isTeacher && !isClub) || isClubPrivilege}
        >
          {translate('resources.users.tabs.equipmentRent')}
        </Tab>
        <Tab
          onClick={() => handleClickActiveTab('resources.users.tabs.schedule')}
        >
          {translate('resources.users.tabs.schedule')}
        </Tab>
        <Tab
          onClick={() =>
            handleClickActiveTab('resources.users.tabs.equipmentRentsScheduler')
          }
        >
          {translate('resources.users.tabs.equipmentRentsScheduler')}
        </Tab>
        <Tab
          onClick={() =>
            handleClickActiveTab('resources.users.tabs.billingData')
          }
          hidden={(!isTeacher && !isClub) || isClubPrivilege}
        >
          {translate('resources.users.tabs.billingData')}
        </Tab>
        <Tab
          onClick={() =>
            handleClickActiveTab('resources.users.tabs.favouriteSports')
          }
          hidden={!isStudent}
        >
          {translate('resources.users.tabs.favouriteSports')}
        </Tab>
        <Tab
          onClick={() =>
            handleClickActiveTab('resources.users.tabs.historical')
          }
          hidden={!isStudent}
        >
          {translate('resources.users.tabs.historical')}
        </Tab>
        <Tab
          onClick={() =>
            handleClickActiveTab('resources.users.tabs.paymentMethods')
          }
          hidden={!(isStudent && isAdmin)}
        >
          {translate('resources.users.tabs.paymentMethods')}
        </Tab>
      </TabList>
      <TabPanel>{isAdmin ? <UserEdit /> : <UserView />}</TabPanel>
      <TabPanel>
        <EmptyList />
      </TabPanel>
      <TabPanel>
        <LinkedTeacherOverview />
      </TabPanel>
      <TabPanel>
        <UserCalendar />
      </TabPanel>
      <TabPanel>
        <UserEquipmentRentsCalendar />
      </TabPanel>
      <TabPanel>
        <LinkedClubsOverview />
      </TabPanel>
      <TabPanel>
        {isStudent ? <UserVoucherOverview /> : <VoucherOverview />}
      </TabPanel>
      <TabPanel>
        <UserActiveVouchersList />
      </TabPanel>
      <TabPanel>
        <RoomOverview />
      </TabPanel>
      <TabPanel>
        <CourseOverview smallCourses={false} isClub={isClub} />
      </TabPanel>
      <TabPanel>
        <CourseOverview smallCourses={true} isClub={isClub} />
      </TabPanel>
      <TabPanel>
        <LessonOverview isClub={isClub} />
      </TabPanel>
      <TabPanel>
        <EquipmentRentOverView />
      </TabPanel>
      <TabPanel>
        <UserSchedule />
      </TabPanel>
      <TabPanel>
        <UserEquipmentRentsScheduler />
      </TabPanel>
      <TabPanel>
        <UserInvoiceData />
      </TabPanel>
      <TabPanel>
        <UserFavouriteSports />
      </TabPanel>
      <TabPanel>
        <BookingOverview />
      </TabPanel>
      <TabPanel>
        <UserPaymentMethodsOverview />
      </TabPanel>
    </Tabs>
  );
};
